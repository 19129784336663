import React, { useEffect, useState } from "react";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import Http from "../services/Http";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormSelect,
  Row,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Star from "../assets/img/star_icon.png";
import artisanImg from "../assets/img/star_icon.png";
import { Link, useLocation } from "react-router-dom";
import like from "../assets/img/love.png";
import disLike from "../assets/img/like.png";
import ImgFilterIc from "../assets/img/filter_ic.png";
import ImgCross from "../assets/img/cross.png";
import featuredBadge from "../assets/img/featured_badge.png";
import MultiRangeSlider from "./Component/MultiRangeSlider";
import { useTranslation } from "react-i18next";
const center = { lat: 48.8584, lng: 2.2945 };

const BrowseArtisans = () => {
  const { t, i18n } = useTranslation();
  let lang = localStorage.getItem("lang");
  // const [map, setMap] = useState(/**@type google.maps.Map */ (null));
  // const [jobLocationRadio, setJobLocationRadio] = useState("artisansPlace");
  // const [userLocationRadio, setUserLocationRadio] = useState("home");

  // const onJobLocationSelect = (e) => {
  //   setJobLocationRadio(e.target.value);
  // };
  // const onUserLocationSelect = (e) => {
  //   setUserLocationRadio(e.target.value);
  // };

  const [artisans, setArtisans] = useState();
  const [categories, setCategories] = useState();
  const [subCategory, setSubCategory] = useState();
  const [categoryID, setCategoryID] = useState();
  const [rating, setRating] = useState("");
  const [hover, setHover] = useState(0);
  const [subCategoryID, setSubCategoryID] = useState();
  const [artisanLevel, setArtisanLevel] = useState();
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [filterFun, setFilterFun] = useState(false);
  const [filterData, setFilterData] = useState();
  const [search, setSearch] = useState();
  const [sort, setSort] = useState();
  const [favArtisan, setFavArtisan] = useState(false);
  const [favVal, setFavVal] = useState(true);
  const { state } = useLocation();
  // console.clear();

  console.log("state?.is_featured", state?.is_featured);

  useEffect(() => {
    getAllArtisans(state);
    getCategories();
  }, [favArtisan, filterFun, sort]);

  useEffect(() => {
    if (!state?.categoryId) return;
    setCategoryID(state?.categoryId);
  }, [state]);

  useEffect(() => {
    if (!categoryID) return;
    setSubCategoryID("");
    getSubCategoryList(categoryID);
  }, [categoryID]);

  const onExpertiseSelect = (e) => {
    // console.log(e.target.value);
    setArtisanLevel(e.target.value);
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  const onSubCatergorySelect = async (e) => {
    console.log(e.target.value, "target");
    setSubCategoryID(e.target.value);
    // console.log(subCategoryID, "subCat");
  };

  /* CATEGORY LIST */
  const getCategories = async () => {
    let path = apiPath.categoryList;
    let options = {
      method: "GET",
    };
    let res = await Http(path, options);
    if (res.status == 200) {
      setCategories(res.data.result.allCategory);
      // console.log(res.data.result);
    } else {
    }
  };

  /* SUB-CATEGORY LIST*/
  const getSubCategoryList = async (value) => {
    let path = apiPath.sub_category_list;
    var formData = new FormData();

    formData.append("categoryId", value);
    let options = {
      method: "POST",
      data: formData,
    };
    try {
      let res = await Http(path, options);
      // console.log(res, "-------------------------");
      if (res.data.success === true) {
        // console.log(artisans);

        setSubCategory(res.data.result);
        // console.log(res.data.result);
      } else {
        toast.error(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  console.log("state?.categoryId", subCategoryID);

  /* ARTISAN LIST */
  const getAllArtisans = async (state) => {
    let path = apiPath.allArtisans;
    console.log("Get Artisan :", state);
    const data = {
      categoryId: categoryID !== undefined ? categoryID : state?.categoryId,
      subcategoryId: subCategoryID ? subCategoryID : null,
      artisan_level: artisanLevel ? artisanLevel : "",
      price: null, //`${minPrice}, ${maxPrice}` ? `${minPrice}, ${maxPrice}` :
      keyword: search ? search : "",
      rating: `${rating}` ? `${rating}` : null,
      sort: sort ? sort : "AtoZ",
      is_featured: state?.is_featured !== undefined ? true : false,
      slots: state?.time_slot,
      date: state?.date,
    };

    let options = {
      method: "POST",
      data: state ? state : data,
    };

    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        console.log("Shree Ram", res.data.result.artisanList);
        setArtisans(res?.data?.result?.artisanList);
        // toast.success(res.data.msg);
      } else {
        toast.error(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const userToken = localStorage.getItem("userToken");

  const resetData = () => {
    const resetData = {
      artisan_level: "",
      is_featured: false,
      keyword: "",
      price: null,
      rating: null,
      sort: "AtoZ",
      subcategoryId: "",
    };
    setRating("");
    setHover(0);
    setSort("");
    setCategoryID("");
    setSubCategoryID("");
    getAllArtisans(resetData);
  };

  const markFavorite = async (id, isFev, index) => {
    // let item = { ...artisans.docs[index] };
    // let fev_val = isFev == null ? "1" : "0";
    // console.log("===============isFev========", isFev);
    if (userToken) {
      const path = apiPath.markFavorite;
      const data = {
        fev_type: "Artisan",
        fev_id: `${id}`,
        fev_val: isFev == null ? "1" : "0",
      };
      const options = { method: "POST", data };

      try {
        const res = await Http(path, options);
        if (res?.data?.success === true) {
          // if (fev_val == "0") {
          //   item.fevData = null;
          // }
          setFavArtisan(!favArtisan);

          console.log(res.data.result);
          toast.success(res?.data?.msg);
        } else {
          console.log(res?.data?.msg);
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Login Required!");
    }
  };

  const sortBy = [
    { title: "ASC", value: "AtoZ" },
    { title: "DESC", value: "ZtoA" },
    // { title: "Price_ASC", value: "price_ASC" },
    // { title: "Price_DESC", value: "price_DESC" },
  ];

  const handleSortBy = (e) => {
    setSort(e.target.value);
  };

  const onFilterClick = () => {
    getAllArtisans();
    toast.success("__Artisan_Filtered__");
  };

  console.log("subCategoryID", subCategoryID, state?.orderType);

  return (
    <>
      <Toaster position="top-right" />
      <section className="browse-artisan-sec">
        <div className="container">
          <div className="browse-artisan-main">
            <div
              className={`browse-artisan-left ${
                openDrawer ? "open_filter" : ""
              }`}
            >
              <div className="filter_main">
                <div className="filter_pop d-md-none">
                  <a
                    className="filter_icon"
                    onClick={() => setOpenDrawer(true)}
                  >
                    <img src={ImgFilterIc} /> Filter
                  </a>
                </div>
              </div>

              <div className="sidebar_bg">
                <button
                  type="button"
                  className="btn_cross d-md-none"
                  onClick={() => setOpenDrawer(false)}
                >
                  <img src={ImgCross} alt="Close" />
                </button>
                <div className="filter-top">
                  <h5>Filter</h5>
                  <Link to="#" onClick={resetData}>
                    Clear Filter
                  </Link>
                </div>
                <div className="sidebar_wrap">
                  {
                    <div className="sidebar_cont">
                      <h5>{t("category")}</h5>
                      <Form.Group className="form-group">
                        <FormSelect
                          className="form-control"
                          onChange={({ target }) => setCategoryID(target.value)}
                          value={categoryID}
                        >
                          <option value={""}>{t("Selectcategory")}</option>

                          {categories?.map((item) => (
                            <option value={item.id.toString()}>
                              {item.name}
                            </option>
                          ))}
                        </FormSelect>
                      </Form.Group>
                    </div>
                  }
                  <div className="sidebar_cont">
                    <h5>{t("sub_category")}</h5>
                    <Form.Group className="form-group">
                      <FormSelect
                        value={subCategoryID}
                        className="form-control"
                        onChange={onSubCatergorySelect}
                      >
                        <option value={" "}>{t("SelectSubcategory")}</option>

                        {subCategory?.map((item) => (
                          <option value={item.id.toString()}>
                            {lang == "en" ? item.name : item.fr_name}
                          </option>
                        ))}
                      </FormSelect>
                    </Form.Group>
                  </div>
                  {/* <div className="sidebar_cont">
                    <h5>Price</h5>
                    <div className="range_slider">
                      <MultiRangeSlider
                        min={0}
                        max={1000}
                        onChange={({ min, max }) => {
                          console.log(`min = ${min}, max = ${max}`);
                          setMinPrice(min);
                          setMaxPrice(max);
                        }}
                      />
                    </div>
                  </div> */}
                  <div className="sidebar_cont">
                    <h5>Rating</h5>
                    <div className="rating_inner">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              ("starBtn",
                              index <= (hover || rating) ? "on" : "off")
                            }
                            onClick={() => {
                              setRating(index);
                              console.log(index);
                            }}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                          >
                            <span className="star">&#9733;</span>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="sidebar_cont">
                    <h5>Expertise Level</h5>
                    <Form.Group className="form-group">
                      <FormSelect
                        className="form-control"
                        onChange={onExpertiseSelect}
                      >
                        <option>Select Option</option>
                        <option value="Freelancer">Freelancer</option>
                        <option>Certified Professional</option>
                      </FormSelect>
                    </Form.Group>
                  </div>
                  {/* <div className="sidebar_cont">
                    <div className="custom_checkbox">
                      <Form.Check
                        name="featured_artisan"
                        size={18}
                        label="Show only Featured Artisans"
                      />
                    </div>
                  </div> */}
                  <div className="login-btn">
                    <Button className="btn_primary" onClick={onFilterClick}>
                      Filter
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="browse-artisan-right">
              <div className="rightside_artisan_top">
                {artisans && <h5>{artisans.totalItems} Artisans</h5>}

                <div className="sort_by">
                  <Form.Group className="form-group">
                    <FormSelect
                      className="form-control"
                      value={sort}
                      onChange={(e) => handleSortBy(e)}
                    >
                      {sortBy?.map((itm, idx) => (
                        <option value={itm.value} key={idx}>
                          {itm.title}
                        </option>
                      ))}
                    </FormSelect>
                  </Form.Group>
                </div>
              </div>
              <div className="artisan_list_wrap">
                <Row>
                  {artisans?.map((item, index) => (
                    <Col md="12" lg="6">
                      <div className="artisan_bg">
                        <div className="artisan_left_img">
                          <div className="artisan_img">
                            {item?.image === undefined ||
                            item?.image === null ||
                            item?.image === "" ? (
                              <img src={artisanImg} alt="artisanImg" />
                            ) : (
                              <img
                                src={`${IMAGE_PATH + item.image}`}
                                alt="artisanImg"
                              />
                            )}
                          </div>
                          <span className="like_img">
                            <Link to="#">
                              <img
                                onClick={() =>
                                  markFavorite(item.id, item.fevData, index)
                                }
                                src={item.fevData?.id ? like : disLike}
                                alt="likeImg"
                              />
                            </Link>
                          </span>
                        </div>

                        {item?.is_featured === "Yes" && (
                          <span className="features_badge">
                            <img key={item.id} src={featuredBadge} />
                          </span>
                        )}

                        <div className="artisan_right_cont">
                          <div className="tag">
                            <span>Most Active</span>
                          </div>
                          <div className="artisan_title">
                            <h5>
                              {item.name}
                              <span>, {item?.artisan_level}</span>
                            </h5>
                          </div>
                          <div className="jobs_review">
                            <div className="job_cont">
                              <span>
                                #{item.job_done === null ? "No" : item.job_done}{" "}
                                {t("Jobs")}
                              </span>
                            </div>
                            <div className="review_cont">
                              <span className="review-ic">
                                <img src={Star} alt="" />
                              </span>
                              {/* {} */}
                              <span>{item.rating}</span>
                            </div>
                          </div>
                          <div className="artisan_price_wrap">
                            {/* <div className="artisan_price_left">
                              <h5>€ 100.00</h5>
                            </div> */}

                            <div className="artisan_view_btn">
                              <Link
                                to={`/order-by-artisan/${item.id}`}
                                state={state}
                              >
                                <Button className="btn_primary">
                                  View Detail
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BrowseArtisans;

import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import toast, { Toaster } from "react-hot-toast";
import Imgcross from "../../../assets/img/cross.png";

const ChangePassword = ({ onClose }) => {
  const [showPassword, setShowPassword] = useState(true);
  const [isRevealOldPwd, setIsRevealOldPwd] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCnfPwd, setIsRevealCnfPwd] = useState(false);

  const navigate = useNavigate();

  const [input, setInput] = useState({
    old_password: "",
    password: "",
    cpassword: "",
  });

  const [error, setError] = useState({
    old_password: "",
    password: "",
    cpassword: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
    setError(validate(input));
  };

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "old_password":
          if (!value) {
            stateObj[name] = "Please enter Old Password.";
          }
          break;
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.cpassword && value !== input.cpassword) {
            stateObj["cpassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["cpassword"] = input.cpassword ? "" : error.cpassword;
          }
          break;

        case "cpassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const validate = (value) => {
    const error = {};
    const regex = /^.{6,}$/g;
    if (!value.old_password?.length) {
      error.old_password = "Please enter Old Password.";
    }
    if (!value.password?.length) {
      error.password = "Please enter Password.";
    }
    if (!regex.test(value.password)) {
      error.password = "Must contain at 6 character.";
    }
    if (!value.cpassword?.length) {
      error.cpassword = "Please enter Confirm Password.";
    } else if (value.password !== value.cpassword) {
      error.cpassword = "Password and Confirm Password does not match.";
    }
    return error;
  };

  const onSubmit = async (e) => {
    console.log(input);
    e.preventDefault();

    setError(validate(input));
    if (
      input.old_password &&
      input.password &&
      input.cpassword !== "" &&
      input.password === input.cpassword
    ) {
      let path = apiPath.changePassword;
      const data = {
        old_password: input.old_password,
        password: input.password,
        cpassword: input.cpassword,
      };
      let options = {
        method: "POST",
        data: data,
      };
      try {
        let res = await Http(path, options);
        if (res?.data?.success === true) {
          console.log(res);
          toast.success(res?.data?.msg);
          setTimeout(() => {
            onClose();
          }, 1000);
        } else {
          toast.error(res?.data?.msg);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        show={showPassword}
        onHide={handleClose}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 id="exampleModalLabel">Change Password</h5>
          <button type="button" className="btn-close" onClick={handleClose}>
            <img src={Imgcross} onClick={handleClose} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group">
            <div className="pw-cls">
              <input
                type={isRevealOldPwd ? "text" : "password"}
                name="old_password"
                placeholder="Enter Old Password"
                value={input.old_password}
                onChange={onInputChange}
                onKeyUp={validateInput}
                className="form-control"
              />
              <button
                onClick={() => setIsRevealOldPwd((prevState) => !prevState)}
                className="btn-dark"
              >
                {isRevealOldPwd ? "hide" : "show"}
              </button>
            </div>
            {error.password && (
              <span className="error_msg">{error.old_password}</span>
            )}
          </Form.Group>
          <Form.Group className="form-group">
            <div className="pw-cls">
              <input
                type={isRevealPwd ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                value={input.password}
                onChange={onInputChange}
                onKeyUp={validateInput}
                className="form-control"
              />
              <button
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
                className="btn-dark"
              >
                {isRevealPwd ? "hide" : "show"}
              </button>
            </div>
            {error.password && (
              <span className="error_msg">{error.password}</span>
            )}
          </Form.Group>
          <Form.Group className="form-group">
            <div className="pw-cls">
              <input
                type={isRevealCnfPwd ? "text" : "password"}
                name="cpassword"
                placeholder="Enter Confirm Password"
                value={input.cpassword}
                onChange={onInputChange}
                onKeyUp={validateInput}
                className="form-control"
              />
              <button
                onClick={() => setIsRevealCnfPwd((prevState) => !prevState)}
                className="btn-dark"
              >
                {isRevealCnfPwd ? "hide" : "show"}
              </button>
            </div>

            {error.cpassword && (
              <span className="error_msg">{error.cpassword}</span>
            )}
          </Form.Group>
          <div className="login-btn">
            <button className="continue-btn d-block" onClick={onSubmit}>
              Continue
            </button>
          </div>

          <div className="row">
            <div className="col-sm-4"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangePassword;

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, Col, Form, FormControl, Modal, Row } from "react-bootstrap";
import avatar from "../../../assets/img/profile-logo.png";
import Imgcross from "../../../assets/img/cross.png";
import Locate from "../../../assets/img/locate_ic.png";
import mapStyle from "../../../assets/mapStyle";
import apiPath, { baseURL, IMAGE_PATH } from "../../../constants/apiPath";
import Http from "../../../services/Http";
import toast, { Toaster } from "react-hot-toast";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import DatePicker from "react-datepicker";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import Login from "./Login";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const libraries = ["places"];

const Profile = ({ pass, onClose }) => {
  const initialValues = {
    username: "",
    email: "",
    dob: new Date(2011, 10, 30),
    gender: "",
    location: "",
    streetname: "",
    acceptAndContinue: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [dob, setDob] = useState(formValues.dob);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [genderRadio, setGenderRadio] = useState();
  const [showProfile, setShowProfile] = useState(true);
  const [TnC, setTnC] = useState(false);
  const [file, setFile] = useState();
  const [address, setAddress] = useState("");
  const [residanceAddress, setResidanceAddress] = useState("");
  const [latLong, setlatLong] = useState({ lat: 48.8584, lng: 2.2945 });
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const fileSelect = (e) => {};

  const handleLocationSelect = async (value) => {
    console.log(value);
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(results[0]?.formatted_address);
    setResidanceAddress(results);

    // console.log(ll);
    setAddress(value);
    setCoordinates(ll);
  };

  console.log("pass", pass);

  const handleClose = () => {
    setShowProfile(false);
    onClose();
    console.log("closed!");
  };

  const onGenderRadioSelect = (e) => {
    setGenderRadio(e.target.value);
  };
  const profileClose = () => {
    setShowProfile(false);
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    console.log(errors, "errors");
    if (Object.keys(errors).length < 1) {
    } else {
      return setFormErrors(errors);
    }

    //Object.keys(errors).length < 1
    // return;
    let path = apiPath.profileInfo;
    var formData = new FormData();
    formData.append("user_type", "2");
    formData.append("name", formValues.username);
    formData.append("email", formValues.email);
    formData.append("password", pass);
    formData.append("image", file);
    formData.append("gender", genderRadio);
    formData.append("dob", moment(dob).format("MM/DD/YYYY"));
    formData.append(
      "residence_address",
      residanceAddress[0]
        ? residanceAddress[0]?.formatted_address
          ? residanceAddress[0]?.formatted_address
          : null
        : null
    );
    formData.append(
      "city",
      residanceAddress[0]
        ? residanceAddress[0]?.address_components[
            residanceAddress[0]?.address_components?.length - 4
          ]?.long_name
        : ""
    );
    formData.append(
      "state",
      residanceAddress[0]
        ? residanceAddress[0]?.address_components[
            residanceAddress[0]?.address_components.length - 3
          ]?.long_name
        : ""
    );
    formData.append("latitude", coordinates.lat);
    formData.append("longitude", coordinates.lng);

    let options = {
      method: "POST",
      data: formData,
    };

    if (TnC && formValues.username !== "" && formValues.email !== "") {
      try {
        let res = await Http(path, options);
        console.log(res?.data?.success === true);
        if (res?.data?.success === true) {
          console.log("Profile Created Successfully", res.data);

          toast.success(res.data.msg);
          setTimeout(() => {
            profileClose();
          }, 1000);
          navigate("/");
        } else {
          toast.error(res.data.msg);
        }
      } catch (err) {
        console.log(err);
        console.log("api hit but some error appeared");
      }
    }
  };

  const handleOnblur = () => {
    inputRef.current.focus();
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setlatLong({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        console.log(latLong);
      });
    }
  }, []);

  const validate = (values) => {
    // console.log(values);
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!values.username) {
      errors.username = "Name is required";
    }

    if (!values.email) {
      errors.email = "Email Address is required";
    } else if (!regex.test(values.email)) {
      errors.email = "Please enter valid email";
    }

    if (!values.dob) {
      errors.dob = "Dob is required";
    }

    if (!genderRadio) {
      errors.gender = "Gender is required";
    }

    if (!residanceAddress.length) {
      errors.streetname = "Street Address is required";
    }

    if (!TnC) {
      errors.acceptAndContinue = "Accept Terms And Conditions is required";
    }

    if (Object.keys(errors).length < 1) {
      setIsSubmit(true);
      return errors;
    }
    return errors;
  };
  const onHandleClick = () => {
    setShowProfile(false);
  };

  /** formik doensn't support file upload so we need to create this handler */
  const onUpload = async (e) => {
    const img = e.target.files[0];
    const fileRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    console.log("img", img);
    console.log(img);
    if (img !== null && img !== "undefined" && img !== "") {
      console.log("File extension is valid.");
      let formData = new FormData();
      formData.append("files", img);
      const path = apiPath.docUpdate;
      const options = { method: "POST", data: formData };
      if (fileRegex.test(img?.name)) {
        try {
          let res = await Http(path, options);
          if (res?.data?.success === true) {
            const { image_url } = res?.data?.result;
            setFile(image_url);
            toast.success(res?.data?.msg);
          } else {
            toast.error(res?.data?.msg);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.error("Please select a valid image format!");
      }
    } else {
      console.log("File extension is not valid.");
      toast.error("This file is not valid!");
    }
  };

  // map functionality
  let libRef = React.useRef(libraries);
  const [map, setMap] = useState(/**@type google.maps.Map */ (null));
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries,
  });
  if (!isLoaded) {
    return <h2>Loading maps</h2>;
  }

  console.log("password", pass);

  return (
    <>
      <Modal
        show={showProfile}
        onHide={onHandleClick}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="profileModalStyle"
        centered
      >
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            onClick={() => toast.error("Please fill the Profile Details")}
          >
            <img src={Imgcross} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_cont">
            <h4>Welcome!</h4>
            <h5>Complete Profile</h5>
            <p>Setup your profile</p>
          </div>
          <form>
            <div className="profile-section">
              <label htmlFor="profile">
                <span className="profile_img">
                  {file ? (
                    <img
                      src={`${file}` ? `${IMAGE_PATH}${file}` : avatar}
                      alt="avatar"
                      onChange={fileSelect}
                    />
                  ) : (
                    <img src={avatar} alt="avatar" onChange={fileSelect} />
                  )}
                </span>
              </label>
              <input
                type="file"
                id="profile"
                name="profile"
                onChange={onUpload}
                accept=".png, .jpeg, .jpg"
              />
              <span className="profile-label fw-bold">
                Upload your picture here
              </span>
            </div>
            <Row>
              <Col md="6">
                <Form.Group className="form-group">
                  <label>Name</label>
                  <FormControl
                    type="text"
                    tabIndex={1}
                    placeholder="Name"
                    value={formValues.username}
                    onChange={handleChange}
                    name="username"
                    ref={inputRef}
                    autoComplete="off"
                  />
                  <span className="error_msg">{formErrors.username}</span>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="form-group">
                  <label>Email Address</label>
                  <FormControl
                    type="text"
                    placeholder="Email"
                    value={formValues.email}
                    onChange={handleChange}
                    name="email"
                    autoComplete="off"
                  />
                  <span className=" error_msg">{formErrors.email}</span>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="form-group">
                  <label>D.O.B.</label>
                  <DatePicker
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    name="dob"
                    filterDate={(date) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);
                      return date.getTime() < today.getTime();
                    }}
                    selected={dob}
                    onChange={(date) => setDob(date)}
                    dateFormat="MM/dd/yyyy"
                    autoComplete="off"
                  />
                  <span className="error_msg">{formErrors.dob}</span>
                </Form.Group>
              </Col>
              <Col md="6">
                <div className="artisans_radioBtn">
                  <Form.Group className="form-group">
                    <label htmlFor="">Gender</label>
                    <div className="radio_cls">
                      <label className="custom_radio">
                        <input
                          type="radio"
                          id="radio"
                          value="Male"
                          checked={genderRadio === "Male"}
                          onChange={onGenderRadioSelect}
                        />
                        Male
                        <span className="checkmark"></span>
                        <span className="error_msg">{formErrors.gender}</span>
                      </label>
                      <label className="custom_radio">
                        <input
                          type="radio"
                          className="mx-2"
                          id="radio"
                          value="Female"
                          checked={genderRadio === "Female"}
                          onChange={onGenderRadioSelect}
                        />
                        Female
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </Form.Group>
                </div>
              </Col>

              <Col md="6">
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleLocationSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <Form.Group className="form-group">
                        <label htmlFor="">Street Address</label>
                        <input
                          {...getInputProps({
                            placeholder: "Search Places ...",
                            className: "form-control",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                        <span className="error_msg">
                          {address === "" || address === null
                            ? formErrors.streetname
                            : ""}
                        </span>
                      </Form.Group>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>

            <div className="orSection">
              <span className="orSectionLine">OR</span>
            </div>
            <div className="mapPinLocation">
              <div className="map-title">
                <h5>Pin your Location</h5>
                <h6 onClick={() => map?.panTo(latLong)}>
                  <span className="locate_icon">
                    <img src={Locate} />
                  </span>
                  <span>Locate yourself</span>
                </h6>
              </div>
              <div className="car mapcard">
                <GoogleMap
                  center={coordinates}
                  zoom={20}
                  mapContainerClassName="googleMap"
                  onLoad={(map) => setMap(map)}
                  options={{ styles: mapStyle.light }}
                >
                  <Marker position={latLong} />
                  <Marker position={coordinates} />
                </GoogleMap>
              </div>
            </div>
            <div className="remember_me_sec">
              <Form.Group className="form-group" htmlFor="acceptme">
                <div className="custom_checkbox">
                  <Form.Check
                    name="accepts_conditions"
                    label="Accepts our Policies And Terms and Conditions."
                    value={TnC}
                    onChange={(e) => setTnC(e.target.checked)}
                    id="acceptme"
                  />
                </div>
                {TnC === false && (
                  <span className="error_msg">
                    {formErrors.acceptAndContinue}
                  </span>
                )}
              </Form.Group>
            </div>
            <div className="login-btn">
              <Button className="btn_primary" onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Profile;

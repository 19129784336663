import React from "react";
import { Button, Modal } from "react-bootstrap";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import toast, { Toaster } from "react-hot-toast";
import Imgcross from "../../../assets/img/cross.png";

const InviteFriends = ({ open, close }) => {
  const invite = (type) => {
    let url = window.location.href + "product-detail/";
    let title = `Hey there! \nI love using TOCTOCME and thought you might too! \nCheck out this awesome mobile app for all your home service needs in just one click. \nAndroid ${localStorage.getItem(
      "playStore"
    )} and IOS ${localStorage.getItem(
      "appStore"
    )} \nLet's be a part of TOCTOCME and enjoy home services with ease.\n Thanks`;
    if (type == "facebook") {
      window.open(
        "https://facebook.com/sharer.php?u=" +
          url +
          "?id=" +
          24 +
          "&quote=" +
          title
      );
    }

    if (type == "twitter") {
      window.open(
        "https://twitter.com/share?url=" + url + "?id=" + 24 + "&text=" + title
      );
    }

    if (type == "whatsapp") {
      window.open("https://web.whatsapp.com/send?text=" + title);
    }

    if (type == "linnkedin") {
      window.open("https://web.whatsapp.com/send?text=" + title);
    }
  };
  return (
    <>
      <Toaster position="top-right" />
      <Modal
        show={open}
        onHide={close}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 id="exampleModalLabel">Invite Friends</h5>
          <button type="button" className="btn-close" onClick={close}>
            <img src={Imgcross} onClick={close} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-between">
            <div className="col-sm-4">
              <Button
                className="btn btn_primary"
                onClick={() => invite("facebook")}
              >
                Facebook
              </Button>
            </div>
            <div className="col-sm-4">
              <Button
                className="btn btn_primary"
                onClick={() => invite("whatsapp")}
              >
                Whats App
              </Button>
            </div>
            <div className="col-sm-4">
              <Button
                className="btn btn_primary"
                onClick={() => invite("twitter")}
              >
                Twitter
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InviteFriends;

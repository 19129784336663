import React, { useEffect, useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import { Button, Container } from "react-bootstrap";
import ArtisanImg from "../../../assets/img/artisan1.png";
import { Link, useLocation } from "react-router-dom";
import Star from "../../../assets/img/star_icon.png";
import Close from "../../../assets/img/cross.png";
import Map from "../../../assets/img/google-map.png";
import apiPath, { IMAGE_PATH } from "../../../constants/apiPath";
import Http from "../../../services/Http";
import Coupon from "../../../assets/img/coupon.png";
import gmapImg from "../../../assets/img/google-map.png";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { calculateDistance } from "../../../helpers/functions";
import { ChangeAddress } from "../../Checkout";
import { useTranslation } from "react-i18next";
import GMap from "../../Component/GMap";

const Checkout = () => {
  const { t, i18n } = useTranslation();
  const [preview, setPreview] = useState();
  const [isCoupon, setIsCoupon] = useState(true);
  const [services, setServices] = useState();
  const [distance, setDistance] = useState();
  const [modal, showModal] = useState(false);
  const [userLatLng, setUserLatLng] = useState({ lat: 0, lng: 0 });
  const [artisanLatLng, setArtisnLatLng] = useState({ lat: 0, lng: 0 });

  const [selectedAddress, setSelectedAdress] = useState();
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("state", state);

  const onToken = (token) => {
    console.log(token);
  };

  console.log("preview", preview);

  const confirmCheckout = async () => {
    let path = apiPath.confirmCheckout;
    var formData = new FormData();
    formData.append("cart_id", preview?.id);

    let options = { method: "POST", data: formData };

    try {
      let res = await Http(path, options);
      console.log(res);
      toast.success(res?.data?.msg);
      const { orderNo } = res?.data?.result;
      localStorage.removeItem("cart_item");
      navigate("/thank-you", { state: { bookingNo: orderNo } });
    } catch (err) {
      console.log(err);
    }
  };

  const getPreview = async () => {
    let path = apiPath.getPreview;
    let data = { order_type: "Soft" };

    let options = {
      method: "POST",
      data: data,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        // toast.success(res.data.msg);
        // console.log(
        //   res.data.result.cartData.products?.map(
        //     (item) => item.serviceData.subcatData.id
        //   )
        // );
        setPreview(res.data.result.cartData);

        setUserLatLng({
          lat: res?.data?.result?.cartData?.addressData?.latitude,
          lng: res?.data?.result?.cartData?.addressData?.longitude,
        });
        setArtisnLatLng({
          ...artisanLatLng,
          lat: res?.data?.result?.cartData?.artisanData?.latitude,
          lng: res?.data?.result?.cartData?.artisanData?.longitude,
        });

        setServices(res.data.result.cartData.products);
        const { cartData } = res.data.result;
        const dist = calculateDistance(
          cartData?.artisanData?.latitude,
          cartData?.artisanData?.longitude,
          cartData?.addressData?.latitude,
          cartData?.addressData?.longitude
        );

        setDistance(dist);
        setSelectedAdress(res.data.result.cartData.addressId);
      } else {
        // toast.error(res.data.msg);
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  useEffect(() => {
    getPreview();
  }, [!isCoupon, refresh, !modal]);

  const serviceName = services?.map((item) => item.serviceData.subcatData.name);
  console.log("serviceName", serviceName);

  const removeCoupon = async () => {
    const path = apiPath.remove_promo_code;
    const data = {
      promocode_id: `${state?.couponId}`,
      cart_id: `${preview?.id}`,
    };
    const options = { method: "POST", data };
    try {
      const res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setIsCoupon(false);
        await getPreview();
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = () => {
    return new Promise(async (resolve, reject) => {
      let path = apiPath.setPreview;

      const cartItems = JSON.parse(localStorage.getItem("cart_item"));
      const { selectedServices, ...data } = cartItems;

      console.log(data.address_id, selectedAddress);

      if (data.address_id == selectedAddress) return resolve();

      data.address_id = selectedAddress;

      let options = {
        method: "POST",
        data,
      };

      try {
        let res = await Http(path, options);
        if (res.data.success === true) {
          setRefresh((prev) => !prev);
          toast.success(res.data.msg);
          localStorage.setItem(
            "cart_item",
            JSON.stringify({ ...cartItems, address_id: selectedAddress })
          );
          resolve(res.data);
        } else {
          toast.error(res.data.msg);
          reject();
        }
      } catch (err) {
        reject();
        console.log(err.res);
      }
    });
  };

  ///Google Map
  const latLngArr = [
    {
      lat: userLatLng.lat,
      lng: userLatLng.lng,
    },
    { lat: artisanLatLng.lat, lng: artisanLatLng.lng },
  ];
  const routeLatLng = latLngArr?.map((item) => {
    const latLng = [];
    latLng?.push(item?.lat);
    latLng?.push(item?.lng);
    return latLng;
  });
  return (
    <>
      <Toaster position="top-right" />{" "}
      <section className="checkout-main">
        <Container>
          <div className="checkout-inner">
            <div className="checkout_left">
              <div className="checkout_bg">
                {preview?.artisanData && (
                  <div className="checkout_cont">
                    <h3>{serviceName[0]}</h3>
                    <div className="artisan_bg">
                      <div className="artisan_left_img">
                        <div className="artisan_img">
                          <img
                            src={`${IMAGE_PATH + preview?.artisanData?.image}`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="artisan_right_cont artisan-dtl-cont">
                        <div className="artisan_title">
                          <h5>
                            {preview?.artisanData?.name},{" "}
                            <span>{preview?.artisanData?.artisan_level}</span>
                          </h5>
                        </div>
                        <div className="jobs_review">
                          <div className="job_cont">
                            <span>
                              #
                              {preview?.artisanData?.job_done === null
                                ? t("NoJobs")
                                : preview?.artisanData?.job_done}{" "}
                              {t("Jobs")}
                            </span>
                          </div>
                          <div className="review_cont">
                            <span className="review-ic">
                              <img src={Star} alt="" />
                            </span>
                            <span>{preview?.artisanData?.rating}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="checkout_selected_serv">
                      <h5>{t("you_have_selected_service")}</h5>
                      {services?.map((item, idx) => (
                        <div key={idx} className="signle_select_serv">
                          <div className="serv_no">
                            <span>{idx + 1}.</span>
                          </div>
                          <div className="serv_desc">
                            <h4>{item?.serviceData?.name}</h4>
                            <p>€ {item?.total_amount}</p>
                          </div>
                          {/* <div className="serv_edit_option">
                            <Button
                              className="black_btn"
                              onClick={() =>
                                navigate(
                                  `/order-by-artisan/${preview?.artisanData?.id}`,
                                  {
                                    state: {
                                      addressId: preview
                                        ? preview?.addressId
                                        : "",
                                      email: preview ? preview?.email_id : "",
                                      mobile: preview ? preview?.mobile_no : "",
                                      date: preview
                                        ? preview?.service_date
                                        : "",
                                      time_slot: preview
                                        ? preview?.time_slot
                                        : "",
                                      scheduling: preview
                                        ? preview?.service_type
                                        : "",
                                      services: services ? services : "",
                                    },
                                  }
                                )
                              }
                            >
                              Edit
                            </Button>
                          </div> */}
                        </div>
                      ))}
                    </div>
                    {preview?.order_type === "Soft" && (
                      <div className="customer_select_address">
                        <h3>{t("address_selected_by_customer")}</h3>
                        <p>
                          {preview?.addressData?.house_number},{" "}
                          {preview?.addressData?.address}
                        </p>

                        <div className="serv_edit_option d-flex justify-content-between align-items-center mt-2">
                          <Button
                            className="black_btn"
                            onClick={() => showModal(true)}
                          >
                            {t("change")}
                          </Button>
                          <div className="gmap-wrap d-flex gap-2 align-items-center">
                            {distance && (
                              <p className="mb-0">
                                {t("Distance")} : {distance} KM
                              </p>
                            )}
                            <img src={gmapImg} alt="gmapImg" />
                          </div>
                        </div>
                      </div>
                    )}
                    {preview?.artisanData?.residence_address && (
                      <div className="customer_select_address">
                        <h3>{t("artisan_location")}</h3>
                        <p>{preview?.artisanData?.residence_address}</p>
                        {distance && (
                          <p style={{ fontWeight: "bold" }}>
                            {t("Distance")} : {distance} KM
                          </p>
                        )}
                      </div>
                    )}{" "}
                    <div className="customer_select_address">
                      <div className="manage_address_inner">
                        <div className="manage_address_listing">
                          <div className="time_map">
                            <div className="map_card ">
                              <GMap latLng={routeLatLng} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="customer_select_address">
                      <h3>{t("TimeSlotSelectedbyTheCustomer")}</h3>
                      <p>
                        {preview?.service_date}, {preview?.time_slot}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="checkout-right">
              <div className="checkout-bg">
                <div className="checkout-coupon">
                  {preview?.discountAmount > 0 && isCoupon ? (
                    <>
                      <div className="coupon-code-btn">
                        <span>{preview?.discountCode}</span>
                        <span className="cross_img cursor-pointer">
                          <img onClick={() => removeCoupon()} src={Close} alt />
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <span className="coupon_icon">
                        <img src={Coupon} alt="" />
                      </span>
                      <Link
                        to="/coupon-by-artisan"
                        state={{ cartId: preview?.id, by: "artisan" }}
                      >
                        <span className="apply_coupon">
                          {t("apply_coupon")}
                        </span>
                      </Link>
                    </>
                  )}{" "}
                </div>

                <div className="card_detail">
                  {/* <h2>Enter Card Detail</h2>
                  <div className="checout_form">
                    <Row>
                      <Col md="12">
                        <Form.Group className="form-group">
                          <label>Name on Card</label>
                          <FormControl></FormControl>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group className="form-group">
                          <label>Enter Card Number</label>
                          <FormControl></FormControl>
                        </Form.Group>
                      </Col>
                      <Col md="8">
                        <Form.Group className="form-group">
                          <label>Exp. Date</label>
                          <FormControl></FormControl>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group className="form-group">
                          <label>CVV</label>
                          <FormControl></FormControl>
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="login-btn chekout-btn">
                      <StripeCheckout
                      className="btn btn_primary"
                        token={onToken}
                        stripeKey="pk_test_51MoojsSEfNkFEWJTv1qNAapxcse2qkcqY2oaJmjNXO7U1niavwdCIX53WXxjBVLhzh81aOWgmPToVQZwGd0wWezb00rGRRehlN"
                      />
                    </div>
                    <Button className="btn_primary">Pay Now</Button>
                  </div> */}
                  <div className="price-specification">
                    <h3>{t("price_specification")}</h3>
                    <ul>
                      <li>
                        <span className="checkout-price-left">
                          {t("ServiceCharge")}
                        </span>
                        <span className="checkout-price-right">
                          € {preview?.orgAmount}
                        </span>
                      </li>
                      {preview?.discountAmount && (
                        <li>
                          <span className="checkout-price-left">
                            {t("Discount")}
                          </span>
                          <span className="checkout-price-right">
                            - € {preview?.discountAmount}
                          </span>
                        </li>
                      )}

                      {parseFloat(preview?.emergencyAmount) > 0 && (
                        <li>
                          <span className="checkout-price-left">
                            {t("EmergencyAmount")}
                          </span>
                          <span className="checkout-price-right">
                            € {preview?.emergencyAmount}
                          </span>
                        </li>
                      )}

                      {preview?.fee && (
                        <li>
                          <span className="checkout-price-left">Fee</span>
                          <span className="checkout-price-right">
                            € {preview?.fee}
                          </span>
                        </li>
                      )}

                      {preview?.taxes && (
                        <li>
                          <span className="checkout-price-left">Taxes</span>
                          <span className="checkout-price-right">
                            € {preview?.taxes}
                          </span>
                        </li>
                      )}
                      <li>
                        <span className="checkout-price-left">
                          {t("TotalAmount")}
                        </span>
                        <span className="checkout-price-right">
                          € {preview?.totalAmount}
                        </span>
                      </li>
                    </ul>
                    <div className="login-btn">
                      <Button className="btn_primary" onClick={confirmCheckout}>
                        {t("confirm_go")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {modal && (
          <ChangeAddress
            open={() => showModal(true)}
            show={modal}
            hide={() => showModal(false)}
            selectedAddress={selectedAddress}
            setSelectedAdress={setSelectedAdress}
            onContinue={() => onSubmit()}
            cartId={preview?.id}
          />
        )}
      </section>
    </>
  );
};
export default Checkout;

import React, { useEffect, useState } from "react";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import Http from "../../services/Http";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormSelect,
  Row,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "react-bootstrap";
import Star from "../../assets/img/star_icon.png";
import artisanImg from "../../assets/img/star_icon.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import like from "../../assets/img/love.png";
import disLike from "../../assets/img/like.png";
import ImgFilterIc from "../../assets/img/filter_ic.png";
import ImgCross from "../../assets/img/cross.png";
import MultiRangeSlider from "../Component/MultiRangeSlider";
import ArtisansNotFound from "../Component/modalComponents/ArtisansNotFound";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
const center = { lat: 48.8584, lng: 2.2945 };

const HeavyServiceArtisans = () => {
  const { t, i18n } = useTranslation();
  let lang = localStorage.getItem("lang");
  const [artisans, setArtisans] = useState();
  const [showArtisan, setShowArtisan] = useState(false);
  const [item, setItem] = useState();
  const [categories, setCategories] = useState();
  const [subCategory, setSubCategory] = useState();
  const [categoryID, setCategoryID] = useState();
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [subCategoryID, setSubCategoryID] = useState();
  const [artisanLevel, setArtisanLevel] = useState();
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [filterFun, setFilterFun] = useState(false);
  const [filterData, setFilterData] = useState();
  const [search, setSearch] = useState();
  const [sort, setSort] = useState();
  const [favArtisan, setFavArtisan] = useState(false);
  const [favVal, setFavVal] = useState(true);
  const [showContact, setShowContact] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { state } = useLocation();
  // console.clear();
  console.log(">>>>>>>>>>>state>>>>>>>>>>>>>", state);

  const heavyOrderData = JSON.parse(localStorage.getItem("heavyOrderData"));

  const onExpertiseSelect = (e) => {
    // console.log(e.target.value);
    setArtisanLevel(e.target.value);
  };
  const [openDrawer, setOpenDrawer] = useState(false);

  const onSubCatergorySelect = async (e) => {
    // console.log(e.target.value, "target");
    setSubCategoryID(e.target.value);
    // console.log(subCategoryID, "subCat");
  };

  /* CATEGORY LIST */
  const getCategories = async () => {
    let path = apiPath.getActiveCategoryList;
    let data = {
      type: "Heavy",
    };
    let options = {
      method: "POST",
      data,
    };
    let res = await Http(path, options);
    if (res.status == 200) {
      setCategories(res.data.result);
      // console.log(res.data.result);
    } else {
    }
  };

  /* SUB-CATEGORY LIST*/
  const getSubCategoryList = async (e) => {
    let path = apiPath.sub_category_list;
    var formData = new FormData();

    formData.append("categoryId", e.target.value);
    let options = {
      method: "POST",
      data: formData,
    };
    try {
      let res = await Http(path, options);
      // console.log(res, "-------------------------");
      if (res.data.success === true) {
        // console.log(artisans);
        setCategoryID(e.target.value);
        setSubCategory(res.data.result);
        // console.log(res.data.result);
      } else {
        toast.error(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  /* ARTISAN LIST */
  const getAllArtisans = async (resetData) => {
    let path = apiPath.allArtisans;
    // ? `${state?.catId}` !== undefined
    //       ? `${state?.catId}`
    //       : ""
    //     : `${categoryID}`,
    // console.log(subCategoryID);
    // ? `${state?.subCatId}` !== undefined
    //       ? `${state?.subCatId}`
    //       : ""
    //     : `${subCategoryID}`,
    const data = {
      categoryId: `${state?.catId === undefined ? "" : state?.catId}`,
      subcategoryId: `${state?.subCatId === undefined ? "" : state?.subCatId}`,
      artisan_level: artisanLevel ? artisanLevel : "",
      price: null, // `${minPrice}, ${maxPrice}` ? `${minPrice}, ${maxPrice}`
      keyword: search ? search : "",
      rating: `${rating}` ? `${rating}` : "",
      sort: sort ? sort : "ASC",
      pincode: state?.postalCode ? state?.postalCode : "",
    };

    let options = {
      method: "POST",
      data: resetData ? resetData : data,
    };

    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        if (res?.data?.result?.artisanList.totalItems === 0) {
          setShowArtisan(true);
        }
        console.log("Shree Ram", res.data.result.artisanList);
        setArtisans(res?.data?.result?.artisanList);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        // toast.success(res.data.msg);
      } else {
        toast.error(res.data.msg);
        setLoading(false);
      }
    } catch (err) {
      console.log(err.res);
      setLoading(false);
    }
  };

  const userToken = localStorage.getItem("userToken");

  const markFavorite = async (id, isFev) => {
    if (userToken) {
      const path = apiPath.markFavorite;
      const data = {
        fev_type: "Artisan",
        fev_id: `${id}`,
        fev_val: isFev == null ? "1" : "0",
      };
      const options = { method: "POST", data };

      try {
        const res = await Http(path, options);
        if (res?.data?.success === true) {
          setFavArtisan(!favArtisan);
          console.log(res.data.result);
          toast.success(res?.data?.msg);
        } else {
          console.log(res?.data?.msg);
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Login Required!");
    }
  };

  const reseData = () => {
    let data = {
      categoryId: "",
      subcategoryId: "",
      artisan_level: "",
      price: null,
      keyword: "",
      rating: "",
      sort: "",
      pincode: state?.postalCode ? state?.postalCode : "",
    };
    setCategoryID("");
    setSubCategoryID("");
    setRating(0);
    getAllArtisans(data);
  };

  const sortBy = [
    { title: "ASC", value: "AtoZ" },
    { title: "DESC", value: "ZtoA" },
    // { title: "Price_ASC", value: "price_ASC" },
    // { title: "Price_DESC", value: "price_DESC" },
  ];

  const handleSortBy = (e) => {
    setSort(e.target.value);
  };

  const onFilterClick = () => {
    getAllArtisans();
  };

  useEffect(() => {
    setLoading(true);
    getAllArtisans();
    getCategories();
  }, [!favArtisan, filterFun, sort]);

  const onShow = (val) => {
    setShowContact(true);
    setItem(val);
  };

  const onHide = () => {
    setShowContact(false);
  };

  const handleSlectTime = (val) => {
    onShow(val);
    // navigate(`/heavy-service-time/${item.id}`, {
    //   state: {
    //     heavyOrderData,
    //     item,
    //   },
    // });
  };

  console.log("heavyOrderData", heavyOrderData);

  return (
    <>
      {showArtisan && <ArtisansNotFound categoryId={state?.catId} />}
      {showContact && (
        <ContactDetail
          onShow={onShow}
          onHide={onHide}
          heavyOrderData={heavyOrderData}
          item={item}
        />
      )}
      <Toaster position="top-right" />
      <section className="browse-artisan-sec">
        <div className="container">
          <div className="browse-artisan-main">
            <div
              className={`browse-artisan-left ${
                openDrawer ? "open_filter" : ""
              }`}
            >
              <div className="filter_main">
                <div className="filter_pop d-md-none">
                  <a
                    className="filter_icon"
                    onClick={() => setOpenDrawer(true)}
                  >
                    <img src={ImgFilterIc} /> {t("filter")}
                  </a>
                </div>
              </div>

              <div className="sidebar_bg">
                <button
                  type="button"
                  className="btn_cross d-md-none"
                  onClick={() => setOpenDrawer(false)}
                >
                  <img src={ImgCross} alt="Close" />
                </button>
                <div className="filter-top">
                  <h5>{t("filter")}</h5>
                  <Link to="#" onClick={reseData}>
                    {t("reset")}
                  </Link>
                </div>
                <div className="sidebar_wrap">
                  <div className="sidebar_cont">
                    <h5>{t("category")}</h5>
                    <Form.Group className="form-group">
                      <FormSelect
                        className="form-control"
                        onChange={getSubCategoryList}
                      >
                        <option value={""}>{t("Selectcategory")}</option>

                        {categories?.map((item) => (
                          <option value={item.id.toString()}>
                            {lang == "en" ? item.name : item.fr_name}
                          </option>
                        ))}
                      </FormSelect>
                    </Form.Group>
                  </div>
                  <div className="sidebar_cont">
                    <h5>{t("sub_category")}</h5>
                    <Form.Group className="form-group">
                      <FormSelect
                        className="form-control"
                        onChange={onSubCatergorySelect}
                      >
                        <option value={" "}>{t("SelectSubcategory")}</option>

                        {subCategory?.map((item) => (
                          <option value={item.id.toString()}>
                            {lang == "en" ? item.name : item.fr_name}
                          </option>
                        ))}
                      </FormSelect>
                    </Form.Group>
                  </div>
                  {/* <div className="sidebar_cont">
                    <h5>Price</h5>
                    <div className="range_slider">
                      <MultiRangeSlider
                        min={0}
                        max={1000}
                        onChange={({ min, max }) => {
                          console.log(`min = ${min}, max = ${max}`);
                          setMinPrice(min);
                          setMaxPrice(max);
                        }}
                      />
                    </div>
                  </div> */}
                  <div className="sidebar_cont">
                    <h5>{t("rating")}</h5>
                    <div className="rating_inner">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              ("starBtn",
                              index <= (hover || rating) ? "on" : "off")
                            }
                            onClick={() => {
                              setRating(index);
                              console.log(index);
                            }}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                          >
                            <span className="star">&#9733;</span>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="sidebar_cont">
                    <h5>{t("expertise_level")}</h5>
                    <Form.Group className="form-group">
                      <FormSelect
                        className="form-control"
                        onChange={onExpertiseSelect}
                      >
                        <option value={""}>{t("SelectOption")}</option>
                        <option value="Freelancer">{t("Freelancer")}</option>
                        <option value="Certified Expert">
                          {t("CertifiedExpert")}
                        </option>
                      </FormSelect>
                    </Form.Group>
                  </div>
                  <div className="sidebar_cont">
                    <div className="custom_checkbox">
                      <Form.Check
                        name="featured_artisan"
                        size={18}
                        label={t("ShowonlyFeaturedArtisans")}
                      />
                    </div>
                  </div>
                  <div className="login-btn">
                    <Button className="btn_primary" onClick={onFilterClick}>
                      {t("filter")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="browse-artisan-right">
              <div className="rightside_artisan_top">
                {artisans && (
                  <h5>
                    {artisans.totalItems} {t("our_artisans")}
                  </h5>
                )}

                <div className="sort_by">
                  <Form.Group className="form-group">
                    <FormSelect
                      className="form-control"
                      value={sort}
                      onChange={(e) => handleSortBy(e)}
                    >
                      {sortBy?.map((itm, idx) => (
                        <option value={itm.value} key={idx}>
                          {itm.title}
                        </option>
                      ))}
                    </FormSelect>
                  </Form.Group>
                </div>
              </div>
              <div className="artisan_list_wrap">
                {loading ? (
                  [1, 2, 3].map((item) => <Skeleton active key={item} />)
                ) : (
                  <Row>
                    {artisans?.map((item, index) => (
                      <Col md="12" lg="6">
                        <div className="artisan_bg">
                          <div className="artisan_left_img">
                            <div className="artisan_img">
                              {item.image === undefined ||
                              item.image === null ||
                              item.image === "" ? (
                                <img src={artisanImg} alt="" />
                              ) : (
                                <img
                                  src={`${IMAGE_PATH + item.image}`}
                                  alt=""
                                />
                              )}
                            </div>
                            <span className="like_img">
                              <Link to="#">
                                <img
                                  onClick={() =>
                                    markFavorite(item.id, item.fevData, index)
                                  }
                                  src={item.fevData?.id ? like : disLike}
                                  alt="likeImg"
                                />
                              </Link>
                            </span>
                          </div>

                          <div className="artisan_right_cont">
                            <div className="tag">
                              <span>{t("MostActive")}</span>
                            </div>
                            <div className="artisan_title">
                              <h5>
                                {item.name}, <span>{item.artisan_level}</span>
                              </h5>
                            </div>
                            <div className="jobs_review">
                              <div className="job_cont">
                                <span>
                                  #
                                  {item.job_done === null
                                    ? "No"
                                    : item.job_done}{" "}
                                  {t("Jobs")}
                                </span>
                              </div>
                              <div className="review_cont">
                                <span className="review-ic">
                                  <img src={Star} alt="" />
                                </span>
                                <span>{item.rating}</span>
                              </div>
                            </div>
                            <div className="artisan_price_wrap">
                              <div className="artisan_view_btn">
                                <Button
                                  className="btn_primary"
                                  onClick={() => handleSlectTime(item)}
                                >
                                  {t("ViewDetail")}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ContactDetail = ({ onShow, onHide, heavyOrderData, item, emgAmount }) => {
  console.log("heavyOrderData", heavyOrderData);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [emailCheck, setEmailCheck] = useState(false);
  const [mobileCheck, setMobileCheck] = useState(false);
  const { t, i18n } = useTranslation();

  const ContactDetailClose = () => onHide();

  const navigate = useNavigate();

  const onEmailCheckBoxClick = () => {
    setEmailCheck(!emailCheck);
  };

  const getUserData = async () => {
    let path = apiPath.get_user_profile;
    let options = { method: "POST" };

    try {
      const res = await Http(path, options);
      setEmail(res?.data?.result?.email);
      setMobile(
        res?.data?.result?.country_code + " " + res?.data?.result?.mobile
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidMobile = (mob) => {
    return /\S+@\S+\.\S+/.test(mob);
  };

  const emailValidate = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };

  const mobileValidate = (e) => {
    if (!isValidMobile(e.target.value)) {
      setError2("Mobile no. is invalid");
    } else {
      setError2(null);
    }
    setMobile(e.target.value);
  };

  const onMobileCheckBoxClick = () => {
    setMobileCheck(!mobileCheck);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (
      (email && mobile !== "") ||
      (email && mobile !== "undefined") ||
      (email && mobile !== null)
    ) {
      onHide();
      console.log(email, mobile);

      navigate(`/heavy-service-time/${item.id}`, {
        state: {
          email,
          mobile,
          emailCheck,
          mobileCheck,
          heavyOrderData,
          item,
        },
      });
    } else {
      onShow();
    }
  };

  return (
    <>
      <Modal
        show={onShow}
        onHide={onHide}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {t("ContactDetail")}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={ContactDetailClose}
          >
            <img src={ImgCross} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="modal_cont">
            <h4>{t("HowdoyouwantArtisanstocontactyou")}</h4>
          </div>
          <Form>
            <div className="popup-cont">
              <Form.Group className="form-group" htmlFor="email">
                <div className="custom_checkbox">
                  <Form.Check
                    id="email"
                    name="email"
                    label={t("Email")}
                    checked={emailCheck}
                    onChange={onEmailCheckBoxClick}
                  />
                </div>
              </Form.Group>
              <Form.Group className="form-group" controlId="form-group">
                <Form.Control
                  type="text"
                  placeholder=""
                  value={email}
                  // onChange={emailValidate}
                />
                {error && <span className=" error_msg">{error}</span>}
              </Form.Group>
              <Form.Group className="form-group" htmlFor="mob">
                <div className="custom_checkbox">
                  <Form.Check
                    id="mob"
                    name="mobile"
                    label={t("MobileNumber")}
                    checked={mobileCheck}
                    onChange={onMobileCheckBoxClick}
                  />
                </div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Control
                  type="text"
                  value={mobile}
                  // onChange={mobileValidate}
                />
                {error && <span className=" error_msg">{error2}</span>}
              </Form.Group>
              <div className="login-btn">
                <Button
                  type="submit"
                  className="btn btn_primary"
                  onClick={handleClick}
                >
                  {t("Save")}
                </Button>
              </div>{" "}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HeavyServiceArtisans;

import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import apiPath from "../../../../constants/apiPath";
import Http from "../../../../services/Http";
import { toast, Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import Imgcross from "../../../../assets/img/cross.png";
import { useNavigate } from "react-router-dom";
import Contact from "./Contact";
import AddNewAddress from "../../../Component/modalComponents/AddNewAddress";

const Address = ({
  artisanId,
  serviceType,
  productData,
  selectedServices,
  cartData,
  onOpen,
  onHide,
}) => {
  const [pageRender, setPageRender] = useState(true);
  const [addAddress, setAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [allAddress, setAllAddress] = useState();
  const [addressId, setAddressId] = useState({});
  const [selectAddress, setSelectAddress] = useState();
  const [showAddress, setShowAddress] = useState(false);
  const [openContactDetail, setOpenContactDetail] = useState(false);
  const [addId, setAddId] = useState();
  const navigate = useNavigate();

  const getAllAddress = async () => {
    let path = apiPath.getAddress;
    let options = {
      method: "POST",
    };
    try {
      let res = await Http(path, options);
      setAllAddress(res?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setShowAddress(true);
    getAllAddress();
  }, [pageRender, !addAddress]);

  const onSubmit = () => {};

  const onEditClose = () => {
    setEditAddress(false);
    setPageRender(true);
    getAllAddress();
  };

  const onClose = () => {
    onHide();
    setPageRender(true);
  };

  const handleOpenEditAddress = (item) => {
    setEditAddress(true);
    setAddressId(item);
  };

  const setDefaultAddress = async (id) => {
    // update-default-address
    setAddId(id);
    let path = apiPath.setDefaultAddress;
    let data = {
      address_id: `${id}`,
    };
    let options = {
      method: "POST",
      data: data,
    };
    if (id !== null || id !== "undefined" || id !== "null") {
      try {
        let res = await Http(path, options);
        console.log(res?.data);
        if (res.data.success === true) {
          toast.success(res.data.msg);
          getAllAddress();
        } else {
          toast.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClose = () => {
    onHide();
  };

  const openContact = () => {
    navigate("/select-time-by-artisan", {
      state: {
        email: "",
        mobile: "",
        isEmail: "",
        isMobile: "",
        addressId: myAddId ? myAddId : "",
        artisanId: artisanId ? artisanId : "",
        productData: productData ? productData : "",
        cartData: cartData ? cartData : "",
        selectedServices: selectedServices ? selectedServices : "",
      },
    });
    setShowAddress(false);
    //setOpenContactDetail(true);
    // onHide();
  };

  const closeContact = () => {
    setOpenContactDetail(false);
    onHide();
  };

  // console.clear();
  // console.log(selectAddress);

  // let data = allAddress?.map((el) => el.filter((id) => id.is_default === "1"));
  // console.clear();
  // console.log(data);
  let data = allAddress?.filter((idx) => idx.is_default === "1");
  let getAddressId = data?.map((elm) => elm);
  let getAddId = getAddressId?.find((elm) => elm?.id);
  let myAddId = getAddId ? getAddId?.id : null;
  console.clear();
  console.log(
    "serviceData",
    selectedServices,
    productData,
    serviceType,
    cartData
  );

  const openAddAddress = () => {
    setAddAddress(true);
  };

  const closeAddAddress = () => {
    setAddAddress(false);
  };

  return (
    <>
      {addAddress && (
        <AddNewAddress onOpen={openAddAddress} onClose={closeAddAddress} />
      )}
      {openContactDetail && (
        <Contact
          artisanId={artisanId}
          addressId={myAddId}
          onShow={openContact}
          onHide={closeContact}
          serviceType={serviceType}
          productData={productData}
          selectedServices={selectedServices}
          cartData={cartData}
        />
      )}
      <Toaster position="top-right" />
      <Modal
        show={showAddress}
        onHide={onHide}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 id="exampleModalLabel">Manage Address</h5>
          <button type="button" className="btn-close" onClick={handleClose}>
            <img src={Imgcross} onClick={onHide} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="m-account-cls">
            <div className="manage_address_inner">
              {allAddress?.map((item, idx) => (
                <div className="manage_address_listing" key={idx}>
                  <div className="single_address">
                    <h5 style={{ textTransform: "capitalize" }}>{item.tag}</h5>
                    <p>
                      {item.house_number}, {item.address}
                    </p>
                    {/* <div className="select_option"> */}
                    {/* <div className="edit_delete_cont">
                        <span
                          className="edit_cls"
                          onClick={() => handleOpenEditAddress(item)}
                        >
                          <img className="img_icon" src={EditIcon} />
                        </span>
                        <span
                          className="delete_cls "
                          onClick={() => confirmDelete(item.id)}
                        >
                          <img className="img_icon" src={DeleteIcon} />
                        </span>
                      </div> */}
                    <div className="select-address">
                      <label className="custom_radio">
                        <input
                          type="radio"
                          checked={item?.is_default === "1"}
                          value={selectAddress}
                          onChange={(e) => {
                            setSelectAddress(e.target.value);
                            setDefaultAddress(item.id);
                          }}
                          name="radio"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              ))}
              <div className="add_new_address add_address mt-4">
                <Button
                  className="btn_primary mb-4"
                  onClick={() => openAddAddress()}
                >
                  Add New Address
                </Button>
                {allAddress?.length != 0 && (
                  <Button className="btn_primary" onClick={openContact}>
                    Continue
                  </Button>
                )}
              </div>
              {/* <div className="add_new_address">
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Address;

import React from "react";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import Http from "../services/Http";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import { ErrorMessage, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().max(255).required("Name is required!"),
    email: Yup.string()
      .max(255)
      .required("Email is required")
      .trim()
      .test("email", "Invalid email address", function (value) {
        // Custom email regex pattern
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(value);
      }),
    message: Yup.string().max(255).required("Message is required!"),
  });
  const onSubmit = async (values) => {
    console.log("values", values);
    const path = apiPath.contactUs;
    const data = {
      name: values?.name,
      email: values?.email,
      // mobile_no: values?.mobile_no,
      message: values?.message,
    };
    const options = {
      method: "POST",
      data,
    };
    console.log("data", data);
    try {
      const res = await Http(path, options);
      console.log("res", res?.data);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg, { autoClose: 2000 });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res?.data?.msg, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg, { autoClose: 2000 });
      console.log("error", error);
    }
  };
  return (
    <div className="contactUsMain py-lg-5 py-4">
      <Toaster position="top-right" />
      <div className="container">
        <div className="row gap-3 gap-lg-0">
          <div className="col-12 mb-lg-4">
            <div className="pageHeading fs-2 fw-bold text-center">
              {t("contact_us")}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="formOuter">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, handleSubmit, handleChange }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name">{t("full_name")}</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        id="name"
                        value={values?.name}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <p className="text-danger">
                        <ErrorMessage name="name" />
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">{t("email_address")}</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleChange}
                        value={values?.email}
                        autoComplete="off"
                      />
                      <p className="text-danger">
                        <ErrorMessage name="email" />
                      </p>
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="mobile_no">Mobile No</label>
                      <input
                        className="form-control"
                        type="number"
                        name="mobile_no"
                        id="mobile_no"
                        value={values?.mobile_no}
                        onChange={handleChange}
                      />
                      <p className="text-danger">
                        <ErrorMessage name="mobile_no" />
                      </p>
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="messgae">Message</label>
                      <textarea
                        className="form-control"
                        name="message"
                        id="messgae"
                        cols="20"
                        rows="10"
                        value={values?.message}
                        onChange={handleChange}
                        autoComplete="off"
                      ></textarea>
                      <p className="text-danger">
                        <ErrorMessage name="messgae" />
                      </p>
                    </div>
                    <div className="submit">
                      <button
                        type="submit"
                        className="btn btn_primary border-0 shadow-none ms-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mapOuter rounded-4 overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113874.30006236058!2d75.70815713116794!3d26.88533996477111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1687863388627!5m2!1sen!2sin"
                width="600"
                height="600"
                className="border-0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

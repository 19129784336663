import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import Imgcross from "../../../assets/img/cross.png";
import { Link, useNavigate } from "react-router-dom";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import { useTranslation } from "react-i18next";

const ContactDetail = ({
  categoryId,
  serviceId,
  addressId,
  orderType,
  onShow,
  onHide,
}) => {
  const [data, setData] = useState();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [emailCheck, setEmailCheck] = useState(false);
  const [mobileCheck, setMobileCheck] = useState(false);
  const [checkErr, setCheckErr] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);

  console.clear();
  console.log(addressId);

  // const ContactDetailClose = () => setShowContactDetails(false);
  const ContactDetailClose = () => onHide();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const navigate = useNavigate();
  // const [showArtisans, setShowArtisans] = useState(true);
  const onEmailCheckBoxClick = () => {
    setEmailCheck(!emailCheck);
    setCheckErr(false);
  };

  const getUserData = async () => {
    let path = apiPath.get_user_profile;
    let options = { method: "POST" };

    try {
      const res = await Http(path, options);
      setData(res?.data?.result);
      setEmail(res?.data?.result?.email);
      setMobile(
        res?.data?.result?.country_code + " " + res?.data?.result?.mobile
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidMobile = (mob) => {
    return /\S+@\S+\.\S+/.test(mob);
  };

  const emailValidate = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };

  const mobileValidate = (e) => {
    if (!isValidMobile(e.target.value)) {
      setError2("Mobile no. is invalid");
    } else {
      setError2(null);
    }
    setMobile(e.target.value);
  };

  const onMobileCheckBoxClick = () => {
    setMobileCheck(!mobileCheck);
    setCheckErr(false);
  };

  console.log(
    "send Data",
    email,
    mobile,
    addressId,
    categoryId,
    serviceId,
    orderType
  );

  const handleClick = async (e) => {
    e.preventDefault();
    if (
      (email && mobile !== "") ||
      (email && mobile !== "undefined") ||
      (email && mobile !== null)
    ) {
      onHide();
      console.log(email, mobile);
      // console.log(props.category_id);
      navigate("/select-time", {
        state: {
          email: email ? email : "",
          mobile: email ? mobile : "",
          addressId: addressId ? addressId : "",
          categoryId: categoryId ? categoryId : "",
          serviceId: serviceId ? serviceId : "",
          orderType: orderType,
        },
      });
      // if (emailCheck || mobileCheck) {
      // } else {
      //   setCheckErr(true);
      //   console.log("checkErr", checkErr);
      // }
    } else {
      onShow();
    }
  };

  // useEffect(() => {
  //   setShowContactDetails(true);
  // }, [props]);

  // const onEmptyEmail = () => {
  //   setEmail("");
  // };

  // const onEmptyMobile = () => {
  //   setMobile("");
  // };

  return (
    <>
      <Modal
        show={onShow}
        onHide={onHide}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            Contact Detail
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={ContactDetailClose}
          >
            <img src={Imgcross} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="modal_cont">
            <h4>How do you want Artisans to contact you?</h4>
          </div>
          <Form>
            <div className="popup-cont">
              <Form.Group className="form-group" htmlFor="email">
                <div className="custom_checkbox">
                  <Form.Check
                    id="email"
                    name="email"
                    label="Email"
                    checked={emailCheck}
                    onChange={onEmailCheckBoxClick}
                  />
                </div>
              </Form.Group>
              <Form.Group className="form-group" controlId="form-group">
                <Form.Control
                  type="text"
                  placeholder="soft@gmail.com"
                  value={email}
                  // onChange={emailValidate}
                />
                {error && <span className=" error_msg">{error}</span>}
                {/* <button onClick={onEmptyEmail()} className="btn-dark">
                  {"Change"}
                </button> */}
              </Form.Group>
              <Form.Group className="form-group" htmlFor="mobile">
                <div className="custom_checkbox">
                  <Form.Check
                    id="mobile"
                    name="mobile"
                    label="Mobile Number"
                    checked={mobileCheck}
                    onChange={onMobileCheckBoxClick}
                  />
                </div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Control
                  type="text"
                  placeholder="0123456789"
                  value={mobile}
                  // onChange={mobileValidate}
                />
                {error && <span className=" error_msg">{error2}</span>}
                {/* <button
                  // onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  onClick={onEmptyMobile}
                  className="btn-dark"
                >
                  {"Change"}
                </button> */}
                {/* {checkErr && (
                  <span className="error_msg">Check one of the option!</span>
                )} */}
              </Form.Group>
              <div className="login-btn">
                <Button
                  type="submit"
                  className="btn btn_primary"
                  onClick={handleClick}
                >
                  {t("save")}
                </Button>
              </div>{" "}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactDetail;

import React, { useState } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import Http from "../services/Http";
import apiPath from "../constants/apiPath";
import UserSidebar from "./UserSidebar";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Password = () => {
  const { t } = useTranslation();
  const [isRevealOldPwd, setIsRevealOldPwd] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCnfPwd, setIsRevealCnfPwd] = useState(false);

  const [input, setInput] = useState({
    old_password: "",
    password: "",
    cpassword: "",
  });

  const [error, setError] = useState({
    old_password: "",
    password: "",
    cpassword: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
    setError(validate(input));
  };

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "old_password":
          if (!value) {
            stateObj[name] = "Please enter Old Password.";
          }
          break;
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.cpassword && value !== input.cpassword) {
            stateObj["cpassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["cpassword"] = input.cpassword ? "" : error.cpassword;
          }
          break;

        case "cpassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  // const regex =
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&*?])[A-Za-z\d#$@!%^&*?]{8,}$/g;
  // "Must be minimum 8  characters long and alphanumeric with at least 1 uppercase letter, 1 number and 1 special character.";

  const validate = (value) => {
    const error = {};
    const regex = /^.{6,}$/g;
    if (!value.old_password?.length) {
      error.old_password = "Please enter Old Password.";
    }
    if (!value.password?.length) {
      error.password = "Please enter Password.";
    }
    if (!regex.test(value.password)) {
      error.password = "Must contain at 6 character.";
    }
    if (!value.cpassword?.length) {
      error.cpassword = "Please enter Confirm Password.";
    } else if (value.password !== value.cpassword) {
      error.cpassword = "Password and Confirm Password does not match.";
    }
    return error;
  };

  const onSubmit = async (e) => {
    console.log(input);
    e.preventDefault();

    setError(validate(input));
    if (
      input.old_password &&
      input.password &&
      input.cpassword !== "" &&
      input.password === input.cpassword
    ) {
      let path = apiPath.changePassword;
      const data = {
        old_password: input.old_password,
        password: input.password,
        cpassword: input.cpassword,
      };
      let options = {
        method: "POST",
        data: data,
      };
      try {
        let res = await Http(path, options);
        if (res?.data?.success === true) {
          toast.success(res?.data?.msg);
          setInput((prev) => ({
            ...prev,
            password: "",
            cpassword: "",
            old_password: "",
          }));
        } else {
          toast.error(res?.data?.msg);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <Toaster position="top-right" />
      <section className="order-main">
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <UserSidebar />
            </div>
            <div className="account-sidebar-right">
              <div className="m_sidebar_bg">
                <div className="title_cls">
                  <h3 className="heading_h3">{t("manage_pwd")}</h3>
                </div>
                <div className="profile_form mt-4">
                  <Row>
                    <Col md="12">
                      <Form.Group className="form-group">
                        <label>{t("old_password")}</label>
                        <div className="pw-cls">
                          <input
                            type={isRevealOldPwd ? "text" : "password"}
                            name="old_password"
                            placeholder={(t("enter"), t("old_password"))}
                            value={input.old_password}
                            onChange={onInputChange}
                            onKeyUp={validateInput}
                            className="form-control"
                          />
                          <button
                            onClick={() =>
                              setIsRevealOldPwd((prevState) => !prevState)
                            }
                            className="btn-dark"
                          >
                            {isRevealOldPwd ? t("hide") : t("show")}
                          </button>
                        </div>
                        {error.password && (
                          <span className="error_msg">
                            {error.old_password}
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <label>{t("password")}</label>
                        <div className="pw-cls">
                          <input
                            type={isRevealPwd ? "text" : "password"}
                            name="password"
                            placeholder={(t("enter"), t("password"))}
                            value={input.password}
                            onChange={onInputChange}
                            onKeyUp={validateInput}
                            className="form-control"
                          />
                          <button
                            onClick={() =>
                              setIsRevealPwd((prevState) => !prevState)
                            }
                            className="btn-dark"
                          >
                            {isRevealPwd ? t("hide") : t("show")}
                          </button>
                        </div>
                        {error.password && (
                          <span className="error_msg">{error.password}</span>
                        )}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <label>{t("confirm_password")}</label>
                        <div className="pw-cls">
                          <input
                            type={isRevealCnfPwd ? "text" : "password"}
                            name="cpassword"
                            placeholder={(t("enter"), t("confirm_password"))}
                            value={input.cpassword}
                            onChange={onInputChange}
                            onKeyUp={validateInput}
                            className="form-control"
                          />
                          <button
                            onClick={() =>
                              setIsRevealCnfPwd((prevState) => !prevState)
                            }
                            className="btn-dark"
                          >
                            {isRevealCnfPwd ? t("hide") : t("show")}
                          </button>
                        </div>

                        {error.cpassword && (
                          <span className="error_msg">{error.cpassword}</span>
                        )}
                      </Form.Group>
                      <div className="login-btn">
                        <button
                          className="continue-btn d-block"
                          onClick={onSubmit}
                        >
                          {t("continue")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Password;

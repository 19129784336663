import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import logo from "../../assets/img/toctocme-logo.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 60,
    paddingHorizontal: 30,
  },
  div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "8px",
  },
  image: {
    width: "15%",
    objectFit: "cover",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 30,
  },
  subtitle: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 20,
  },
  text: {
    margin: 5,
    fontSize: 12,
  },
  bold: {
    fontWeight: "bold",
  },
  divider: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    marginVertical: 10,
  },
});

const PDF = ({ orderDetail }) => {
  // const { userData } = orderDetail;

  console.log("orderDetail", orderDetail);

  return (
    <>
      <Document>
        <Page style={styles.page}>
          <View style={styles.section}>
            <View style={styles.div}>
              <Image src={logo} style={styles.image} />
            </View>
            <Text style={styles.subtitle}>Invoice</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Order No: #{orderDetail?.orderNo}</Text>
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>
                Date: {moment(orderDetail?.orderDate).format("yyyy-MM-DD")}
              </Text>{" "}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>
                Amount Due: € {orderDetail?.totalAmount}
              </Text>
            </Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.section}>
            <Text style={styles.subtitle}>Billing Information</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>
                Name: {orderDetail?.userData?.name}
              </Text>
            </Text>
            {orderDetail?.userData?.residence_address?.length > 0 && (
              <Text style={styles.text}>
                <Text style={styles.bold}>
                  Address: {orderDetail?.userData?.residence_address}
                </Text>{" "}
              </Text>
            )}
            {orderDetail?.mobile_no?.length > 0 && (
              <Text style={styles.text}>
                <Text style={styles.bold}>
                  Phone:{" "}
                  {orderDetail?.mobile_no ? orderDetail?.mobile_no : " - "}
                </Text>
              </Text>
            )}
            {orderDetail?.email_val?.length > 0 && (
              <Text style={styles.text}>
                <Text style={styles.bold}>
                  Email:{" "}
                  {orderDetail?.email_val ? orderDetail?.email_val : " - "}
                </Text>
              </Text>
            )}
          </View>
          <View style={styles.divider} />
          {orderDetail?.order_type === "Heavy" ? (
            <View style={styles.section}>
              <Text style={styles.subtitle}>Items</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ flex: 1 }}>Questions</Text>
                <Text>Answers</Text>
              </View>
              {orderDetail?.products?.map((item, idx) => (
                <View key={item.id} style={{ flexDirection: "row" }}>
                  <Text style={{ flex: 1 }}>
                    {" "}
                    {idx + 1}. {item.question}
                  </Text>
                  <Text>{item.answer ? item.answer : " - "}</Text>
                </View>
              ))}
              <View style={styles.divider} />
              <View style={{ flexDirection: "row" }}>
                <Text style={{ flex: 1 }}>SubTotal</Text>
                <Text>€ {orderDetail?.orgAmount}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ flex: 1 }}>Discount</Text>
                <Text>€ {orderDetail?.discountAmount}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ flex: 1 }}>Total</Text>
                <Text>€ {orderDetail?.totalAmount}</Text>
              </View>
            </View>
          ) : (
            <View style={styles.section}>
              <Text style={styles.subtitle}>Items</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ flex: 1, fontWeight: "bold" }}>Service</Text>
                <Text style={{ flex: 1, fontWeight: "bold" }}>Amount</Text>
              </View>
              {orderDetail?.products?.map((item) => (
                <View key={item.id} style={{ flexDirection: "row" }}>
                  <Text style={{ flex: 1 }}>{item?.serviceData?.name}</Text>
                  <Text style={{ flex: 1 }}>{item?.total_amount}</Text>
                </View>
              ))}
              <View style={styles.divider} />
              <View style={{ flexDirection: "row" }}>
                <Text style={{ flex: 1 }}>SubTotal</Text>
                <Text>€ {orderDetail?.orgAmount}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ flex: 1 }}>Discount</Text>
                <Text>€ {orderDetail?.discountAmount}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ flex: 1 }}>Total</Text>
                <Text>€ {orderDetail?.totalAmount}</Text>
              </View>
            </View>
          )}
        </Page>
      </Document>
    </>
  );
};

export default PDF;

import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Star from "../../assets/img/star_icon.png";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import Http from "../../services/Http";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t, i18n } = useTranslation();
  const [adminSettings, setAdminSettings] = useState();

  const {
    catId,
    subCatId,
    artisanData,
    categoryId,
    subCategoryId,
    scheduling,
    heavyData,
    postalCode,
    artisanId,
    time_slot,
    date,
    email,
    mobile,
    isEmail,
    isMobile,
    serviceId,
  } = state;

  const confirmCheckout = async () => {
    let path = apiPath.heavyOrder;
    const data = {
      categoryId: `${catId === undefined}` ? `${categoryId}` : `${catId}`,
      subcategoryId: `${subCatId === undefined}`
        ? `${subCategoryId}`
        : `${subCatId}`,
      service_type: scheduling ? scheduling : "",
      time_slot: time_slot ? time_slot : "",
      service_date: date ? date : "",
      pincode: postalCode ? postalCode : "",
      artisan_id: artisanId ? artisanId : "",
      qData: heavyData ? heavyData : "",
      email_val: email ? email : "",
      is_email: isEmail ? isEmail : "",
      mobile_no: mobile ? mobile : "",
      is_mobile: isMobile ? isMobile : "",
      serviceId: serviceId ? serviceId.toString() : null,
    };
    let options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      console.log(res);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        const { orderNo } = res?.data?.result;
        navigate("/thank-you", { state: { bookingNo: orderNo } });
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSettingsData = async () => {
    let path = apiPath.getSettings;
    let options = {
      method: "GET",
    };
    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        setAdminSettings(res.data.result);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };
  useEffect(() => {
    getSettingsData();
  }, []);
  return (
    <>
      <Toaster position="top-right" />{" "}
      <section className="checkout-main">
        <Container>
          <div className="checkout-inner">
            <div className="checkout_left">
              <div className="checkout_bg">
                {artisanData && (
                  <div className="checkout_cont">
                    <div className="artisan_bg">
                      <div className="artisan_left_img">
                        <div className="artisan_img">
                          <img
                            src={`${IMAGE_PATH + artisanData?.image}`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="artisan_right_cont artisan-dtl-cont">
                        <div className="artisan_title">
                          <h5>
                            {artisanData?.name},{" "}
                            <span>{artisanData?.artisan_level}</span>
                          </h5>
                        </div>
                        <div className="jobs_review">
                          <div className="job_cont">
                            <span>
                              #
                              {artisanData?.job_done === null ||
                              artisanData?.job_done === 0
                                ? t("NoJobs")
                                : artisanData?.job_done}{" "}
                              {t("Jobs")}
                            </span>
                          </div>
                          <div className="review_cont">
                            <span className="review-ic">
                              <img src={Star} alt="" />
                            </span>
                            <span>{artisanData?.rating}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {heavyData?.length > 0 &&
                      heavyData?.map((item, idx) => (
                        <div className="checkout_selected_serv">
                          <h5>{item.question}</h5>

                          <div className="signle_select_serv">
                            <div className="serv_desc">
                              <h4>
                                {item.answer.length !== 0
                                  ? item.answer
                                  : item.text}
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))}

                    <div className="customer_select_address">
                      <h3>{t("PostalCodeofThePlaceofWork")}</h3>
                      <p>{postalCode}</p>
                    </div>
                    {time_slot && (
                      <div className="customer_select_address">
                        <h3>{t("TimeSlotSelectedbyTheCustomer")}</h3>
                        <p>
                          {date}, {time_slot}
                        </p>
                      </div>
                    )}
                    {/* <div className="customer_select_address">
                      <h3>Artisan Location</h3>
                      <p>{artisanData?.residence_address}</p>
                    </div> */}
                  </div>
                )}
                <span className="checkout_error_msg">
                  {t("YouhavetoPay")} €{adminSettings?.min_service_charges}{" "}
                  {t("as_a_signing_amount")}
                </span>
              </div>
            </div>
            <div className="checkout-right">
              <div className="checkout_bg_heavy">
                <div className="card_detail">
                  <div className="price-specification">
                    <h3>{t("price_specification")}</h3>
                    <ul>
                      <li>
                        <span className="checkout-price-left">
                          {t("Amounttobepaid")}
                        </span>
                        <span className="checkout-price-right">
                          €{adminSettings?.min_service_charges}
                        </span>
                      </li>
                      <li>
                        <span className="checkout-price-left">
                          {t("TotalAmount")}
                        </span>
                        <span className="checkout-price-right">
                          €{adminSettings?.min_service_charges}
                        </span>
                      </li>
                    </ul>
                    <div className="login-btn">
                      <Button className="btn_primary" onClick={confirmCheckout}>
                        {t("confirm_go")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
export default Checkout;

import React, { useEffect, useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import gmapImg from "../assets/img/google-map.png";
import {
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  FormControl,
  FormSelect,
  Row,
} from "react-bootstrap";
import { Modal, Button as AntButton, Skeleton } from "antd";
import ArtisanImg from "../assets/img/artisan1.png";
import { Link, useLocation } from "react-router-dom";
import Star from "../assets/img/star_icon.png";
import Close from "../assets/img/cross.png";
import GMap from "./Component/GMap";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import Http from "../services/Http";
import Coupon from "../assets/img/coupon.png";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { VariableSizeGrid } from "react-window";
import { calculateDistance } from "../helpers/functions";
import AddNewAddress from "./Component/modalComponents/AddNewAddress";
import ContactDetail from "./Component/modalComponents/ContactDetail";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const Checkout = () => {
  const { t, i18n } = useTranslation();
  const [preview, setPreview] = useState();
  const [services, setServices] = useState();
  const [modal, showModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedAddress, setSelectedAdress] = useState();
  const [distance, setDistance] = useState();
  const [location, setLocation] = useState([]);
  let lang = localStorage.getItem("lang");

  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("location", state);

  const onToken = (token) => {
    console.log(token);
  };
  const confirmCheckout = async () => {
    let path = apiPath.confirmCheckout;
    let formData = new FormData();
    formData.append("cart_id", preview?.id);

    let options = { method: "POST", data: formData };

    try {
      let res = await Http(path, options);
      console.log(res);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        const { orderNo } = res?.data?.result;
        localStorage.removeItem("cart_item");
        navigate("/thank-you", { state: { bookingNo: orderNo } });
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const removePromocode = async (codeId) => {
    const path = apiPath.remove_promo_code;
    const data = { promocode_id: codeId + "", cart_id: preview?.id + "" };
    const options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setPreview((prev) => ({ ...prev, promocodeId: null }));
        // await getPreview();
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPreview = async () => {
    let path = apiPath.getPreview;
    var formData = new FormData();
    formData.append(
      "order_type",
      state?.orderType === "Offline" ? "Offline" : "Soft"
    );

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        const { cartData } = res.data.result;

        if (cartData?.addressData) {
          let LatLng = [];
          LatLng.push(parseFloat(cartData?.addressData?.latitude));
          LatLng.push(parseFloat(cartData?.addressData?.longitude));
          setLocation((prev) => [...prev, LatLng]);
        }
        if (cartData?.artisanData) {
          let LatLng = [];
          LatLng.push(parseFloat(cartData?.artisanData?.latitude));
          LatLng.push(parseFloat(cartData?.artisanData?.longitude));
          setLocation((prev) => [...prev, LatLng]);
        }

        setPreview(res.data.result.cartData);
        const dist = calculateDistance(
          cartData?.artisanData?.latitude,
          cartData?.artisanData?.longitude,
          cartData?.addressData?.latitude,
          cartData?.addressData?.longitude
        );

        setDistance(dist);
        setServices(res.data.result.cartData.products);
        setSelectedAdress(res.data.result.cartData.addressId);
      } else {
        toast.error(res.data.msg);
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  useEffect(() => {
    getPreview();
  }, [refresh, !modal]);

  const serviceName = services?.map((item) =>
    lang == "en"
      ? item.serviceData.subcatData.name
      : item.serviceData.subcatData.fr_name
  );

  const onSubmit = () => {
    return new Promise(async (resolve, reject) => {
      let path = apiPath.setPreview;

      const cartItems = JSON.parse(localStorage.getItem("cart_item"));
      const { selectedServices, ...data } = cartItems;

      console.log(data.address_id, selectedAddress);

      if (data.address_id == selectedAddress) return resolve();

      data.address_id = selectedAddress;

      let options = {
        method: "POST",
        data,
      };
      try {
        let res = await Http(path, options);
        if (res.data.success === true) {
          setRefresh((prev) => !prev);
          // toast.success(res.data.msg);
          localStorage.setItem(
            "cart_item",
            JSON.stringify({ ...cartItems, address_id: selectedAddress })
          );
          resolve(res.data);
        } else {
          toast.error(res.data.msg);
          reject();
        }
      } catch (err) {
        reject();
        console.log(err.res);
      }
    });
  };

  return (
    <>
      <Toaster position="top-right" />{" "}
      <section className="checkout-main">
        <Container>
          <div className="checkout-inner">
            <div className="checkout_left">
              <div className="checkout_bg">
                {preview?.artisanData && (
                  <div className="checkout_cont">
                    <h3>{serviceName[0]}</h3>
                    <div className="artisan_bg">
                      <div className="artisan_left_img">
                        <div className="artisan_img">
                          <img
                            src={`${IMAGE_PATH + preview?.artisanData?.image}`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="artisan_right_cont artisan-dtl-cont">
                        <div className="artisan_title">
                          <h5>
                            {preview?.artisanData?.name},{" "}
                            <span>{preview?.artisanData?.artisan_level}</span>
                          </h5>
                        </div>

                        <div className="jobs_review">
                          {preview?.artisanData?.job_done > 0 && (
                            <div className="job_cont">
                              <span>
                                #
                                {preview?.artisanData?.job_done == 0 ||
                                preview?.artisanData?.job_done == null
                                  ? t("NoJobs")
                                  : preview?.artisanData?.job_done + t("Jobs")}
                              </span>
                            </div>
                          )}
                          <div className="review_cont">
                            <span className="review-ic">
                              <img src={Star} alt="" />
                            </span>
                            <span>{preview?.artisanData?.rating}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="checkout_selected_serv">
                      <h5>{t("you_have_selected_service")}</h5>
                      {services?.map((item, idx) => (
                        <div className="signle_select_serv" key={idx}>
                          <div className="serv_no">
                            <span>{idx + 1}.</span>
                          </div>
                          <div className="serv_desc">
                            <h4>
                              {lang == "en"
                                ? item.serviceData.name
                                : item.serviceData.name_fr}
                            </h4>
                            <p>€ {item.total_amount}</p>
                          </div>
                          {/* <div className="serv_edit_option">
                            <Button
                              className="black_btn"
                              onClick={() =>
                                navigate(
                                  `/artisan-detail/${preview?.artisanData?.id}`,
                                  {
                                    state: {
                                      addressId: preview
                                        ? preview?.addressId
                                        : "",
                                      email: preview ? preview?.email_id : "",
                                      mobile: preview ? preview?.mobile_no : "",
                                      date: preview
                                        ? preview?.service_date
                                        : "",
                                      time_slot: preview
                                        ? preview?.time_slot
                                        : "",
                                      scheduling: preview
                                        ? preview?.service_type
                                        : "",
                                      services: services ? services : "",
                                    },
                                  }
                                )
                              }
                            >
                              {t("edit")}
                            </Button>
                          </div> */}
                        </div>
                      ))}
                    </div>
                    {/* {preview?.order_type === "Soft" && (
                      <div className="customer_select_address">
                        <h3>{t("address_selected_by_customer")}</h3>
                        <p>
                          {preview?.addressData?.house_number},{" "}
                          {preview?.addressData?.address}
                        </p>

                        <div className="serv_edit_option d-flex justify-content-between align-items-center mt-2">
                          <Button
                            className="black_btn"
                            onClick={() => showModal(true)}
                          >
                            {t("change")}
                          </Button>
                          {preview?.order_type === "Soft" && (
                            <div className="gmap-wrap d-flex gap-2 align-items-center">
                              {distance && (
                                <p className="mb-0">
                                  {t("Distance")} : {distance} KM
                                </p>
                              )}
                              <img src={gmapImg} alt="gmapImg" />
                            </div>
                          )}
                        </div>
                      </div>
                    )} */}

                    {preview?.service_type === "Right Now" ? (
                      ""
                    ) : (
                      <div className="customer_select_address">
                        <h3>{t("TimeSlotSelectedbyTheCustomer")}</h3>
                        <p>
                          {preview?.service_date}, {preview?.time_slot}
                        </p>
                      </div>
                    )}

                    {/* {preview?.artisanData?.residence_address && (
                      <div className="customer_select_address">
                        <h3>{t("artisan_location")}</h3>
                        <p>{preview?.artisanData?.residence_address}</p>
                        {preview?.order_type === "Soft" && (
                          <div class="serv_edit_option d-flex justify-content-between align-items-center mt-2">
                            <div className="gmap-wrap d-flex gap-2 align-items-center ms-auto">
                              {distance && (
                                <p className="mb-0">
                                  Distance : {parseFloat(distance)} Km
                                </p>
                              )}
                              <img src={gmapImg} alt="gmapImg" />
                            </div>
                          </div>
                        )}
                      </div>
                    )} */}
                    <div className="time_map customer_select_address">
                      <div className="map_card">
                        {location?.length > 0 ? (
                          <GMap latLng={location} />
                        ) : (
                          <>
                            <h3>{t("Uhoh")}..</h3>
                            <h4>{t("Noartisanisavailablecurrently")}</h4>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="checkout-right">
              <div className="checkout-bg">
                <div className="checkout-coupon">
                  {preview?.promocodeId ? (
                    <>
                      <div className="coupon-code-btn">
                        <span>{preview?.discountCode}</span>
                        <span className="cross_img">
                          <Link to="#">
                            <img
                              onClick={() =>
                                removePromocode(preview?.promocodeId)
                              }
                              src={Close}
                              alt
                            />
                          </Link>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <span className="coupon_icon">
                        <img src={Coupon} alt="" />
                      </span>
                      <Link
                        to="/coupon"
                        state={{ cartId: preview?.id, by: state?.orderType }}
                      >
                        <span className="apply_coupon">
                          {t("apply_coupon")}
                        </span>
                      </Link>
                    </>
                  )}{" "}
                </div>
                <div className="card_detail">
                  {/* <h2>Enter Card Detail</h2>
                  <div className="checout_form">
                    <Row>
                      <Col md="12">
                        <Form.Group className="form-group">
                          <label>Name on Card</label>
                          <FormControl></FormControl>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group className="form-group">
                          <label>Enter Card Number</label>
                          <FormControl></FormControl>
                        </Form.Group>
                      </Col>
                      <Col md="8">
                        <Form.Group className="form-group">
                          <label>Exp. Date</label>
                          <FormControl></FormControl>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group className="form-group">
                          <label>CVV</label>
                          <FormControl></FormControl>
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="login-btn chekout-btn">
                      <StripeCheckout
                      className="btn btn_primary"
                        token={onToken}
                        stripeKey="pk_test_51MoojsSEfNkFEWJTv1qNAapxcse2qkcqY2oaJmjNXO7U1niavwdCIX53WXxjBVLhzh81aOWgmPToVQZwGd0wWezb00rGRRehlN"
                      />
                    </div>
                    <Button className="btn_primary">Pay Now</Button>
                  </div> */}
                  <div className="price-specification">
                    <h3>{t("price_specification")}</h3>
                    <ul>
                      <li>
                        <span className="checkout-price-left">
                          {t("ServiceCharge")}
                        </span>
                        <span className="checkout-price-right">
                          € {preview?.orgAmount}
                        </span>
                      </li>
                      {preview?.discountAmount && (
                        <li>
                          <span className="checkout-price-left">
                            {t("Discount")}
                          </span>
                          <span className="checkout-price-right">
                            - € {preview?.discountAmount}
                          </span>
                        </li>
                      )}

                      {parseFloat(preview?.emergencyAmount) > 0 && (
                        <li>
                          <span className="checkout-price-left">
                            {t("EmergencyAmount")}
                          </span>
                          <span className="checkout-price-right">
                            € {preview?.emergencyAmount}
                          </span>
                        </li>
                      )}

                      {preview?.taxes && (
                        <li>
                          <span className="checkout-price-left">Taxes</span>
                          <span className="checkout-price-right">
                            € {preview?.taxes}
                          </span>
                        </li>
                      )}
                      <li>
                        <span className="checkout-price-left">
                          {t("TotalAmount")}
                        </span>
                        <span className="checkout-price-right">
                          € {preview?.totalAmount}
                        </span>
                      </li>
                    </ul>
                    <div className="login-btn">
                      <Button className="btn_primary" onClick={confirmCheckout}>
                        {t("confirm_go")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {modal && (
          <ChangeAddress
            open={() => showModal(true)}
            show={modal}
            hide={() => showModal(false)}
            selectedAddress={selectedAddress}
            setSelectedAdress={setSelectedAdress}
            onContinue={onSubmit}
            cartId={preview?.id}
          />
        )}
      </section>
    </>
  );
};

export const ChangeAddress = ({
  open,
  show,
  hide,
  orderType,
  categoryId,
  onContinue,
  cartId,
  serviceId,
}) => {
  const [allAddress, setAllAddress] = useState();
  const [loading, setLoading] = useState(true);
  const [openContactPage, setOpenContactPage] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [selectedAddress, setSelectedAdress] = useState();
  const [addId, setAddId] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();

  const getAllAddress = async () => {
    setLoading(true);
    let path = apiPath.getAddress;
    let options = {
      method: "POST",
    };
    try {
      let res = await Http(path, options);
      setLoading(false);
      setAllAddress(res?.data?.result);
      const selected = res?.data?.result?.filter(
        (address) => address?.is_default === "1"
      );
      setSelectedAdress(selected[0]?.id);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    console.log(selectedAddress, "selectedAddress");
  }, [selectedAddress]);

  const userToken = localStorage.getItem("userToken");

  const setDefaultAddress = async (id) => {
    // update-default-address
    setAddId(id);
    let path = apiPath.setDefaultAddress;
    let data = {
      address_id: `${id}`,
    };
    let options = {
      method: "POST",
      data: data,
    };
    if (id !== null || id !== "undefined" || id !== "null") {
      try {
        let res = await Http(path, options);
        console.log(res?.data);
        if (res.data.success === true) {
          toast.success(res.data.msg);
          getAllAddress();
        } else {
          toast.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onOpenContact = () => {
    if (userToken !== null || userToken !== undefined || userToken !== "") {
      setOpenContactPage(true);
    } else {
      toast.error("Login Required!");
    }
    hide();
  };

  const onCloseContact = () => {
    setOpenContactPage(false);
  };

  const openContact = async () => {
    const path = apiPath.checkArtisanAddress;
    const data = {
      cartId: `${cartId}` ? `${cartId}` : "",
      new_address_id: `${selectedAddress}` ? `${selectedAddress}` : "",
    };
    const options = {
      method: "POST",
      data,
    };
    
    if (serviceId) {
      navigate("/select-time", {
        state: {
          selectedAddress,
          categoryId,
          serviceId,
          orderType,
        },
      });
    } else {
      try {
        let res = await Http(path, options);
        if (res?.data?.success === true) {
          await onContinue();
          toast.success(res?.data?.msg);
          hide();
        } else {
          toast.error(res.data.msg);
          open();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const openAddAddress = () => {
    setAddAddress(true);
  };
  const closeAddAddress = async () => {
    setAddAddress(false);
    await getAllAddress();
  };

  return (
    <>
      {openContactPage && (
        <ContactDetail
          categoryId={categoryId}
          serviceId={serviceId}
          orderType={orderType}
          addressId={selectedAddress}
          onShow={onOpenContact}
          onHide={onCloseContact}
        />
      )}
      {addAddress === true && (
        <AddNewAddress onOpen={openAddAddress} onClose={closeAddAddress} />
      )}
      <Modal open={show} onOk={hide} onCancel={hide} footer={null}>
        <section className="artisans-sec">
          <div className="container">
            {/* <div className="artisans-box"> */}
            <h4>{t("Location")}</h4>
            <p>{t("Wherethejobneedstobeperformed")} </p>
            <div className="addressDetails">
              {loading ? (
                [1, 2, 3].map((item) => <Skeleton active key={item} />)
              ) : (
                <Row>
                  <Col md="12">
                    <div className="manage_address_inner">
                      {allAddress?.map((item, idx) => (
                        <div className="manage_address_listing" key={idx}>
                          <div className="single_address">
                            <h5 style={{ textTransform: "capitalize" }}>
                              {item.tag}
                            </h5>
                            <p>
                              {item.house_number}, {item.address}
                            </p>
                            <div className="select-address">
                              <label className="custom_radio">
                                <input
                                  type="radio"
                                  checked={item?.is_default === "1"}
                                  onChange={(e) => {
                                    setSelectedAdress(item.id);
                                    localStorage.setItem("addressId", item.id);
                                    setDefaultAddress(item.id);
                                  }}
                                  name="radio"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="add_new_address add_address mt-4">
                        <Button
                          className="btn_primary mb-4"
                          onClick={() =>
                            userToken
                              ? openAddAddress()
                              : toast.error("Login Required!")
                          }
                        >
                          {t("add_new_address")}
                        </Button>
                        <Button className="btn_primary" onClick={openContact}>
                          {t("conitnue")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            {/* </div> */}
          </div>
        </section>
      </Modal>
    </>
  );
};

export default Checkout;

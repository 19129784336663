import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowRight from "../assets/img/rightarrow.png";
import Icon1 from "../assets/img/m-1.png";
import Icon2 from "../assets/img/m-2.png";
import Icon3 from "../assets/img/m-3.png";
import Icon4 from "../assets/img/m-4.png";
import Icon5 from "../assets/img/m-5.png";
import Icon6 from "../assets/img/m-6.png";
import Icon7 from "../assets/img/m-7.png";
import Icon8 from "../assets/img/m-8.png";
import Icon9 from "../assets/img/profile.png";
import Icon10 from "../assets/img/order.png";
import Icon11 from "../assets/img/m-off.png";
import UserSidebar from "./UserSidebar";
import Http from "../services/Http";
import apiPath from "../constants/apiPath";
import ChangePassword from "./Component/modalComponents/ChangePassword";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import InviteFriends from "./Component/modalComponents/InviteFriends";

const MyDashboard = () => {
  const { t } = useTranslation();
  const [share, setShare] = useState(false);
  const [notify, setNotify] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const onOpen = () => {
    setChangePassword(true);
  };

  const onClose = () => {
    setChangePassword(false);
  };

  const onOpenShare = () => {
    setShare(true);
  };

  const onCloseShare = () => {
    setShare(false);
  };

  const handelUpdateNotification = async () => {
    setNotify(!notify);
    let path = apiPath.updateNotification;
    let data = { is_notify: notify ? "1" : "0" };
    let options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
      } else {
        toast.success(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
    console.log("notify", notify);
  };

  return (
    <>
      {changePassword && <ChangePassword onClose={onClose} />}
      {share && <InviteFriends open={onOpenShare} close={onCloseShare} />}
      <section className="order-main">
        <Toaster position="top-right" />
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <UserSidebar />
            </div>
            <div className="account-sidebar-right">
              <div className="m-account-extrnal mt-0">
                <div className="extrnal_links">
                  <Row>
                    <Col xs="6" md="4" lg="3">
                      <div className="link-box">
                        <Link to="/manage-address">
                          <div className="link-ic">
                            <img src={Icon1} alt="" />
                          </div>
                          <h5>{t("manage_address")}</h5>
                        </Link>
                      </div>
                    </Col>
                    <Col xs="6" md="4" lg="3">
                      <div className="link-box">
                        <Link to="/favorites">
                          <div className="link-ic">
                            <img src={Icon2} alt="" />
                          </div>
                          <h5>{t("my_fav_artisans")}</h5>
                        </Link>
                      </div>
                    </Col>
                    <Col xs="6" md="4" lg="3">
                      <div className="link-box">
                        <Link to="/my-account">
                          <div className="link-ic">
                            <img src={Icon9} alt="" />
                          </div>
                          <h5>{t("my_profile")}</h5>
                        </Link>
                      </div>
                    </Col>
                    <Col xs="6" md="4" lg="3">
                      <div className="link-box">
                        <Link to="/my-orders">
                          <div className="link-ic">
                            <img src={Icon10} alt="" />
                          </div>
                          <h5>{t("my_orders")}</h5>
                        </Link>
                      </div>
                    </Col>
                    <Col xs="6" md="4" lg="3">
                      <div className="link-box">
                        <Link to="/wallet">
                          <div className="link-ic">
                            <img src={Icon3} alt="" />
                          </div>
                          <h5>{t("wallet")}</h5>
                        </Link>
                      </div>
                    </Col>
                    {/* <Col xs="6" md="4" lg="3">
                      <div className="link-box">
                        <div className="link-ic">
                          <img src={Icon4} alt="" />
                        </div>
                        <h5>Manage Cards</h5>
                      </div>
                    </Col> */}
                    <Col xs="6" md="4" lg="3">
                      <div className="link-box">
                        <Link to="#" onClick={handelUpdateNotification}>
                          <div className="link-ic">
                            {notify ? (
                              <img src={Icon11} alt="off" />
                            ) : (
                              <img src={Icon5} alt="on" />
                            )}
                          </div>
                          <h5>Notification</h5>
                        </Link>
                      </div>
                    </Col>
                    <Col xs="6" md="4" lg="3">
                      <div className="link-box" onClick={onOpenShare}>
                        <div className="link-ic">
                          <img src={Icon6} alt="" />
                        </div>
                        <h5>{t("invite")}</h5>
                      </div>
                    </Col>
                    <Col xs="6" md="4" lg="3">
                      <div className="link-box">
                        <Link to="/my-coupons">
                          <div className="link-ic">
                            <img src={Icon7} alt="" />
                          </div>
                          <h5>{t("my_coupon")}</h5>
                        </Link>
                      </div>
                    </Col>
                    <Col xs="6" md="4" lg="3">
                      <div className="link-box" onClick={onOpen}>
                        <div className="link-ic">
                          <img src={Icon8} alt="" />
                        </div>
                        <h5>{t("manage_pwd")}</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default MyDashboard;

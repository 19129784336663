import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import EditIcon from "../assets/img/edit.png";
import DeleteIcon from "../assets/img/delete-1.png";
import UserSidebar from "./UserSidebar";
import EditAddress from "./Component/modalComponents/EditAddress";
import AddNewAddress from "./Component/modalComponents/AddNewAddress";
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import { toast, Toaster } from "react-hot-toast";
import Swal from "sweetalert2";

const center = { lat: 48.8584, lng: 2.2945 };

const ManageAddress = () => {
  const [map, setMap] = useState(/**@type google.maps.Map */ (null));
  const [jobLocationRadio, setJobLocationRadio] = useState("artisansPlace");
  const [userLocationRadio, setUserLocationRadio] = useState("home");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [pageRender, setPageRender] = useState(true);
  const [addAddress, setAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [allAddress, setAllAddress] = useState();
  const [addressId, setAddressId] = useState({});
  const [selectAddress, setSelectAddress] = useState();

  const getAllAddress = async () => {
    let path = apiPath.getAddress;
    let options = {
      method: "POST",
    };
    try {
      let res = await Http(path, options);
      setAllAddress(res?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAddress();
  }, [pageRender]);

  const onJobLocationSelect = (e) => {
    setJobLocationRadio(e.target.value);
  };
  const onSubmit = () => {};
  const onUserLocationSelect = (e) => {
    setUserLocationRadio(e.target.value);
  };

  const handleOpenEditAddress = (item) => {
    setEditAddress(true);
    setAddressId(item);
  };

  const setDefaultAddress = async (e, id) => {
    setSelectAddress(e.target.value);
    // update-default-address
    let path = apiPath.setDefaultAddress;
    let data = {
      address_id: `${id}`,
    };
    let options = {
      method: "POST",
      data: data,
    };
    if (id !== null || id !== "undefined" || id !== "null") {
      try {
        let res = await Http(path, options);
        console.log(res?.data);
        if (res.data.success === true) {
          toast.success(res.data.msg);
          getAllAddress();
        } else {
          toast.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Something went wrong!");
    }
  };

  const confirmDelete = (id) => {
    console.log(id);
    Swal.fire({
      title: "Delete Address!",
      text: "Do you really  want to delete this address?",
      icon: "warning",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result?.isConfirmed === true) {
        deleteAddress(id);
      } else {
        Swal.fire("Cancelled", "", "error");
      }
    });
  };

  const deleteAddress = async (id) => {
    // console.log("address_id", id);

    let path = apiPath.deleteAddress;
    let data = {
      address_id: `${id}`,
    };
    let options = {
      method: "POST",
      data: data,
    };

    if (id !== null || id !== "undefined" || id !== "null") {
      try {
        let res = await Http(path, options);
        if (res.data.success === true) {
          toast.success(res.data.msg);
          getAllAddress();
        } else {
          toast.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onOpen = () => {
    setAddAddress(true);
    setPageRender(false);
  };

  const onEditClose = () => {
    setEditAddress(false);
    setPageRender(true);
    getAllAddress();
  };

  const onClose = () => {
    setAddAddress(false);
    setPageRender(true);
  };

  return (
    <>
      {addAddress && <AddNewAddress onOpen={addAddress} onClose={onClose} />}
      {editAddress && (
        <EditAddress
          data={addressId}
          id={addressId}
          onOpen={editAddress}
          onClose={onEditClose}
        />
      )}
      <section className="order-main">
        <Toaster position="top-right" />
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <UserSidebar />
            </div>
            <div className="account-sidebar-right">
              <div className="m_sidebar_bg">
                <div className="m-account-cls">
                  <div className="manage_address_inner">
                    <h4>Manage Address</h4>
                    {allAddress?.map((item, idx) => (
                      <div className="manage_address_listing" key={idx}>
                        <div className="single_address">
                          <h5 style={{ textTransform: "capitalize" }}>
                            {item.tag}
                          </h5>
                          <p>
                            {item.house_number}, {item.address}
                          </p>
                          <div className="select_option">
                            <div className="edit_delete_cont">
                              <span
                                className="edit_cls"
                                onClick={() => handleOpenEditAddress(item)}
                              >
                                <img src={EditIcon} />
                              </span>
                              <span
                                className="delete_cls"
                                onClick={() => confirmDelete(item.id)}
                              >
                                <img src={DeleteIcon} />
                              </span>
                            </div>
                            <div className="select-address">
                              <label className="custom_radio">
                                <input
                                  type="radio"
                                  checked={item?.is_default === "1"}
                                  value={selectAddress}
                                  onChange={(e) => {
                                    setDefaultAddress(e, item.id);
                                  }}
                                  name="radio"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="add_new_address">
                      <Button className="btn_primary mb-4" onClick={onOpen}>
                        Add New Address
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ManageAddress;

import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Star from "../assets/img/star_icon.png";
import ChristopheImg from "../assets/img/christophe_robin.png";
import ArtisanImg from "../assets/img/artisan1.png";
import like from "../assets/img/love.png";
import disLike from "../assets/img/like.png";
import CheckIcon from "../assets/img/check_icon.png";
import OwlCarousel from "react-owl-carousel3";
import { useLocation, useParams } from "react-router-dom";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import Http from "../services/Http";
import { useNavigate, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const center = { lat: 48.8584, lng: 2.2945 };

const category = {
  rtl: "true",
  margin: 15,
  nav: true,
  dots: false,
  loop: false,
  responsive: {
    0: {
      items: 2,
    },
    420: {
      items: 2,
    },
    577: {
      items: 3,
    },
    991: {
      items: 4,
    },
    1366: {
      items: 5,
    },
  },
};

const ArtisansDetail = () => {
  const { t, i18n } = useTranslation();
  let lang = localStorage.getItem("lang");
  const [artisanDetail, setArtisanDetail] = useState({});
  const [serviceData, setServiceData] = useState();
  const [remainingPrice, setReaminingPrice] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderPrice, setOrderPrice] = useState();
  const myAddressId = localStorage.getItem("addressId");
  const location = useLocation();
  const { state } = location;
  const cartDetail = state;
  const [favArtisan, setFavArtisan] = useState(false);
  const [selectServiceType, setSelectServiceType] = useState(
    state?.orderType ? state?.orderType : ""
  );
  const [favVal, setFavVal] = useState(true);
  console.log("state", state);
  const { id } = useParams();

  const [cartItem, setCartItem] = useState(
    JSON.parse(window.localStorage.getItem("cart_item"))
  );

  const artisanID = id;
  const schedule = location.state;
  const email = location.state;
  const mobile = location.state;

  const [isChecked, setIsChecked] = useState(false);
  const preCart = localStorage.getItem("cart_item");
  const [productData, setProductData] = useState([]);
  const [firstPrice, setFirstPrice] = useState(0);

  useEffect(() => {
    let ordersTotal = 0;
    productData?.map((item) => {
      ordersTotal = parseFloat(ordersTotal) + parseFloat(item.price);
    });
    setOrderTotal(ordersTotal);
  }, [productData]);

  useEffect(() => {
    if (!cartItem) return;
    cartItem.artisan_id == id && setProductData(cartItem.selectedServices);
    if (cartItem?.selectedServices?.length) {
      setProductData((prev) => [...prev, ...cartItem.selectedServices]);
    }
  }, [cartItem]);

  const removeProduct = (product) => {
    const find = productData.filter((item) => item.id !== product.id);
    setProductData(find);
    setCartItem(find);
    if (find?.length === 0) {
      setSelectServiceType("");
    }
  };

  const navigate = useNavigate();

  // localStorage.getItem("service");

  const onSubmit = async () => {
    let path;
    let data;
    function removeSimilarObjects(arr, compareFn) {
      const uniqueObjects = [];

      arr.forEach((obj) => {
        if (!uniqueObjects.some((uniqueObj) => compareFn(obj, uniqueObj))) {
          uniqueObjects.push(obj);
        }
      });

      return uniqueObjects;
    }
    // Define a comparison function based on a specific property
    const compareByName = (a, b) => a.id === b.id;
    
    // Remove similar objects based on the "id" property an set into product data
     let newProduct = removeSimilarObjects(productData, compareByName);

    if (selectServiceType === "Offline") {
     
      window.localStorage.setItem(
        "cart_item",
        JSON.stringify({
          service_type: state ? state?.scheduling : "Right Now",
          order_type: "Offline",
          time_slot: state ? state?.time_slot : "",
          service_date: state ? state?.date : "",
          artisan_id: artisanID.toString(),
          addressId: myAddressId,
          cartData: {
            totalItemQty: newProduct.length,
            amount: newProduct.reduce(
              (acc, curr) => acc + parseInt(curr.price),
              0
            ),
            discount_amount: "0",
          },
          productData: newProduct.map((item) => ({
            serviceId: selectServiceType === "Soft" ? item.id : null,
            price: item.price ? item?.price : firstPrice,
          })),
          selectedServices: newProduct,
        })
      );
      path = apiPath.setPreviewOffline;
      data = {
        service_type: state ? state?.scheduling : "Right Now",
        order_type: "Offline",
         addressId: myAddressId,
        time_slot: state ? state?.time_slot : "",
        service_date: state ? state?.date : "",
        artisan_id: artisanID.toString(),
        cartData: {
          totalItemQty: newProduct.length,
          amount: newProduct.reduce(
            (acc, curr) => acc + parseInt(curr.price),
            0
          ),
          discount_amount: "0",
        },
        productData: newProduct.map((item) => ({
          serviceId: item.id,
          price: item.price,
        })),
      };
    } else {
      window.localStorage.setItem(
        "cart_item",
        JSON.stringify({
          service_type: state ? state?.scheduling : "Right Now",
          order_type: "Soft",
          time_slot: state ? state?.time_slot : "",
          service_date: state ? state?.date : "",
          address_id: myAddressId,
          mobile_no: state ? state?.mobile : "",
          email_val: state ? state?.email : "",
          artisan_id: `${artisanID}`,
          cartData: {
            totalItemQty: newProduct.length,
            amount: newProduct.reduce(
              (acc, curr) => acc + parseInt(curr.price),
              0
            ),
            discount_amount: "0",
          },
          productData: newProduct.map((item) => ({
            serviceId: selectServiceType === "Soft" ? item.id : null,
            price: item.price,
          })),
          selectedServices: newProduct,
        })
      );
      path = apiPath.setPreview;
      data = {
        service_type: state ? state?.scheduling : "Right Now",
        order_type: "Soft",
        time_slot: state ? state?.time_slot : "",
        service_date: state ? state?.date : "",
        address_id: myAddressId,
        mobile_no: state ? state?.mobile : "",
        email_val: state ? state?.email : "",
        artisan_id: artisanID.toString(),
        cartData: {
          totalItemQty: newProduct.length,
          amount: newProduct.reduce(
            (acc, curr) => acc + parseInt(curr.price),
            0
          ),
          discount_amount: "0",
        },
        productData: newProduct.map((item) => ({
          serviceId: item.id,
          price: item.price,
        })),
      };
    }

    let options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        toast.success(res.data.msg);

        navigate("/checkout", {
          state: { state: cartDetail, orderType: selectServiceType },
        });
      } else {
        toast.error(res.data.msg);
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const getArtisanDetails = async () => {
    let path = apiPath.artisanDetails;
    console.log(artisanID);
    var formData = new FormData();
    formData.append("id", artisanID.toString());

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        setArtisanDetail(res.data.result);
        const { min_order } = res.data.result?.artisanDetails;
        setOrderPrice(min_order);
        setReaminingPrice(min_order);
        let { serviceData } = res.data.result?.serviceResult;
        setServiceData(serviceData);
        let firstPrice = res?.data?.result?.serviceResult?.serviceData?.filter(
          (itm) => itm?.id === JSON.parse(cartItem?.selectedServices[0]?.id)
        );
        console.log("ok", res.data.result);
        console.log("jai shree ram", firstPrice[0]?.price);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const userToken = localStorage.getItem("userToken");

  const markFavorite = async (id) => {
    if (userToken) {
      setFavVal(!favVal);
      const path = apiPath.markFavorite;
      const data = {
        fev_type: "Artisan",
        fev_id: `${id}`,
        fev_val: favVal === true ? "1" : "0",
      };
      const options = { method: "POST", data };
      try {
        const res = await Http(path, options);
        if (res?.data?.success === true) {
          setFavArtisan(!favArtisan);
          console.log(res.data.result);
          toast.success(res?.data?.msg);
          getArtisanDetails();
        } else {
          console.log(res?.data?.msg);
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Login Required!");
    }
  };

  useEffect(() => {
    getArtisanDetails();
  }, []);

  const handelContinue = () => {
    if (productData?.length > 0) {
      onSubmit();
    } else {
      toast.error("Please select a service!");
    }
  };

  const onSelectService = (e, item) => {
    // console.log(productData, item);
    // alert("1");
    setSelectServiceType(item?.service_type);
    if (productData.length) {
      // alert("2");
      const isTest = productData.filter(
        (data) => data.service_type != item.service_type
      );
      console.log(isTest, "isTest");
      if (isTest.length) {
        toast.error(
          "You could'nt select both soft and in-shop service at same time!"
        );
        return;
      }

      if (selectServiceType == "" || item?.service_type === selectServiceType) {
        // alert("4");
        if (parseInt(state?.serviceId) === item.id) {
          // alert("5");
          setProductData((prev) => [...prev, item]);
          // setSelectServiceType(item?.service_type);
          console.log("selectServiceType", item);
          // toast.error(
          //   "You could'nt select both soft and in-shop service at same time!"
          // );
        }

        const selected = e.target.checked;
        selected
          ? setProductData((prev) => [...prev, item])
          : removeProduct(item);
        setIsChecked((prev) => !prev);
      } else if (productData?.length === 0) {
        // alert("6");
        setSelectServiceType("");
      } else {
        // alert("7");
        return;
      }
    } else {
      setProductData((prev) => [...prev, item]);
      // alert("8");
    }
  };

  // console.log("services", state?.services);
  // console.log("orderType", state?.orderType);

  return (
    <section className="artisan-detail-sec">
      <Toaster position="top-right" />
      {artisanDetail?.artisanDetails === undefined ||
      artisanDetail?.artisanDetails === null ||
      artisanDetail?.artisanDetails === "" ? (
        <div className="d-flex justify-content-center align-items-lg-center">
          <h1>No Data Found!</h1>
        </div>
      ) : (
        artisanDetail?.artisanDetails && (
          <div className="container">
            <div className="browse-artisan-detail">
              <div className="artisan_list_wrap">
                <Row>
                  <Col md="8">
                    <div className="artisan_bg">
                      <div className="artisan_left_img">
                        <div className="artisan_img">
                          <img
                            src={`${
                              IMAGE_PATH + artisanDetail?.artisanDetails.image
                            }`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="artisan_right_cont artisan-dtl-cont">
                        <div className="artisan_title">
                          <h5>
                            {artisanDetail?.artisanDetails.name},{" "}
                            <span>
                              {artisanDetail?.artisanDetails?.artisan_level}
                            </span>
                          </h5>
                          <div className="artisan_dtl_right">
                            <div className="tag">
                              <span>{t("MostActive")}</span>
                            </div>
                            <span className="like_img">
                              <Link to="#">
                                <img
                                  onClick={() =>
                                    markFavorite(
                                      artisanDetail?.artisanDetails?.id
                                    )
                                  }
                                  src={
                                    artisanDetail?.artisanDetails?.fevData?.id
                                      ? like
                                      : disLike
                                  }
                                  alt
                                />
                              </Link>
                            </span>
                          </div>
                        </div>
                        <div className="jobs_review">
                          <div className="job_cont">
                            <span>
                              #
                              {artisanDetail?.artisanDetails?.job_done === null
                                ? t("NoJobs")
                                : artisanDetail?.artisanDetails?.job_done}{" "}
                              {t("Jobs")}
                            </span>
                          </div>
                        </div>
                        <div className="artisan_price_wrap">
                          {/* <div className="artisan_price_left">
                        <h5>€ 100.00</h5>
                      </div> */}
                          <div className="review_cont">
                            <span className="review-ic">
                              <img src={Star} alt="" />
                            </span>
                            <span>{artisanDetail?.artisanDetails?.rating}</span>
                          </div>
                        </div>
                        {artisanDetail?.artisanDetails?.tag_line ? (
                          <>
                            <div className="artisan_dtl_inner_cont">
                              <p>{artisanDetail?.artisanDetails.tag_line} </p>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  {artisanDetail?.artisanDetails.parentData && (
                    <Col md="4">
                      <div className="associated_bg">
                        <div className="associted_inner_cont">
                          <h5>{t("AssociatedWith")}</h5>
                          <div className="christophe_profile">
                            <div className="christophe_img">
                              <img
                                src={`${
                                  IMAGE_PATH +
                                  artisanDetail?.artisanDetails.parentData
                                    ?.image
                                }`}
                              />
                            </div>
                            <div className="Christophe_user_name">
                              {artisanDetail?.artisanDetails.parentData?.name
                                ? artisanDetail?.artisanDetails.parentData?.name
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>

              <div className="artisan_about_cont">
                <h5>
                  {t("nav_about")} {artisanDetail?.artisanDetails.name}
                </h5>
                {artisanDetail?.artisanDetails?.bio ? (
                  <>
                    <p>
                      {t("bio")} : {artisanDetail?.artisanDetails?.bio}
                    </p>
                  </>
                ) : null}
                {artisanDetail?.artisanDetails?.job_description ? (
                  <>
                    <p>
                      {t("JobDescription")} :{" "}
                      {artisanDetail?.artisanDetails?.job_description}
                    </p>
                  </>
                ) : null}

                {artisanDetail?.artisanDetails?.job_experience ? (
                  <>
                    <h5>{t("Experience")}</h5>
                    <p>
                      {t("JobExperience")} :{" "}
                      {artisanDetail?.artisanDetails?.job_experience}
                    </p>
                  </>
                ) : null}

                {artisanDetail?.artisanDetails?.workingPlaces ? (
                  <>
                    <h5>{t("ServiceAreas")}</h5>
                    <p>{artisanDetail?.artisanDetails?.workingPlaces}</p>
                  </>
                ) : null}

                <div className="category_slider row g-3">
                  {artisanDetail.serviceResult.count === 0 ? (
                    <h1>{t("Noservices")}</h1>
                  ) : (
                    artisanDetail?.serviceResult?.serviceData?.map(
                      (item, idx) => (
                        <div className="item col-3" key={idx}>
                          <div className="slect_category_check h-100">
                            <label className="custom_checkbox h-100">
                              <div className="form-chcek h-100">
                                <input
                                  checked={
                                    productData?.find(
                                      (data) => data.id === item.id
                                    )
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                  className="form-check-input"
                                  onChange={(e) => {
                                    onSelectService(e, item);
                                  }}
                                />
                                <span className="checkmark">
                                  <img src={CheckIcon} alt="" />
                                </span>
                                <div className="categroy_slider_inner h-100">
                                  <div className="categroy_slider_img">
                                    <img
                                      src={`${IMAGE_PATH + item.image}`}
                                      onClick={() =>
                                        navigate(`/service-detail/${item?.id}`)
                                      }
                                    />
                                    <span
                                      className={`${
                                        item.service_type !== "Soft"
                                          ? "offline_badge"
                                          : "soft_badge"
                                      }`}
                                    >
                                      {item.service_type === "Offline"
                                        ? "In Shop"
                                        : item.service_type}
                                    </span>
                                  </div>
                                  <div className="categroy_prd_cont">
                                    <h4>
                                      {lang == "en"
                                        ? item?.name
                                        : item?.name_fr}
                                    </h4>
                                    <p>{t("starting_from")}</p>
                                    <h5>€ {item.price}</h5>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
                {orderPrice > orderTotal ? (
                  <div className="service-price">
                    <div className="service_price_left">
                      <span>
                        {t("AddServicesworth")}
                        <span className="price_highlight">
                          € {orderPrice - orderTotal}
                        </span>{" "}
                        {t("moretocheckout")}
                      </span>
                    </div>
                    <div className="service_price_right">
                      <span>
                        {t("Minimumorderamountfor")}{" "}
                        {artisanDetail?.artisanDetails.name} is{" "}
                        <span className="price_highlight">€ {orderPrice}</span>
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="selected_service">
                      <div className="artisan_dtl_inner_cont">
                        <p>
                          {t("No.ofSelectedServices")}: {productData.length}{" "}
                        </p>
                      </div>

                      <div className="continue_btn">
                        {artisanDetail?.serviceResult?.count === 0 ? (
                          <Button
                            className="btn_primary"
                            onClick={() => navigate("/browse-artisans")}
                          >
                            {t("BrowseMoreArtisans")}
                          </Button>
                        ) : (
                          <Button
                            className="btn_primary"
                            onClick={handelContinue}
                          >
                            {t("conitnue")}
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </section>
  );
};

export default ArtisansDetail;

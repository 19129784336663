import React, { useState, useEffect, useContext } from "react";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import Imgcross from "../../../assets/img/cross.png";
import { Modal, Form, Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import toast, { Toaster } from "react-hot-toast";
import PasswordSet from "./PasswordSet";
import { Link } from "react-router-dom";
import { ModalContext } from "../home/Navbar";

const OtpVerification = ({
  show,
  hide,
  userData,
  type,
  onClose,
  showPassword,
}) => {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [getTimer, setTimer] = useState(0);
  const [otpError, setOtpError] = useState("");
  const { getOtp, setGetOtp } = useContext(ModalContext);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const onOTPSubmit = async () => {
    if (getOtp.otp == null || getOtp.otp == "" || getOtp.otp?.length < 4) {
      // toast.error("OTP is required!");
      setOtpError("OTP is required!");
      // isValid = false;
    } else if (getOtp.otp !== "1234") {
      setOtpError("OTP is invalid!");
    } else {
      if (type === "forgot") {
        let path = apiPath.verifyOTP;
        let data = {
          type: type,
          otp: getOtp.otp,
          verify_type: "mobile",
          mobile: userData.mobile,
          country_code: "+" + userData.country_code,
        };
        let options = {
          method: "POST",
          data,
        };
        try {
          let res = await Http(path, options);
          if (res.data.success === true) {
            toast.success(res.data.msg);
            showPassword();
            hide();
          } else {
            toast.error(res.data.msg);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        showPassword();
        hide();
        toast.dismiss("call made!", "");
      }
    }
  };

  const resendOTP = async () => {
    let path = apiPath.signup_sendOTP;
    let cc = userData.country_code;
    var formData = new FormData();
    formData.append("type", type);
    formData.append("user_type", "2");
    formData.append("verify_type", "mobile");
    formData.append("mobile", userData.mobile);
    formData.append("country_code", "+" + cc);
    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        toast.success(res.data.msg);
        setMinutes(1);
        setSeconds(30);
      } else {
        toast.error(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const handleChange = (value, data, event, formattedValue) => {
    setGetOtp({ ...getOtp, otp: value });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={hide}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            OTP
          </h5>
          <button type="button" className="btn-close" onClick={hide}>
            <img src={Imgcross} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_cont">
            <h4>Welcome !</h4>
            <h5>Verify Account</h5>
            <p>Please find OTP here XXXX{userData?.mobile?.match(/\d{3}$/)}</p>
          </div>
          <span className="error_msg">{otpError}</span>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <OtpInput
              autoFocus
              required
              value={getOtp.otp}
              onChange={handleChange}
              numInputs={4}
              className="otp-mob-input"
              hasErrored={getOtp.hasErrored}
              isInputNum={true}
              // containerStyle={{'justify-content':'center'}}
              errorMessage={{ required: "Enter OTP " }}
            />
          </Form.Group>
          <div className="otp_timer">
            {seconds > 0 || minutes > 0 ? (
              <p>
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              <>
                <p>
                  Didn't get otp?{" "}
                  <Link
                    style={{ color: "#0147AD", font: "bold" }}
                    to="#"
                    onClick={resendOTP}
                  >
                    Resend
                  </Link>{" "}
                </p>
              </>
            )}
          </div>

          <div className="countdown-text"></div>

          <div className="login-btn">
            <button className="continue-btn btn_primary" onClick={onOTPSubmit}>
              Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpVerification;

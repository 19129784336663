/*global google*/
import React from "react";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import Geocode from "react-geocode";
const _ = require("lodash");
Geocode.setApiKey("AIzaSyCHjWiLctEWEIwycRfETV1LZOEHsEEeZA4");
Geocode.setRegion("es");
Geocode.setLanguage("en");

const MapWithASearchBox = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCHjWiLctEWEIwycRfETV1LZOEHsEEeZA4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentDidMount() {
      const refs = {};

      this.setState({
        bounds: null,
        center: {
          lat: 41.8781136,
          lng: -87.6297982,
        },
        markers: [],
        onMapMounted: (ref) => {
          console.log(ref);
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          });
        },
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new google.maps.LatLngBounds();

          places.forEach((place) => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map((place) => ({
            position: place.geometry.location,
          }));
          places.map(
            ({
              place_id,
              formatted_address,
              address_components,
              geometry: { location },
            }) =>
              this.setState({
                address: formatted_address,
                address_components: address_components,
                lat: location.lat(),
                lng: location.lng(),
              })
          );
          const nextCenter = _.get(
            nextMarkers,
            "0.position",
            this.state.center
          );
          this.setState({
            center: nextCenter,
            markers: nextMarkers,
            places: places,
          });
          // refs.map.fitBounds(bounds);
        },
        onMarkerMounted: (ref) => {
          refs.marker = ref;
        },
        onPositionChanged: () => {
          const position = refs.marker.getPosition();
          var formatted_address = "";
          var address_components = "";
          var lat = position.lat();
          var lng = position.lng();
          Geocode.fromLatLng(lat, lng).then(
            (response) => {
              formatted_address = response.results[0].formatted_address;
              address_components = response.results[0].address_components;
              this.setState({
                address: formatted_address,
                address_components: address_components,
                lat: lat,
                lng: lng,
              });
            },
            (error) => {
              console.error(error);
            }
          );
          var addressField =
            document.getElementsByClassName("pac-target-input");
          addressField[0].value = this.state.address;
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={15}
      center={props.center}
      onBoundsChanged={props.onBoundsChanged}
    >
      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={props.onPlacesChanged}
        onPlaces={props.onPlaces(
          props.address,
          props.address_components,
          props.lat,
          props.lng
        )}
      >
        <input
          type="text"
          placeholder="Enter your address"
          autoComplete="off"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `40px`,
            marginTop: `10px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox>
      {props.markers.map((marker, index) => (
        <Marker
          key={index}
          position={marker.position}
          draggable={true}
          ref={props.onMarkerMounted}
          onPositionChanged={props.onPositionChanged}
        />
      ))}
    </GoogleMap>
  );
});
export default MapWithASearchBox;

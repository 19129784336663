import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Star from "../assets/img/star_icon.png";
import ArtisanImg from "../assets/img/artisan1.png";
import like from "../assets/img/like.png";
import disLike from "../assets/img/like.png";
import mapStyle from "../assets/mapStyle";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import Http from "../services/Http";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import MultiRangeSlider from "./Component/MultiRangeSlider";
import GMap from "./Component/GMap";
import { toast, Toaster } from "react-hot-toast";
import ArtisansNotFound from "./Component/modalComponents/ArtisansNotFound";
import { useTranslation } from "react-i18next";

const timeSlots = [
  { time: "06:00-07:00", duration: "06:00 To 07:00" },
  { time: "07:00-08:00", duration: "07:00 To 08:00" },
  { time: "08:00-09:00", duration: "08:00 To 09:00" },
  { time: "09:00-10:00", duration: "09:00 To 10:00" },
  { time: "10:00-11:00", duration: "10:00 To 11:00" },
  { time: "11:00-12:00", duration: "11:00 To 12:00" },
  { time: "12:00-13:00", duration: "12:00 To 13:00" },
  { time: "13:00-14:00", duration: "13:00 To 14:00" },
  { time: "14:00-15:00", duration: "14:00 To 15:00" },
  { time: "15:00-16:00", duration: "15:00 To 16:00" },
  { time: "16:00-17:00", duration: "16:00 To 17:00" },
  { time: "17:00-18:00", duration: "17:00 To 18:00" },
  { time: "18:00-19:00", duration: "18:00 To 19:00" },
  { time: "19:00-20:00", duration: "19:00 To 20:00" },
];

const SelectTime = () => {
  const { t, i18n } = useTranslation();
  const [schedule, setSchedule] = useState("Right Now");
  const [selectTime, setSelectTime] = useState();
  const [showArtisans, setShowArtisans] = useState(false);
  const [selectFreeLancer, setSelectFreeLancer] = useState(false);
  const [artisanList, setArtisanList] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showartisanList, setShowArtisanList] = useState();
  const [timeSlotError, setTimeSlotError] = useState();
  const [minRate, setMinRate] = useState();
  const [maxRate, setMaxRate] = useState();
  const [favArtisan, setFavArtisan] = useState(false);
  const [favVal, setFavVal] = useState(true);

  const [timeSlot, setTimeSlot] = useState([]);

  const navigate = useNavigate();

  const { state } = useLocation();
  // console.clear();
  console.log(state);
  const { selectedAddress, categoryId, serviceId, orderType } = state;

  useEffect(() => {
    getTimeSlots(date);
  }, [date]);

  const getTimeSlots = async (date) => {
    let path = apiPath.timeSlots;
    var formData = new FormData();
    formData.append("service_date", moment(date).format("YYYY-MM-DD"));

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);

      if (res.data.success === true) {
        const time = convertArray(res.data.result);
        setTimeSlot(time);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const handleSubmit = () => {
    console.log("timeSlot--", selectTime, selectTime?.length > 0);
    if (selectTime?.length > 0) {
      setTimeSlotError(false);
      navigate("/all-artisans", {
        state: {
          scheduling: "Later",
          email: "",
          mobile: "",
          addressId: selectedAddress ? selectedAddress : "",
          categoryId: categoryId ? categoryId : "",
          subcategoryId: "",
          serviceId: serviceId ? serviceId : "",
          date: moment(date).format("yyyy-MM-DD"),
          time_slot: selectTime,
          min: minRate,
          max: maxRate,
          byService: true,
          orderType: orderType,
        },
      });
    } else {
      setTimeSlotError(true);
    }
  };

  const userToken = localStorage.getItem("userToken");

  const markFavorite = async (id) => {
    if (userToken) {
      setFavVal(!favVal);
      console.log("artisanId", id);
      const path = apiPath.markFavorite;
      const data = {
        fev_type: "Artisan",
        fev_id: `${id}`,
        fev_val: favVal === true ? "1" : "0",
      };
      const options = { method: "POST", data };

      try {
        const res = await Http(path, options);
        if (res?.data?.success === true) {
          setFavArtisan(!favArtisan);
          console.log(res.data.result);
          toast.success(res?.data?.msg);
          onRightnowClick();
        } else {
          console.log(res?.data?.msg);
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Login Required!");
    }
  };

  const onRightnowClick = async () => {
    // let today = new Date();
    // var date =
    //   today.getFullYear() +
    //   "-" +
    //   (today.getMonth() + 1) +
    //   "-" +
    //   today.getDate();
    // var time = today.getHours() + ":" + today.getMinutes();

    let path = apiPath.categoryBasedArtisanList;
    let formData = new FormData();
    formData.append(
      "address_id",
      selectedAddress ? selectedAddress.toString() : ""
    );
    formData.append("category_id", categoryId ? categoryId.toString() : "");
    formData.append("service_id", serviceId ? serviceId.toString() : "");
    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);

      if (res.data.success === true) {
        console.log("api hit successfully", res.data.result);
        if (res?.data?.result?.length === 0) {
          setShowArtisans(true);
        }
        setArtisanList(true);
        if (!res?.data?.result) setShowArtisanList([]);
        setShowArtisanList(res?.data?.result);
        // setCenter({
        //   lat: res.data.result.artisanList.docs.latitude,
        //   lng: res.data.result.artisanList.docs.longitude,
        // });
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };
  const onScheduling = (e) => {
    const { value } = e.target;
    console.log("value", value);
    setSchedule(value);
    setArtisanList(false);
  };

  useEffect(() => {
    onRightnowClick();
  }, []);

  const onTimeSelect = (e) => {
    const { value } = e.target;
    setSelectTime(value);
    console.log(value);
  };

  const artisanLat =
    showartisanList &&
    showartisanList.length > 0 &&
    showartisanList?.map((item) => {
      console.log(item, "item");
      const latLng = [];
      latLng?.push(
        item?.latitude === null ? 26.85635186559557 : parseFloat(item?.latitude)
      );
      latLng?.push(
        item?.longitude === null
          ? 75.76756965368986
          : parseFloat(item?.longitude)
      );
      latLng?.push("https://backend.toctocme.com" + item?.image);
      latLng?.push(item?.name);
      latLng?.push(item?.residence_address);
      latLng?.push(item?.artisan_level);
      return latLng;
    });

  return (
    <section className="select-time-sec">
      {showArtisans ? <ArtisansNotFound categoryId={categoryId} /> : ""}
      <Toaster position="top-right" />
      <div className="container">
        <div className="selecttime_main">
          <div className="time_map_left">
            <h4>{t("when_do_you_want_service")}?</h4>

            <div className="artisans_radioBtn">
              <form>
                <div className="radio_cls">
                  <label className="custom_radio">
                    {" "}
                    <input
                      type="radio"
                      id="radio"
                      value="Right Now"
                      onClick={onRightnowClick}
                      checked={schedule === "Right Now"}
                      onChange={onScheduling}
                    />
                    {t("RightNow")}
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom_radio">
                    <input
                      type="radio"
                      id="radio"
                      value="Later"
                      checked={schedule === "Later"}
                      onChange={onScheduling}
                    />
                    {t("Later")}
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
            </div>
            {schedule === "Right Now" && (
              <>
                <div className="time_map">
                  <div className="map_card">
                    {showartisanList?.length > 0 ? (
                      <GMap latLng={artisanLat} />
                    ) : (
                      <>
                        <h3>{t("Uhoh")}..</h3>
                        <h4>{t("Noartisanisavailablecurrently")}</h4>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {!artisanList && (
              <div className="time-select-slot">
                <Row>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <label htmlFor="">{t("specific_date")}</label>
                      <DatePicker
                        showIcon
                        className="form-control"
                        showYearDropdown
                        selected={date}
                        onChange={(date) => setDate(date)}
                        minDate={moment().toDate()}
                        dateFormat="dd-MM-yyyy"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <div className="price-range-sec">
                        <h5>{t("price")}</h5>
                        <div className="range_slider">
                          <MultiRangeSlider
                            min={0}
                            max={1000}
                            onChange={({ min, max }) => {
                              console.log(`min = ${min}, max = ${max}`);
                              setMinRate(min);
                              setMaxRate(max);
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <h5>{t("time_slot")}</h5>
                    <div className="time-slot">
                      {timeSlot.map((item, idx) => (
                        <label className="time-label" key={idx}>
                          <input
                            type="radio"
                            id="radio"
                            value={item.time}
                            checked={selectTime === item.time}
                            onChange={onTimeSelect}
                          />
                          <span className="checkmark">{item.duration}</span>
                        </label>
                      ))}
                      {timeSlotError && (
                        <span className="error_msg">
                          {"Pleas select a time slot!"}
                        </span>
                      )}
                    </div>
                  </Col>
                  <div className="custom_checkbox mt-4">
                    <Form.Check
                      id="featured_artisan"
                      name="featured_artisan"
                      size={18}
                      label={t("include_freelancers_as_well")}
                      value={setSelectFreeLancer}
                      onChange={() => setSelectFreeLancer(!selectFreeLancer)}
                      htmlFor={"featured_artisan"}
                    />
                  </div>
                </Row>
              </div>
            )}
          </div>
          <div className="time_list_wrap">
            <div className="time_bg_wrap">
              {artisanList &&
                showartisanList?.map((item, idx) => (
                  <div className="artisan_bg select" key={idx}>
                    <div className="artisan_left_img">
                      <div className="artisan_img">
                        <img src={`${IMAGE_PATH + item.image}`} alt="" />
                      </div>
                      <span className="like_img cursor-pointer">
                        <img
                          onClick={() => markFavorite(item.id)}
                          src={item.fevData?.id ? like : disLike}
                          alt="likeImg"
                        />
                      </span>
                    </div>

                    <div className="artisan_right_cont">
                      <div className="tag">
                        <span>Most Active</span>
                      </div>
                      <div className="artisan_title">
                        <h5>{item.name}</h5>
                        <div className="tagLine text-muted">
                          {item.artisan_level}
                        </div>
                      </div>
                      <div className="jobs_review">
                        <div className="job_cont">
                          <span>
                            #{item.job_done === null ? "No" : item.job_done}{" "}
                            {t("Jobs")}
                          </span>
                        </div>
                        <div className="review_cont">
                          <span className="review-ic">
                            <img src={Star} alt="" />
                          </span>
                          <span>{item.rating}</span>
                        </div>
                      </div>
                      <div className="artisan_price_wrap">
                        {item?.price > 0 && (
                          <div className="artisan_price_left">
                            <h5>€ {item?.price}</h5>
                          </div>
                        )}

                        <div className="artisan_view_btn">
                          <Link
                            to={`/artisan-detail/${item.id}`}
                            state={{
                              scheduling: "Right now",
                              addressId: selectedAddress ? selectedAddress : "",
                              categoryId: categoryId ? categoryId : "",
                              serviceId: serviceId ? serviceId : "",
                              artisanID: item.id ? item.id : "",
                              scheduling: schedule ? schedule : "",
                              email: "",
                              mobile: "",
                              orderType: orderType ? orderType : "Soft",
                            }}
                          >
                            <Button className="btn_primary viewDetailbtn">
                              {t("view_detail")}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {!artisanList && (
                <div className="login-btn">
                  {/* <Link
                  to="/browse-artisans"
                  state={{
                    scheduling: "Later",
                    email: email ? email : "",
                    mobile: mobile ? mobile : "",
                    addressId: addressId ? addressId : "",
                    date: moment(date).format("yyyy-MM-DD"),
                    time_slot: selectTime,
                    min: minRate,
                    max: maxRate,
                  }}
                > */}
                  <Button className="btn_primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                  {/* </Link> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function convertArray(givenArray) {
  const newArray = givenArray.map((item) => {
    const time = `${item.from}-${item.to}`;
    const duration = `${item.from} To ${item.to}`;
    return { time, duration };
  });

  return newArray;
}

export default SelectTime;

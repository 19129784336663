import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={2100}
      height={2100}
      viewBox="0 0 700 1024"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="-914" y="-23" rx="8" ry="8" width="1804" height="165" />
      <rect x="190" y="158" rx="8" ry="8" width="136" height="66" />
      <rect x="317" y="126" rx="8" ry="8" width="1" height="6" />
      <rect x="27" y="1600" rx="8" ry="8" width="900" height="91" />
      <rect x="81" y="237" rx="8" ry="8" width="550" height="111" />
      <rect x="126" y="329" rx="8" ry="8" width="1" height="1" />
      <rect x="406" y="341" rx="8" ry="8" width="2" height="1" />
      <rect x="28" y="399" rx="8" ry="8" width="150" height="78" />
      <rect x="213" y="398" rx="8" ry="8" width="161" height="75" />
      <rect x="408" y="396" rx="8" ry="8" width="150" height="76" />
      <rect x="50" y="526" rx="8" ry="8" width="225" height="89" />
      <rect x="307" y="529" rx="8" ry="8" width="216" height="87" />
      <rect x="33" y="909" rx="8" ry="8" width="228" height="87" />
      <rect x="49" y="661" rx="8" ry="8" width="136" height="70" />
      <rect x="406" y="660" rx="8" ry="8" width="135" height="70" />
      <rect x="55" y="786" rx="8" ry="8" width="107" height="62" />
      <rect x="185" y="784" rx="8" ry="8" width="101" height="62" />
      <rect x="316" y="782" rx="8" ry="8" width="112" height="64" />
      <rect x="447" y="784" rx="8" ry="8" width="103" height="57" />
      <rect x="319" y="906" rx="8" ry="8" width="247" height="90" />
      <rect x="355" y="156" rx="8" ry="8" width="117" height="66" />
      <rect x="232" y="660" rx="8" ry="8" width="136" height="70" />
    </ContentLoader>
  );
};

export default Skeleton;

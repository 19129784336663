import React, { useState, useMemo, useEffect, useContext } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import OtpVerification from "./OtpVerification";
import countryList from "react-select-country-list";
import Imgcross from "../../../assets/img/cross.png";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import toast, { Toaster } from "react-hot-toast";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import { internalIpV6, internalIpV4 } from "internal-ip";
import Login from "./Login";
import { ModalContext } from "../home/Navbar";
import {
  ValidationForm,
  TextInput,
  TextInputGroup,
  FileInput,
  SelectGroup,
  Checkbox,
} from "react-bootstrap4-form-validation";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";

const ForgetPassword = ({
  data,
  type,
  onClose,
  showOTP,
  show,
  hide,
  showLogin,
}) => {
  const { t, i18n } = useTranslation();
  const [isSubmit, setIsSubmit] = useState(true);
  const [errors, setErrors] = useState({});
  const { getSignup, setSignup } = useContext(ModalContext);
  const formRef = React.createRef();

  /* Mobile validation */
  const validate = (values) => {
    const errors = {};
    if (!values.mobile) {
      errors.mobile = "Mobile Number is required";
    } else if (values?.mobile?.length < 7) {
      errors.mobile = "Mobile Number must be valid.";
    }
    setIsSubmit(false);
    return errors;
  };

  const ForgetPasswordSubmit = async (e, formData, inputs) => {
    e.preventDefault();
    setErrors(validate(getSignup));
    setIsSubmit(true);
    let isValidMobile = getSignup?.mobile?.length >= 8;
    if (isValidMobile) {
      let path = apiPath.signup_sendOTP;
      var formData = new FormData();
      formData.append("type", type);
      formData.append("user_type", "2");
      formData.append("mobile", getSignup.mobile);
      formData.append("verify_type", "mobile");
      formData.append("country_code", "+" + getSignup.country_code);
      let options = {
        method: "POST",
        data: formData,
      };
      try {
        let res = await Http(path, options);
        if (res.data.success === true) {
          toast.success(res.data.msg);
          showOTP("forgot");
          const { token } = res.data;
          localStorage.setItem("token", token);
          data.userLogin(res.data.formData);
          toast.success(res.data.message);
          // window.location.reload();
        } else {
          toast.error(res.data.msg);
        }
      } catch (err) {
        if (err.response) {
          toast.error(err);
        } else if (err.response?.status === 400) {
          toast.error("Missing Username or Password");
        } else if (err.response?.status === 401) {
          toast.error("Unauthorized");
        } else {
          //toast.error(err.response);
        }
      }
    }
  };

  const ForgetPasswordErrorSubmit = (e, formData, inputs) => {
    e.preventDefault();
  };
  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setSignup({
      country_code: country_code,
      mobile: value.slice(data.dialCode.length),
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={hide}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 id="exampleModalLabel">{t("ForgotPassword")}</h5>
          <button type="button" className="btn-close" onClick={hide}>
            <img src={Imgcross} onClick={hide} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <ValidationForm
            onSubmit={ForgetPasswordSubmit}
            onErrorSubmit={ForgetPasswordErrorSubmit}
            ref={formRef}
          >
            <div>
              <Form.Group className="form-group" controlId="form-group">
                <Form.Label>{t("EnterMobileNumber")}</Form.Label>
                <PhoneInput
                  inputProps={{
                    name: "mobile",
                    required: true,
                    autoFocus: false,
                  }}
                  isValid={(value, country) => {
                    if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else {
                      return true;
                    }
                  }}
                  country={"fr"}
                  value={getSignup.phone}
                  onChange={handleChange}
                />
                <span className="error_msg">{errors.mobile}</span>
              </Form.Group>
              <div>
                <Button type="submit" className="btn btn_primary">
                  {t("SendOTP")}
                </Button>
              </div>
              {/*<div className="already_cls">
                          <p>If you already have an account? <Link to="#" onClick={openLogin}>Sign In</Link></p>
                                                      </div>*/}
            </div>
          </ValidationForm>
          {/* <span>
            Already have an account ? <Link onClick={onLoginClick}>Login</Link>{" "}
            from here
          </span> */}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ForgetPassword;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import Imgcross from "../../../assets/img/cross.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { Toaster, toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ArtisansNotFound = ({ categoryId }) => {
  const initialValues = {
    email: "",
    request: "",
  };
  // const [email, setEmail] = useState("");
  // const [trackingID, setTrackingID] = useState("");
  const [showArtisans, setShowArtisans] = useState(false);
  const navigate = useNavigate();
  // const [request, setRequest] = useState("");
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const { t, i18n } = useTranslation();
  const anf = () => {
    setShowArtisans(false);
    // setTimeout(() => {
    //   navigate(-1);
    // }, 500);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormValues({ ...formValues, [name]: value });
  };

  const REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email Address is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email address!";
    }
    if (!values.request) {
      errors.request = "Please enter your request!";
    }

    return errors;
  };

  const onHandleSubmit = async (e) => {
    // let path = apiPath.artisanRequest;
    // var formData = new FormData();
    // formData.append("email", email);
    // formData.append("remark", request);
    // formData.append("categoryId", props.categories_id);
    // let options = {
    //   method: "POST",
    //   data: formData,
    // };
    // try {
    //   let res = await Http(path, options);
    //   console.log(res, "-------------------------");
    //   if (res.data.success === true) {
    //     console.log("api hit successfully", res.data.msg);
    //     console.log(res.data.msg);
    // e.preventDefault();
    // setFormErrors(validate(formValues));
    // if (formValues.email && formValues.request !== "") {
    //   setShowArtisans(false);
    //   setFormErrors("");
    // }
    // console.log(data);
    //     } else {
    //       console.log(res.data.msg);
    //     }
    //   } catch (err) {
    //     console.log(err.res);
    //   }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .strict()
      .matches(REGEX, "Email is not valid!")
      .required("Email is required"),
    request: Yup.string().required("Request is required!"),
  });

  const onSubmit = async (values) => {
    let path = apiPath.artisanRequest;
    let formData = new FormData();
    formData.append("email", values.email);
    formData.append("remark", values.request);
    formData.append("categoryId", categoryId?.toString());

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        toast.success(res?.data?.msg);
        console.log("api hit successfully", res.data.msg);
        console.log(res.data.msg);
        setTimeout(() => {
          navigate("/");
        }, 500);
        setShowArtisans(false);
      } else {
        toast.error(res?.data?.msg);
        setShowArtisans(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setShowArtisans(true);
  }, [categoryId]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  console.log("categoryId", categoryId);

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        show={showArtisans}
        animation={true}
        size="md"
        onHide={anf}
        backdrop="static"
        keyboard={false}
        dialogClassName="morequestdalStyle"
        centered
      >
        <Modal.Header>
          <h5 className="modal-title fw-bold text-dark" id="exampleModalLabel">
          {t("NoArtisanAvailable")}
          </h5>
          <button type="button" className="btn-close" onClick={anf}>
            <img src={Imgcross} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="modal_cont">
            <p>
            {t("noArtisanText")}
            </p>
          </div>
          <div className="popup-cont">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                errors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Form.Group className="form-group" controlId="form-group">
                    <Form.Label>{t("Email")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder={t("Email")}
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="error_msg">{errors.email}</span>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>{t("request")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="request"
                      placeholder={t("request")}
                      value={values.request}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="error_msg">{errors.request}</span>
                  </Form.Group>

                  <div className="login-btn">
                    <Button
                      type="submit"
                      className="btn btn_primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ArtisansNotFound;

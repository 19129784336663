import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

const ScrollToTop = () => {
  let pathname = useNavigate();
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname]);
  return null;
};

export default ScrollToTop;

import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Thankyou from "../assets/img/thankyou.png";
import DownloadInvoice from "../assets/img/download_invoice.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PDF from "./Component/PDF";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import Http from "../services/Http";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";

const ThankYou = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [orderDetail, setOrderDetail] = useState();
  const [invoiceUrl, setInvoiceUrl] = useState();
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state);

  useEffect(() => {
    setLoading(true);
    getInvoiceInfo();
    downloadPdf();
  }, []);

  const getInvoiceInfo = async () => {
    const path = apiPath.orderDetail;
    const data = { orderNo: state?.bookingNo ? state?.bookingNo : "" };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      console.log("res?.data", res?.data);
      if (res?.data?.success === true) {
        // toast.success(res?.data?.msg);
        
        setOrderDetail(res?.data?.result);
      } else {
        toast.error(res?.data?.msg);
        console.log(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("orderDetail", orderDetail);

  const downloadPdf = async () => {
    let path = apiPath.downloadInvoice + "?orderNo=" + state?.bookingNo;
    let options = {
      method: "GET",
    };

    try {
      let res = await Http(path, options);
      console.log('res?.data', res?.data);
      if (res?.data?.success === true) {
        // toast.success(res?.data?.msg);
        setInvoiceUrl(res?.data?.result);
        setLoading(false);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const linkTo = `/order-detail/${state?.bookingNo}`;
  return (
    <>
      <Toaster position="top-right" />
      <section className="thankyou-main">
        <Container>
          {/* <PDFViewer>
            <PDF orderDetail={orderDetail} />
          </PDFViewer> */}
          {loading ? (
            [1, 2, 3].map((item) => <Skeleton active key={item} />)
          ) : (
            <div className="thankyou_inner">
              <div className="thankyou-icon">
                <img src={Thankyou} alt="" />
              </div>
              <div className="thankyou-cont">
                <h3>{t("thank_you")}!</h3>
                <p>{t("thank_you_msg")}.</p>
                <p className="booking_id">
                  {t("BookingID")}: {state?.bookingNo}
                </p>
                <div className="thankyou-btn">
                  <Link to={linkTo}>
                    <Button className="btn_primary">{t("continue")} </Button>
                  </Link>
                  <Link to="/">
                    <Button className="btn_primary">{t("back_to_home")}</Button>
                  </Link>
                </div>
                <div className="order-btn">
                  <Button
                    className="border-btn d-none"
                    onClick={() =>
                      navigate("/track-artisan", { state: state?.bookingNo })
                    }
                  >
                    Track Order
                  </Button>
                  <Link
                    className="border-btn btn btn-primary"
                    to={`${IMAGE_PATH}${invoiceUrl}`}
                    target="_blank"
                  >
                    <span className="invoice-icon">
                      <img src={DownloadInvoice} alt="" />
                    </span>{" "}
                    {t("invoice")}
                  </Link>
                  {/* <PDFDownloadLink
                  document={<PDF orderDetail={orderDetail} />}
                  fileName="invoice.pdf"
                  className="border-btn btn btn-primary"
                >
                  <span className="invoice-icon">
                    <img src={DownloadInvoice} alt="" />
                  </span>{" "}
                  Invoice
                </PDFDownloadLink> */}
                </div>
              </div>
            </div>
          )}
        </Container>
      </section>
    </>
  );
};
export default ThankYou;

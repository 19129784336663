import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Nav,
  TabContainer,
  TabContent,
  TabPane,
} from "react-bootstrap";
import mIcon1 from "../assets/img/a-ic1.png";
import mIcon2 from "../assets/img/a-ic2.png";
import mIcon3 from "../assets/img/a-ic3.png";
import mIcon4 from "../assets/img/a-ic4.png";
import mIcon5 from "../assets/img/a-ic5.png";
import ArtisanImg from "../assets/img/artisan1.png";
import otherServiceImg from "../assets/img/otherServices.jpg";
import Star from "../assets/img/star_icon.png";
import DownloadInvoice from "../assets/img/download_invoice.png";
import CheckIcon from "../assets/img/check_icon.png";
import noData from "../assets/img/no-data.png";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import OwlCarousel from "react-owl-carousel3";
import { Link, useNavigate, useParams } from "react-router-dom";
import Http from "../services/Http";
import toast, { Toaster } from "react-hot-toast";
import UserSidebar from "./UserSidebar";
import moment from "moment";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
const center = { lat: 48.8584, lng: 2.2945 };

const OrderDetail = () => {
  const { t, i18n } = useTranslation();
  const [map, setMap] = useState(/**@type google.maps.Map */ (null));
  const [jobLocationRadio, setJobLocationRadio] = useState("artisansPlace");
  const [userLocationRadio, setUserLocationRadio] = useState("home");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [otherService, setOtherService] = useState();
  const [productDetail, setProductDetails] = useState();
  const [artisanDetail, setArtisanDetails] = useState();
  const [customerDetail, setCustomerDetails] = useState();
  const [invoiceUrl, setInvoiceUrl] = useState();
  const [isActive, setIsActive] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  let lang = localStorage.getItem("lang");
  const orderId = id;

  const onJobLocationSelect = (e) => {
    setJobLocationRadio(e.target.value);
  };

  const category = {
    rtl: true,
    margin: 15,
    nav: true,
    dots: false,
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      420: {
        items: 1,
      },
      577: {
        items: 2,
      },
      991: {
        items: 4,
      },
      1366: {
        items: 4,
      },
    },
  };
  const onUserLocationSelect = (e) => {
    setUserLocationRadio(e.target.value);
  };

  useEffect(() => {
    viewOrderDetail();
    downloadPdf();
    otherServices();
  }, [refresh]);

  const viewOrderDetail = async () => {
    const path = apiPath.orderDetail;
    const data = { orderNo: orderId };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        setOrderDetail(res?.data?.result);
        setProductDetails(res?.data?.result?.products);
        setArtisanDetails(res?.data?.result?.artisanData);
        setArtisanDetails(res?.data?.result?.artisanData);
        setCustomerDetails(res?.data?.result?.userData);
      } else {
        // toast.error(res?.data?.msg);
        console.log(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmCancel = (id) => {
    console.log(id);
    Swal.fire({
      title: "Cancel Order!",
      text: "Do you really  want to cancel this order?",
      icon: "warning",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result?.isConfirmed === true) {
        cancelOrder(id);
      } else {
        Swal.fire("Cancelled", "", "error");
      }
    });
  };

  const cancelOrder = async (orderNo) => {
    const path = apiPath.cancelOrder;
    const data = { orderNo: `${orderNo}` };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setRefresh((prev) => !prev);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const otherServices = async (catId) => {
    const path = apiPath.getserviceListCategoryBase;
    const data = { categoryId: catId ? catId : "1" };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        setOtherService(res?.data?.result?.docs);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPdf = async () => {
    let path = apiPath.downloadInvoice + "?orderNo=" + orderId;
    let options = {
      method: "GET",
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        // toast.success(res?.data?.msg);
        setInvoiceUrl(res?.data?.result);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster position="top-right" />
      <section className="order-main">
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <div className="account-sidebar">
                <UserSidebar />
              </div>
            </div>
            <div className="account-sidebar-right">
              <div className="m_sidebar_bg">
                <div className="order-detail">
                  <div className="order-dtl-top">
                    <div className="order-top-left">
                      {/* <h5>Services</h5> */}
                      {productDetail?.map((item, idx) => {
                        return (
                          <>
                            <div className="d-flex align-items-center justify-content-between order-top-para">
                              <h5
                                key={idx}
                                style={{ color: "black", font: "bold" }}
                              >
                                {item.serviceData === null
                                  ? item.question
                                  : lang === "en" ? item.serviceData.name : item.serviceData.name_fr}
                              </h5>

                              {orderDetail?.order_type !== "Heavy" && (
                                <p key={idx}>€ {item?.total_amount}</p>
                              )}
                            </div>

                            <p>{item.serviceData === null && item.answer}</p>
                            <p>{item.order_type === "Heavy" && item.answer}</p>
                          </>
                        );
                      })}
                      <p className="mt-2 mb-2">
                        {moment(orderDetail?.orderDate).format("yyyy-MM-DD")},{" "}
                        {orderDetail?.time_slot}{" "}
                      </p>
                    </div>
                  </div>
                  {orderDetail?.order_type === "Soft" && (
                    <div className="order-artisan-dtl">
                      <div className="artisan-order">
                        <div className="order-art-img">
                          <img
                            src={`${IMAGE_PATH + artisanDetail?.image}`}
                            alt=""
                          />
                        </div>
                        <div className="order-artisan-cont">
                          <h5>{artisanDetail?.name},</h5>
                          <p>{artisanDetail?.artisan_level}</p>
                          <div className="review_cont">
                            <span className="review-ic">
                              <img src={Star} alt="" />
                            </span>
                            <span>{artisanDetail?.rating}</span>
                          </div>
                        </div>
                        <div className="order_track_btn">
                          {["1", "2", "4"].includes(orderDetail?.status) && (
                            <Button
                              className="btn_primary"
                              onClick={() =>
                                navigate("/track-artisan", { state: orderId })
                              }
                            >
                              {t("TrackArtisan")}
                            </Button>
                          )}
                          {parseInt(orderDetail?.status) < 5 ? (
                            <Button
                              className="btn_primary"
                              onClick={() => confirmCancel(orderId)}
                            >
                              {t("cancel_order")}
                            </Button>
                          ) : (
                            ""
                          )}
                          <div className="order-btn">
                            <Link
                              className="border-btn btn btn-primary"
                              to={`${IMAGE_PATH}${invoiceUrl}`}
                              target="_blank"
                            >
                              <span className="invoice-icon">
                                <img src={DownloadInvoice} alt="" />
                              </span>{" "}
                              {t("invoice")}
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* <div className="d-flex flex-column justify-content-start p-2 mt-2">
                        <>
                          <span>
                            <b>{t("artisan_location")}</b>{" "}
                          </span>
                          <span> {artisanDetail?.residence_address}</span>
                          <span>
                            <b>{t("Email")}</b>{" "}
                          </span>
                          <span> {artisanDetail?.email}</span>
                          <span>
                            <b>{t('MobileNumber')}</b>{" "}
                          </span>
                          <span>
                            {" "}
                            {artisanDetail?.country_code} &nbsp;
                            {artisanDetail?.mobile} &nbsp;
                          </span>
                        </>
                      </div> */}
                    </div>
                  )}
                  {orderDetail?.order_type === "Offline" && (
                    <div className="order-artisan-dtl">
                      <div className="artisan-order">
                        <div className="order-art-img">
                          <img
                            src={`${IMAGE_PATH + artisanDetail?.image}`}
                            alt=""
                          />
                        </div>
                        <div className="order-artisan-cont">
                          <h5>{artisanDetail?.name},</h5>
                          <p>{artisanDetail?.artisan_level}</p>
                          <div className="review_cont">
                            <span className="review-ic">
                              <img src={Star} alt="" />
                            </span>
                            <span>{artisanDetail?.rating}</span>
                          </div>
                        </div>
                        <div className="order_track_btn">
                          {["1", "2", "4"].includes(orderDetail?.status) && (
                            <Button
                              className="btn btn_primary"
                              onClick={() =>
                                navigate("/track-artisan", { state: orderId })
                              }
                            >
                              {t("TrackArtisan")}
                            </Button>
                          )}
                          {parseInt(orderDetail?.status) < 5 ? (
                            <Button
                              className="btn_primary"
                              onClick={() => confirmCancel(orderId)}
                            >
                              {t("cancel_order")}
                            </Button>
                          ) : (
                            ""
                          )}
                          <div className="order-btn">
                            <Link
                              className="border-btn btn btn-primary"
                              to={`${IMAGE_PATH}${invoiceUrl}`}
                              target="_blank"
                            >
                              <span className="invoice-icon">
                                <img src={DownloadInvoice} alt="" />
                              </span>{" "}
                              {t("invoice")}
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* <div className="d-flex flex-column justify-content-start p-2 mt-2">
                        <span>
                          <b>{t("artisan_location")}</b>{" "}
                        </span>
                        <span> {artisanDetail?.residence_address}</span>
                        <span>
                          <b>{t("Email")}</b>{" "}
                        </span>
                        <span> {artisanDetail?.email}</span>
                        <span>
                          <b>{t('MobileNumber')}</b>{" "}
                        </span>
                        <span>
                          {artisanDetail?.country_code} &nbsp;
                          {artisanDetail?.mobile}
                        </span>
                      </div> */}
                    </div>
                  )}
                  {orderDetail?.order_type === "Heavy" && (
                    <div className="d-flex justify-content-end">
                      <div className="order-btn">
                        <Link
                          className="border-btn btn btn-primary"
                          to={`${IMAGE_PATH}${invoiceUrl}`}
                          target="_blank"
                        >
                          <span className="invoice-icon">
                            <img src={DownloadInvoice} alt="" />
                          </span>{" "}
                          {t("invoice")}
                        </Link>
                      </div>
                    </div>
                  )}
                  {orderDetail?.order_type === "Heavy" &&
                  orderDetail?.paymentStatus !== "Pending" ? (
                    <div className="order-artisan-dtl">
                      <div className="artisan-order">
                        <div className="order-art-img">
                          <img
                            src={`${IMAGE_PATH + artisanDetail?.image}`}
                            alt=""
                          />
                        </div>
                        <div className="order-artisan-cont">
                          <h5>{artisanDetail?.name},</h5>
                          <p>{artisanDetail?.artisan_level}</p>
                          <div className="review_cont">
                            <span className="review-ic">
                              <img src={Star} alt="" />
                            </span>
                            <span>{artisanDetail?.rating}</span>
                          </div>
                        </div>
                        <div className="order_track_btn">
                          {["1", "2", "4"].includes(orderDetail?.status) && (
                            <Button
                              className="btn_primary"
                              onClick={() =>
                                navigate("/track-artisan", {
                                  state: orderId,
                                })
                              }
                            >
                              {t("TrackArtisan")}
                            </Button>
                          )}
                          {parseInt(orderDetail?.status) < 5 ? (
                            <Button
                              className="btn_primary"
                              onClick={() => confirmCancel(orderId)}
                            >
                              {t("cancel_order")}
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-start p-2 mt-2">
                        {orderDetail?.order_type === "Heavy" &&
                        orderDetail?.paymentStatus !== "Pending" ? (
                          <>
                            <span>
                              <b>{t("artisan_location")}</b>{" "}
                            </span>
                            <span> {artisanDetail?.residence_address}</span>
                            <span>
                              <b>{t("Email")}</b>{" "}
                            </span>
                            <span> {artisanDetail?.email}</span>
                            <span>
                              <b>{t("MobileNumber")}</b>{" "}
                            </span>
                            <span>
                              {artisanDetail?.country_code} &nbsp;
                              {artisanDetail?.mobile}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="order-price-specification">
                    <div className="price-specification">
                      <h5>{t("price_specification")}</h5>
                      <ul>
                        <li>
                          <span className="checkout-price-left">
                            {t("Amounttobepaid")}
                          </span>
                          <span className="checkout-price-right">
                            € {orderDetail?.orgAmount}
                          </span>
                        </li>
                        <li>
                          <span className="checkout-price-left">
                            {t("Discount")}
                          </span>
                          <span className="checkout-price-right">
                            - € {orderDetail?.discountAmount}
                          </span>
                        </li>
                        {parseFloat(orderDetail?.emergencyAmount) > 0 && (
                          <li>
                            <span className="checkout-price-left">
                              {t("EmergencyAmount")}
                            </span>
                            <span className="checkout-price-right">
                              € {orderDetail?.emergencyAmount}
                            </span>
                          </li>
                        )}

                        {/* <li>
                          <span className="checkout-price-left">Fee</span>
                          <span className="checkout-price-right">€ 10.00</span>
                        </li> */}
                        <li>
                          <span className="checkout-price-left">
                            {t("TotalAmount")}
                          </span>
                          <span className="checkout-price-right">
                            € {orderDetail?.totalAmount}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="other-service">
                    <h5>{t("other_service_like")}</h5>
                    <ul class="other-serv-inner">
                      <li
                        className={`cursor-pointer ${
                          isActive === 0 ? "active" : ""
                        } `}
                        onClick={() => {
                          otherServices("1");
                          setIsActive(0);
                        }}
                      >
                        Soft
                      </li>
                      <li
                        className={`cursor-pointer ${
                          isActive === 1 ? "active" : ""
                        } `}
                        onClick={() => {
                          otherServices("2");
                          setIsActive(1);
                        }}
                      >
                        Heavy
                      </li>
                      <li
                        className={`cursor-pointer ${
                          isActive === 2 ? "active" : ""
                        } `}
                        onClick={() => {
                          otherServices("3");
                          setIsActive(2);
                        }}
                      >
                        Reparing
                      </li>
                      <li
                        className={`cursor-pointer ${
                          isActive === 3 ? "active" : ""
                        } `}
                        onClick={() => {
                          otherServices("4");
                          setIsActive(3);
                        }}
                      >
                        Painting
                      </li>
                      <li
                        className={`cursor-pointer ${
                          isActive === 4 ? "active" : ""
                        } `}
                        onClick={() => {
                          otherServices("5");
                          setIsActive(4);
                        }}
                      >
                        Cleaning
                      </li>
                    </ul>
                  </div>
                  <div className="category_slider">
                    {otherService?.length > 0 ? (
                      <OwlCarousel
                        className="owl-carousel owl-theme"
                        {...category}
                      >
                        {otherService?.map((item, idx) => (
                          <div className="item" key={idx}>
                            <div className="slect_category_check">
                              <label className="custom_checkbox">
                                <div className="form-chcek">
                                  {/* <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={(e) => {
                                      const selected = e.target.checked;
                                      selected
                                        ? setProductData((prev) => [...prev, item])
                                        : removeProduct(item);
                                      setIsChecked(!isChecked);
                                      console.log(isChecked, item.id);
                                    }}
                                  /> */}
                                  {/* <span className="checkmark">
                                    <img src={CheckIcon} alt="" />
                                  </span> */}
                                  <div className="categroy_slider_inner">
                                    <div
                                      className="categroy_slider_img"
                                      onClick={() =>
                                        navigate(`/service-detail/${item.id}`)
                                      }
                                    >
                                      {item.image === null ||
                                      item.image === undefined ||
                                      item.image === " " ? (
                                        <img src={otherServiceImg} />
                                      ) : (
                                        <img
                                          src={`${IMAGE_PATH + item.image}`}
                                        />
                                      )}
                                      <span
                                        className={`${
                                          item.service_type === "Soft"
                                            ? "soft_badge"
                                            : item.service_type === "Offline"
                                            ? "offline_badge"
                                            : "heavy_badge"
                                        }`}
                                      >
                                        {item.service_type}
                                      </span>
                                    </div>
                                    <div className="categroy_prd_cont">
                                      <h4>
                                        {lang == "en"
                                          ? item?.name
                                          : item?.name_fr}
                                      </h4>
                                      <p>{t("starting_from")}</p>
                                      <h5>€ {item.min_price}</h5>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    ) : (
                      <div className="categroy_slider_inner">
                        <div className="no_data">
                          <img src={noData} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OrderDetail;

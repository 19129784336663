import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Nav,
  TabContainer,
  TabContent,
  TabPane,
} from "react-bootstrap";
import { Toaster, toast } from "react-hot-toast";
import Http from "../services/Http";
import UserSidebar from "./UserSidebar";
import apiPath from "../constants/apiPath";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import RateUs from "./Component/modalComponents/RateUs";
import noData from "../assets/img/no-data.png";
import { useTranslation } from "react-i18next";

const center = { lat: 48.8584, lng: 2.2945 };

const Order = () => {
  const { t, i18n } = useTranslation();
  const [rateUs, setRateUs] = useState(false);
  const [allAddressList, setAllAddressList] = useState();
  const [orderNo, setOrderNo] = useState();
  const [productStatus, setProductStatus] = useState();
  const [pendingOrder, setPendingOrder] = useState();
  const [completedOrder, setCompletedOrder] = useState();
  const [canceledOrder, setCanceledOrder] = useState();
  const [onCancel, setOnCancel] = useState(false);
  let lang = localStorage.getItem("lang");
  const navigate = useNavigate();

  useEffect(() => {
    allOrders();
    pendingOrders();
    completedOrders();
    canceledOrders();
  }, [onCancel]);

  const onClose = () => {
    setRateUs(false);
  };

  const allOrders = async () => {
    const path = apiPath.orderList;
    const data = { status: "all" };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        setAllAddressList(res?.data?.result);
        setProductStatus(res.data?.result?.products);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const pendingOrders = async () => {
    const path = apiPath.orderList;
    const data = { status: "Progress" };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        setPendingOrder(res?.data?.result);
        setProductStatus(res.data?.result?.products);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const completedOrders = async () => {
    const path = apiPath.orderList;
    const data = { status: "5" };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        setCompletedOrder(res?.data?.result);
        setProductStatus(res.data?.result?.products);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("completedOrder", completedOrder?.length > 0);

  const canceledOrders = async () => {
    const path = apiPath.orderList;
    const data = { status: "6" };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        setCanceledOrder(res?.data?.result);
        setProductStatus(res.data?.result?.products);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmCancel = (id) => {
    console.log(id);
    Swal.fire({
      title: "Cancel Order!",
      text: "Do you really  want to cancel this order?",
      icon: "warning",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result?.isConfirmed === true) {
        cancelOrder(id);
      } else {
        Swal.fire("Cancelled", "", "error");
      }
    });
  };
  const setHeavyPreviewData = async () => {
    let path = apiPath.getPreview;
    let options = {
      method: "POST",
      data: { order_type: "Heavy" },
    };
    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        console.log("rest", res.data?.result);
        //console.log(">>artisanData>>", res.data?.result?.cartData?.artisanData);
        //console.log(">>scheduling>>", res.data?.result?.cartData?.service_type);
        console.log(">>categoryId>>", res.data?.result?.cartData?.service_type);
        // navigate("/heavy-order-checkout", {
        //   state: { artisanData: res.data?.result?.cartData?.artisanData },
        // });
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };
  const handelReOrder = async (order_no, order_type) => {
    Swal.fire({
      title: "Re Order!",
      text: "Do you really want to order this again ?",
      icon: "warning",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let path = apiPath.reOrder;
        const data = { orderNo: order_no ? order_no : "" };
        let options = { method: "POST", data };
        try {
          let res = await Http(path, options);
          if (res?.data?.success === true) {
            toast.success(res?.data?.msg);
            if (order_type != "Heavy") {
              navigate("/checkout", {
                state: { orderType: order_type },
              });
            } else {
              setHeavyPreviewData();
            }
          } else {
            toast.error(res?.data?.msg);
          }
        } catch (error) {
          console.log(error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked "Cancel"
        Swal.fire("Cancelled", "Action canceled.", "error");
      }
    });
  };

  const cancelOrder = async (orderNo) => {
    const path = apiPath.cancelOrder;
    const data = { orderNo: `${orderNo}` };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setCanceledOrder(res?.data?.result);
        setOnCancel(true);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelViewOrder = (id) => {
    console.log("orderNo", id);
    navigate(`/order-detail/${id}`);
  };

  return (
    <>
      {rateUs && <RateUs orderNo={orderNo} onClose={onClose} />}
      <section className="order-main">
        <Toaster position="top-right" />
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <UserSidebar />
            </div>
            <div className="account-sidebar-right">
              <div className="m_sidebar_bg h-100">
                <TabContainer defaultActiveKey="all" className="h-100">
                  <Nav variant="pills" className="white_bg">
                    <Nav.Item>
                      <Nav.Link eventKey="all">{t("All Orders")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="progress">{t("Progress")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="completed">{t("Completed")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="cancelled">{t("Cancelled")}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <TabContent className="h-100">
                    <TabPane className="h-100" eventKey="all">
                      {allAddressList?.length > 0 ? (
                        allAddressList?.map((item, idx) => (
                          <div className="order-list" key={idx}>
                            <div className="order-inner mb-4">
                              <div className="order-top">
                                <div className="order-id">
                                  <h5>#{item?.orderNo}</h5>
                                </div>
                                <div className="order-status">
                                  <span
                                    className={`${
                                      item?.order_type === "Soft"
                                        ? "order-serv-type"
                                        : item?.order_type === "Heavy"
                                        ? "order-serv-heavy"
                                        : "order-serv-off"
                                    }`}
                                  >
                                    {item?.order_type}
                                  </span>
                                  {/* 0 = Pending , 1 = Accepted, 2 = Processing, 3= Rejected, 4 = Arrived, 5 = Delivery, 6 = Cancel */}
                                  <span
                                    className={
                                      item?.status === "0"
                                        ? "order-type-status progress"
                                        : item?.status === "1"
                                        ? "order-type-status complete"
                                        : item?.status === "2"
                                        ? "order-type-status progress"
                                        : item?.status === "6"
                                        ? "order-type-status cancel"
                                        : item?.status === "4"
                                        ? "order-type-status progress"
                                        : item?.status === "5"
                                        ? "order-type-status complete"
                                        : "order-type-status cancel"
                                    }
                                  >
                                    {item?.status == "0"
                                      ? "Pending"
                                      : item?.status === "1"
                                      ? "Accepted"
                                      : item?.status === "2"
                                      ? "Processing"
                                      : item?.status === "3"
                                      ? "Rejected"
                                      : item?.status === "4"
                                      ? "Arrived"
                                      : item?.status === "5"
                                      ? "Completed"
                                      : "Cancelled"}
                                  </span>
                                </div>
                              </div>
                              <div className="order-desc">
                                <p className="order-date">
                                  {moment(`${item?.orderDate}`).format(
                                    "yyyy-MM-DD hh:mm A"
                                  )}
                                </p>
                              </div>
                              <div className="order-serv-name">
                                {item?.products?.map((data, idx) => (
                                  <h4 key={idx}>
                                    {lang == "en"
                                      ? data?.serviceData?.name
                                      : data?.serviceData?.name_fr}
                                  </h4>
                                ))}
                                <div className="order-price">
                                  <span>€ {item?.totalAmount}</span>
                                </div>
                              </div>
                              <div className="order_btn">
                                <Button
                                  className="btn_primary"
                                  onClick={() => handelViewOrder(item?.orderNo)}
                                >
                                  {t("view_order")}
                                </Button>
                                {item.status === "0" && (
                                  <Button
                                    className="btn_black text-uppercase"
                                    onClick={() => confirmCancel(item?.orderNo)}
                                  >
                                    {t("cancel_order")}
                                  </Button>
                                )}
                                {item?.paymentStatus === "Pending" &&
                                  item?.status === "1" && (
                                    <Button
                                      className="btn_primary text-uppercase"
                                      onClick={() => {
                                        item?.orderNo?.length > 0
                                          ? navigate(
                                              `/payment-gateway/${item.orderNo}`,
                                              {
                                                state: {
                                                  amount: item?.totalAmount,
                                                  notifyId: item?.id,
                                                },
                                              }
                                            )
                                          : toast.error("Order no. not valid");
                                      }}
                                    >
                                      {t("paynow")}
                                    </Button>
                                  )}
                                {(item.status === "5" ||
                                  item.status === "6") && (
                                  <Button
                                    className="btn_black text-uppercase"
                                    onClick={() =>
                                      handelReOrder(
                                        item?.orderNo,
                                        item?.order_type
                                      )
                                    }
                                  >
                                    {t("reorder")}
                                  </Button>
                                )}
                                {/* {(item?.status === "5" ||
                                    item?.status === "6") && (
                                    <Button
                                      className="btn_primary"
                                      onClick={() => handelReOrder(item?.id)}
                                    >
                                      Re-Order
                                    </Button>
                                  )} */}
                                {/* <Button className="btn_black">Rate Us</Button> */}
                                {/* <span className="order-type-status cancel">
                                    Cancelled
                                  </span> */}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="categroy_slider_inner h-100 d-flex align-items-center justify-content-between">
                          <div className="no_data text-center">
                            <img src={noData} />
                          </div>
                        </div>
                      )}
                      {/* <NoOrderFound/> */}
                    </TabPane>
                    <TabPane className="h-100" eventKey="progress">
                      {pendingOrder !== "undefined" &&
                      pendingOrder?.length > 0 ? (
                        pendingOrder?.map((item, idx) => (
                          <div className="order-inner" key={idx}>
                            <div className="order-top">
                              <div className="order-id">
                                <h5>#{item.orderNo}</h5>
                              </div>
                              <div className="order-status">
                                <span
                                  className={`${
                                    item?.order_type !== "Soft"
                                      ? "order-serv-heavy"
                                      : "order-serv-type"
                                  }`}
                                >
                                  {item?.order_type}
                                </span>
                                <span className="order-type-status progress">
                                  {item?.status && "Progress"}
                                </span>
                              </div>
                            </div>
                            <div className="order-desc">
                              <p className="order-date">
                                {moment(`${item?.orderDate}`).format(
                                  "yyyy-MM-DD hh:mm A"
                                )}
                              </p>
                            </div>
                            <div className="order-serv-name">
                              {productStatus?.map((data, idx) => (
                                <h4 key={idx}>
                                  {lang == "en"
                                    ? data?.serviceData?.name
                                    : data?.serviceData?.name_fr}
                                </h4>
                              ))}
                              <div className="order-price">
                                <span>€ {item?.totalAmount}</span>
                              </div>
                            </div>
                            <div className="order_btn">
                              <Button
                                className="btn_primary"
                                onClick={() => handelViewOrder(item?.orderNo)}
                              >
                                {t("ViewOrder")}
                              </Button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="categroy_slider_inner h-100 d-flex align-items-center justify-content-between">
                          <div className="no_data text-center">
                            <img src={noData} />
                          </div>
                        </div>
                      )}
                      {/* <NoOrderFound/> */}
                    </TabPane>
                    <TabPane className="h-100" eventKey="completed">
                      {completedOrder?.length > 0 ? (
                        completedOrder?.map((item, idx) => (
                          <div className="order-inner" key={idx}>
                            <div className="order-top">
                              <div className="order-id">
                                <h5>#{item.orderNo}</h5>
                              </div>
                              <div className="order-status">
                                <span
                                  className={`${
                                    item?.order_type !== "Soft"
                                      ? "order-serv-heavy"
                                      : "order-serv-type"
                                  }`}
                                >
                                  {item?.order_type}
                                </span>
                                <span className="order-type-status complete">
                                  {item?.status === "5" ? "Completed" : ""}
                                </span>
                              </div>
                            </div>
                            <div className="order-desc">
                              <p className="order-date">
                                {/* {moment(item?.orderDate).format("yyyy-MM-DD")}
                                  , {item?.time_slot}{" "} */}
                                {moment(`${item?.orderDate}`).format(
                                  "yyyy-MM-DD hh:mm A"
                                )}
                              </p>
                            </div>
                            <div className="order-serv-name">
                              {productStatus?.map((data, idx) => (
                                <h4 key={idx}>
                                  {lang == "en"
                                    ? data?.serviceData?.name
                                    : data?.serviceData?.name_fr}
                                </h4>
                              ))}
                              <div className="order-price">
                                <span>€ {item?.totalAmount}</span>
                              </div>
                            </div>
                            <div className="order_btn">
                              <Button
                                className="btn_primary"
                                onClick={() => handelViewOrder(item?.orderNo)}
                              >
                                {t("ViewOrder")}
                              </Button>
                              <Button
                                className="btn_primary"
                                onClick={() => handelReOrder(item?.orderNo)}
                              >
                                {t("Rebook")}
                              </Button>
                              <Button
                                className="btn_black"
                                onClick={() => {
                                  setOrderNo(item?.orderNo);
                                  setRateUs(true);
                                }}
                              >
                                Rate Us
                              </Button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="categroy_slider_inner h-100 d-flex align-items-center justify-content-between">
                          <div className="no_data text-center">
                            <img src={noData} />
                          </div>
                        </div>
                      )}
                      {/* <NoOrderFound/> */}
                    </TabPane>
                    <TabPane className="h-100" eventKey="cancelled">
                      {canceledOrder?.length > 0 ? (
                        canceledOrder?.map((item, idx) => (
                          <div className="order-inner" key={idx}>
                            <div className="order-top">
                              <div className="order-id">
                                <h5>#{item.orderNo}</h5>
                              </div>
                              <div className="order-status">
                                <span
                                  className={`${
                                    item?.order_type !== "Soft"
                                      ? "order-serv-heavy"
                                      : "order-serv-type"
                                  }`}
                                >
                                  {item?.order_type}
                                </span>
                                <span className="order-type-status cancel">
                                  {t("Cancelled")}
                                </span>
                              </div>
                            </div>
                            <div className="order-desc">
                              <p className="order-date">
                                {/* {moment(item?.orderDate).format("yyyy-MM-DD")}
                                  , {item?.time_slot}{" "} */}
                                {moment(`${item?.orderDate}`).format(
                                  "yyyy-MM-DD hh:mm A"
                                )}
                              </p>
                            </div>
                            <div className="order-serv-name">
                              {productStatus?.map((data, idx) => (
                                <h4 key={idx}>
                                  {lang == "en"
                                    ? data?.serviceData?.name
                                    : data?.serviceData?.name_fr}
                                </h4>
                              ))}
                              <div className="order-price">
                                <span>€ {item?.totalAmount}</span>
                              </div>
                            </div>
                            <div className="order_btn">
                              <Button
                                className="btn_primary"
                                onClick={() => handelViewOrder(item?.orderNo)}
                              >
                                {t("ViewOrder")}
                              </Button>
                            </div>
                            <div className="order_btn">
                              {/* <Button className="btn_primary">
                                  Re-Order
                                </Button> */}
                              {/* <Button className="btn_black">Rate Us</Button> */}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="categroy_slider_inner h-100 d-flex align-items-center justify-content-between">
                          <div className="no_data text-center">
                            <img src={noData} />
                          </div>
                        </div>
                      )}
                      {/* <NoOrderFound/> */}
                    </TabPane>
                  </TabContent>
                </TabContainer>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Order;

import React, { useEffect, useState } from "react";

import OwlCarousel from "react-owl-carousel3";
import like from "../assets/img/love.png";
import disLike from "../assets/img/like.png";
import Check from "../assets/img/check_ic1.png";
import star from "../assets/img/star_icon.png";
import Video from "../assets/img/video_img.png";
import PlayIcon from "../assets/img/play_icon.png";
import noService from "../assets/img/noService.jpg";
import ServiceImg from "../assets/img/disinfecting-home.png";
import featuredBadge from "../assets/img/featured_badge.png";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import UserInfo from "../view/Component/ModalComponent";
import Login from "../view/Component/modalComponents/Login";
import Signup from "../view/Component/modalComponents/Signup";
import { Button, Container } from "react-bootstrap";
import Http from "../services/Http";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Address from "./Component/modalComponents/Address";
import { toast, Toaster } from "react-hot-toast";
import noArtisanImg from "../";
import noData from "../assets/img/no-data.png";
import noServiceImg from "../assets/img/no-service.png";
import { getYouTubeVideoId } from "../helpers/functions";
import { ChangeAddress } from "./Checkout";
import OtpVerification from "../view/Component/modalComponents/OtpVerification";
import PasswordSet from "../view/Component/modalComponents/PasswordSet";
import { useTranslation } from "react-i18next";
const center = { lat: 48.8584, lng: 2.2945 };

const category = {
  rtl: "true",
  margin: 0,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 1,
    },
    991: {
      items: 1,
    },
    1366: {
      items: 1,
    },
  },
};
const internelOffer = {
  rtl: "true",
  margin: 15,
  nav: true,
  dots: false,
  loop: false,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 2,
    },
    991: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  },
};
const categoryfeatured = {
  rtl: "true",
  margin: 15,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 2,
    },
    577: {
      items: 3,
    },
    991: {
      items: 4,
    },
    1199: {
      items: 5,
    },
    1366: {
      items: 6,
    },
  },
};
const reletedService = {
  rtl: "true",
  margin: 15,
  nav: true,
  dots: false,
  loop: false,
  responsive: {
    0: {
      items: 2,
    },
    420: {
      items: 2,
    },
    577: {
      items: 3,
    },
    991: {
      items: 4,
    },
    1366: {
      items: 5,
    },
  },
};

const ServiceDetail = () => {
  const { t, i18n } = useTranslation();
  const [map, setMap] = useState(/**@type google.maps.Map */ (null));
  const [jobLocationRadio, setJobLocationRadio] = useState("artisansPlace");
  const [userLocationRadio, setUserLocationRadio] = useState("home");
  const [selectedAddress, setSelectedAdress] = useState();
  const [serviceDetails, setServiceDetails] = useState();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [type, setType] = useState("register");
  const [showOtp, setShowOtp] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [galleries, setGallery] = useState([]);
  const [artisanResult, setArtisanResult] = useState();
  const [relatedService, setRelatedService] = useState();
  const [openAddress, setOpenAddress] = useState(false);
  const [fav, setFav] = useState(false);
  const [favVal, setFavVal] = useState(true);
  const [isStatus, setIsStatus] = useState(false);
  const navigate = useNavigate();
  let lang = localStorage.getItem("lang");
  let params = useParams();
  const { id } = params;
  const [getSignup, setSignup] = useState({
    country_code: "",
    mobile: "",
  });
  const [showProfile, setShowProfile] = useState(false);

  const markFavorite = async (id, type) => {
    if (userToken) {
      setFavVal(!favVal);
      const path = apiPath.markFavorite;
      const data = {
        fev_type: type,
        fev_id: `${id}`,
        fev_val: favVal ? "1" : "0",
      };
      const options = { method: "POST", data };

      try {
        const res = await Http(path, options);
        if (res?.data?.success === true) {
          setFav(!fav);
          console.log(res.data.result);
          toast.success(res?.data?.msg);
        } else {
          console.log(res?.data?.msg);
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Login Required!");
    }
  };

  const getServiceDetail = async () => {
    let path = apiPath.serviceDetails;
    let options = {
      method: "POST",
      data: { id: id },
    };
    let res = await Http(path, options);
    if (res?.data?.success === true) {
      setServiceDetails(res?.data?.result?.serviceDetails);
      setArtisanResult(res?.data?.result?.artisanResult);
      setRelatedService(res?.data?.result?.relatedService);
      let gall = [];
      gall.push(res?.data?.result?.serviceDetails?.image);
      if (res?.data?.result?.serviceDetails?.gallery) {
        let gall_1 = res?.data?.result?.serviceDetails?.gallery.split(",");
        console.log("gall_1", gall_1);
        if (gall_1 && gall_1.length > 0) {
          for (let gall_1 of res?.data?.result?.serviceDetails?.gallery.split(
            ","
          )) {
            gall.push(gall_1);
          }
        }
      }
      setGallery(gall);
    } else {
      setIsStatus(true);
    }
  };

  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    getServiceDetail();
  }, [id, fav]);

  const onJobLocationSelect = (e) => {
    setJobLocationRadio(e.target.value);
  };
  const onUserLocationSelect = (e) => {
    setUserLocationRadio(e.target.value);
  };

  const onOpen = () => {
    if (userToken) {
      setOpenAddress(true);
    } else {
      setShowLogin(true);
    }
  };

  const onHide = () => {
    setOpenAddress(false);
  };

  const [getOtp, setGetOtp] = useState({
    otp: "",
    hasErrored: false,
  });

  window.localStorage.setItem(
    "cart_item",
    JSON.stringify({
      selectedServices: [serviceDetails],
    })
  );
  const [isClicked, setIsClicked] = useState(false);
  const handleSeviceClick = () => {
    setIsClicked(!isClicked);
  };
  const [isServiceNote, setServiceNote] = useState(false);
  const handleSeviceNote = () => {
    setServiceNote(!isServiceNote);
  };

  const handleReadMore = (val) => {
    return val
      ? lang == "en"
        ? "Read Less"
        : "Lire moins"
      : lang == "en"
      ? "Read More"
      : "En savoir plus";
  };

  const onClose = () => {
    setShowLogin(false);
  };
  const hideLogin = () => {
    setShowLogin(false);
  };

  const hideSignup = () => {
    setShowSignup(false);
  };
  const hideOTP = () => {
    setShowOtp(false);
  };

  return (
    <>
      <Toaster position="top-right" />
      {openAddress && (
        <ChangeAddress
          categoryId={serviceDetails?.categoryId}
          serviceId={serviceDetails?.id}
          orderType={serviceDetails?.service_type}
          show={onOpen}
          hide={onHide}
        />
      )}
      {isStatus === false ? (
        <section className="service-detail-sec">
          <div className="container">
            <div className="service-detail">
              <div className="service_slider">
                {galleries?.length > 0 ? (
                  <OwlCarousel className="owl-carousel owl-theme" {...category}>
                    {galleries.map((item, idx) => (
                      <div className="item" key={idx}>
                        <div className="serv_slider_img">
                          <img src={IMAGE_PATH + item} alt="gallaryImage" />
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                ) : (
                  <div className="item">
                    <div className="serv_slider_img">
                      <img src={noService} alt="Service Image" />
                    </div>
                  </div>
                )}
              </div>

              <div className="serv_dtl_cont">
                <div className="serv-dtl-title">
                  <h3>
                    {lang == "en"
                      ? serviceDetails?.name
                      : serviceDetails?.name_fr}
                  </h3>
                  <div className="serv_type">
                    <div className="serv_type_tag">
                      <span
                        className={
                          serviceDetails?.service_type === "Soft"
                            ? "soft"
                            : serviceDetails?.service_type === "Offline"
                            ? "offline"
                            : "heavy"
                        }
                      >
                        {serviceDetails?.service_type === "Offline"
                          ? "In Shop"
                          : serviceDetails?.service_type}
                      </span>
                    </div>
                    <div className="serv-like cursor-pointer">
                      <span
                        onClick={() =>
                          markFavorite(serviceDetails?.id, "Service")
                        }
                        className="like"
                      >
                        <img
                          src={serviceDetails?.fevData?.id ? like : disLike}
                          alt="likeImg"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <p
                  className={`box ${isClicked ? "active" : ""} serv-cont-main `}
                >
                  {lang == "en"
                    ? serviceDetails?.description
                    : serviceDetails?.description_fr}
                </p>

                <p
                  style={{
                    cursor: "pointer",
                    display:
                      serviceDetails?.description.trim().split(/\s+/).length >
                      50
                        ? "block"
                        : "none",
                  }}
                  onClick={handleSeviceClick}
                >
                  {handleReadMore(isClicked)}
                </p>
                {serviceDetails?.service_note !== null && (
                  <>
                    <h6>{t("Whatthisserviceincludes")}</h6>
                    <ul>
                      <li>
                        <span className="check_ic">
                          <img src={Check} alt="checkImg" />
                        </span>
                        <span
                          className={`box ${
                            isServiceNote ? "active" : ""
                          } serv-cont-main serv_name`}
                        >
                          {lang == "en"
                            ? serviceDetails?.service_note
                            : serviceDetails?.service_note_fr}
                        </span>
                      </li>
                    </ul>
                    <p
                      style={{
                        cursor: "pointer",
                        display:
                          serviceDetails?.service_note.trim().split(/\s+/)
                            .length > 50
                            ? "block"
                            : "none",
                      }}
                      onClick={handleSeviceNote}
                    >
                      {handleReadMore(isServiceNote)}
                    </p>
                  </>
                )}
                <div className="search_book_artisan">
                  <Button
                    className="btn_primary d-none "
                    onClick={() => navigate("/browse-artisans")}
                  >
                    {t("SearchArtisan")}
                  </Button>
                  {serviceDetails?.service_type === "Soft" ||
                  serviceDetails?.service_type === "Offline" ? (
                    <Button className="btn_primary" onClick={onOpen}>
                      {t("book")}
                    </Button>
                  ) : (
                    <Button
                      className="btn_primary"
                      onClick={() =>
                        navigate("/heavy-service", {
                          state: {
                            serviceId: serviceDetails?.id,
                            step: "1",
                            cat_id: serviceDetails?.categoryId,
                            subCat_id: serviceDetails?.subcategoryId,
                          },
                        })
                      }
                    >
                      {t("book")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="fetured_artisan_sec">
              <div className="title_cls">
                <p>
                  {t("NumberofArtisansfor")}{" "}
                  {lang == "en"
                    ? serviceDetails?.name
                    : serviceDetails?.name_fr}{" "}
                  :- <b>{artisanResult?.count}</b>
                </p>
              </div>
            </div>
            <div className="watch_demo_sec">
              {serviceDetails?.video_link?.length > 0 ? (
                <div className="title_cls">
                  <h3 className="heading_h3">{t("WatchaDemo")}</h3>
                </div>
              ) : (
                ""
              )}
              <div className="video_main_sec">
                {serviceDetails?.video_link ? (
                  <>
                    <div className="video-inner">
                      {serviceDetails?.video_link.length > 0 ? (
                        <div className="video">
                          <iframe
                            width={550}
                            height={550}
                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                              serviceDetails?.video_link
                            )}`}
                            type="video/mp4"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : null}
                <div
                  className={
                    serviceDetails?.video_link ? "viedo_cont" : "viedo_cont_1"
                  }
                >
                  {serviceDetails?.service_note !== null && (
                    <div className="video_cont_inner">
                      <h5>{t("PleaseNote")}</h5>
                      <ul>
                        <li>
                          {lang == "en"
                            ? serviceDetails?.service_note
                            : serviceDetails?.service_note_fr}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="d-flex align-items-center justify-content-center ml-auto mt-2">
          <div className="no_data_1">
            <img src={noData} />
          </div>
        </div>
      )}
      {serviceDetails?.service_type === "Soft" ? (
        <section className="releted_service">
          <Container>
            <div className="title_cls">
              <h3 className="heading_h3">{t("RelatedServices")}</h3>
            </div>
            {relatedService?.length > 0 && (
              <OwlCarousel
                className="owl-carousel owl-theme"
                {...reletedService}
              >
                {relatedService?.map((item, idx) => (
                  <div className="item" key={idx}>
                    <div className="categroy_slider_inner">
                      <div className="categroy_slider_img">
                        {item.image === null ||
                        item.image === "undefined" ||
                        item.image === "" ? (
                          <img
                            src={noServiceImg}
                            onClick={() =>
                              navigate(`/service-detail/${item?.id}`, {
                                replace: true,
                              })
                            }
                          />
                        ) : (
                          <img
                            src={`${IMAGE_PATH + item.image}`}
                            onClick={() =>
                              navigate(`/service-detail/${item?.id}`, {
                                replace: true,
                              })
                            }
                          />
                        )}
                        <span className="soft_badge">{item.service_type}</span>
                      </div>
                      <div className="categroy_prd_cont">
                        <h4>{lang === "en" ? item.name : item.name_fr}</h4>
                        <p>{t("starting_from")}</p>
                        <h5>€ {item.min_price}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </Container>
        </section>
      ) : (
        ""
      )}
      {showLogin === true && (
        <Login
          showLogin={showLogin}
          onClose={onClose}
          showSignup={() => {
            console.log("called signup");
            setShowLogin(false);
            setShowSignup(true);
          }}
          showForget={() => {
            setType("forgot");
            setShowLogin(false);
            setShowForgetPassword(true);
          }}
          hide={hideLogin}
        />
      )}
      {showSignup && (
        <Signup
          showLogin={() => {
            hideSignup();
            setShowLogin(true);
          }}
          show={showSignup}
          hide={hideSignup}
          data={showSignup}
          onClose={onClose}
          showOTP={() => {
            setShowOtp(true);
          }}
        />
      )}
      {/* 
      {showForgetPassword && (
        <ForgetPassword
          show={showForgetPassword}
          hide={() => {
            setShowForgetPassword(false);
          }}
          showLogin={() => {
            setShowForgetPassword(false);
            setShowLogin(true);
          }}
          showOTP={() => {
            setShowForgetPassword(false);
            setShowOtp(true);
          }}
          data={showForgetPassword}
          type={type}
          onClose={onClose}
        />
      )} */}

      {showOtp ? (
        <OtpVerification
          show={showOtp}
          hide={hideOTP}
          data={showOtp}
          userData={getSignup}
          type={type}
          onClose={onClose}
          showPassword={() => {
            hideOTP();
            setShowPassword(true);
          }}
        />
      ) : (
        ""
      )}

      {showPassword && (
        <PasswordSet
          show={showPassword}
          hide={() => setShowPassword(false)}
          mobile={getSignup.mobile}
          cc={"+" + getSignup.country_code}
          otp={getOtp.otp}
          type={type === "forgot" ? "forgot" : "register"}
          onClose={onClose}
          showOtp={() => {
            setShowOtp(true);
          }}
          showPassword={() => {
            setShowPassword(true);
          }}
          showLogin={() => {
            setShowPassword(false);
            setShowLogin(true);
          }}
          showProfile={() => setShowProfile(true)}
          closePassword={() => {
            setShowPassword(false);
          }}
        />
      )}
    </>
  );
};
export default ServiceDetail;

import React, { useState, useEffect, useRef } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import Http from "../services/Http";
import softServ from "../assets/img/soft_icon.png";
import heavyServ from "../assets/img/heavy_icon.png";
import noData from "../assets/img/no-data.png";
import like from "../assets/img/love.png";
import disLike from "../assets/img/like.png";
import star from "../assets/img/star_icon.png";
import featuredBadge from "../assets/img/featured_badge.png";
import featuredBadge_fr from "../assets/img/featured_badge_fr.png";
import pastServ from "../assets/img/past_serv1.png";
import bannerServ from "../assets/img/banner-serv.png";
import noService from "../assets/img/no-service.png";
import OwlCarousel from "react-owl-carousel3";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Container, Form, FormSelect } from "react-bootstrap";
import ArtisansNotFound from "./Component/modalComponents/ArtisansNotFound";
import serv6 from "../assets/img/serv6.png";
import Header from "./Component/home/Navbar";
import Skeleton from "./Component/Skeleton";
import { toast, Toaster } from "react-hot-toast";
import Footer from "../layout/Footer";
import { useTranslation } from "react-i18next";
import axios from "axios";

const options = {
  rtl: "true",
  margin: 0,
  nav: true,
  dots: false,
  loop: true,
  autoplay: true,
  autoplayTimeout: 2000,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 1,
    },
    992: {
      items: 1,
    },
    1366: {
      items: 1,
    },
  },
};

const offer = {
  rtl: "true",
  margin: 15,
  nav: true,
  dots: false,
  loop: false,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 2,
    },
    991: {
      items: 3,
    },
    1366: {
      items: 3,
    },
  },
};

const serviceOffer = {
  rtl: "true",
  margin: 15,
  nav: true,
  dots: false,
  loop: false,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 2,
    },
    991: {
      items: 4,
    },
    1366: {
      items: 4,
    },
  },
};

const internelOffer = {
  rtl: "true",
  margin: 15,
  nav: true,
  dots: false,
  loop: false,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 2,
    },
    991: {
      items: 3,
    },
    1200: {
      items: 3,
    },
  },
};

const category = {
  rtl: "true",
  margin: 15,
  nav: true,
  dots: false,
  loop: false,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 2,
    },
    991: {
      items: 4,
    },
    1366: {
      items: 5,
    },
  },
};

const Home = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState();
  const [search, setSearch] = useState("");
  const [homeItems, setHomeItems] = useState();
  const [allService, setAllService] = useState();
  const [featureService, setFeatureService] = useState();
  const [allArtisans, setAllArtisans] = useState();
  const [featureArtisans, setFeatureArtisans] = useState();
  const [lastSeenService, setLastSeenService] = useState();
  const [pastService, setPastService] = useState();
  const [banner, setBanner] = useState();
  const [favArtisan, setFavArtisan] = useState(false);
  const [favVal, setFavVal] = useState(true);
  const [tkn, setTkn] = useState();
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  // const path = useParams();
  // const { state } = useLocation();
  const serviceRef = useRef(null);

  const [showArtisans, setShowArtisans] = useState(false);
  const [catID, setCatID] = useState();
  const [latLong, setLatLong] = useState({ lat: 0, lng: 0 });
  const [ip, setIP] = useState("");
  const [delay, setDelay] = useState(true);

  const userToken = localStorage.getItem("userToken");
  let lang = localStorage.getItem("lang");
  const getIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json");
      const ip = response.data.ip;
      // console.log("---ip==========>".ip);
      localStorage.setItem("deviceIp", ip);
    } catch (error) {
      console.error(error);
    }
  };

  const deviceIp = localStorage.getItem("deviceIp");

  useEffect(() => {
    getIp();
    setTkn(userToken);
  }, [tkn]);

  // console.log("deviceIp", deviceIp);

  const getBanners = async () => {
    let path = apiPath.homeBanner;
    let options = {
      method: "GET",
    };
    let res = await Http(path, options);
    if (res?.status == 200) {
      setBanner(res?.data?.result);
      // console.log(res?.data?.result);
    } else {
    }
  };

  const loggedIn = localStorage.getItem("isLoggedIn");

  useEffect(() => {
    if (userToken && loggedIn === "0") {
      navigate("/complete-profile");
    }
  }, []);

  const guest = async () => {
    const path = apiPath.guest_user;
    const data = {
      ip: "0.0.0.1", ///deviceIp,
      user_type: "2",
    };

    let options = {
      method: "POST",
      data,
    };
    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        const { token } = res.data.result;
        localStorage.setItem("isLoggedIn", "0");
        localStorage.setItem("guestToken", token);
        localStorage.setItem("isLoggedIn", 0);
        getAllCategories();
        getHomeItems();
        getBanners();
      } else {
        // console.log(res.data.msg);
      }
    } catch (err) {
      // console.log(err.res);
    }
  };

  const isLogin = localStorage.getItem("isLoggedIn");

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLong({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (userToken === null) {
      guest();
    }
  }, []);

  const getHomeItems = async () => {
    let path = apiPath.homeData;
    let data = { name: `${search}` };
    let options = {
      method: "POST",
      data,
    };
    // console.log(token);
    //
    let res = await Http(path, options);
    if (res?.data?.success === true) {
      setAllService(res.data.result.allServiceList);
      setFeatureService(res.data.result.serviceList);
      setFeatureArtisans(res.data.result.artisanList);
      setAllArtisans(res.data.result.allArtisanList);
      setLastSeenService(res.data.result.recentSeenServiceList);
      setPastService(res.data.result.pastServiceList);
      // toast.success(res?.data?.msg);
      // console.log("allArtisanList", res.data.result.allArtisanList);
    } else if (res.status === 401) {
      guest();
    } else {
      toast.error(res?.data?.msg);
    }
  };

  useEffect(() => {
    if (isLogin === "0") {
      getAllCategories();
      // getHomeItems();
      getBanners();
    }
  }, []);

  useEffect(() => {
    if (userToken) {
      getAllCategories();
      getHomeItems();
      getBanners();
    }
  }, []);

  useEffect(() => {
    // if (userToken || isLogin === "0") {
    // filterCategories();
    // }
    getHomeItems();
  }, [search]);

  useEffect(() => {
    if (deviceIp === undefined || deviceIp === undefined) {
      setDelay(true);
    }
    setTimeout(() => {
      setDelay(false);
    }, 2000);
  }, [delay]);

  const searchCategories = () => {
    if (search?.length > 1) serviceRef.current.scrollIntoView();
  };

  // const filterCategories = async () => {
  //   let path = apiPath.homeData;
  //   let formData = new FormData();
  //   formData.append("name", search);
  //   let options = {
  //     method: "POST",
  //     data: formData,
  //   };
  //   try {
  //     let res = await Http(path, options);
  //     // console.log("________res________", res.data.result);
  //     const {
  //       artisanList,
  //       serviceList,
  //       recentSeenServiceList,
  //       pastServiceList,
  //       allService,
  //       allArtisans,
  //     } = res.data.result;
  //     setAllArtisans(allArtisans);
  //     setFeatureArtisans(artisanList);
  //     setAllService(allService);
  //     setFeatureService(serviceList);
  //     setLastSeenService(recentSeenServiceList);
  //     setPastService(pastServiceList);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // console.clear();
  const getAllCategories = async () => {
    let path = apiPath.categoryList;
    let options = {
      method: "GET",
    };
    try {
      let res = await Http(path, options);
      //console.clear();
      // console.log("res", res);
      setCategories(res?.data?.result);
    } catch (err) {
      // console.log(err);
    }
  };

  const onArtisansAvailable = (id) => {
    setShowArtisans(true);
    setCatID(id);
    // console.log(catID);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    // console.log(search);
  };

  if (!homeItems && !banner) return null;

  const markFavorite = async (id, isFev) => {
    // console.log("isFav", isFav);
    // return;
    if (userToken) {
      setFavVal(!favVal);
      const path = apiPath.markFavorite;
      const data = {
        fev_type: "Artisan",
        fev_id: `${id}`,
        fev_val: isFev == null ? "1" : "0",
      };
      const options = { method: "POST", data };

      try {
        const res = await Http(path, options);
        if (res?.data?.success === true) {
          setFavArtisan(!favArtisan);
          getHomeItems();
          // console.log(res.data.result);
          toast.success(res?.data?.msg);
        } else {
          // console.log(res?.data?.msg);
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      toast.error("Login Required!");
    }
  };

  const handelReOrder = async (order_no) => {
    let path = apiPath.reOrder;
    const data = { orderNo: order_no ? order_no : "" };
    let options = { method: "POST", data };

    let res = await Http(path, options);
    if (res?.data?.success === true) {
      toast.success(res?.data?.msg);
    } else {
      toast.error(res?.data?.msg);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchCategories();
    }
  };

  return (
    <>
      {" "}
      {delay ? (
        <div className="d-flex justify-content-center align-items-center">
          <Skeleton />
        </div>
      ) : (
        <>
          {showArtisans ? <ArtisansNotFound categories_id={catID} /> : ""}
          <section className="banner-sec">
            <Toaster position="top-right" />
            <Header />
            <div className="banner-cont">
              <Container>
                <div className="home_cont">
                  <h3>{t("home_msg")}</h3>
                  <div className="search_cls">
                    <div className="search_inner_cls">
                      {/* <FormSelect>
                    <option>Location</option>
                    <option>Jaipur</option>
                    <option>Tonk</option>
                  </FormSelect> */}
                      <div className="search_form_cls">
                        <Form.Group
                          className="form-group"
                          controlId="formBasicEmail"
                        >
                          <div className="input_group">
                            <Form.Control
                              type="text"
                              onChange={handleChange}
                              value={search}
                              onKeyDown={handleKeyDown}
                            />

                            <div
                              className="search-icon"
                              onClick={searchCategories}
                            >
                              <svg
                                id="Icon-Search"
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.064"
                                height="17.1"
                                viewBox="0 0 17.064 17.1"
                              >
                                <path
                                  id="Fill-1"
                                  d="M-14.809-207.919a7,7,0,0,1-6.991-6.991,7,7,0,0,1,6.991-6.991,6.977,6.977,0,0,1,6.991,6.991,7.023,7.023,0,0,1-6.991,6.991Zm0-12.941a6,6,0,0,0-5.987,5.987,6,6,0,0,0,5.987,5.987,6,6,0,0,0,5.987-5.987,6.044,6.044,0,0,0-5.987-5.987Z"
                                  transform="translate(21.8 221.9)"
                                  fill="#fff"
                                />
                                <path
                                  id="Fill-2"
                                  d="M14.975-184.272l-4.875-4.84.789-.789,4.84,4.875-.753.753"
                                  transform="translate(1.336 201.372)"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className="service_main">
                    <div className="service_inner_cls">
                      {/* allCategory?.slice(0, 5) */}
                      {categories?.softCategory
                        ?.slice(0, 5)
                        ?.map((item, idx) => (
                          <div className="single_serv" key={idx}>
                            <Link
                              onClick={() =>
                                item.is_artisan === 0
                                  ? onArtisansAvailable(item.id)
                                  : ""
                              }
                              to={
                                item.is_artisan !== 0
                                  ? `/soft-service-list/${item?.id}`
                                  : ""
                              }
                              state={{
                                category_id: item.id,
                              }}
                            >
                              <span>
                                <img
                                  key={item.id}
                                  src={`${IMAGE_PATH + item.image}`}
                                  alt=""
                                />
                              </span>
                              <span className="categroy_name" key={item.id}>
                                {lang == "en" ? item?.name : item?.fr_name}
                              </span>
                            </Link>
                          </div>
                        ))}

                      <div className="single_serv">
                        <Link to="/category/soft">
                          <span>
                            <img src={serv6} alt="" />
                          </span>
                          <span className="categroy_name">{t("all")}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </section>
          <section className="service_type_sec">
            <Container>
              <div className="service_def">
                <div className="serv-btn">
                  <Link to="/category/soft">
                    <Button className="service_btn">
                      <span className="service_btn_border">
                        <span className="serv-btn-icon">
                          <img src={softServ} alt="" />
                        </span>
                        <span className="serv-btn-name">
                          {t("soft_service")}
                        </span>
                      </span>
                    </Button>
                  </Link>

                  <Button className="heavy_service_btn">
                    <Link to="/category/heavy">
                      <span className="serv-btn-icon">
                        <img src={heavyServ} alt="" />
                      </span>
                      <span className="serv-btn-name">
                        {t("heavy_service")}
                      </span>
                    </Link>
                  </Button>
                </div>
              </div>
              {/* Category Banner */}
              {banner?.categoryBanners?.length > 0 && (
                <OwlCarousel className="owl-carousel owl-theme" {...options}>
                  {banner?.categoryBanners?.map((item, idx) => (
                    <div className="item" key={idx}>
                      <div className="slider_banner">
                        <img
                          src={`${IMAGE_PATH + item.banner_image}`}
                          onClick={() => {
                            if (item?.category === "Heavy") {
                              navigate("/category/heavy");
                            } else if (item?.type === "Promocode") {
                              navigate("/my-coupons");
                            } else {
                              navigate("/category/soft");
                            }
                          }}
                          alt="bannerImage"
                        />
                        {/* <span
                          className={`${
                            item.category === "Heavy"
                              ? "heavy_badge"
                              : "soft_badge"
                          }`}
                          key={item.id}
                        >
                          {item.category}
                        </span> */}
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </Container>
          </section>
          {/* Services Banner */}
          <section className="offer-sec">
            <Container>
              {banner?.serviceBanners.length > 0 && (
                <OwlCarousel className="owl-carousel owl-theme" {...offer}>
                  {banner?.serviceBanners.map((item, idx) => (
                    <div className="item" key={idx}>
                      <div className="offfer_banner">
                        <img
                          src={`${IMAGE_PATH + item.banner_image}`}
                          onClick={() =>
                            navigate(`/service-detail/${item?.id}`)
                          }
                          alt="Service Banner"
                        />
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </Container>
          </section>
          {/* Featured Serivces */}
          <section className="featured_sec">
            <Container>
              {featureService?.length > 0 && (
                <div className="title_cls">
                  <h3 className="heading_h3">{t("featured_service")}</h3>
                  <div className="view_all">
                    <Link to="/all-services" state={{ isFeatured: true }}>
                      {t("see_all")}
                    </Link>
                  </div>
                </div>
              )}
              <div className="category_slider">
                {featureService?.length > 0 && (
                  <OwlCarousel className="owl-carousel owl-theme" {...category}>
                    {featureService?.map((item, idx) => (
                      <div className="item" key={idx}>
                        <div className="categroy_slider_inner">
                          <div className="categroy_slider_img">
                            {item?.image === null ||
                            item?.image === "undefined" ||
                            item?.image === undefined ||
                            item?.image === "" ? (
                              <img
                                src={noService}
                                onClick={() =>
                                  navigate(`/service-detail/${item?.id}`)
                                }
                                alt="FeaturedService"
                              />
                            ) : (
                              <img
                                src={`${IMAGE_PATH + item.image}`}
                                onClick={() => {
                                  if (item?.service_type === "Soft") {
                                    navigate(`/service-detail/${item?.id}`);
                                  } else if (item?.service_type === "Offline") {
                                    navigate(
                                      `/offline-service-detail/${item?.id}`
                                    );
                                  } else {
                                    navigate(`/service-detail/${item?.id}`);
                                  }
                                }}
                                alt="FeaturedService"
                              />
                            )}
                            <span
                              className={
                                item.service_type === "Soft"
                                  ? "soft_badge"
                                  : item.service_type === "Heavy"
                                  ? "heavy_badge"
                                  : "offline_badge"
                              }
                            >
                              {item.service_type === "Offline"
                                ? "In-Shop"
                                : item.service_type}
                            </span>
                            <span className="features_badge">
                              <img
                                key={item.id}
                                src={
                                  lang == "en"
                                    ? featuredBadge
                                    : featuredBadge_fr
                                }
                              />
                            </span>
                          </div>
                          <div
                            className={
                              `${item.service_type}` === "Soft"
                                ? "categroy_prd_cont"
                                : "categroy_prd_cont no-content-cls"
                            }
                          >
                            <h4>{lang == "en" ? item?.name : item?.name_fr}</h4>
                            {item?.service_type === "Soft" ? (
                              <>
                                <p>{t("starting_from")}</p>
                                <h5>€ {item.min_price}</h5>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </Container>
          </section>
          {/* All Serivces */}
          <section className="featured_sec" ref={serviceRef} id={"allService"}>
            <Container>
              {allService?.length > 0 ? (
                <div className="title_cls">
                  <h3 className="heading_h3">{t("AllServices")}</h3>
                  <div className="view_all">
                    <Link to="/all-services" state={allService}>
                      {t("see_all")}
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="category_slider">
                {allService?.length > 0 && (
                  <OwlCarousel className="owl-carousel owl-theme" {...category}>
                    {allService?.map((item, idx) => (
                      <div className="item" key={idx}>
                        <div className="categroy_slider_inner">
                          <div className="categroy_slider_img">
                            {item?.image === null ||
                            item?.image === "undefined" ||
                            item?.image === undefined ||
                            item?.image === "" ? (
                              <img
                                src={noService}
                                onClick={() => {
                                  if (item?.service_type === "Soft") {
                                    navigate(`/service-detail/${item?.id}`);
                                  } else if (item?.service_type === "Offline") {
                                    navigate(
                                      `/offline-service-detail/${item?.id}`
                                    );
                                  } else {
                                    navigate(`/service-detail/${item?.id}`);
                                  }
                                }}
                                alt="All Service"
                              />
                            ) : (
                              <img
                                src={`${IMAGE_PATH + item.image}`}
                                onClick={() => {
                                  if (item?.service_type === "Soft") {
                                    navigate(`/service-detail/${item?.id}`);
                                  } else if (item?.service_type === "Offline") {
                                    navigate(
                                      `/offline-service-detail/${item?.id}`
                                    );
                                  } else {
                                    navigate(`/service-detail/${item?.id}`);
                                  }
                                }}
                                alt="allService"
                              />
                            )}
                            <span
                              className={
                                item.service_type === "Soft"
                                  ? "soft_badge"
                                  : item.service_type === "Heavy"
                                  ? "heavy_badge"
                                  : "offline_badge"
                              }
                            >
                              {item.service_type === "Offline"
                                ? "In-Shop"
                                : item.service_type}
                            </span>
                           
                          </div>
                          <div
                            className={
                              `${item.service_type}` === "Soft"
                                ? "categroy_prd_cont"
                                : "categroy_prd_cont no-content-cls"
                            }
                          >
                            <h4>{lang == "en" ? item?.name : item?.name_fr}</h4>
                            {item?.service_type === "Soft" ? (
                              <>
                                <p>{t("starting_from")}</p>
                                <h5>€ {item.min_price}</h5>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </Container>
          </section>
          {/* Featured Artisans */}
          <section className="featured_artisan_sec">
            <Container>
              {featureArtisans?.length > 0 && (
                <div className="title_cls">
                  <h3 className="heading_h3">{t("featured_artisan")}</h3>
                  <div className="view_all">
                    <Link to="/browse-artisans" state={{ is_featured: true }}>
                      {t("see_all")}
                    </Link>
                  </div>
                </div>
              )}
              <div className="artisans_slider">
                {featureArtisans?.length > 0 && (
                  <OwlCarousel className="owl-carousel owl-theme" {...category}>
                    {featureArtisans.map((item, idx) => (
                      <div className="item" key={idx}>
                        <div className="categroy_slider_inner">
                          <div className="categroy_slider_img">
                            <img
                              src={`${IMAGE_PATH}${item.image}`}
                              onClick={() =>
                                navigate(`/order-by-artisan/${item.id}`)
                              }
                              alt="img"
                            />
                            <span className="features_badge">
                              <img
                                key={item.id}
                                src={
                                  lang == "en"
                                    ? featuredBadge
                                    : featuredBadge_fr
                                }
                              />
                            </span>
                            <Link to="#" className="like_cls">
                              <img
                                onClick={() =>
                                  markFavorite(item.id, item.fevData)
                                }
                                src={item.fevData?.id ? like : disLike}
                                alt="likeImg"
                              />
                            </Link>
                          </div>
                          <div className="categroy_prd_cont">
                            <div className="artisan_review">
                              <div className="most_active">
                                <span>{t("most_active")}</span>
                              </div>
                              <div className="review_inner">
                                <span className="review-ic">
                                  <img src={star} alt="" />
                                </span>
                                <span className="review_dtl">
                                  {item.rating}
                                </span>
                              </div>
                            </div>
                            {/* <h4>{lang == "en" ? item?.name : item?.name_fr}</h4> */}
                            <h4>{item?.name}</h4>
                            {item?.job_done ? (
                              <p>
                                #{item.job_done} {t("Jobs")}
                              </p>
                            ) : (
                              <p>{t("NoJobs")}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </Container>
          </section>
          <section className="featured_artisan_sec">
            <Container>
              {allArtisans?.length > 0 ? (
                <div className="title_cls">
                  <h3 className="heading_h3">{t("AllArtisans")}</h3>
                  <div className="view_all">
                    <Link to="/browse-artisans">{t("see_all")}</Link>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="artisans_slider">
                {allArtisans && (
                  <OwlCarousel className="owl-carousel owl-theme" {...category}>
                    {allArtisans.map((item, idx) => (
                      <div className="item" key={idx}>
                        <div className="categroy_slider_inner">
                          <div className="categroy_slider_img">
                            <img
                              src={`${IMAGE_PATH + item.image}`}
                              onClick={() =>
                                navigate(`/order-by-artisan/${item.id}`)
                              }
                              alt="img"
                            />
                            {/* <span className="features_badge">
                              <img key={item.id} src={featuredBadge} />
                            </span> */}
                            <Link to="#" className="like_cls">
                              <img
                                onClick={() =>
                                  markFavorite(item.id, item.fevData)
                                }
                                src={item.fevData?.id ? like : disLike}
                                alt="likeImg"
                              />
                            </Link>
                          </div>
                          <div className="categroy_prd_cont">
                            <div className="artisan_review">
                              <div className="most_active">
                                <span>{t("most_active")}</span>
                              </div>
                              <div className="review_inner">
                                <span className="review-ic">
                                  <img src={star} alt="" />
                                </span>
                                <span className="review_dtl">
                                  {item.rating}
                                </span>
                              </div>
                            </div>
                            <h4>{item.name}</h4>
                            {item?.job_done ? (
                              <p>
                                #{item.job_done} {t("Jobs")}
                              </p>
                            ) : (
                              <p>{t("NoJobs")}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </Container>
          </section>
          {/* Artisans Banner */}
          <section className="internel_sec">
            <Container>
              {banner?.artisanBanners.length > 0 && (
                <OwlCarousel
                  className="owl-carousel owl-theme"
                  {...internelOffer}
                >
                  {banner?.artisanBanners.map((item, idx) => (
                    <div className="item" key={idx}>
                      <div className="offfer_banner">
                        <img
                          src={`${IMAGE_PATH + item.banner_image}`}
                          onClick={() =>
                            navigate(`/artisan-detail/${item?.artisan_id}`)
                          }
                          alt="artisanImg"
                        />
                        <span
                          className={`${
                            item.category === "Heavy"
                              ? "heavy_badge"
                              : "soft_badge"
                          }`}
                          key={item.id}
                        >
                          {item.category}
                        </span>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </Container>
          </section>
          {/* Last seen services */}
          <section className="featured_sec">
            <Container>
              {lastSeenService?.length > 0 && (
                <div className="title_cls">
                  <h3 className="heading_h3">{t("last_seen_service")}</h3>
                  <div className="view_all">
                    <Link to="/all-services" state={lastSeenService}>
                      {t("see_all")}
                    </Link>
                  </div>
                </div>
              )}
              <div className="category_slider">
                {lastSeenService?.length > 0 && (
                  <OwlCarousel className="owl-carousel owl-theme" {...category}>
                    {lastSeenService.map((item, idx) => (
                      <div className="item" key={idx}>
                        <div className="categroy_slider_inner">
                          <div className="categroy_slider_img">
                            <img
                              src={`${IMAGE_PATH + item.image}`}
                              onClick={() => {
                                if (item?.service_type === "Soft") {
                                  navigate(`/service-detail/${item?.id}`);
                                } else if (item?.service_type === "Offline") {
                                  navigate(
                                    `/offline-service-detail/${item?.id}`
                                  );
                                } else {
                                  navigate(`/service-detail/${item?.id}`);
                                }
                              }}
                              alt="serviiceImg"
                            />
                            <span
                              className={
                                item.service_type === "Soft"
                                  ? "soft_badge"
                                  : item.service_type === "Heavy"
                                  ? "heavy_badge"
                                  : "offline_badge"
                              }
                            >
                              {item.service_type === "Offline"
                                ? "In-Shop"
                                : item.service_type}
                            </span>
                            <span className="features_badge">
                              <img
                                key={item.id}
                                src={
                                  lang == "en"
                                    ? featuredBadge
                                    : featuredBadge_fr
                                }
                              />
                            </span>
                          </div>
                          <div
                            className={
                              `${item.service_type}` === "Soft"
                                ? "categroy_prd_cont"
                                : "categroy_prd_cont no-content-cls"
                            }
                          >
                            <h4>{lang == "en" ? item?.name : item?.name_fr}</h4>
                            {item?.service_type === "Soft" ? (
                              <>
                                <p>{t("starting_from")}</p>
                                <h5>€ {item.min_price}</h5>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </Container>
          </section>
          {/* Past Serivces */}
          {userToken && pastService?.length !== 0 && (
            <section className="past_serv_sec">
              <Container>
                {pastService?.length > 0 && (
                  <div className="title_cls">
                    <h3 className="heading_h3">{t("past_seen_service")}</h3>
                    <div className="view_all">
                      <Link to="/my-orders" state={pastService}>
                        {t("see_all")}
                      </Link>
                    </div>
                  </div>
                )}
                {pastService?.length > 0 ? (
                  <OwlCarousel
                    className="owl-carousel owl-theme"
                    {...serviceOffer}
                  >
                    {pastService.slice(0, 4).map((item, idx) => (
                      <div className="item" key={idx}>
                        <div className="past_serv_bg">
                          <div className="past_serv_inner">
                            <div className="past_serv_img h-100">
                              <img
                                onClick={() =>
                                  navigate(`/order-detail/${item.orderNo}`)
                                }
                                src={`${IMAGE_PATH + item.artisanData.image}`}
                                alt="img"
                              />
                              <span
                                className={`${
                                  item?.order_type !== "Heavy"
                                    ? "soft_badge"
                                    : "heavy_badge"
                                }`}
                              >
                                {item.order_type}
                              </span>
                            </div>
                            <div className="past_serv_cont">
                              <div
                                className="past_serv_tit"
                                onClick={() =>
                                  navigate(`/order-detail/${item.orderNo}`)
                                }
                              >
                                <h5>#{item.orderNo}</h5>
                                <div className="review_inner">
                                  <span className="review-ic">
                                    <img src={star} alt="" />
                                  </span>
                                  <span className="review_dtl">
                                    {item.rating}
                                  </span>
                                </div>
                              </div>
                              <div className="tag d-flex align-items-center justify-content-between">
                                <span className="tag_complete">
                                  {item.paymentStatus === "Success"
                                    ? "Completed"
                                    : item.paymentStatus}
                                </span>
                                {/* <div className="totalServices rounded-circle p-1 overflow-hidden text-white d-flex align-items-center justify-content-center">
                                  {item?.products?.length}
                                </div> */}
                              </div>
                              {/* {console.log("prod_name", item?.products)} */}
                              <div className="Totalservices">
                                {item?.products?.length > 0
                                  ? item?.products?.map((prod, idx) => (
                                      <div
                                        className="serviceItem d-inline-block me-1 text-truncate w-100"
                                        key={idx}
                                      >
                                        {lang == "en"
                                          ? prod?.serviceData?.name
                                          : prod?.serviceData?.name_fr}
                                      </div>
                                    ))
                                  : ""}
                              </div>
                              <p>€ {item?.totalAmount}</p>
                              {item?.status === "5" || item?.status === "6" ? (
                                <Button
                                  type="button"
                                  className="#btn btn_primary"
                                  onClick={() => handelReOrder(item.orderNo)}
                                >
                                  {t("Rebook")}
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                ) : (
                  <div className="no_data">
                    <img src={noData} />
                  </div>
                )}
              </Container>
            </section>
          )}

          <section className="service_type_sec">
            <Container>
              <OwlCarousel className="owl-carousel owl-theme" {...options}>
                {banner?.serviceBanners.map((item, idx) => (
                  <div className="item" key={idx}>
                    <div className="offfer_bannerBottom">
                      <img
                        src={`${IMAGE_PATH + item.banner_image}`}
                        onClick={() => {
                          if (item?.service_type === "Soft") {
                            navigate(`/service-detail/${item?.id}`);
                          } else if (item?.service_type === "Offline") {
                            navigate(`/offline-service-detail/${item?.id}`);
                          } else {
                            navigate(`/service-detail/${item?.id}`);
                          }
                        }}
                        alt="Banner Image"
                      />
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </Container>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};
export default Home;

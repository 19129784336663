import React, { useEffect, useState } from "react";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import Http from "../../services/Http";
import {
   CloseOutlined  
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  FormSelect,
  InputGroup,
  Row,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import noData from "../../assets/img/no-data.png";
import subIcon from "../../assets/img/sub-c1.png";

import { Link, useNavigate, useParams } from "react-router-dom";
import MultiRangeSlider from "../Component/MultiRangeSlider";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const SoftServiceList = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [resetFlag, setResetFlag] = useState(false);
  const [artisans, setArtisans] = useState();
  const [categoryData, setCategoryData] = useState();
  const [subCategoryData, setSubCategoryData] = useState();
  const [serviceData, setServiceData] = useState();
  const [artisanData, setArtisanData] = useState();
  const [artisan, setArtisan] = useState("");
  const [minRate, setMinRate] = useState(0);
  const [maxRate, setMaxRate] = useState(1000);
  const [filter, setFilter] = useState(false);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectSubCategory, setSelectSubCategory] = useState("");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState();
  const navigate = useNavigate();
  let lang = localStorage.getItem("lang");
  useEffect(() => {
    getSubCategory();
  }, [sort, selectSubCategory]);

  const onFilter = async () => {
    let path = apiPath.allArtisans;

    var formData = new FormData();
    formData.append("slots", "08:00 - 09:00");
    formData.append("date", "2023-02-01");

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      // console.log(res, "-------------------------");
      if (res.data.success === true) {
        // console.log(artisans);
        setArtisans(res.data.result.artisanList.docs);
        console.log(res);
        console.log(artisans, "--------------------------");

        toast.success(res.data.msg);
      } else {
        toast.error(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const onCategorySelect = (e) => {
    const { value } = e.target;
    setSelectSubCategory(value);
  };

  const onSelectCategoryDropDown = (e) => {
    const { value } = e.target;
    setSelectSubCategory(value);
    console.log("catVal", value);
  };

  const selectArtisan = (e) => {
    const { value } = e.target;
    setArtisan(value);
    console.log("catVal", value);
  };

  const onFilterData = () => {
    getSubCategory();
  };

  const resetData = () => {
    const resetData = {
      categoryId: `${id}`,
      subcategoryId: "",
      price: null,
      keyword: "",
      sort: sort ? sort : "",
    };
    setSearch("");
    setResetFlag(!resetFlag);
    setSelectSubCategory("");
    setMinRate(0);
    setMaxRate(1000);
    getSubCategory(resetData);
  };

  // const onClearFilter = () => {
  //   setMinRate(0);
  //   setMaxRate(1000);
  //   setArtisan();
  //   setSelectSubCategory();
  // };

  const getSubCategory = async (resetData) => {
    const path = apiPath.serviceList;
    const data = {
      categoryId: `${id}`,
      subcategoryId: `${selectSubCategory}`,
      price: `${minRate}, ${maxRate}` ? `${minRate}, ${maxRate}` : "0, 1000",
      keyword: search ? search : "",
      sort: sort ? sort : "",
    }; //`${id}`
    const options = { method: "POST", data: resetData ? resetData : data };
    try {
      const res = await Http(path, options);
      if (res?.data?.success === true) {
        // toast.success(res?.data?.msg);
        const { artisanArr, categoryData, subcategoryArr, serviceData } =
          res?.data?.result;
        setCategoryData(categoryData);
        setSubCategoryData(subcategoryArr);
        setServiceData(serviceData);
        setArtisanData(artisanArr);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleSortBy = (e) => {
    setSort(e.target.value);
  };

  const sortBy = [
    { title: "Sort", value: "" },
    { title: "ASC", value: "AtoZ" },
    { title: "DESC", value: "ZtoA" },
    // { title: "Price ASC", value: "price_ASC" },
    // { title: "Price DESC", value: "price_DESC" },
  ];
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  // Function to toggle the class
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  const removeSidebarClass = () => {
    setSidebarVisible(false);
  };

  return (
    <>
      <Toaster position="top-right" />
      <section className="browse-artisan-sec">
        <div className="container">
            <div className="filter-button mb-2">
                <button className="btn_primary btn btn-primary"  onClick={toggleSidebar}>
                Filter
                </button>
            </div>
          <div className="subcategory_sec">
            <div className="subcategory-inner">
              <div className="subcategory-left">
                <div className="subcat-main-img">
                  <img src={IMAGE_PATH + categoryData?.image} alt="" />
                </div>
              </div>
              <div className="subcategory-right">
                <div className="title_cls">
                  <h3 className="heading_h3">{t("SelectSubcategories")}</h3>
                </div>
                <div className="subcategory-inner-list">
                  {subCategoryData?.length > 0 &&
                    subCategoryData.slice(0, 6)?.map((item, idx) => (
                      <div className="single-subcategory">
                        <label className="subcategory_select" key={idx}>
                          <input
                            type="radio"
                            id="radio"
                            value={item.id}
                            checked={`${selectSubCategory}` === `${item.id}`}
                            onChange={(e) => onCategorySelect(e)}
                          />
                          <span className={"checkmark"}>
                            <div className="subcate-icon">
                              {item.image === null ||
                              item.image === "undefined" ||
                              item.image === "" ? (
                                <img src={subIcon} alt="sucategoryImg" />
                              ) : (
                                <img
                                  src={IMAGE_PATH + item.image}
                                  alt="sucategoryImg"
                                />
                              )}
                            </div>
                          </span>
                          <div className="subcateogry-name">
                            <h5>{lang == "en" ? item?.name : item?.fr_name}</h5>
                          </div>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="browse-artisan-main">
            <div className="browse-artisan-left"> 
              <div className={classNames("sidebar_bg", { "active-class": isSidebarVisible })}>
                <div className="filter-top">
                  <div className="cancle-filter d-md-none d-flex" onClick={removeSidebarClass}><CloseOutlined /></div>
                  <h5>{t("filter")}</h5>
                  <Link to="#" onClick={resetData}>
                    {t("reset")}
                  </Link>
                </div>
                <div className="sidebar_wrap">
                  <div className="sidebar_cont">
                    <h5>{t("search")}</h5>
                    <Form.Group className="form-group">
                      <Form.Control
                        type="text"
                        placeholder="Search by service name.."
                        className="form-control"
                        value={search}
                        onChange={(e) => handleSearch(e)}
                      />
                    </Form.Group>
                  </div>
                  <div className="sidebar_cont">
                    <h5>{t("sub_category")}</h5>
                    <Form.Group className="form-group">
                      <FormSelect
                        className="form-control"
                        value={selectSubCategory}
                        onChange={onSelectCategoryDropDown}
                      >
                        <option value={""}>{t("SelectSubcategory")}</option>
                        {subCategoryData?.length > 0 &&
                          subCategoryData?.map((option, idx) => (
                            <option key={idx} value={option.id}>
                              {lang == "en" ? option.name : option.fr_name}
                            </option>
                          ))}
                      </FormSelect>
                    </Form.Group>
                  </div>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <div className="price-range-sec">
                        <h5>{t("Price")}</h5>
                        <div className="range_slider">
                          <MultiRangeSlider
                            reset={resetFlag} //
                            min={0}
                            max={1000}
                            onChange={({ min, max }) => {
                              console.log(`min = ${min}, max = ${max}`);
                              setMinRate(min);
                              setMaxRate(max);
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  {/* <div className="sidebar_cont">
                    <h5>Artisans</h5>
                    <Form.Group className="form-group">
                      <FormSelect className="form-control">
                        {artisanData?.length > 0 &&
                          artisanData?.map((artisn, idx) => (
                            <option
                              key={idx}
                              value={artisn.name}
                              onChange={selectArtisan}
                            >
                              {artisn.name}
                            </option>
                          ))}
                      </FormSelect>
                    </Form.Group>
                  </div> */}

                  {/* <div className="sidebar_cont">
                      <h5>Filter By</h5>
                      <Form.Group className="form-group">
                        <FormSelect className="form-control">
                          <option>Service at Home</option>
                          <option>Select by</option>
                          <option>Select by</option>
                          <option>Select by</option>
                        </FormSelect>
                      </Form.Group>
                    </div> */}
                  <div className="login-btn">
                    <Button className="btn_primary" onClick={onFilterData}>
                      {t("filter")}
                    </Button>
                  </div>
                  {/* <div className="login-btn mt-2">
                    <Button className="btn_primary" onClick={resetData}>
                      {t("reset")}
                    </Button>
                  </div> */}
                </div>
              </div> 
            </div>
            <div className="browse-artisan-right">
              <div className="rightside_artisan_top">
                <h5>
                  {serviceData?.docs?.length} {t("Services")}
                </h5>

                <div className="sort_by">
                  <Form.Group className="form-group">
                    <FormSelect
                      className="form-control"
                      value={sort}
                      onChange={(e) => handleSortBy(e)}
                    >
                      {sortBy?.map((itm) => (
                        <option value={itm.value}>{itm.title}</option>
                      ))}
                    </FormSelect>
                  </Form.Group>
                </div>

              </div>
              <div className="artisan_list_wrap artisan_category_list">
                <Row>
                  {serviceData?.docs?.length !== 0 ? (
                    serviceData?.docs?.map((item, idx) => (
                      <Col xs="6" sm="4" md="6" lg="4" xl="3" key={idx}>
                        <div className="categroy_slider_inner">
                          <div className="categroy_slider_img">
                            {item.image === null ||
                            item.image === "undefined" ||
                            item.image === "" ? (
                              <img
                                onClick={() =>
                                  navigate(`/service-detail/${item.id}`, {
                                    state: { categoryId: id },
                                  })
                                }
                                src="http://3.20.147.34:2000/images/service/202301061240135304.jpg"
                                alt="serviceImage"
                              />
                            ) : (
                              <img
                                onClick={() =>
                                  navigate(`/service-detail/${item.id}`, {
                                    state: { categoryId: id },
                                  })
                                }
                                src={IMAGE_PATH + item.image}
                                alt="serviceImage"
                              />
                            )}
                            <span
                              className={
                                item?.service_type === "Soft"
                                  ? "soft_badge"
                                  : "heavy_badge"
                              }
                            >
                              {item?.service_type}
                            </span>
                          </div>
                          <div className="categroy_prd_cont">
                            <h4>{lang == "en" ? item?.name : item?.name_fr}</h4>
                            <p>{t("Startingfrom")}</p>
                            <h5>
                              € {item.min_price} - € {item.max_price}
                            </h5>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div className="no_data">
                      <img src={noData} />
                    </div>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SoftServiceList;

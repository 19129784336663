import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Star from "../../assets/img/star_icon.png";
import ArtisanImg from "../../assets/img/artisan1.png";
import like from "../../assets/img/like.png";
import disLike from "../../assets/img/like.png";
import mapStyle from "../../assets/mapStyle";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import Http from "../../services/Http";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import MultiRangeSlider from "../Component/MultiRangeSlider";
import GMap from "../Component/GMap";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const SelectTime = () => {
  const { t, i18n } = useTranslation();
  const [schedule, setSchedule] = useState("Right Now");
  const [selectTime, setSelectTime] = useState();
  const [selectFreeLancer, setSelectFreeLancer] = useState(false);
  const [artisanList, setArtisanList] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showartisanList, setShowArtisanList] = useState();
  const [timeSlotError, setTimeSlotError] = useState();
  const [minRate, setMinRate] = useState();
  const [maxRate, setMaxRate] = useState();
  const [favArtisan, setFavArtisan] = useState(false);
  const [favVal, setFavVal] = useState(true);
  const [timeSlot, setTimeSlot] = useState([]);

  const navigate = useNavigate();

  const { state } = useLocation();
  // console.clear();
  console.log(state);
  const { categoryId, serviceId } = state;

  useEffect(() => {
    getTimeSlots(date);
  }, [date]);

  const getTimeSlots = async (date) => {
    let path = apiPath.timeSlots;
    var formData = new FormData();
    formData.append("service_date", moment(date).format("YYYY-MM-DD"));

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);

      if (res.data.success === true) {
        const time = convertArray(res.data.result);
        setTimeSlot(time);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const handleSubmit = () => {
    console.log("timeSlot--", selectTime?.length > 0);
    if (selectTime?.length > 0) {
      setTimeSlotError(false);
      navigate("/browse-offline-artisans", {
        state: {
          scheduling: "Later",
          categoryId: categoryId ? `${categoryId}` : "",
          serviceId: serviceId ? `${serviceId.toString()}` : "",
          date: moment(date).format("yyyy-MM-DD"),
          time_slot: `${selectTime}` ? `${selectTime}` : "",
          min: `${minRate}` ? `${minRate}` : "",
          max: `${maxRate}` ? `${maxRate}` : "",
          amount: state ? `${state?.amount}` : "",
        },
      });
    } else {
      setTimeSlotError(true);
    }
  };

  console.log("first", schedule);

  const userToken = localStorage.getItem("userToken");

  const markFavorite = async (id) => {
    if (userToken) {
      setFavVal(!favVal);
      console.log("artisanId", id);
      const path = apiPath.markFavorite;
      const data = {
        fev_type: "Artisan",
        fev_id: `${id}`,
        fev_val: favVal === true ? "1" : "0",
      };
      const options = { method: "POST", data };

      try {
        const res = await Http(path, options);
        if (res?.data?.success === true) {
          setFavArtisan(!favArtisan);
          console.log(res.data.result);
          toast.success(res?.data?.msg);
          onRightnowClick();
        } else {
          console.log(res?.data?.msg);
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Login Required!");
    }
  };

  const onRightnowClick = async () => {
    // let today = new Date();
    // var date =
    //   today.getFullYear() +
    //   "-" +
    //   (today.getMonth() + 1) +
    //   "-" +
    //   today.getDate();
    // var time = today.getHours() + ":" + today.getMinutes();

    let path = apiPath.categoryBasedArtisanList;
    let formData = new FormData();
    formData.append("category_id", categoryId ? categoryId.toString() : "");
    formData.append("service_id", serviceId ? serviceId.toString() : "");
    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);

      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        console.log(res.data.msg);
        console.log(res.data.result);
        setArtisanList(true);
        if (!res?.data?.result) setShowArtisanList([]);
        setShowArtisanList(res?.data?.result);
        // setCenter({
        //   lat: res.data.result.artisanList.docs.latitude,
        //   lng: res.data.result.artisanList.docs.longitude,
        // });
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };
  const onScheduling = (e) => {
    const { value } = e.target;
    setSchedule(value);
    setArtisanList(false);
    console.log(value);
  };

  const onTimeSelect = (e) => {
    const { value } = e.target;
    setSelectTime(value);
    console.log(value);
  };

  const onLaterService = async () => {
    // let path = apiPath.allArtisans;
    // var formData = new FormData();
    // let options = {
    //   method: "POST",
    //   data: formData,
    // };
    // try {
    //   let res = await Http(path, options);
    //   console.log(res, "-------------------------");
    //   if (res.data.success === true) {
    //     console.log("api hit successfully", res.data.msg);
    //     console.log(res.data.msg);
    //     console.log(res.data.result.artisanList.docs);
    //     setArtisanList(true);
    //     setShowArtisanList(res.data.result.artisanList.docs);
    //   } else {
    //     console.log(res.data.msg);
    //   }
    // } catch (err) {
    //   console.log(err.res);
    // }
  };

  const artisanLat =
    showartisanList &&
    showartisanList.length > 0 &&
    showartisanList?.map((item) => {
      const latLng = [];
      latLng?.push(
        item?.latitude === null ? 26.85635186559557 : parseFloat(item?.latitude)
      );
      latLng?.push(
        item?.longitude === null
          ? 75.76756965368986
          : parseFloat(item?.longitude)
      );
      return latLng;
    });

  console.log("lat", artisanLat);
  // console.log("lng", artisanLng);

  const onRightNow = async (artisanId) => {
    let path = apiPath.setPreviewOffline;
    const data = {
      order_type: "Offline",
      service_type: "Right Now",
      time_slot: null,
      service_date: null,
      artisan_id: `${artisanId}`,
      service_id: state?.serviceId ? `${state?.serviceId}` : "",
      service_amount: `${state.amount}` ? `${state.amount}` : "",
    };

    let options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        toast.success(res.data.msg);
        navigate(`/artisan-detail-offline/${artisanId}`, { state: state });
      } else {
        toast.error(res.data.msg);
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  console.log("artisanList", artisanList);

  return (
    <section className="select-time-sec">
      <Toaster position="top-right" />
      <div className="container">
        <div className="selecttime_main">
          <div className="time_map_left">
            <h4>{t("when_do_you_want_service")}?</h4>

            <div className="artisans_radioBtn">
              <form>
                <div className="radio_cls">
                  <label className="custom_radio">
                    {" "}
                    <input
                      type="radio"
                      id="radio"
                      value="Right Now"
                      onClick={onRightnowClick}
                      checked={schedule === "Right Now"}
                      onChange={onScheduling}
                    />
                    Right Now
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom_radio">
                    <input
                      type="radio"
                      id="radio"
                      value="Later"
                      checked={schedule === "Later"}
                      onChange={onScheduling}
                    />
                    Later
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
            </div>
            {schedule === "Right Now" && (
              <>
                <div className="time_map">
                  <div className="map_card">
                    {showartisanList?.length > 0 ? (
                      <GMap latLng={artisanLat} />
                    ) : (
                      <>
                        <h3>Uh oh..</h3>
                        <h4>No artisan is available currently!</h4>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {!artisanList && (
              <div className="time-select-slot">
                <Row>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <label htmlFor="">{t("specific_date")}</label>
                      <DatePicker
                        showIcon
                        className="form-control"
                        showYearDropdown
                        selected={date}
                        onChange={(date) => setDate(date)}
                        minDate={moment().toDate()}
                        dateFormat="dd-MM-yyyy"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <div className="price-range-sec">
                        <h5>Price</h5>
                        <div className="range_slider">
                          <MultiRangeSlider
                            min={0}
                            max={1000}
                            onChange={({ min, max }) => {
                              console.log(`min = ${min}, max = ${max}`);
                              setMinRate(min);
                              setMaxRate(max);
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <h5>{t("time_slot")}</h5>
                    <div className="time-slot">
                      {timeSlot.map((item, idx) => (
                        <label className="time-label" key={idx}>
                          <input
                            type="radio"
                            id="radio"
                            value={item.time}
                            checked={selectTime === item.time}
                            onChange={onTimeSelect}
                          />
                          <span className="checkmark">{item.duration}</span>
                        </label>
                      ))}
                      {timeSlotError && (
                        <span className="error_msg">
                          {"Pleas select a time slot!"}
                        </span>
                      )}
                    </div>
                  </Col>
                  <div className="custom_checkbox mt-4">
                    <Form.Check
                      name="featured_artisan"
                      size={18}
                      label={t("include_freelancers_as_well")}
                      value={setSelectFreeLancer}
                      onChange={() => setSelectFreeLancer(!selectFreeLancer)}
                    />
                  </div>
                </Row>
              </div>
            )}
          </div>
          <div className="time_list_wrap">
            <div className="time_bg_wrap">
              {artisanList &&
                showartisanList?.map((item, idx) => (
                  <div className="artisan_bg select" key={idx}>
                    <div className="artisan_left_img">
                      <div className="artisan_img">
                        <img src={`${IMAGE_PATH + item.image}`} alt="" />
                      </div>
                      <span className="like_img cursor-pointer">
                        <img
                          onClick={() => markFavorite(item.id)}
                          src={item.fevData?.id ? like : disLike}
                          alt="likeImg"
                        />
                      </span>
                    </div>

                    <div className="artisan_right_cont">
                      <div className="tag">
                        <span>Most Active</span>
                      </div>
                      <div className="artisan_title">
                        <h5>
                          {item.name}, <span>{item.artisan_level}</span>
                        </h5>
                      </div>
                      <div className="jobs_review">
                        <div className="job_cont">
                          <span>
                            #{item.job_done === null ? "No" : item.job_done}{" "}
                            {t("Jobs")}
                          </span>
                        </div>
                        <div className="review_cont">
                          <span className="review-ic">
                            <img src={Star} alt="" />
                          </span>
                          <span>{item.rating}</span>
                        </div>
                      </div>
                      <div className="artisan_price_wrap">
                        {/* <div className="artisan_price_left">
                          <h5>€ 100.00</h5>
                        </div> */}

                        <div className="artisan_view_btn">
                          <Button
                            className="btn_primary"
                            onClick={() => onRightNow(item.id)}
                          >
                            {t("view_detail")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {!artisanList && (
                <div className="login-btn">
                  {/* <Link
                  to="/browse-artisans"
                  state={{
                    scheduling: "Later",
                    email: email ? email : "",
                    mobile: mobile ? mobile : "",
                    addressId: addressId ? addressId : "",
                    date: moment(date).format("yyyy-MM-DD"),
                    time_slot: selectTime,
                    min: minRate,
                    max: maxRate,
                  }}
                > */}
                  <Button className="btn_primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                  {/* </Link> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function convertArray(givenArray) {
  const newArray = givenArray.map((item) => {
    const time = `${item.from}-${item.to}`;
    const duration = `${item.from} To ${item.to}`;
    return { time, duration };
  });

  return newArray;
}

export default SelectTime;

import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Star from "../../assets/img/star_icon.png";
import Like from "../../assets/img/like.png";
import Love from "../../assets/img/love.png";
import CheckIcon from "../../assets/img/check_icon.png";
import OwlCarousel from "react-owl-carousel3";
import { useLocation, useParams } from "react-router-dom";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import Http from "../../services/Http";
import { useNavigate, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

// import Address from "modals/Address";
import { Skeleton } from "antd";
const category = {
  rtl: true,
  margin: 15,
  nav: true,
  dots: false,
  loop: false,
  responsive: {
    0: {
      items: 2,
    },
    420: {
      items: 2,
    },
    577: {
      items: 3,
    },
    991: {
      items: 4,
    },
    1366: {
      items: 5,
    },
  },
};

const ArtisansDetail = () => {
  const { t } = useTranslation();

  // const { productData, setProductData } = useContext(ProductContext);
  // console.log("first", productData);
  const [artisanDetail, setArtisanDetail] = useState({});
  const [serviceData, setServiceData] = useState();
  const [remainingPrice, setReaminingPrice] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderPrice, setOrderPrice] = useState();
  const [pageError, setPageError] = useState(false);
  const location = useLocation();
  const { state } = location;
  const cartDetail = state;
  const { id } = useParams();

  const artisanID = id;
  const [selectServiceType, setSelectServiceType] = useState("");
  const userToken = localStorage.getItem("userToken");
  const [favArtisan, setFavArtisan] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [favVal, setFavVal] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const [cartItem, setCartItem] = useState(
    JSON.parse(window.localStorage.getItem("cart_item"))
  );

  useEffect(() => {
    let ordersTotal = 0;
    productData?.map((item) => {
      // console.log("item", item);
      ordersTotal = parseFloat(ordersTotal) + parseFloat(item.price);
    });
    setOrderTotal(ordersTotal);
  }, [productData]);

  useEffect(() => {
    if (!cartItem) return;
    cartItem.artisan_id == id && setProductData(cartItem.selectedServices);
  }, [cartItem]);

  const removeProduct = (product) => {
    const find = productData.filter((item) => item.id !== product.id);
    setProductData(find);
  };

  const navigate = useNavigate();

  console.log("state", state);

  const cartData = {
    totalItemQty: productData.length,
    amount: productData.reduce((acc, curr) => acc + parseInt(curr.price), 0),
    discount_amount: "0",
  };

  const prodktData = productData.map((item) => ({
    serviceId: item.id,
    price: item.price,
  }));

  const serviceType = serviceData?.map((itm) => itm.service_type);

  console.log("serviceType", serviceType);

  const getArtisanDetails = async () => {
    setLoading(true);
    let path = apiPath.artisanDetails;
    console.log(artisanID);
    const data = {
      id: artisanID.toString(),
    };
    let options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        setArtisanDetail(res.data.result);
        const { min_order, fevData } = res.data.result?.artisanDetails;
        setOrderPrice(min_order);
        setReaminingPrice(min_order);
        fevData ? setIsFav(true) : setIsFav(false);
        let { serviceData } = res.data.result?.serviceResult;
        setServiceData(serviceData);
        console.log(res.data.result);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res.data.msg);
        console.log(res.data.msg);
        setPageError(true);
      }
    } catch (err) {
      setLoading(false);
      console.log(err.res);
    }
  };

  useEffect(() => {
    getArtisanDetails();
  }, []);

  const onOpen = () => {
    if (userToken) {
      navigate(`/checkout-offline`, { state: state });
    } else {
      toast.error("Login Required!");
    }
  };

  const onHide = () => {
    setShowAddress(false);
  };

  const markFavorite = async (id) => {
    if (userToken) {
      setFavVal(!favVal);
      const path = apiPath.markFavorite;
      const data = {
        fev_type: "Artisan",
        fev_id: `${id}`,
        // fev_val: favVal === true ? "1" : "0",
        fev_val: isFav ? "0" : "1",
      };
      const options = { method: "POST", data };

      try {
        const res = await Http(path, options);
        if (res?.data?.success === true) {
          setFavArtisan(!favArtisan);
          console.log(res.data.result);
          toast.success(res?.data?.msg);
          setIsFav((prev) => !prev);
        } else {
          console.log(res?.data?.msg);
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Login Required!");
    }
  };

  const handelContinue = () => {
    if (productData?.length > 0) {
      onOpen();
    } else {
      toast.error("Please select a service!");
    }
  };

  const FavIcon = isFav ? Love : Like;

  const onSelectService = (e, item) => {
    console.log(productData, item);
    setSelectServiceType(item?.service_type);
    if (productData.length) {
      const isTest = productData.filter(
        (data) => data.service_type != item.service_type
      );
      console.log(isTest, "isTest");
      if (isTest.length) {
        return;
      }

      if (selectServiceType == "" || item?.service_type === selectServiceType) {
        if (parseInt(state?.serviceId) === item.id) {
          setProductData((prev) => [...prev, item]);
          // setSelectServiceType(item?.service_type);
          console.log("selectServiceType", item);
        }

        const selected = e.target.checked;
        selected
          ? setProductData((prev) => [...prev, item])
          : removeProduct(item);
        setIsChecked((prev) => !prev);
      } else if (productData?.length === 0) {
        setSelectServiceType("");
      } else {
        toast.error(
          "You could'nt select both soft and in-shop service at same time!"
        );
      }
    } else {
      setProductData((prev) => [...prev, item]);
    }
  };

  return (
    <>
      {/* {showAddress && (
        <Address
          serviceType={serviceType}
          productData={prodktData}
          selectedServices={productData}
          cartData={cartData}
          artisanId={artisanID}
          serviceData={window.localStorage.getItem("cart_item")}
          onOpen={onOpen}
          onHide={onHide}
        />
      )} */}

      <section className="artisan-detail-sec">
        <Toaster position="top-right" />
        {loading ? (
          [1, 2, 3, 4].map((item) => <Skeleton active key={item} />)
        ) : artisanDetail?.artisanDetails ? (
          <div className="container">
            <div className="browse-artisan-detail">
              <div className="artisan_list_wrap">
                <Row>
                  <Col md="8">
                    <div className="artisan_bg">
                      <div className="artisan_left_img">
                        <div className="artisan_img">
                          <img
                            src={`${
                              IMAGE_PATH + artisanDetail?.artisanDetails.image
                            }`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="artisan_right_cont artisan-dtl-cont">
                        <div className="artisan_title">
                          <h5>
                            {artisanDetail?.artisanDetails.name},{" "}
                            <span>
                              {artisanDetail?.artisanDetails?.artisan_level}
                            </span>
                          </h5>
                          <div className="artisan_dtl_right">
                            <div className="tag">
                              <span>Most Active</span>
                            </div>
                            <span className="like_img">
                              <Link to="#">
                                <img
                                  onClick={() =>
                                    markFavorite(
                                      artisanDetail?.artisanDetails?.id
                                    )
                                  }
                                  src={FavIcon}
                                  alt
                                />
                              </Link>
                            </span>
                          </div>
                        </div>
                        <div className="jobs_review">
                          <div className="job_cont">
                            <span>
                              #
                              {artisanDetail?.artisanDetails?.job_done === null
                                ? "No"
                                : artisanDetail?.artisanDetails?.job_done}{" "}
                              {t("Jobs")}
                            </span>
                          </div>
                        </div>
                        <div className="artisan_price_wrap">
                          {/* <div className="artisan_price_left">
                            <h5>€ 100.00</h5>
                          </div> */}
                          <div className="review_cont">
                            <span className="review-ic">
                              <img src={Star} alt="" />
                            </span>
                            <span>{artisanDetail?.artisanDetails?.rating}</span>
                          </div>
                        </div>
                        <div className="artisan_dtl_inner_cont">
                          <p>{artisanDetail?.artisanDetails.tag_line} </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {artisanDetail?.artisanDetails.parentData && (
                    <Col md="4">
                      <div className="associated_bg">
                        <div className="associted_inner_cont">
                          <h5>Associated With</h5>
                          <div className="christophe_profile">
                            <div className="christophe_img">
                              <img
                                src={`${
                                  IMAGE_PATH +
                                  artisanDetail?.artisanDetails?.parentData
                                    ?.image
                                }`}
                              />
                            </div>
                            <div className="Christophe_user_name">
                              {artisanDetail?.artisanDetails?.parentData?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>

              <div className="artisan_about_cont">
                <h4>About {artisanDetail?.artisanDetails.name}</h4>
                <p>{artisanDetail?.artisanDetails.bio}</p>
                <h5>Address</h5>
                <p>{artisanDetail?.artisanDetails.residence_address}</p>
                <div className="category_slider">
                  <OwlCarousel className="owl-carousel owl-theme" {...category}>
                    {artisanDetail.serviceResult.count === 0 ? (
                      <h1>No services</h1>
                    ) : (
                      artisanDetail?.serviceResult?.serviceData?.map(
                        (item, idx) => (
                          <div className="item" key={idx}>
                            <div className="slect_category_check">
                              <label className="custom_checkbox">
                                <div className="form-chcek">
                                  <input
                                    checked={
                                      productData?.find(
                                        (data) => data.id === item.id
                                      )
                                        ? true
                                        : false
                                      // ||
                                      //   (parseInt(state?.serviceId) ===
                                      //   item.id
                                      //     ? true
                                      //     : false)
                                    }
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={(e) => {
                                      onSelectService(e, item);
                                    }}
                                    // value={services?.serviceId}
                                  />
                                  <span className="checkmark">
                                    <img src={CheckIcon} alt="" />
                                  </span>
                                  <div className="categroy_slider_inner">
                                    <div className="categroy_slider_img">
                                      <img
                                        src={`${IMAGE_PATH + item.image}`}
                                        onClick={() =>
                                          navigate(
                                            `/service-detail/${item?.id}`
                                          )
                                        }
                                      />
                                      <span
                                        className={`${
                                          item.service_type !== "Soft"
                                            ? "heavy_badge"
                                            : "soft_badge"
                                        }`}
                                      >
                                        {item.service_type}
                                      </span>
                                    </div>
                                    <div className="categroy_prd_cont">
                                      <h4>{item.name}</h4>
                                      <p>{t("starting_from")}</p>
                                      <h5>€ {item.price}</h5>
                                    </div>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        )
                      )
                    )}
                  </OwlCarousel>
                </div>
                {orderPrice > orderTotal ? (
                  <>
                    <div className="service-price">
                      <div className="service_price_left">
                        <span>
                          Add Services worth{" "}
                          <span className="price_highlight">
                            € {orderPrice - orderTotal}
                          </span>{" "}
                          more to checkout
                        </span>
                      </div>
                      <div className="service_price_right">
                        <span>
                          Minimum order amount for Wade is{" "}
                          <span className="price_highlight">
                            € {orderPrice}
                          </span>
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="selected_service">
                    <div className="artisan_dtl_inner_cont">
                      <p>No. of Selected Services: {productData.length} </p>
                    </div>

                    <div className="continue_btn">
                      <Button className="btn_primary" onClick={handelContinue}>
                        Continue
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center ml-auto">
            <h3>No Artisan Found!</h3>
            <Button
              className="btn-dark"
              onClick={() => navigate("/", { replace: true })}
            >
              Go to Home
            </Button>
          </div>
        )}
      </section>
    </>
  );
};

export default ArtisansDetail;

import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel3";
import like from "../../assets/img/love.png";
import disLike from "../../assets/img/like.png";
import star from "../../assets/img/star_icon.png";
import Video from "../../assets/img/video_img.png";
import PlayIcon from "../../assets/img/play_icon.png";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import { Button, Container } from "react-bootstrap";
import Http from "../../services/Http";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Address from "../Component/modalComponents/Address";
import { toast, Toaster } from "react-hot-toast";
// import noArtisanImg from "../../assets/img/noArtisanImg.png";
import noServiceImg from "../../assets/img/no-service.png";
import { getYouTubeVideoId } from "../../helpers/functions";
import { ChangeAddress } from "../Checkout";
import { useTranslation } from "react-i18next";

const center = { lat: 48.8584, lng: 2.2945 };
const category = {
  rtl: true,
  margin: 0,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 1,
    },
    991: {
      items: 1,
    },
    1366: {
      items: 1,
    },
  },
};
const categoryfeatured = {
  rtl: true,
  margin: 15,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 2,
    },
    577: {
      items: 3,
    },
    991: {
      items: 4,
    },
    1199: {
      items: 5,
    },
    1366: {
      items: 6,
    },
  },
};
const internelOffer = {
  rtl: true,
  margin: 15,
  nav: true,
  dots: false,
  loop: false,
  responsive: {
    0: {
      items: 1,
    },
    420: {
      items: 1,
    },
    577: {
      items: 2,
    },
    991: {
      items: 4,
    },
    1200: {
      items: 4,
    },
  },
};
const reletedService = {
  rtl: true,
  margin: 15,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 2,
    },
    420: {
      items: 2,
    },
    577: {
      items: 3,
    },
    991: {
      items: 4,
    },
    1366: {
      items: 5,
    },
  },
};

const ServiceDetail = () => {
  const { t, i18n } = useTranslation();
  const [serviceDetails, setServiceDetails] = useState();
  const [artisanResult, setArtisanResult] = useState();
  const [relatedService, setRelatedService] = useState();
  const [galleries, setGallery] = useState([]);
  const [favArtisan, setFavArtisan] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [favVal, setFavVal] = useState(true);
  const navigate = useNavigate();
  const [selectedAddress, setSelectedAdress] = useState(1);

  let { id } = useParams();
  let { state } = useLocation();
  const categoryId = state?.categoryId;
  let lang = localStorage.getItem("lang");
  const getServiceDetail = async () => {
    let path = apiPath.serviceDetails;
    let options = {
      method: "POST",
      data: { id: `${id}` },
    };
    let res = await Http(path, options);
    if (res.data?.success == true) {
      setServiceDetails(res.data.result.serviceDetails);
      setArtisanResult(res.data.result.artisanResult);
      setRelatedService(res.data.result.relatedService);
      // console.log(
      //   IMAGE_PATH + res.data.result.artisanResult.artisanData[0].image
      // );
      let gall = [];
      gall.push(res?.data?.result?.serviceDetails?.image);
      if (res?.data?.result?.serviceDetails?.gallery) {
        let gall_1 = res?.data?.result?.serviceDetails?.gallery.split(",");
        console.log("gall_1", gall_1);
        if (gall_1 && gall_1.length > 0) {
          for (let gall_1 of res?.data?.result?.serviceDetails?.gallery.split(
            ","
          )) {
            gall.push(gall_1);
          }
        }
      }
      console.log("gel", gall);
      setGallery(gall);
    } else {
    }
  };

  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    getServiceDetail();
  }, []);

  const onOpen = (serviceId, amount) => {
    if (userToken) {
      navigate("/select-time-offline", {
        state: { categoryId, serviceId, amount },
      });
    } else {
      toast.error("Login Required!");
    }
  };

  const markFavorite = async (type, id) => {
    if (userToken) {
      setFavVal(!favVal);
      const path = apiPath.markFavorite;
      const data = {
        fev_type: type,
        fev_id: `${id}`,
        fev_val: favVal === true ? "1" : "0",
      };
      const options = { method: "POST", data };

      try {
        const res = await Http(path, options);
        if (res?.data?.success === true) {
          setFavArtisan(!favArtisan);
          console.log(res.data.result);
          toast.success(res?.data?.msg);
          getServiceDetail();
        } else {
          console.log(res?.data?.msg);
          toast.error(res?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Login Required!");
    }
  };

  const openAddress = () => {
    if (userToken) {
      setAddAddress(true);
    } else {
      toast.error("Login Required!");
    }
  };

  const hideAddress = () => {
    setAddAddress(false);
  };

  // console.clear();
  console.log("serviceDetails", serviceDetails);
  // console.log("artisanData", artisanResult?.artisanData?.length);

  console.log("type", serviceDetails?.service_type);

  window.localStorage.setItem(
    "cart_item",
    JSON.stringify({
      selectedServices: [serviceDetails],
    })
  );

  return (
    <>
      <Toaster position="top-right" />
      {addAddress && (
        <ChangeAddress
          categoryId={serviceDetails?.categoryId}
          serviceId={serviceDetails?.id}
          show={openAddress}
          hide={hideAddress}
          selectedAddress={selectedAddress}
          setSelectedAdress={setSelectedAdress}
          orderType={serviceDetails?.service_type}
        />
      )}
      <section className="service-detail-sec">
        <div className="container">
          <div className="service-detail">
            <div className="service_slider">
              {galleries?.length > 0 ? (
                <OwlCarousel className="owl-carousel owl-theme" {...category}>
                  {galleries.map((item, idx) => (
                    <div className="item" key={idx}>
                      <div className="serv_slider_img">
                        <img src={IMAGE_PATH + item} alt="gallaryImage" />
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              ) : (
                <div className="item">
                  <div className="serv_slider_img">
                    <img src={noServiceImg} alt="Service Image" />
                  </div>
                </div>
              )}
            </div>
            <div className="serv_dtl_cont">
              <div className="serv-dtl-title">
                <h3>{lang == "en" ? serviceDetails?.name : serviceDetails?.name_fr}</h3>
                <div className="serv_type">
                  <div className="serv_type_tag">
                    <span className="offline">{"In-Shop"}</span>
                  </div>
                  <div className="serv-like cursor-pointer">
                    <span
                      onClick={() =>
                        markFavorite("Service", serviceDetails?.id)
                      }
                      className="like"
                    >
                      <img
                        src={serviceDetails?.fevData === null ? disLike : like}
                        alt="likeImg"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <p>{lang == "en" ? serviceDetails?.description : serviceDetails?.description_fr}</p>
              {/* {serviceDetails?.service_note !== null && (
                <>
                  <h6>What this service includes</h6>
                  <ul>
                    <li>
                      <span className="check_ic">
                        <img src={Check} alt="checkImg" />
                      </span>
                      <span className="serv_name">
                        {serviceDetails?.service_note}
                      </span>
                    </li>
                  </ul>
                </>
              )} */}
              <div className="search_book_artisan">
                <Button
                  className="btn_primary d-none "
                  onClick={() => navigate("/browse-artisans")}
                >
                    {t("SearchArtisan")}
                </Button>
                {artisanResult?.artisanData?.length > 0 && (
                  <Button
                    className="btn_primary"
                    onClick={() =>
                      // onOpen(serviceDetails?.id, serviceDetails?.min_price)
                      openAddress()
                    }
                  >
                    {t("book")}
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="watch_demo_sec">
            {serviceDetails?.video_link?.length > 0 ? (
              <div className="title_cls">
                <h3 className="heading_h3">  {t("WatchaDemo")}</h3>
              </div>
            ) : (
              ""
            )}
            <div className="video_main_sec">
              {serviceDetails?.video_link ? (
                <>
                  <div className="video-inner">
                    {serviceDetails?.video_link.length > 0 ? (
                      <div className="video">
                        <iframe
                          width={550}
                          height={550}
                          src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                            serviceDetails?.video_link
                          )}`}
                          type="video/mp4"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : null}

              <div
                className={
                  serviceDetails?.video_link ? "viedo_cont" : "viedo_cont_1"
                }
              >
                {serviceDetails?.service_note !== null && (
                  <div className="video_cont_inner">
                    <h5>  {t("PleaseNote")}</h5>
                    <ul>
                      <li>{lang == "en" ? serviceDetails?.service_note : serviceDetails?.service_note_fr}</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="releted_service">
        <Container>
          <div className="title_cls">
            <h3 className="heading_h3">  {t("RelatedServices")}</h3>
          </div>
          {relatedService?.length > 0 && (
            <OwlCarousel className="owl-carousel owl-theme" {...internelOffer}>
              {relatedService?.map((item, idx) => (
                <div className="item" key={idx}>
                  <div className="categroy_slider_inner">
                    <div className="categroy_slider_img">
                      {item.image === null ||
                      item.image === "undefined" ||
                      item.image === "" ? (
                        <img
                          src={noServiceImg}
                          onClick={() =>
                            navigate(`/service-detail/${item?.id}`)
                          }
                        />
                      ) : (
                        <img
                          src={`${IMAGE_PATH + item.image}`}
                          onClick={() =>
                            navigate(`/service-detail/${item?.id}`)
                          }
                        />
                      )}
                      <span className="offline_badge">{"In Shop"}</span>
                    </div>
                    <div className="categroy_prd_cont">
                      <h4>{lang == "en" ? item?.name : item?.name_fr}</h4>
                      <p>{t("starting_from")}</p>
                      <h5>{item.min_price}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          )}
        </Container>
      </section>
    </>
  );
};
export default ServiceDetail;

import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import apiPath from "../constants/apiPath";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Http from "../services/Http";
import noData from "../assets/img/no-data.png";
import UserSidebar from "./UserSidebar";
import { useTranslation } from "react-i18next";

const MyCoupons = () => {
  const { t, i18n } = useTranslation();
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [search, setSearch] = useState("");
  console.log(
    promoCodeList?.filter((item) => item.name.toLowerCase().includes(search))
  );
  console.clear();
  const codeId = promoCodeList.map((item) => item.id);
  console.log("codeID", codeId);

  const getPromoCodeList = async () => {
    let path = apiPath.promo_code_list;
    let formData = new FormData();
    formData.append("name", "");

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log(res.data.msg);
        setPromoCodeList(res.data.result);
        console.log(res.data.result);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getPromoCodeList();
  }, []);

  return (
    <>
      <section className="order-main">
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <UserSidebar />
            </div>
            <div className="account-sidebar-right">
              <div className="m_sidebar_bg">
                <div className="coupon_inner">
                  <div className="coupon_code">
                    <div className="title_cls">
                      <h3 className="heading_h3">{t("AvailableCoupons")}</h3>
                    </div>
                    {/* <div className="form-group">
                      <label>Enter Coupon Code</label>
                      <div className="apply-code-inner">
                        <input
                          type="text"
                          className="form-control"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <button className="btn black_btn">Apply</button>
                      </div>
                    </div> */}
                  </div>
                  <div className="available_code">
                    <div className="available_code_list">
                      {promoCodeList?.length > 0 ? (
                        promoCodeList
                          ?.filter((item) =>
                            item.name.toLowerCase().includes(search)
                          )
                          .map((item) => (
                            <div className="coupocode-inner">
                              <div className="coupon-code-top">
                                <div className="coupon-code-btn">
                                  <span key={item.id}>{item.name}</span>
                                </div>
                                {/* <div className="coupon-code-apply">
                                    <button
                                      className="btn black_btn"
                                      onClick={handleApplyCode}
                                    >
                                      Apply
                                    </button>
                                  </div> */}
                              </div>
                              <p className="code_off" key={item.id}>
                                Get {item.discount}% off
                              </p>
                              {/* <p>
                                Use code HCFC102 & get 15% off for all home
                                cleaning services. Lorem Ipsum is simply dummy
                                text of the printing and typesetting industry.
                              </p> */}
                              {/* <a href="#">More</a> */}
                            </div>
                          ))
                      ) : (
                        <div className="categroy_slider_inner">
                          <div className="no_data">
                            <img src={noData} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default MyCoupons;

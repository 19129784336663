import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import apiPath from "../constants/apiPath";
import { useNavigate, useLocation } from "react-router-dom";
import Http from "../services/Http";
import noData from "../assets/img/no-data.png";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const { t, i18n } = useTranslation();
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { cartId, offline, by } = location.state;
  // console.log(cartId);

  console.log(location.state)

  const onSearch = (e) => {
    console.log(e.target.value);
  };
  console.log(
    promoCodeList?.filter((item) => item.name.toLowerCase().includes(search))
  );

  console.clear();
  // const codeId = promoCodeList.map((item) => item.id);
  // console.log("codeID", codeId, offline);

  const handleApplyCode = async (codeId) => {
    let path = apiPath.apply_promo_code;
    let data = {
      promocode_id: `${codeId}`,
      cart_id: `${cartId}`,
    };
    let options = {
      method: "POST",
      data,
    };
    try {
      let res = await Http(path, options);
        // alert(by)
      if (res?.data?.success === true) {
        toast.success(res.data.msg);
        if (by === "artisan" || by === "Soft" || by === "Offline") {
            navigate("/checkout", {
          state: {  orderType: by },
        });
          // navigate("/checkout");
          // navigate("/coupon-by-artisan", {
          //   chcha: {
          //     couponName: res?.data?.result?.name,
          //     couponId: res?.data?.result?.id,
          //   },
          // });
        } else {
          navigate("/checkout-offline");
        }
      } else {
        toast.error(res?.data?.msg);
      }
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getPromoCodeList = async () => {
    let path = apiPath.promo_code_list;
    let formData = new FormData();
    formData.append("name", "");

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log(res.data.msg);
        setPromoCodeList(res.data.result);
        console.log(res.data.result);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getPromoCodeList();
  }, []);
  return (
    <>
      <Toaster position="top-right" />{" "}
      <section className="coupon-main">
        <Container>
          <div className="coupon_inner">
            {/* <div className="coupon_code">
              <div className="form-group">
                <label>Enter Coupon Code</label>
                <div className="apply-code-inner">
                  <input
                    type="text"
                    className="form-control"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button className="btn black_btn">Apply</button>
                </div>
              </div>
            </div> */}
            <div className="available_code">
              <div className="title_cls">
                <h3 className="heading_h3">{t("AvailableCoupons")}</h3>
              </div>
              <div className="available_code_list">
                {promoCodeList?.length > 0 ? (
                  promoCodeList
                    ?.filter((item) => item.name.toLowerCase().includes(search))
                    .map((item) => (
                      <div className="coupocode-inner">
                        <div className="coupon-code-top">
                          <div className="coupon-code-btn">
                            <span key={item.id}>{item.name}</span>
                          </div>
                          <div className="coupon-code-apply">
                            <button
                              className="btn black_btn"
                              onClick={() => handleApplyCode(item?.id)}
                            >
                              {t("Apply")}
                            </button>
                          </div>
                        </div>
                        {item?.p_type === "Flat" ? (
                          <p className="code_off">
                            Get flat €{item.discount} off
                          </p>
                        ) : (
                          <p className="code_off">Get {item.discount}% off</p>
                        )}
                        {/* <p>
                          Use code HCFC102 & get 15% off for all home cleaning
                          services. Lorem Ipsum is simply dummy text of the
                          printing and typesetting industry.
                        </p> */}
                        {/* <Link to="#">More</Link> */}
                      </div>
                    ))
                ) : (
                  // <div className="d-flex align-items-center justify-content-center m-auto">
                  //   <h5>No Coupon Found!</h5>
                  // </div>
                  <div className="categroy_slider_inner">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="no_data">
                        <img src={noData} />
                      </div>
                      <button className="main-btn" onClick={() => navigate(-1)}>
                        {t("go_back")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
export default Checkout;

import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useLocation, useParams } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_test_51MibuCAJXk8oRWrAMVcAvfGl8u9sUzNTNKDKlnyWh4P30sxWWmxgF7WOtBPTUh2A21REi5LJvyg69WIg9WPU0pMN00bnhCKSU3"
);

const Stripe = () => {
  const [stripe, setStripe] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const { id } = useParams();
  const { state } = useLocation();

  console.log("cartId", id, state);

  useEffect(() => {
    async function fetchStripe() {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);
    }
    fetchStripe();
  }, []);

  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret:
  //     "{{sk_test_51MibuCAJXk8oRWrA2QcFKypoRZPLhKN1SBeGUdXYWJZ5Yoit7qvvIWCNElqn7gpYKpLoXnQKUnVhSQHw2gecH47600pbORBtfk}}",
  // };

  const successMessage = () => {
    return (
      <div className="success-msg">
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-check2"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
          />
        </svg>
        <div className="title">Payment Successful</div>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="py-5 text-center">
        <h4>Payment Gateway</h4>
      </div>

      <div className="row s-box">
        {paymentCompleted ? (
          successMessage()
        ) : (
          <React.Fragment>
            <div className="col-md-7 order-md-1">
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  amount={2000}
                  setPaymentCompleted={setPaymentCompleted}
                  cartId={id}
                  price={state?.amount}
                  notifyId={state?.notifyId}
                />
              </Elements>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Stripe;

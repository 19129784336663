import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Toast } from "react-bootstrap";

import Locate from "../../../assets/img/locate_ic.png"; //../assets/img/locate_ic.png
import mapStyle from "../../../assets/mapStyle";
import { toast, Toaster } from "react-hot-toast";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import { useLocation } from "react-router-dom";
import Imgcross from "../../../assets/img/cross.png";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";

const EditAddress = ({ data, onOpen, onClose }) => {
  console.log("data", data);
  const [map, setMap] = useState(/**@type google.maps.Map */ (null));
  // const [jobLocationRadio, setJobLocationRadio] = useState("artisansPlace");
  const [userLocationRadio, setUserLocationRadio] = useState(data?.tag);
  const [buildingNum, setBuildingNum] = useState(data?.house_number);
  const [address, setAddress] = useState(data?.address);
  const [myLatLng, setMyLatLng] = useState({ lat: 48.8584, lng: 2.2945 });
  const [coordinates, setCoordinates] = useState({
    lat: parseFloat(data?.latitude),
    lng: parseFloat(data?.longitude),
  });
  const [postalCode, setPostalCode] = useState("");
  const [errmsg1, seterrmsg1] = useState();
  const [errmsg2, seterrmsg2] = useState();

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    console.log("hello", results[0]?.formatted_address);

    const ll = await getLatLng(results[0]);
    const formattedAddress = results[0]?.formatted_address;
    const postalCodeRegex = /(\d{6})[\s-]?(\d{4})?/;

    const match = formattedAddress.match(postalCodeRegex);

    const pinCode = match ? match[0] : "";
    console.log("postalCode", postalCode);
    setPostalCode(pinCode);
    setAddress(value);
    setCoordinates(ll);
  };
  const center = { coordinates };

  const location = useLocation();
  const category_id = location.state;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setMyLatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);

  // const onJobLocationSelect = (e) => {
  //   setJobLocationRadio(e.target.value);
  //   console.log(e.target.value);
  // };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(category_id);
    let path = apiPath.updateAddress;
    var formData = new FormData();
    formData.append("address_id", data?.id);
    formData.append("type", "Own Place");
    formData.append("house_number", buildingNum);
    formData.append("address", address);
    formData.append("latitude", coordinates.lat);
    formData.append("longitude", coordinates.lng);
    formData.append("is_default", "1");
    formData.append("tag", userLocationRadio);
    formData.append("pincode", postalCode);
    let options = {
      method: "POST",
      data: formData,
    };

    if (buildingNum && address !== "") {
      try {
        let res = await Http(path, options);
        console.log(res, "-------------------------");
        if (res.data.success === true) {
          toast.success(res?.data?.msg);
          setTimeout(() => {
            onClose();
          }, 1000);
        } else {
          toast.error(res.data.msg);
        }
      } catch (err) {
        console.log(err.res);
      }
    } else {
      seterrmsg1("building name required");
      seterrmsg2("Street address required");
    }
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    setUserLocationRadio(event.target.value);
  };
  return (
    <div>
      <Toaster position="top-right" />
      <Modal
        show={onOpen}
        onHide={onClose}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalAddress"
        centered
      >
        <Modal.Header>
          <h5 id="exampleModalLabel">Edit Address</h5>
          <button type="button" className="btn-close" onClick={onClose}>
            <img src={Imgcross} onClick={onClose} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="your_location_wrap">
            <h5>Your Location</h5>
            <form>
              <div className="radio_cls">
                <label className="custom_radio">
                  <input
                    type="radio"
                    id="home"
                    name="userLocation"
                    value="home"
                    checked={userLocationRadio === "home"}
                    onChange={handleChange}
                  />
                  Home
                  <span className="checkmark"></span>
                </label>
                <label className="custom_radio">
                  <input
                    type="radio"
                    id="office"
                    name="userLocation"
                    value="office"
                    checked={userLocationRadio === "office"}
                    onChange={handleChange}
                  />
                  Office
                  <span className="checkmark"></span>
                </label>
                <label className="custom_radio">
                  <input
                    type="radio"
                    id="other"
                    name="userLocation"
                    value="other"
                    checked={userLocationRadio === "other"}
                    onChange={handleChange}
                  />
                  Other
                  <span className="checkmark"></span>
                </label>
              </div>
            </form>
          </div>
          <div className="addressDetails">
            <Row>
              <Col md="6">
                <Form.Group className="form-group">
                  <label htmlFor="">Building Name & Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Building number"
                    value={buildingNum}
                    onChange={(e) => setBuildingNum(e.target.value)}
                  />
                  <span className="error_msg">{errmsg1}</span>
                </Form.Group>
              </Col>
              <Col md="6">
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <Form.Group className="form-group">
                        <label htmlFor="">Street Address</label>
                        <input
                          {...getInputProps({
                            placeholder: "Search Places ...",
                            className: "form-control",
                          })}
                        />
                        <span className="error_msg">{errmsg2}</span>
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>
          </div>
          <div className="orSection">
            <span className="orSectionLine">OR</span>
          </div>
          <div className="mapPinLocation">
            <div className="map-title">
              <h5>Pin your Location</h5>
              <h6 onClick={() => map.panTo(myLatLng)}>
                <span className="locate_icon">
                  <img src={Locate} />
                </span>
                <span>Locate yourself</span>
              </h6>
            </div>
            <div className="map_card">
              <GoogleMap
                center={coordinates}
                zoom={20}
                mapContainerClassName="googleMap"
                onLoad={(map) => setMap(map)}
                options={{ styles: mapStyle.light }}
              >
                <Marker position={coordinates} />
              </GoogleMap>
            </div>
          </div>

          <Button className="btn_primary" onClick={onSubmit}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditAddress;

import Http from "../services/Http";
import apiPath from "../constants/apiPath";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import mIcon1 from "../assets/img/a-ic1.png";
import mIcon2 from "../assets/img/a-ic2.png";
import mIcon3 from "../assets/img/a-ic3.png";
import mIcon4 from "../assets/img/a-ic4.png";
import mIcon5 from "../assets/img/a-ic5.png";
import mIcon6 from "../assets/img/loc_icon.png";
import mIcon7 from "../assets/img/my_wallet_icon.png";
import mIcon8 from "../assets/img/coupon_icon.png";
import mIcon9 from "../assets/img/change_pass_icon.png";
import mIcon10 from "../assets/img/dash_icon.png";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const UserSidebar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [ip, setIP] = useState("");
  // useEffect(() => {
  //   getIpv4();
  // }, []);

  const getIpv4 = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      console.log(data.ip);
      setIP(data.ip);
    } catch (err) {
      console.log(err);
    }
  };

  const { pathname } = location;
  const guest = async () => {
    let path = apiPath.guest_user;
    let formData = new FormData();
    formData.append("ip", "0.0.0.1"); //ip.toString()
    formData.append("user_type", "2");
    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        const { token } = res.data.result;
        localStorage.setItem("guestToken", token);
        localStorage.setItem("userToken", "");
        localStorage.setItem("isLoggedIn", 0);
        console.log(res.data.msg);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure want to logout ?")) {
      const userToken = localStorage.getItem("userToken");
      console.clear();
      console.log("token", userToken);
      const path = apiPath.logot;
      const options = {
        method: "POST",
        headers: { Authorization: `Bearer ${userToken}` },
      };
      try {
        let res = await Http(path, options);
        if (res.data.success === true) {
          guest();
          getIpv4();
          localStorage.setItem("userToken", "");
          localStorage.removeItem("userToken");
          localStorage.setItem("userData", "");
          localStorage.removeItem("userData");
          localStorage.setItem("isLoggedIn", 0);
          toast.success("Logged Out!");
          navigate("/");
        } else {
          console.log("__Somthing went wrong!__", res.data.success);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="m_sidebar">
        <ul>
          <li>
            <NavLink to="/dashboard" activeclassname="active">
              <span className="m-icon">
                <img src={mIcon10} alt="" />
              </span>
              <span>{t("dashboard")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/my-account" activeclassname="active">
              <span className="m-icon">
                <img src={mIcon1} alt="" />
              </span>
              <span>{t("my_account")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/notification" activeclassname="active">
              <span className="m-icon">
                <img src={mIcon2} alt="" />
              </span>
              <span>{t("prof_notification")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/my-orders"
              isActive={() => {
                const activeUrls = ["/my-orders", "/order-detail/:id"];
                return activeUrls.includes(pathname);
              }}
              activeclassname="active"
            >
              <span className="m-icon">
                <img src={mIcon3} alt="" />
              </span>
              <span>{t("my_orders")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/favorites" activeclassname="active">
              <span className="m-icon">
                <img src={mIcon4} alt="" />
              </span>
              <span>{t("prof_favorites")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/manage-address" activeclassname="active">
              <span className="m-icon">
                <img src={mIcon6} alt="" />
              </span>
              <span>{t("manage_address")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/wallet" activeclassname="active">
              <span className="m-icon">
                <img src={mIcon7} alt="" />
              </span>
              <span>{t("wallet")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/my-coupons" activeclassname="active">
              <span className="m-icon">
                <img src={mIcon8} alt="" />
              </span>
              <span>{t("my_coupon")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/manage-password" activeclassname="active">
              <span className="m-icon">
                <img src={mIcon9} alt="" />
              </span>
              <span>{t("manage_pwd")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="#"
              onClick={(e) => {
                handleLogout(e);
              }}
            >
              <span className="m-icon">
                <img src={mIcon5} alt="" />
              </span>
              <span>{t("logout")}</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserSidebar;

import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { LoadScript } from "@react-google-maps/api";
import { GoogleMap, Marker } from "react-google-maps";
import mapStyle from "../assets/mapStyle";
import Http from "../services/Http";
import apiPath from "../constants/apiPath";
import { Link, useLocation } from "react-router-dom";
import TracArtisanGmap from "./Component/TracArtisanGmap";
import { useTranslation } from "react-i18next";
import reloadImg from "../../src/assets/img/reload-map.png";

const TrackArtisan = () => {
  const { t, i18n } = useTranslation();
  const [map, setMap] = useState(/**@type google.maps.Map */ (null));
  const [latLng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [libraries, setLibraries] = useState(["places"]);
  const [userLatLng, setUserLatLng] = useState({ lat: 0, lng: 0 });
  const [artisanLatLng, setArtisnLatLng] = useState({ lat: 0, lng: 0 });

  const { state } = useLocation();

  useEffect(() => {
    // handleLoad();
    setTimeout(() => {
      getArtisanCords();
    }, 3000);
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLng({
          lat: position.coords.latitude.toString(),
          lng: position.coords.longitude.toString(),
        });
      });
    }
  }, []);

  const handleLoad = () => {
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    const pointA = new window.google.maps.LatLng(
      userLatLng.lat,
      userLatLng.lng
    );
    const pointB = new window.google.maps.LatLng(
      artisanLatLng.lat ? artisanLatLng.lat : "28.8504593",
      artisanLatLng.lng ? artisanLatLng.lng : "76.76277019999999"
    );
    const pointC = new window.google.maps.LatLng(latLng.lat, latLng.lng);
    const mapOptions = {
      center: pointA,
      zoom: 10,
      onload: handleLoad,
    };
    const newMap = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );

    directionsRenderer.setMap(newMap);

    const request = {
      origin: pointA,
      destination: pointC,
      waypoints: [
        {
          location: pointB,
          stopover: true,
        },
      ],
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        directionsRenderer.setDirections(result);
      } else {
        console.log("Error calculating directions:", status);
      }
    });

    setMap(newMap);
  };

  const getArtisanCords = async () => {
    let path = apiPath.trackOrder;
    let data = { orderNo: `${state}` };
    let options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        const { orderData } = res?.data?.result;
        let { userData, artisanData } = orderData;
        console.log("data", userData);

        setUserLatLng({
          lat: orderData?.latitude,
          lng: orderData?.longitude,
        });

        setArtisnLatLng({
          ...artisanLatLng,
          lat: artisanData?.latitude,
          lng: artisanData?.longitude,
        });

        console.log(res?.data?.msg);
      } else {
        console.log(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const latLngArr = [
    //{ lat: latLng.lat, lng: latLng.lng },
    {
      lat: userLatLng.lat,
      lng: userLatLng.lng,
    },
    { lat: artisanLatLng.lat, lng: artisanLatLng.lng },
  ];

  const routeLatLng = latLngArr?.map((item) => {
    const latLng = [];
    latLng?.push(item?.lat);
    latLng?.push(item?.lng);

    return latLng;
  });

  return (
    <>
      <section className="artisans-sec">
        <div className="container">
          <div className="artisans-box">
            <h4>{t("TrackArtisan")}</h4>
            <p>
              <img
                height={20}
                onClick={() => getArtisanCords()}
                src={reloadImg}
              />
            </p>
            <div className="addressDetails">
              <Row>
                <Col md="12">
                  <div className="manage_address_inner">
                    <div className="manage_address_listing">
                      <div className="time_map">
                        <div className="map_card ">
                          {/* <GMap latLng={routeLatLng} /> */}
                          <TracArtisanGmap latLng={routeLatLng} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <Col md="12">
                    <Link
                      to={"/order-detail/" + state}
                      className="btn btn_primary"
                    >
                      {t("ViewOrder")}
                    </Link>
                  </Col>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TrackArtisan;

// {
//   lat:
//     latLng?.length > 0
//       ? parseFloat(latLng[0][0])
//       : 0,
//   lng:
//     latLng?.length > 0
//       ? parseFloat(latLng[0][1])
//       : 0,
// }

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Nav,
  Row,
  TabContainer,
  TabContent,
  TabPane,
} from "react-bootstrap";
import PriceRange from "../assets/img/price-range-img.png";
import Star from "../assets/img/star_icon.png";
import StarFill from "../assets/img/star_fill.png";
import ArtisanImg from "../assets/img/artisan1.png";
import serviceImg from "../assets/img/artisan1.png";
import Close from "../assets/img/cross.png";
import { Link, useNavigate } from "react-router-dom";
import UserSidebar from "./UserSidebar";
import { toast, Toaster } from "react-hot-toast";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import noData from "../assets/img/no-data.png";
import Http from "../services/Http";
import { useTranslation } from "react-i18next";

const Favorite = () => {
  const { t, i18n } = useTranslation();
  let lang = localStorage.getItem("lang");
  const [favService, setFavService] = useState(true);
  const [favArtisan, setFavArtisan] = useState(false);
  const [favType, setFavType] = useState("Service");
  const [favList, getFavList] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    getList();
  }, [favService, favArtisan]);
  const getList = async () => {
    const path = apiPath.getFavList;
    const data = { fev_type: favType };
    const options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        getFavList(res?.data?.result);
        console.log("list", res?.data?.result);
        // if (favType === "Service") {
        //   toast.success("Favorite Service List");
        // } else {
        //   toast.success("Favorite Artisan List");
        // }
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFavService = () => {
    console.log(favService, favArtisan);
    setFavType("Service");
    setFavService(true);
    setFavArtisan(!favArtisan);
  };

  const handleFavArtisan = () => {
    console.log(favService, favArtisan);
    setFavType("Artisan");
    setFavService(!favService);
    setFavArtisan(true);
  };

  const removeFavorite = async (id) => {
    const path = apiPath.markFavorite;
    const data = { fev_type: favType, fev_id: `${id}`, fev_val: "0" };
    const options = { method: "POST", data };

    try {
      let res = await Http(path, options);
      const { result, msg } = res?.data;
      if (res?.data?.success === true) {
        getList();
        toast.success(msg);
      } else {
        toast.error(msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("favList", favList);

  return (
    <>
      <Toaster position="top-right" />
      <section className="order-main">
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <UserSidebar />
            </div>
            <div className="account-sidebar-right">
              <div className="m_sidebar_bg h-100">
                <TabContainer defaultActiveKey="favoriteservice" className="h-100">
                  <Nav variant="pills" className="white_bg">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="favoriteservice"
                        onClick={handleFavService}
                      >
                        {t("fav_service")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="favoriteartisan"
                        onClick={handleFavArtisan}
                      >
                        {t("fav_artisan")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <TabContent className="h-100">
                    <TabPane className="h-100" eventKey="favoriteservice">
                      <div className="favorite-serv h-100">
                        <Row className="h-100">
                          {favList?.length === 0 ? (
                            <div className="categroy_slider_inner h-100 d-flex align-items-center justify-content-between">
                              <div className="no_data text-center">
                                <img src={noData} />
                              </div>
                            </div>
                          ) : (
                            favList?.map((item, idx) => (
                              <Col sm="6" md="6" lg="3" key={idx}>
                                <div className="categroy_slider_inner">
                                  <div className="categroy_slider_img cursor-pointer">
                                    {item.img === null ||
                                    item.img === "udefined" ||
                                    item.img === " " ? (
                                      <img
                                        onClick={() =>
                                          navigate(
                                            `/service-detail/${item?.serviceData?.id}`
                                          )
                                        }
                                        src={serviceImg}
                                        alt="serviceImg"
                                      />
                                    ) : (
                                      <img
                                        onClick={() =>
                                          navigate(
                                            `/service-detail/${item?.serviceData?.id}`
                                          )
                                        }
                                        src={
                                          IMAGE_PATH + item?.serviceData?.image
                                        }
                                        alt=""
                                      />
                                    )}

                                    <span className="cross_img">
                                      <Link to="#">
                                        <img
                                          onClick={() =>
                                            removeFavorite(item.fev_id)
                                          }
                                          src={Close}
                                          alt
                                        />
                                      </Link>
                                    </span>
                                  </div>
                                  <div className="categroy_prd_cont">
                                    <h4>
                                      {lang == "en"
                                        ? item.serviceData?.name
                                        : item.serviceData?.name_fr}
                                    </h4>
                                    <p>{t("starting_from")}</p>
                                    <h5>€ {item.serviceData?.min_price}</h5>
                                  </div>
                                </div>
                              </Col>
                            ))
                          )}
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane className="h-100" eventKey="favoriteartisan">
                      <div className="artisan_list_wrap">
                        <Row>
                          {favList?.length === 0 ? (
                            <div className="categroy_slider_inner h-100 d-flex align-items-center justify-content-between">
                              <div className="no_data text-center">
                                <img src={noData} />
                              </div>
                            </div>
                          ) : (
                            favList?.map((item, idx) => (
                              <Col md="12" lg="6" key={idx}>
                                <div className="artisan_bg">
                                  <div className="artisan_left_img cursor-pointer">
                                    <div className="artisan_img">
                                      {item.img === null ||
                                      item.img === "udefined" ||
                                      item.img === " " ? (
                                        <img
                                          src={ArtisanImg}
                                          onClick={() =>
                                            navigate(
                                              `/artisan-detail/${item?.artisanData?.id}`
                                            )
                                          }
                                          alt="artisan Image"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            IMAGE_PATH +
                                            item?.artisanData?.image
                                          }
                                          onClick={() =>
                                            navigate(
                                              `/order-by-artisan/${item?.artisanData?.id}`
                                            )
                                          }
                                          alt="Artisan Image"
                                        />
                                      )}
                                    </div>
                                    <span className="cross_img">
                                      <Link to="#">
                                        <img
                                          onClick={() =>
                                            removeFavorite(item.fev_id)
                                          }
                                          src={Close}
                                          alt
                                        />
                                      </Link>
                                    </span>
                                  </div>
                                  <div className="artisan_right_cont">
                                    <div className="tag">
                                      <span>Most Active</span>
                                    </div>
                                    <div className="artisan_title">
                                      <h5>
                                        {item.artisanData?.name},{" "}
                                        <span>
                                          {item.artisanData?.artisan_level}
                                        </span>
                                      </h5>
                                    </div>
                                    <div className="jobs_review">
                                      <div className="job_cont">
                                        <span>
                                          #
                                          {item.artisanData?.job_done === 0
                                            ? "No"
                                            : item.artisanData?.job_done}{" "}
                                          {t("Jobs")}
                                        </span>
                                      </div>
                                      <div className="review_cont">
                                        <span className="review-ic">
                                          <img src={Star} alt="" />
                                        </span>
                                        <span>{item?.artisanData?.rating}</span>
                                      </div>
                                    </div>
                                    <div className="artisan_price_wrap">
                                      <div className="artisan_view_btn">
                                        {/* <Button className="btn_primary" >
                                            View Detail
                                          </Button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            ))
                          )}
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </TabContainer>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Favorite;

import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "../assets/css/style.css";
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import { Link, useParams } from "react-router-dom";

import serv6 from "../assets/img/serv6.png";
import "../assets/css/responsive.css";
import "../assets/css/flipdown.css";
import "react-loading-skeleton/dist/skeleton.css";
import logo from "../assets/img/logo.png";
import langImg from "../assets/img/langImg.png";
import engImg from "../assets/img/english-language-flag.jpg";
import frImg from "../assets/img/france-language-flag.jpg";
import { useLocation } from "react-router-dom";
import { ModalContext } from "../view/Component/home/Navbar";
import { Container, Form, Nav, Navbar, Offcanvas } from "react-bootstrap";
import UserInfo from "../view/Component/ModalComponent";
import Login from "../view/Component/modalComponents/Login";
import Signup from "../view/Component/modalComponents/Signup";
import ForgetPassword from "../view/Component/modalComponents/ForgetPassword";
import OtpVerification from "../view/Component/modalComponents/OtpVerification";
import PasswordSet from "../view/Component/modalComponents/PasswordSet";
import Profile from "../view/Component/modalComponents/Profile";
import { useTranslation } from "react-i18next";

const HomeHeader = (props) => {
  const { t, i18n } = useTranslation();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [type, setType] = useState("register");
  const [search, setSearch] = useState();

  const [showOtp, setShowOtp] = useState(false);
  const [getSignup, setSignup] = useState({
    country_code: "",
    mobile: "",
  });

  const [getOtp, setGetOtp] = useState({
    otp: "",
    hasErrored: false,
  });

  const closeAll = () => {
    setShowLogin(false);
    setShowSignup(false);
    setShowForgetPassword(false);
    setShowPassword(false);
    setShowProfile(false);
  };
  const onClose = () => {
    setShowLogin(false);
  };

  const hideLogin = () => {
    setShowLogin(false);
  };

  const hideSignup = () => {
    setShowSignup(false);
  };

  const hideOTP = () => {
    setShowOtp(false);
  };

  const location = useLocation();
  const path = location.pathname;
  const params = useParams();
  const pathId = params.id;
  const [token, setToken] = useState();
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    setToken(userToken);
  }, [token]);

  const pathNames = ["/category/soft", "/category/heavy", "/book-offline"];

  console.log("isPath", pathNames.includes(path));

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    window.location.reload()
  };

  return (
    <ModalContext.Provider
      value={{
        getSignup,
        setSignup,
        setGetOtp,
        setType,
        getOtp,
      }}
    >
      <div>
        <header className="header_sec">
          <Navbar
            key="md"
            expand="md"
            className="navbar navbar-expand-lg navbar-light"
          >
            <Container>
              <div className="logo_wrap">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-md`}
                aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                placement="start"
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body className="align-items-center">
                  <Nav className="flex-grow-1">
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link to="/" className="nav-link">
                          {t("nav_home")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/about" className="nav-link">
                          {t("nav_about")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/all-artisans"
                          className="nav-link"
                          // onClick={onShowArtisansClick}
                        >
                          {t("nav_artisans")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/book-offline" className="nav-link">
                          {t("nav_offlineBooking")}
                        </Link>
                      </li>
                    </ul>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>

              <div className="d-flex align-content-center gap-3">
                <Dropdown className="dropdwon_inner">
                  <Dropdown.Toggle
                    variant=""
                    className="w-auto h-auto rounded-0 bg-transparent border-0 shadow-none"
                    id="dropdown-basic"
                  >
                    <img src={langImg} alt="Language Icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="user-profile-link"
                      onClick={() => {
                        localStorage.setItem("lang", "en");
                        handleLanguageChange("en");
                      }}
                    >
                      {t("English")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="user-profile-link"
                      onClick={() => {
                        handleLanguageChange("fr");
                        localStorage.setItem("lang", "fr");
                      }}
                    >
                      {t("French")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <UserInfo setShowLogin={setShowLogin} />
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
              </div>
            </Container>
          </Navbar>
        </header>

        <section className="banner-sec">
          <div className="banner-cont">
            {path !== "/" && (
              <div className="container">
                <hr className="hr-header" />
                <div className="d-flex justify-content-between align-items-center">
                  <span className="page-name">
                    {path === "/artisans"
                      ? `${t("search_artisans")}`
                      : path === "/artisan-detail"
                      ? `${t("artisan_detail")}`
                      : path === "/thank-you"
                      ? `${t("thank_you")}`
                      : path === "/my-orders"
                      ? `${t("my_orders")}`
                      : path === "/favorites"
                      ? `${t("favorites")}`
                      : path === "/my-account"
                      ? `${t("my_account")}`
                      : path === "/notification"
                      ? `${t("Notification")}`
                      : path === "/dashboard"
                      ? `${t("dashboard")}`
                      : path === "/manage-address"
                      ? `${t("manage_address")}`
                      : path === "/wallet"
                      ? `${t("wallet")}`
                      : path === "/my-coupons"
                      ? `${t("my_coupon")}`
                      : path === "/about"
                      ? `${t("About")}`
                      : path === "/terms-and-conditions"
                      ? `${t("Terms And Conditions")}`
                      : path === "/privacy"
                      ? `${t("Privacy Policy")}`
                      : path === "/manage-password"
                      ? `${t("manage_pwd")}`
                      : path === "/heavy-order-checkout"
                      ? `${t("checkout")}`
                      : path === "/checkout-by-artisan"
                      ? `${t("checkout")}`
                      : path === "/checkout-offline"
                      ? `${t("checkout")}`
                      : path === "/checkout"
                      ? `${t("checkout")}`
                      : path === "/select-time-offline"
                      ? `${t("Search Artisan")}`
                      : path === "/select-time-by-artisan"
                      ? `${t("Search Artisan")}`
                      : path === "/select-time"
                      ? `${t("Search Artisan")}`
                      : path === "/heavy-service"
                      ? `${t("Heavy Service")}`
                      : path === "/browse-offline-artisans"
                      ? `${t("All Artisans")}`
                      : path === ("/browse-artisans" || "/all-artisans")
                      ? `${t("All Artisans")}`
                      : path === `/offline-service-detail/${pathId}`
                      ? `${t("Service Detail")}`
                      : path === `/order-detail/${pathId}`
                      ? `${t("My Order")}`
                      : path === `/service-detail/${pathId}`
                      ? `${t("Service Detail")}`
                      : path === `/artisan-detail/${pathId}`
                      ? `${t("Artisan Detail")}`
                      : path === `/payment-gateway/${pathId}`
                      ? `${t("Payment")}`
                      : path === `/heavy-service-artisans`
                      ? `${t("Heavy Service Artisans")}`
                      : path === `/soft-service-list/${pathId}`
                      ? `${t("Categories")}`
                      : path === `/all-artisans`
                      ? `${t("All Artisan")}`
                      : path === `/contact-us`
                      ? `${t("contact_us")}`
                      : path === `/book-offline`
                      ? `${t("Offline Booking")}`
                      : path === `/heavy-service-time/:id?`
                      ? `${t("Heavy Service Time")}`
                      : `${t("categories")}`}
                  </span>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      {showLogin === true && (
        <Login
          showLogin={showLogin}
          onClose={onClose}
          showSignup={() => {
            setShowLogin(false);
            setShowSignup(true);
          }}
          showForget={() => {
            setType("forgot");
            setShowLogin(false);
            setShowForgetPassword(true);
          }}
          hide={hideLogin}
        />
      )}

      {showSignup && (
        <Signup
          showLogin={() => {
            hideSignup();
            setShowLogin(true);
          }}
          show={showSignup}
          hide={hideSignup}
          data={showSignup}
          onClose={onClose}
          showOTP={() => {
            setShowOtp(true);
          }}
        />
      )}

      {showForgetPassword && (
        <ForgetPassword
          show={showForgetPassword}
          hide={() => {
            setShowForgetPassword(false);
          }}
          showLogin={() => {
            setShowForgetPassword(false);
            setShowLogin(true);
          }}
          showOTP={() => {
            setShowForgetPassword(false);
            setShowOtp(true);
          }}
          data={showForgetPassword}
          type={type}
          onClose={onClose}
        />
      )}

      {showOtp ? (
        <OtpVerification
          show={showOtp}
          hide={hideOTP}
          data={showOtp}
          userData={getSignup}
          type={type}
          onClose={onClose}
          showPassword={() => {
            hideOTP();
            setShowPassword(true);
          }}
        />
      ) : (
        ""
      )}

      {showPassword && (
        <PasswordSet
          show={showPassword}
          hide={() => setShowPassword(false)}
          mobile={getSignup.mobile}
          cc={"+" + getSignup.country_code}
          otp={getOtp.otp}
          type={type === "forgot" ? "forgot" : "register"}
          onClose={onClose}
          showOtp={() => {
            setShowOtp(true);
          }}
          showPassword={() => {
            setShowPassword(true);
          }}
          showLogin={() => {
            setShowPassword(false);
            setShowLogin(true);
          }}
          showProfile={() => setShowProfile(true)}
          closePassword={() => {
            setShowPassword(false);
          }}
        />
      )}

      {showProfile ? (
        <Profile
          pass={"password"}
          onClose={() => {
            onClose();
            closeAll();
          }}
        />
      ) : (
        ""
      )}
    </ModalContext.Provider>
  );
};

export default HomeHeader;

import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ModalDialog from "react-bootstrap/esm/ModalDialog";
import Modal from "react-bootstrap/Modal";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Login from "./modalComponents/Login";
import OtpVerification from "./modalComponents/OtpVerification";
import Dropdown from "react-bootstrap/Dropdown";
import avatar from "../../assets/img/avatar.png";
import Signup from "./modalComponents/Signup";
import * as jwt_decode from "jwt-decode";
import { internalIpV4 } from "internal-ip";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import Http from "../../services/Http";
import { Toaster, toast } from "react-hot-toast";
import ProfileImg from "../../assets/img/profile-logo.png";
import LoginIcon from "../../assets/img/loginIcon.png";
import vendorLoginIcon from "../../assets/img/vendorLoginIcon.png";
import { useTranslation } from "react-i18next";

function ModalComponent({ setShowLogin }) {
  const { t, i18n } = useTranslation();
  let lang = localStorage.getItem("lang");
  const [loggedIn, setLoggedIn] = useState();
  const [image, setImage] = useState();
  const [name, setName] = useState();
  const [ip, setIP] = useState("");
  const navigate = useNavigate();

  const getIpv4 = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      console.log(data.ip);
      setIP(data.ip);
    } catch (err) {
      console.log(err);
    }
  };

  const test = localStorage.getItem("isLoggedIn");
  useEffect(() => {
    setLoggedIn(test);
  }, [test]);

  // useEffect(() => {
  //   getIpv4();
  // }, []);

  const handleShow = () => {
    setShowLogin(true);
  };

  const getUser = async () => {
    let path = apiPath.get_user_profile;
    let options = { method: "POST" };
    try {
      let res = await Http(path, options);
      const { image, name } = res?.data?.result;
      setImage(image);
      setName(name);
    } catch (err) {
      toast.error(err.msg);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const guest = async () => {
    // await getIpv4();
    console.log(await internalIpV4());
    let path = apiPath.guest_user;

    // let formData = new FormData();
    let data = {
      ip: "0.0.0.1", //ip,
      user_type: "2",
    };
    // formData.append("ip", ip.toString());
    // formData.append("user_type", "2");
    let options = {
      method: "POST",
      data: data,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        // console.log(res.data.result);
        const token = res.data.result.token;
        localStorage.setItem("guestToken", token);
        localStorage.setItem("userToken", "");
        localStorage.removeItem("userToken");
        localStorage.setItem("isLoggedIn", 0);
        setLoggedIn(0);
        console.log(res.data.msg);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const handleLogout = async () => {
    const userToken = localStorage.getItem("userToken");
    console.clear();
    console.log("token", userToken);
    const path = apiPath.logot;
    const options = {
      method: "POST",
      headers: { Authorization: `Bearer ${userToken}` },
    };
    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        toast.success("Logged Out!");
        localStorage.removeItem("userData");
        localStorage.removeItem("userToken");
        getIpv4();
        guest();

        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        console.log("__Somthing went wrong!__", res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("Shree Ram", loggedIn);

  return (
    <div className="d-flex align-items-center gap-2">
      <Toaster position="top-right" />
      {loggedIn == 1 ? (
        <>
          <Dropdown className="dropdwon_inner">
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="border-0 shadow-none">
              {image === "undefined" ||
                image === "null" ||
                image === null ||
                image === "" ? (
                <img
                  src={ProfileImg}
                  className="profileImg"
                  alt="undefined11"
                />
              ) : (
                <img
                  src={IMAGE_PATH + image}
                  className="profileImg"
                  alt="undefined--111"
                />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <NavLink
                  id="RouterNavLink"
                  to="/my-account"
                  className="user-profile-link"
                >
                  {t("my_account")}
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item>
                <NavLink
                  id="RouterNavLink"
                  to="/notification"
                  className="user-profile-link"
                >
                  {t("prof_notification")}
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item>
                <NavLink
                  id="RouterNavLink"
                  to="/my-orders"
                  className="user-profile-link"
                >
                  {t("prof_myOrders")}
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item>
                <NavLink
                  id="RouterNavLink"
                  to="/favorites"
                  className="user-profile-link"
                >
                  {t("prof_favorites")}
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item onClick={handleLogout}>
                {t("prof_logout")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <>
          <button className="main-btn mobileBtn" onClick={handleShow}>
            <span className="d-none d-sm-block">Login/Signup</span>
            <img className="d-sm-none" src={LoginIcon} alt="" />
          </button>
          <Link
            className="main-btn mobileBtn"
            to="https://admin.toctocme.com"
            target="_blank"
          >
            <span className="d-none d-sm-block">Vendor Login</span>
            <img className="d-sm-none" src={vendorLoginIcon} alt="" />
          </Link>
        </>
      )}

      {/* <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        className="modal-container"
      > */}
      {/* {change === "signup" && (
          <Signup change={change} setChange={setChange} />
        )} */}
      {/* </Modal> */}
    </div>
  );
}

export default ModalComponent;

import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Star from "../../../assets/img/star_icon.png";
import ArtisanImg from "../../../assets/img/artisan1.png";
import Like from "../../../assets/img/like.png";
import mapStyle from "../../../assets/mapStyle";
import apiPath, { IMAGE_PATH } from "../../../constants/apiPath";
import Http from "../../../services/Http";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import MultiRangeSlider from "../../Component/MultiRangeSlider";
import GMap from "../../Component/GMap";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const timeSlots = [
  { time: "06:00-07:00", duration: "06:00 To 07:00" },
  { time: "07:00-08:00", duration: "07:00 To 08:00" },
  { time: "08:00-09:00", duration: "08:00 To 09:00" },
  { time: "09:00-10:00", duration: "09:00 To 10:00" },
  { time: "10:00-11:00", duration: "10:00 To 11:00" },
  { time: "11:00-12:00", duration: "11:00 To 12:00" },
  { time: "12:00-13:00", duration: "12:00 To 13:00" },
  { time: "13:00-14:00", duration: "13:00 To 14:00" },
  { time: "14:00-15:00", duration: "14:00 To 15:00" },
  { time: "15:00-16:00", duration: "15:00 To 16:00" },
  { time: "16:00-17:00", duration: "16:00 To 17:00" },
  { time: "17:00-18:00", duration: "17:00 To 18:00" },
  { time: "18:00-19:00", duration: "18:00 To 19:00" },
  { time: "19:00-20:00", duration: "19:00 To 20:00" },
];

const SelectTime = () => {
  const { t, i18n } = useTranslation();
  const [schedule, setSchedule] = useState("Later");
  const [selectTime, setSelectTime] = useState();
  const [artisanList, setArtisanList] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showartisanList, setShowArtisanList] = useState();
  const [timeSlotError, setTimeSlotError] = useState();
  const [minRate, setMinRate] = useState();
  const [maxRate, setMaxRate] = useState();
  const navigate = useNavigate();
  const [timeSlot, setTimeSlot] = useState([]);

  const { state } = useLocation();
  const {
    email,
    mobile,
    addressId,
    serviceData,
    artisanId,
    productData,
    cartData,
    serviceType,
    selectedServices,
    isEmail,
    isMobile,
  } = state;

  const service_id = selectedServices[0]?.id ? selectedServices[0]?.id : "";

  useEffect(() => {
    getTimeSlots(date);
  }, [date]);

  const getTimeSlots = async (date) => {
    let path = apiPath.timeSlots;
    var formData = new FormData();
    formData.append("service_date", moment(date).format("YYYY-MM-DD"));

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);

      if (res.data.success === true) {
        const time = convertArray(res.data.result);
        setTimeSlot(time);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const handleSubmit = async () => {
    let path = apiPath.setPreview;
    const data = {
      service_type: "Right Now",
      order_type: "Soft",
      address_id: addressId ? addressId : "",
      mobile_no: mobile ? mobile : "",
      email_val: email ? email : "",
      is_email: isEmail ? isEmail : "0",
      is_mobile: isMobile ? isMobile : "0",
      artisan_id: artisanId ? artisanId : "",
      cartData: cartData ? cartData : "",
      productData: productData ? productData : "",
    };
    let options = {
      method: "POST",
      data,
    };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        data.selectedServices = selectedServices;
        navigate("/checkout-by-artisan", { state: data });
        console.log(res?.data?.msg);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }

    // navigate("/checkout-by-artisan", {
    //   state: {
    //     scheduling: "Right Now",
    //     order_type: "Soft",
    //     address_id: addressId ? addressId : "",
    //     mobile_no: mobile ? mobile : "",
    //     email_val: email ? email : "",
    //     is_email: isEmail ? isEmail : "0",
    //     is_mobile: isMobile ? isMobile : "0",
    //     artisan_id: artisanId ? artisanId : "",
    //     cartData: cartData ? cartData : "",
    //     productData: productData ? productData : "",
    //   },
    // });
  };

  console.log("first", schedule);

  const onRightnowClick = async () => {
    let path = apiPath.categoryBasedArtisanList;
    let data = {
      category_id: serviceData ? serviceData : "",
      service_id: `${service_id}` ? `${service_id}` : "",
    };
    let options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        console.log(res.data.msg);
        console.log(res.data.result);
        setArtisanList(true);

        if (!res?.data?.result) setShowArtisanList([]);
        setShowArtisanList(res?.data?.result);
        // setCenter({
        //   lat: res.data.result.artisanList.docs.latitude,
        //   lng: res.data.result.artisanList.docs.longitude,
        // });
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };
  const onScheduling = (e) => {
    const { value } = e.target;
    setSchedule(value);
    setArtisanList(false);
    console.log(value);
  };

  const onTimeSelect = (e) => {
    const { value } = e.target;
    setSelectTime(value);
    console.log(value);
  };

  const onLaterService = async () => {
    let path = apiPath.setPreview;
    let servicedata = {
      service_type: "Later",
      order_type: "Soft",
      time_slot: selectTime,
      service_date: moment(date).format("yyyy-MM-DD"),
      address_id: `${addressId}` ? `${addressId}` : "",
      mobile_no: mobile ? mobile : "",
      email_val: email ? email : "",
      is_email: isEmail ? isEmail : "0",
      is_mobile: isMobile ? isMobile : "0",
      artisan_id: artisanId ? artisanId : "",
      cartData: cartData ? cartData : "",
      productData: productData ? productData : "",
    };

    let options = {
      method: "POST",
      data: servicedata,
    };

    console.log("timeSlot--", selectTime?.length > 0);
    if (selectTime?.length > 0) {
      setTimeSlotError(false);
      try {
        let res = await Http(path, options);
        console.log(res, "-------------------------");
        if (res.data.success === true) {
          console.log("api hit successfully", res.data.msg);
          console.log(res.data.msg);
          setArtisanList(true);
          toast.success(res?.data?.msg);
          servicedata.selectedServices = selectedServices;
          localStorage.setItem("cart_item", JSON.stringify(servicedata));
          navigate("/checkout-by-artisan", { state: servicedata });
          // setShowArtisanList(res.data.result.artisanList.docs);
        } else {
          toast.error(res?.data?.msg);
          console.log(res.data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setTimeSlotError(true);
    }
  };

  const artisanLat = showartisanList?.map((item) => {
    const latLng = [];
    latLng?.push(
      item?.latitude === null ? "26.85635186559557" : item?.latitude
    );
    latLng?.push(
      item?.longitude === null ? "75.76756965368986" : item?.longitude
    );
    latLng?.push(item?.name);
    latLng?.push(item?.residence_address);
    latLng?.push(item?.artisan_level);
    return latLng;
  });

  console.log("lat", artisanLat);
  // console.log("lng", artisanLng);

  return (
    <section className="select-time-sec">
      <Toaster position="top-right" />
      <div className="container">
        <div className="selecttime_main">
          <div className="time_map_left">
            <h4>{t("when_do_you_want_service")}?</h4>

            <div className="artisans_radioBtn">
              <form>
                <div className="radio_cls">
                  <label className="custom_radio">
                    {" "}
                    <input
                      type="radio"
                      id="radio"
                      value="Right Now"
                      onClick={onRightnowClick}
                      checked={schedule === "Right Now"}
                      onChange={onScheduling}
                    />
                    Right Now
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom_radio">
                    <input
                      type="radio"
                      id="radio"
                      value="Later"
                      checked={schedule === "Later"}
                      onChange={onScheduling}
                    />
                    Later
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
            </div>
            {schedule === "Right Now" && (
              <>
                <div className="time_map">
                  <div className="map_card">
                    {showartisanList?.length > 0 ? (
                      <GMap latLng={artisanLat} />
                    ) : (
                      <>
                        <h3>Uh oh..</h3>
                        <h4>No artisan is available currently!</h4>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {!artisanList && (
              <div className="time-select-slot">
                <Row>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <label htmlFor="">{t("specific_date")}</label>
                      <DatePicker
                        showIcon
                        className="form-control"
                        showYearDropdown
                        selected={date}
                        onChange={(date) => setDate(date)}
                        minDate={moment().toDate()}
                        dateFormat="dd-MM-yyyy"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <div className="price-range-sec">
                        <h5>{t("price")}</h5>
                        <div className="range_slider">
                          <MultiRangeSlider
                            min={0}
                            max={1000}
                            onChange={({ min, max }) => {
                              console.log(`min = ${min}, max = ${max}`);
                              setMinRate(min);
                              setMaxRate(max);
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <h5>{t("time_slot")}</h5>
                    <div className="time-slot">
                      {timeSlot.map((item, idx) => (
                        <label className="time-label" key={idx}>
                          <input
                            type="radio"
                            id="radio"
                            value={item.time}
                            checked={selectTime === item.time}
                            onChange={onTimeSelect}
                          />
                          <span className="checkmark">{item.duration}</span>
                        </label>
                      ))}
                      {timeSlotError && (
                        <span className="error_msg">
                          {"Pleas select a time slot!"}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          {!showartisanList && (
            <div className="time_list_wrap">
              <div className="time_bg_wrap">
                <div className="login-btn">
                  <Button className="btn_primary" onClick={onLaterService}>
                    {t("checkout")}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {showartisanList?.length > 0 && (
            <div className="time_list_wrap">
              <div className="time_bg_wrap">
                <div className="login-btn">
                  <Button className="btn_primary" onClick={handleSubmit}>
                    {t("checkout")}
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

function convertArray(givenArray) {
  const newArray = givenArray.map((item) => {
    const time = `${item.from}-${item.to}`;
    const duration = `${item.from} To ${item.to}`;
    return { time, duration };
  });

  return newArray;
}

export default SelectTime;

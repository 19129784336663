import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ModalDialog from "react-bootstrap/esm/ModalDialog";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import Login from "../modalComponents/Login";
import OtpVerification from "../modalComponents/OtpVerification";
import Dropdown from "react-bootstrap/Dropdown";
import avatar from "../../../assets/img/avatar.png";
import Signup from "../modalComponents/Signup";
import * as jwt_decode from "jwt-decode";
import { internalIpV4 } from "internal-ip";
import apiPath, { IMAGE_PATH } from "../../../constants/apiPath";
import Http from "../../../services/Http";
import { toast } from "react-hot-toast";
import ProfileImg from "../../../assets/img/profile-logo.png";
import LoginIcon from "../../../assets/img/loginIcon.png";
import vendorLoginIcon from "../../../assets/img/vendorLoginIcon.png";
import { useTranslation } from "react-i18next";

const UserNav = ({ setShowLogin }) => {
  const { t, i18n } = useTranslation();
  let lang = localStorage.getItem("lang");
  const [loggedIn, setLoggedIn] = useState();
  const [image, setImage] = useState();
  const [name, setName] = useState();
  const [ip, setIP] = useState("");
  const navigate = useNavigate();

  const getIpv4 = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      console.log(data.ip);
      setIP(data.ip);
    } catch (err) {
      console.log(err);
    }
  };

  const test = localStorage.getItem("isLoggedIn");
  useEffect(() => {
    setLoggedIn(test);
  }, [test]);

  // useEffect(() => {
  //   getIpv4();
  // }, []);

  const handleShow = () => {
    setShowLogin(true);
  };

  const getUser = async () => {
    let path = apiPath.get_user_profile;
    let options = { method: "POST" };
    try {
      let res = await Http(path, options);
      const { image, name } = res?.data?.result;
      setImage(image);
      setName(name);
    } catch (err) {
      toast.error(err.msg);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  const guest = async () => {
    let path = apiPath.guest_user;

    let data = {
      ip: "0.0.0.1", ///ip,
      user_type: "2",
    };

    let options = {
      method: "POST",
      data: data,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        // console.log(res.data.result);
        const token = res.data.result.token;
        localStorage.setItem("guestToken", token);
        localStorage.setItem("userToken", "");
        localStorage.setItem("isLoggedIn", "0");
        setLoggedIn(0);
        console.log(res.data.msg);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };
  const handleLogout = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure want to logout ?")) {
      const userToken = localStorage.getItem("userToken");
      console.clear();
      console.log("token", userToken);
      const path = apiPath.logot;
      const options = {
        method: "POST",
        headers: { Authorization: `Bearer ${userToken}` },
      };
      try {
        let res = await Http(path, options);
        if (res.data.success === true) {
          getIpv4();
          guest();
          localStorage.setItem("userToken", "");
          localStorage.removeItem("userToken");
          localStorage.setItem("userData", "");
          localStorage.removeItem("userData");
          localStorage.setItem("isLoggedIn", 0);
          toast.success("Logged Out!");
          navigate("/");
        } else {
          console.log("__Somthing went wrong!__", res.data.success);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="d-flex align-items-center gap-2">
      {loggedIn == 1 ? (
        <>
          <Dropdown className="dropdwon_inner">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="border-0 shadow-none"
            >
              {image === "undefined" ||
                image === "null" ||
                image === null ||
                image === "" ? (
                <img
                  src={ProfileImg}
                  className="profileImg"
                  alt="undefined111"
                />
              ) : (
                <img
                  src={IMAGE_PATH + image}
                  className="profileImg"
                  alt="undefined--1"
                />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu
              placement="bottomRight"
              className="end-0"
              style={{ left: "auto !important" }}
            >
              <Dropdown.Item
                as={Link}
                to="/my-account"
                className="user-profile-link"
              >
                {t("my_account")}
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/notification"
                className="user-profile-link"
              >
                {t("prof_notification")}
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/my-orders"
                className="user-profile-link"
              >
                {t("prof_myOrders")}
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/favorites"
                className="user-profile-link"
              >
                {t("prof_favorites")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => {
                  handleLogout(e);
                }}
              >
                {" "}
                {t("prof_logout")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <>
          <button className="main-btn mobileBtn" onClick={handleShow}>
            <span className="d-none d-sm-block">
              {t("Login")}/{t("SignUp")}
            </span>
            <img className="d-sm-none" src={LoginIcon} alt="" />
          </button>

          <Link
            target={"_blank"}
            className="main-btn mobileBtn"
            to={"https://admin.toctocme.com/"}
          >
            <span className="d-none d-sm-block">{t("VendorLogin")}</span>
            <img className="d-sm-none" src={vendorLoginIcon} alt="" />
          </Link>
        </>
      )}
    </div>
  );
};

export default UserNav;

import React, { useState } from "react";
import { Navbar, Container, Offcanvas, Nav, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserNav from "./UserNav";
import logo from "../../../assets/img/logo.png";
import langImg from "../../../assets/img/langImg.png";
import Login from "../modalComponents/Login";
import Signup from "../modalComponents/Signup";
import ForgetPassword from "../modalComponents/ForgetPassword";
import OtpVerification from "../modalComponents/OtpVerification";
import PasswordSet from "../modalComponents/PasswordSet";
import Profile from "../modalComponents/Profile";
import { useTranslation } from "react-i18next";

export const ModalContext = React.createContext();

const Header = () => {
  const { t, i18n } = useTranslation();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [type, setType] = useState("register");

  const [showOtp, setShowOtp] = useState(false);
  const [getSignup, setSignup] = useState({
    country_code: "",
    mobile: "",
  });

  const [getOtp, setGetOtp] = useState({
    otp: "",
    hasErrored: false,
  });

  const closeAll = () => {
    setShowLogin(false);
    setShowSignup(false);
    setShowForgetPassword(false);
    setShowPassword(false);
    setShowProfile(false);
  };
  const onClose = () => {
    setShowLogin(false);
    console.log(showLogin);
  };

  const hideLogin = () => {
    setShowLogin(false);
  };

  const hideSignup = () => {
    setShowSignup(false);
  };

  const hideOTP = () => {
    setShowOtp(false);
  };

  const handleLanguageChange = (language) => {
    var currentUrl = window.location.href;
    i18n.changeLanguage(language);
    localStorage.setItem("lang", language);
    window.location.href = currentUrl;
  };

  return (
    <ModalContext.Provider
      value={{
        getSignup,
        setSignup,
        setGetOtp,
        setType,
        getOtp,
      }}
    >
      <div>
        <header className="header_sec">
          <Navbar
            key="md"
            expand="md"
            className="navbar navbar-expand-lg navbar-light"
          >
            <Container>
              <div className="logo_wrap">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>

              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-md`}
                aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                placement="start"
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body className="align-items-center">
                  <Nav className="flex-grow-1">
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link to="/" className="nav-link">
                          {t("nav_home")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/about" className="nav-link">
                          {t("nav_about")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/all-artisans" ///artisans
                          className="nav-link"
                          // onClick={onShowArtisansClick}
                        >
                          {t("nav_artisans")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/book-offline" className="nav-link">
                          {t("nav_offlineBooking")}
                        </Link>
                      </li>
                    </ul>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
              <div className="d-flex align-content-center gap-3">
                <Dropdown className="dropdwon_inner bg-transparent  ">
                  <Dropdown.Toggle
                    variant=""
                    className="w-auto h-auto rounded-0 bg-transparent border-0 shadow-none"
                    id="dropdown-basic"
                  >
                    <img src={langImg} alt="Language Icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="user-profile-link"
                      onClick={() => handleLanguageChange("en")}
                    >
                      {t("English")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="user-profile-link"
                      onClick={() => handleLanguageChange("fr")}
                    >
                      {t("French")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <UserNav setShowLogin={setShowLogin} />
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
              </div>
            </Container>
          </Navbar>
        </header>
      </div>

      {showLogin === true && (
        <Login
          showLogin={showLogin}
          onClose={onClose}
          showSignup={() => {
            setShowLogin(false);
            setShowSignup(true);
          }}
          showForget={() => {
            setType("forgot");
            setShowLogin(false);
            setShowForgetPassword(true);
          }}
          hide={hideLogin}
        />
      )}

      {showSignup && (
        <Signup
          showLogin={() => {
            hideSignup();
            setShowLogin(true);
          }}
          show={showSignup}
          hide={hideSignup}
          data={showSignup}
          onClose={onClose}
          showOTP={() => {
            setShowOtp(true);
          }}
        />
      )}

      {showForgetPassword && (
        <ForgetPassword
          show={showForgetPassword}
          hide={() => {
            setShowForgetPassword(false);
          }}
          showLogin={() => {
            setShowForgetPassword(false);
            setShowLogin(true);
          }}
          showOTP={() => {
            setShowForgetPassword(false);
            setShowOtp(true);
          }}
          data={showForgetPassword}
          type={type}
          onClose={onClose}
        />
      )}

      {showOtp ? (
        <OtpVerification
          show={showOtp}
          hide={hideOTP}
          data={showOtp}
          userData={getSignup}
          type={type}
          onClose={onClose}
          showPassword={() => {
            hideOTP();
            setShowPassword(true);
          }}
        />
      ) : (
        ""
      )}

      {showPassword && (
        <PasswordSet
          show={showPassword}
          hide={() => setShowPassword(false)}
          mobile={getSignup.mobile}
          cc={"+" + getSignup.country_code}
          otp={getOtp.otp}
          type={type === "forgot" ? "forgot" : "register"}
          onClose={onClose}
          showOtp={() => {
            setShowOtp(true);
          }}
          showPassword={() => {
            setShowPassword(true);
          }}
          showLogin={() => {
            setShowPassword(false);
            setShowLogin(true);
          }}
          showProfile={() => setShowProfile(true)}
          closePassword={() => {
            setShowPassword(false);
          }}
        />
      )}

      {showProfile ? (
        <Profile
          onClose={() => {
            onClose();
            closeAll();
          }}
        />
      ) : (
        ""
      )}
    </ModalContext.Provider>
  );
};

export default Header;

import "./App.css";
import Home from "./view/Home";
import Order from "./view/Order";
import Coupon from "./view/Coupon";
import Wallet from "./view/Wallet";
import Layout from "./layout/Layout";
import Address from "./view/Address";
import AboutUs from "./view/AboutUs";
import Privacy from "./view/Privacy";
import Artisans from "./view/Artisans";
import Checkout from "./view/Checkout";
import ThankYou from "./view/ThankYou";
import Favorite from "./view/Favorite";
import Password from "./view/Password";
import MyAccount from "./view/MyAccount";
import MyCoupons from "./view/MyCoupons";
import Categories from "./view/Categories";
import SelectTime from "./view/SelectTime";
import Stripe from "./view/Component/Stripe";
import OrderDetail from "./view/OrderDetail";
import MyDashboard from "./view/MyDashboard";
import AllServices from "./view/AllServices";
import AllArtisans from "./view/AllArtisans";
import Notification from "./view/Notification";
import TrackArtisan from "./view/TrackArtisan";
import PrivateRoute from "./layout/PrivateRoute";
import ServiceDetail from "./view/ServiceDetail";
import ManageAddress from "./view/ManageAddress";
import ArtisansDetail from "./view/ArtisansDetail";
import BrowseArtisans from "./view/BrowseArtisans";
import OrderByArtisan from "./view/orderby/artisan";
import BookOffline from "./view/offline/BookOffline";
import HeavyService from "./view/heavy-service/index";
import ScrollToTop from "./view/Component/ScrollToTop";
import SelectTimeOffline from "./view/offline/SelectTime";
import SoftCategory from "./view/categories/SoftCategory";
import HeavyCategory from "./view/categories/HeavyCategory";
import CouponByArtisan from "./view/orderby/artisan/Coupon";
import CheckoutOffline from "./view/offline/CheckoutOffline";
import TermsAndCondiations from "./view/TermsAndCondiations";
import HeavyServiceTime from "./view/heavy-service/SelectTime";
import HeavyOrderCheckout from "./view/heavy-service/Checkout";
import BrowseOfflineArtisan from "./view/offline/BrowseArtisan";
import CheckoutByArtisan from "./view/orderby/artisan/Checkout";
import OfflineServiceDetail from "./view/offline/ServiceDetails";
import SoftServiceList from "./view/soft-service/SoftServiceList";
import HeavyServiceList from "./view/heavy-service/ServiceList";
import SelectTimeByArtisan from "./view/orderby/artisan/SelectTime";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HeavyServiceArtisans from "./view/heavy-service/HeavyServiceArtisans";
import OfflineBookingServiceListing from "./view/offline/OfflineBookingServiceListing";
import { ContextProvider } from "./context/ProductContext";
import ContactUs from "./view/ContactUs";
import ArtisanDetailOffline from "../src/view/offline/ArtisanDetail";
import CompleteProfile from "./view/CompleteProfile";
import Refund from "./view/Refund";
import Faq from "./view/Faq";

const App = () => {
  return (
    <ContextProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/complete-profile" element={<CompleteProfile />}></Route>
          <Route element={<Layout />}>
            <Route path="/about" element={<AboutUs />}></Route>
            <Route path="/categories" element={<Categories />}></Route>
            <Route path="/category/soft" element={<SoftCategory />}></Route>
            <Route path="/category/heavy" element={<HeavyCategory />}></Route>
            <Route path="/artisans" element={<Artisans />}></Route>
            <Route path="/browse-artisans" element={<BrowseArtisans />}></Route>
            <Route path="/all-artisans" element={<AllArtisans />}></Route>
            <Route path="/all-services" element={<AllServices />}></Route>
            <Route path="/address" element={<Address />}></Route>
            <Route path="/select-time" element={<SelectTime />}></Route>
            <Route
              path="/select-time-by-artisan"
              element={<SelectTimeByArtisan />}
            ></Route>
            <Route
              path="/artisan-detail/:id"
              element={<ArtisansDetail />}
            ></Route>
            <Route
              path="/order-by-artisan/:id"
              element={<OrderByArtisan />}
            ></Route>

            {/* -Heavy Service- */}
            <Route path="/heavy-service" element={<HeavyService />}></Route>
            <Route
              path="/heavy-service-artisans"
              element={<HeavyServiceArtisans />}
            ></Route>
            <Route
              path="/heavy-order-checkout"
              element={<HeavyOrderCheckout />}
            ></Route>
            <Route
              path="/heavy-service-time/:id"
              element={<HeavyServiceTime />}
            ></Route>

            <Route
              path="/service-detail/:id"
              element={<ServiceDetail />}
            ></Route>
            <Route path="/checkout" element={<Checkout />}></Route>
            <Route
              path="/checkout-by-artisan"
              element={<CheckoutByArtisan />}
            ></Route>
            <Route path="/order-detail/:id" element={<OrderDetail />}></Route>

            {/* Offline Service Routes */}
            <Route path="/book-offline" element={<BookOffline />}></Route>
            <Route
              path="/offline-service-list/:id"
              element={<OfflineBookingServiceListing />}
            ></Route>
            <Route
              path="/soft-service-list/:id"
              element={<SoftServiceList />}
            ></Route>
            <Route
              path="/heavy-service-list/:id"
              element={<HeavyServiceList />}
            ></Route>
            <Route
              path="/offline-service-detail/:id"
              element={<OfflineServiceDetail />}
            ></Route>
            <Route
              path="/select-time-offline"
              element={<SelectTimeOffline />}
            ></Route>
            <Route
              path="/browse-offline-artisans"
              element={<BrowseOfflineArtisan />}
            ></Route>
            <Route
              path="/artisan-detail-offline/:id"
              element={<ArtisanDetailOffline />}
            ></Route>
            <Route
              path="/checkout-offline"
              element={<CheckoutOffline />}
            ></Route>

            <Route exact element={<PrivateRoute />}>
              <Route path="/payment-gateway/:id" element={<Stripe />}></Route>
              <Route
                path="/coupon-by-artisan"
                element={<CouponByArtisan />}
              ></Route>
              {/* User Details Route */}
              <Route path="/thank-you" element={<ThankYou />}></Route>
              <Route path="/coupon" element={<Coupon />}></Route>
              <Route path="/my-coupons" element={<MyCoupons />}></Route>
              <Route path="/my-orders" element={<Order />}></Route>
              <Route path="/favorites" element={<Favorite />}></Route>
              <Route path="/manage-address" element={<ManageAddress />}></Route>
              <Route path="/notification" element={<Notification />}></Route>
              <Route path="/my-account" element={<MyAccount />}></Route>
              <Route path="/manage-password" element={<Password />}></Route>
              <Route path="/dashboard" element={<MyDashboard />}></Route>
              <Route path="/wallet" element={<Wallet />}></Route>
              <Route path="/track-artisan" element={<TrackArtisan />}></Route>
            </Route>
            <Route
              path="/terms-and-conditions"
              element={<TermsAndCondiations />}
            ></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route path="/refund" element={<Refund />}></Route>
            <Route path="/faq" element={<Faq />}></Route>
          </Route>
          <Route path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      </BrowserRouter>
    </ContextProvider>
  );
};

export default App;

import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const token = localStorage.getItem("userToken");
  const isToken = token !== "" && token !== null && token !== undefined;
  console.log("userToken", isToken);
  return isToken ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoute;

import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import mapStyle from "../../assets/mapStyle";

const center = { lat: 48.8584, lng: 2.2945 };

const GMap = ({ latLng }) => {
  const [map, setMap] = useState(null);
  const [latLong, setLatLong] = useState({ lat: 0, lng: 0 });
  const [libraries, setLibraries] = useState(["places"]);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    console.log(latLng, "latLng");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLong({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        console.log(latLong);
      });
    }
  }, []);

  useEffect(() => {
    if (map) {
      const directionsServiceInstance =
        new window.google.maps.DirectionsService();
      const directionsRendererInstance =
        new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: "blue",
          },
        });

      directionsRendererInstance.setMap(map);
      setDirectionsService(directionsServiceInstance);
      setDirectionsRenderer(directionsRendererInstance);
    }
  }, [map]);

  useEffect(() => {
    if (directionsService && directionsRenderer && latLng?.length >= 2) {
      const origin = new window.google.maps.LatLng(
        parseFloat(latLng[0][0]),
        parseFloat(latLng[0][1])
      );
      const destination = new window.google.maps.LatLng(
        parseFloat(latLng[1][0]),
        parseFloat(latLng[1][1])
      );

      const request = {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        }
      });
    }
  }, [directionsService, directionsRenderer, latLng]);

  const { isLoaded } = useJsApiLoader({
    libraries: libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API, // Replace with your own API key,
  });

  if (!isLoaded) {
    return <h2>Loading map..</h2>;
  }

  return (
    <GoogleMap
      center={{
        lat: latLng?.length > 0 ? parseFloat(latLng[0][0]) : 0,
        lng: latLng?.length > 0 ? parseFloat(latLng[0][1]) : 0,
      }}
      zoom={10}
      mapContainerClassName="googleMap"
      onLoad={(map) => setMap(map)}
      options={{ styles: mapStyle.light }}
    >
      {latLng?.map((latlong, index) => (
        <Marker
          key={index}
          position={{
            lat: parseFloat(latlong[0]),
            lng: parseFloat(latlong[1]),
          }}
          onClick={() => setSelectedMarker(latlong)}
          icon={{
            url: latlong[2],// Replace with your icon path
            scaledSize: new window.google.maps.Size(30, 30), // Adjust the size as needed
          }}
          
        />
      ))}

      {selectedMarker && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedMarker[0]),
            lng: parseFloat(selectedMarker[1]),
          }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>
            <h3>Marker Details</h3>
            <p>Lat: {parseFloat(selectedMarker[0])}</p>
            <p>Lng: {parseFloat(selectedMarker[1])}</p>
            <p>Name: {(selectedMarker[3])}</p>
            <p>Residence Address: {(selectedMarker[4])}</p>
            <p>Artisan Level: {(selectedMarker[5])}</p>

          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default GMap;

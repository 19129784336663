import React, { useState, useMemo, useEffect, useContext } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import OtpVerification from "./OtpVerification";
import Imgcross from "../../../assets/img/cross.png";
import Facebook from "../../../assets/img/facebook.png";
import Google from "../../../assets/img/google.png";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import toast, { Toaster } from "react-hot-toast";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import Login from "./Login";
import { GoogleLogin } from "@react-oauth/google";
import { ValidationForm } from "react-bootstrap4-form-validation";
import "react-phone-input-2/lib/style.css";
import jwt_decode from "jwt-decode";
import FacebookLogin from "react-facebook-login";
import { ModalContext } from "../home/Navbar";
// import {
//   GoogleAuthProvider,
//   FacebookAuthProvider,
//   signInWithPopup,
// } from "firebase/auth";
// import { auth, provider, googleProvider } from "../../../firebase/firebase";
// import { firebaseConfig } from "../../../firebase/firebaseConfig";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { initializeApp } from "firebase/app";

const Signup = ({ showLogin, show, hide, userLogin, onClose, showOTP }) => {
  const [showOtp, setShowOtp] = useState(false);
  const [errors, setErrors] = useState({});
  const { getSignup, setSignup } = useContext(ModalContext);
  const [user, setUser] = useState(null);
  const [fcmToken, setFcmToken] = useState();
  const navigate = useNavigate();

  const formRef = React.createRef();

  // useEffect(() => {
  //   askForPermission();
  // }, []);

  // const askForPermission = async () => {
  //   const app = initializeApp(firebaseConfig);

  //   // Get Firebase messaging instance
  //   const messaging = getMessaging(app);

  //   // Request permission to receive notifications
  //   Notification.requestPermission()
  //     .then((permission) => {
  //       if (permission === "granted") {
  //         console.log("Notification permission granted.");

  //         // Get FCM token
  //         getToken(messaging, {
  //           vapidKey:
  //             "BLLdG9h5WrLrY5aPKL881CBET6SZAu8JKdkt8PXo7Wn8Em2KOsweAQCmFbAN546ye0e1reizzD8ZqErmZuGUaRM",
  //         })
  //           .then((token) => {
  //             console.log("FCM token:", token);
  //             setFcmToken(token);
  //           })
  //           .catch((error) => {
  //             console.log("Error getting FCM token:", error);
  //           });

  //         // Listen for incoming messages
  //         onMessage(messaging, (payload) => {
  //           console.log("Incoming message:", payload);
  //         });
  //       } else {
  //         console.log("Notification permission denied.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error requesting notification permission:", error);
  //     });
  // };

  // const handleFacebookLogin = () => {
  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       setUser(result.user);
  //       const { displayName, email, accessToken } = result?.user;
  //       const cred = FacebookAuthProvider.credentialFromResult(result);
  //       // const fcmToken = cred.accessToken;
  //       handleSocialSignup("Facebook", email, displayName, accessToken);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleGoogleLogin = () => {
  //   signInWithPopup(auth, googleProvider)
  //     .then((result) => {
  //       setUser(result.user);
  //       const { displayName, email, accessToken } = result?.user;
  //       const cred = GoogleAuthProvider.credentialFromResult(result);
  //       // const fcmToken = cred.accessToken;
  //       handleSocialSignup("Google", email, displayName, accessToken);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleSocialSignup = async (method, userEmail, userName, userToken) => {
  //   const path = apiPath.socialLoginSignup;
  //   const data = {
  //     type: `${method}` ? `${method}` : "",
  //     email: `${userEmail}` ? `${userEmail}` : "",
  //     name: `${userName}` ? `${userName}` : "",
  //     social_id: `${userToken}` ? `${userToken}` : "",
  //     // fcm_token: `${fcmToken}` ? `${fcmToken}` : null,
  //   };
  //   const options = { method: "POST", data };
  //   try {
  //     let res = await Http(path, options);
  //     if (res?.data?.success === true) {
  //       toast.success(res?.data?.msg);
  //       console.log(res?.data?.result);
  //       setShowLogin(false);
  //       navigate("/order");
  //       // setIsLoggedIn(true);
  //       const { token } = res?.data?.result;
  //       localStorage.setItem("guestToken", "");
  //       localStorage.removeItem("guestToken");
  //       localStorage.setItem("userToken", token);
  //       localStorage.setItem("isLoggedIn", "1");
  //     } else {
  //       toast.error(res?.data?.msg);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const signupSubmit = async (e, formData, inputs) => {
    e.preventDefault();
    setErrors(validate(getSignup));
    if (getSignup?.mobile.length > 8) {
      let path = apiPath.signup_sendOTP;
      let cc = getSignup.country_code;
      var formData = new FormData();
      //user_type :"2"
      formData.append("type", "register");
      formData.append("user_type", "2");
      formData.append("verify_type", "mobile");
      formData.append("mobile", getSignup.mobile);
      formData.append("country_code", "+" + cc);
      let options = {
        method: "POST",
        data: formData,
      };

      try {
        let res = await Http(path, options);
        console.log(res, "-------------------------");
        if (res.data.success === true) {
          console.log("api hit successfully", res.data.msg);
          toast.success(res.data.msg);
          hide();
          showOTP();
        } else {
          toast.error(res.data.msg);
        }
      } catch (err) {
        console.log(err.res);
      }
    }
  };


  /* Mobile validation */
  const validate = (values) => {
    const errors = {};
    if (!values.mobile.length) {
      errors.mobile = "Mobile Number is required";
    } else if (values.mobile.length < 8) {
      errors.mobile = "Mobile Number must be valid.";
    }
    return errors;
  };

  const signupErrorSubmit = (e, formData, inputs) => {
    e.preventDefault();
  };
  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setSignup({
      country_code: country_code,
      mobile: value.slice(data.dialCode.length),
    });
  };

  return (
    <>
      <Modal
        show={show}
        // onHide={hide}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="modalStyle"
      >
        <Modal.Header>
          <h5 id="exampleModalLabel">Signup</h5>
          <button type="button" className="btn-close" onClick={() => hide()}>
            <img src={Imgcross} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_cont">
            <h4>Welcome!</h4>
            <h5>Create Your Account</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
          <ValidationForm onErrorSubmit={signupErrorSubmit} ref={formRef}>
            <div>
              <Form.Group className="form-group" controlId="form-group">
                <Form.Label>Enter Mobile Number</Form.Label>
                <PhoneInput
                  enableSearch={true}
                  inputProps={{
                    name: "mobile",
                    required: true,
                    autoFocus: false,
                  }}
                  isValid={(value, country) => {
                    if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else {
                      return true;
                    }
                  }}
                  country={"fr"}
                  value={getSignup.phone}
                  onChange={handleChange}
                />
                <span className="error_msg">{errors.mobile}</span>
              </Form.Group>
              <div className="login-btn">
                <Button className="btn btn_primary" onClick={signupSubmit}>
                  Signup
                </Button>
              </div>
              {/*<div className="already_cls">
                        <p>If you already have an account? <Link to="#" onClick={openLogin}>Sign In</Link></p>
                                                    </div>*/}
            </div>
          </ValidationForm>
          <div className="already_cls">
            <p>
              Already have an account? <Link onClick={showLogin}>Login</Link>
            </p>
          </div>
          {/* <div className="social-login">
            <Link to="#">
              <img src={Facebook} onClick={handleFacebookLogin} />
            </Link>
            <Link to="#">
              <img src={Google} onClick={handleGoogleLogin} />
            </Link>
          </div> */}

          {/* <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse.credential);
                let decoded = jwt_decode(credentialResponse.credential);
                console.log(decoded);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            /> */}
          {/* <FacebookLogin
            appId="1088597931155576"
            autoLoad={true}
            fields="name,email,picture"
            onClick={componentClicked}
            callback={responseFacebook}
          /> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Signup;

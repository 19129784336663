import { createContext, useState } from "react";
const ProductContext = createContext();

export const ContextProvider = ({ children }) => {
  const [productData, setProductData] = useState([]);
  return (
    <ProductContext.Provider value={{ productData, setProductData }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContext;

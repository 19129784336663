import React, { useEffect, useState } from "react";
import StripeCheckout from "react-stripe-checkout";

import {
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  FormControl,
  FormSelect,
  Row,
} from "react-bootstrap";
import ArtisanImg from "../../assets/img/artisan1.png";
import Close from "../../assets/img/cross.png";
import { Link, useLocation } from "react-router-dom";
import Star from "../../assets/img/star_icon.png";
import Map from "../../assets/img/google-map.png";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import Http from "../../services/Http";
import Coupon from "../../assets/img/coupon.png";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckoutOffline = () => {
  const { t, i18n } = useTranslation();

  const [preview, setPreview] = useState();
  const [services, setServices] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("location", state);

  const onToken = (token) => {
    console.log(token);
  };

  console.log("preview", preview);

  const confirmCheckout = async () => {
    let path = apiPath.confirmCheckout;
    let formData = new FormData();
    formData.append("cart_id", preview?.id);

    let options = { method: "POST", data: formData };

    try {
      let res = await Http(path, options);
      console.log(res);
      toast.success(res?.data?.msg);
      const { orderNo } = res?.data?.result;
      navigate("/thank-you", { state: { bookingNo: orderNo } });
    } catch (err) {
      console.log(err);
    }
  };

  const removePromocode = async (codeId) => {
    const path = apiPath.remove_promo_code;
    const data = { promocode_id: codeId + "", cart_id: preview?.id + "" };
    const options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setPreview((prev) => ({ ...prev, promocodeId: null }));
        await getPreview();
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPreview = async () => {
    let path = apiPath.getPreview;
    var formData = new FormData();
    formData.append("order_type", "Offline");

    let options = {
      method: "POST",
      data: formData,
    };

    try {
      let res = await Http(path, options);
      console.log(res, "-------------------------");
      if (res.data.success === true) {
        console.log("api hit successfully", res.data.msg);
        toast.success(res.data.msg);
        console.log("CART_DATA", res.data.result.cartData);
        // console.log(
        //   res.data.result.cartData.products?.map(
        //     (item) => item.serviceData.subcatData.id
        //   )
        // );
        setPreview(res?.data?.result?.cartData);
        setServices(res?.data?.result?.cartData?.products);
        // console.log(
        //   services.map((item) => )
        // );
        // navigate("/checkout");
      } else {
        // toast.error(res.data.msg);
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  useEffect(() => {
    getPreview();
  }, []);

  const serviceName = services?.map((item) => item.serviceData.subcatData.name);
  console.log(
    "serviceName",
    serviceName,
    preview?.service_type === "Right Now"
  );

  return (
    <>
      <Toaster position="top-right" />{" "}
      <section className="checkout-main">
        <Container>
          <div className="checkout-inner">
            <div className="checkout_left">
              <div className="checkout_bg">
                {preview?.artisanData && (
                  <div className="checkout_cont">
                    <h3>{serviceName[0]}</h3>
                    <div className="artisan_bg">
                      <div className="artisan_left_img">
                        <div className="artisan_img">
                          <img
                            src={`${IMAGE_PATH + preview?.artisanData?.image}`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="artisan_right_cont artisan-dtl-cont">
                        <div className="artisan_title">
                          <h5>
                            {preview?.artisanData?.name},{" "}
                            <span>{preview?.artisanData?.artisan_level}</span>
                          </h5>
                        </div>
                        <div className="jobs_review">
                          <div className="job_cont">
                            <span>
                              #
                              {preview?.artisanData?.job_done === null
                                ? "No"
                                : preview?.artisanData?.job_done}{" "}
                              {t("Jobs")}
                            </span>
                          </div>
                          <div className="review_cont">
                            <span className="review-ic">
                              <img src={Star} alt="" />
                            </span>
                            <span>{preview?.artisanData?.rating}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="checkout_selected_serv">
                      <h5>You have selected services</h5>
                      {services?.map((item, idx) => (
                        <div className="signle_select_serv">
                          <div className="serv_no">
                            <span>{idx + 1}.</span>
                          </div>
                          <div className="serv_desc">
                            <h4>{item.serviceData.name}</h4>
                            <p>€ {item.total_amount}</p>
                          </div>
                          <div className="serv_edit_option d-none">
                            <Button
                              className="black_btn"
                              onClick={() =>
                                navigate(
                                  `/artisan-detail-offline/${preview?.artisanData?.id}`,
                                  {
                                    state: {
                                      date: preview
                                        ? preview?.service_date
                                        : "",
                                      time_slot: preview
                                        ? preview?.time_slot
                                        : "",
                                      scheduling: preview
                                        ? preview?.service_type
                                        : "",
                                      services: services ? services : "",
                                    },
                                  }
                                )
                              }
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                    {preview?.service_type === "Right Now" ? (
                      ""
                    ) : (
                      <div className="customer_select_address">
                        <h3>Time Slot Selected by The Customer</h3>
                        <p>
                          {preview?.service_date}, {preview?.time_slot}
                        </p>
                      </div>
                    )}
                    <div className="customer_select_address">
                      <h3>Artisan Location</h3>
                      <p>{preview?.artisanData?.residence_address}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="checkout-right">
              <div className="checkout-bg">
                <div className="checkout-coupon">
                  {preview?.promocodeId ? (
                    <>
                      <div className="coupon-code-btn">
                        <span>{preview?.discountCode}</span>
                        <span className="cross_img">
                          <Link to="#">
                            <img
                              onClick={() =>
                                removePromocode(preview?.promocodeId)
                              }
                              src={Close}
                              alt
                            />
                          </Link>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <span className="coupon_icon">
                        <img src={Coupon} alt="" />
                      </span>
                      <Link
                        to="/coupon"
                        state={{ cartId: preview?.id, by: "offline" }}
                      >
                        <span className="apply_coupon">
                          {t("apply_coupon")}
                        </span>
                      </Link>
                    </>
                  )}{" "}
                </div>
                <div className="card_detail">
                  <div className="price-specification">
                    <h3>{t("price_specification")}</h3>
                    <ul>
                      <li>
                        <span className="checkout-price-left">
                          {t("ServiceCharge")}{" "}
                        </span>
                        <span className="checkout-price-right">
                          € {preview?.orgAmount}
                        </span>
                      </li>
                      {preview?.discountAmount && (
                        <li>
                          <span className="checkout-price-left">
                            {t("Discount")}
                          </span>
                          <span className="checkout-price-right">
                            - € {preview?.discountAmount}
                          </span>
                        </li>
                      )}

                      {parseFloat(preview?.emergencyAmount) > 0 && (
                        <li>
                          <span className="checkout-price-left">
                            {t("EmergencyAmount")}{" "}
                          </span>
                          <span className="checkout-price-right">
                            € {preview?.emergencyAmount}
                          </span>
                        </li>
                      )}

                      {preview?.fee && (
                        <li>
                          <span className="checkout-price-left">Fee</span>
                          <span className="checkout-price-right">€ 350.00</span>
                        </li>
                      )}

                      {preview?.taxes && (
                        <li>
                          <span className="checkout-price-left">Taxes</span>
                          <span className="checkout-price-right">
                            € {preview?.taxes}
                          </span>
                        </li>
                      )}
                      <li>
                        <span className="checkout-price-left">
                          {t("TotalAmount")}
                        </span>
                        <span className="checkout-price-right">
                          € {preview?.totalAmount}
                        </span>
                      </li>
                    </ul>
                    <div className="login-btn">
                      <Button className="btn_primary" onClick={confirmCheckout}>
                        Confirm & Go
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
export default CheckoutOffline;

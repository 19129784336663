import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Imgcross from "../../../assets/img/cross.png";
import Http from "../../../services/Http";
import apiPath from "../../../constants/apiPath";
import { Toaster, toast } from "react-hot-toast";

const RateUs = ({ orderNo, onClose }) => {
  const [rateUs, setRateUs] = useState(true);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  console.log("orderNo", orderNo);

  const handleClose = () => {
    onClose();
  };

  const handleRating = async () => {
    let path = apiPath.reviewOrder;
    let data = {
      orderNo: `${orderNo}`,
      rating: `${rating}`,
    };
    let options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        show={rateUs}
        onHide={handleClose}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalAddress"
        centered
      >
        <Modal.Header>
          <h5 id="exampleModalLabel">Rate Our Service</h5>
          <button type="button" className="btn-close" onClick={onClose}>
            <img src={Imgcross} onClick={onClose} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="addressDetails">
            <Row>
              <Col md="6">
                <Form.Group className="form-group">
                  <h5>Rating</h5>
                  <div className="rating_inner">
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <button
                          type="button"
                          key={index}
                          className={
                            ("starBtn",
                            index <= (hover || rating) ? "on" : "off")
                          }
                          onClick={() => {
                            setRating(index);
                            console.log(index);
                          }}
                          onMouseEnter={() => setHover(index)}
                          onMouseLeave={() => setHover(rating)}
                        >
                          <span className="star">&#9733;</span>
                        </button>
                      );
                    })}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            {rating > 0 && (
              <div className="d-flex align-items-center justify-content-end">
                <Button className="btn_primary mb-4" onClick={handleRating}>
                  Confirm Rating
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RateUs;

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ArrowIcon from "../assets/img/arrow.png";
import FacebookIcon from "../assets/img/facebook_ic.png";
import InstagramIcon from "../assets/img/instagram.png";
import YoutubeIcon from "../assets/img/youtube.png";
import TwitterIcon from "../assets/img/twitter.png";
import PinterestIcon from "../assets/img/pinterest.png";
import AppleIcon from "../assets/img/apple_ic.png";
import IosDownload from "../assets/img/ios_download.png";
import AndroidDownload from "../assets/img/android_download.png";
import Http from "../services/Http";
import apiPath from "../constants/apiPath";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [linkData, setLinkData] = useState();
  const currDate = new Date();
  const navigate = useNavigate();

  const getFooterData = async () => {
    let path = apiPath.getSettings;
    let options = {
      method: "GET",
    };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        setLinkData(res?.data?.result);
        localStorage.setItem("playStore", res?.data?.result?.PLAY_STORE);
        localStorage.setItem("appStore", res?.data?.result?.APP_STORE);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFooterData();
  }, []);

  // console.log("data", linkData);

  return (
    <footer className="footer">
      <div className="top-footer">
        <Container>
          <Row>
            <Col sm="6" md="3">
              <div className="footer-widget">
                <div className="footer_title">
                  <span>{t("about_ttm")}</span>
                </div>
                <div className="fw-light">
                  <p>
                    Lorem ipsum dolor sit amet, consec tetur adipisicing elit,
                    sed do eiusmod tempor incididuntut consec tetur adipisicing
                    elit,Lorem ipsum dolor sit amet.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6" md="3">
              <div className="footer-widget">
                <div className="footer_title">
                  <span>{t("quick_links")}</span>
                </div>

                <ul className="list-unstyled">
                  <li>
                    <Link
                      className="text-decoration-none text-dark fw-light footer-link"
                      to="/"
                    >
                      <span className="footer_left_icon">
                        <img src={ArrowIcon} />
                      </span>
                      {t("nav_home")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-decoration-none text-dark fw-light footer-link"
                      to="/about"
                    >
                      <span className="footer_left_icon">
                        <img src={ArrowIcon} />
                      </span>
                      {t("nav_about")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-decoration-none text-dark fw-light footer-link"
                      to="/all-artisans"
                    >
                      <span className="footer_left_icon">
                        <img src={ArrowIcon} />
                      </span>
                      {t("our_artisans")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-decoration-none text-dark fw-light footer-link"
                      to="/all-services"
                    >
                      <span className="footer_left_icon">
                        <img src={ArrowIcon} />
                      </span>
                      {t("our_services")}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm="6" md="3">
              <div className="footer-widget">
                <div className="footer_title">
                  <span>{t("need_help")} ?</span>
                </div>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      className="text-decoration-none text-dark fw-light footer-link"
                      to="/privacy"
                    >
                      <span className="footer_left_icon">
                        <img src={ArrowIcon} />
                      </span>
                      {t("privacy_policy")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-decoration-none text-dark fw-light footer-link"
                      to="/terms-and-conditions"
                    >
                      <span className="footer_left_icon">
                        <img src={ArrowIcon} />
                      </span>
                      {t("terms")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-decoration-none text-dark fw-light footer-link"
                      to="/contact-us"
                    >
                      <span className="footer_left_icon">
                        <img src={ArrowIcon} />
                      </span>
                      {t("contact_us")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-decoration-none text-dark fw-light footer-link"
                      to="/refund"
                    >
                      <span className="footer_left_icon">
                        <img src={ArrowIcon} />
                      </span>
                      {t("Refund")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-decoration-none text-dark fw-light footer-link"
                      to="/faq"
                    >
                      <span className="footer_left_icon">
                        <img src={ArrowIcon} />
                      </span>
                      {t("FAQs")}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm="6" md="3">
              <div className="footer-widget">
                <div className="footer_title">
                  <span>{t("need_professional")} ?</span>
                </div>
                <ul className="list-unstyled footer_help">
                  <li>
                    <span className="black_bg">
                      <i className="bi bi-envelope fs-xl"></i>
                    </span>
                    <span className="ms-3">
                      <NavLink
                        target="_blank"
                        to={"mailto:" + linkData?.support_email}
                      >
                        {linkData?.support_email}
                      </NavLink>
                    </span>
                  </li>
                  <li>
                    <span className="black_bg">
                      <i className="bi bi-telephone"></i>
                    </span>
                    <span style={{ cursor: "pointer" }} className="ms-3">
                      <NavLink
                        target="_blank"
                        to={"tel:" + linkData?.support_phone}
                      >
                        {linkData?.support_phone}
                      </NavLink>
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottom-footer">
        <Container>
          <Row>
            <Col sm="12" md="4" lg="3">
              <div className="copyright_cont">
                <p>
                  © {moment(currDate).format("YYYY")} Toctocme. All rights
                  reserved.
                </p>
              </div>
            </Col>
            <Col sm="12" md="8" lg="9">
              <div className="footer-social-cls">
                <div className="footer_social">
                  <ul>
                    <li>
                      <Link target="_blank" to={`${linkData?.fb_link}`}>
                        <img src={FacebookIcon} />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to={`${linkData?.insta_link}`}>
                        <img src={InstagramIcon} />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to={`${linkData?.tw_link}`}>
                        <img src={TwitterIcon} />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to={`${linkData?.youtube_link}`}>
                        <img src={YoutubeIcon} />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to={`${linkData?.pinterest_link}`}>
                        <img src={PinterestIcon} />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to={`${linkData?.APP_STORE}`}>
                        <img src={AppleIcon} />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="download_app">
                  <Link target="_blank" to={`${linkData?.APP_STORE}`}>
                    <img src={IosDownload} />
                  </Link>
                  <Link target="_blank" to={`${linkData?.PLAY_STORE}`}>
                    <img src={AndroidDownload} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { Button, Container, Form, FormSelect } from "react-bootstrap";
import HeavyServ from "../../assets/img/heavy-serv.png";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Http from "../../services/Http";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import { toast, Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HeavyService = () => {
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState();
  const [subCategory, setSubCategory] = useState();
  const [categoryID, setCategoryID] = useState();
  const [subCategoryID, setSubCategoryID] = useState();
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeErr, setPostalCodeErr] = useState({});
  const [stepForm, setStepForm] = useState(0);
  const [steps, setSteps] = useState();
  const [questions, setQuestions] = useState([]);
  const [heavyData, setHeavyData] = useState([]);
  const [totalSteps, setTotalSteps] = useState();
  const [serviceType, setServiceType] = useState();
  const [currStep, setCurrStep] = useState(0);
  const [prevStep, setPrevStep] = useState(-1);
  const [nextStep, setNextStep] = useState(1);
  const [cartData, setCartData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [selectError, setSlectError] = useState(false);
  // const [isRequired, setIsRequired] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  let lang = localStorage.getItem("lang");

  const catId = state?.cat_id;
  const subCatId = state?.subCat_id;
  const emg_amount = state?.emg_amount;
  const serviceId = state?.serviceId;

  const [fieldRadio, setFieldRadio] = useState();
  const [fieldCheckbox, setFieldCheckbox] = useState({
    options: [],
  });

  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const [stepQuestions, setStepQuestions] = useState([
    { question: "", question_type: "", answer: {}, answer_type: "" },
  ]);

  useEffect(() => {
    getCategories();
    if (state?.step) {
      setCurrStep((curr) => curr + 1);
      setPrevStep((prev) => prev - 1);
      setNextStep((next_2) => next_2 + 1);
    }
  }, []);

  useEffect(() => {
    console.log(formData, "formData");
  }, [formData]);

  useEffect(() => {
    console.log(heavyData, "heavyData");
  }, [heavyData]);

  const handleSelect = async (value) => {
    // console.log(value, "ll");
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);

    console.log(ll);
    setAddress(value);
    setCoordinates(ll);
  };

  const onSubCatergorySelect = async (e) => {
    console.log(e.target.value, "target");
    setSubCategoryID(e.target.value);
    // console.log(subCategoryID, "subCat");
  };

  /* CATEGORY LIST */
  const getCategories = async () => {
    let path = apiPath.categoryList;
    let options = {
      method: "GET",
    };
    let res = await Http(path, options);
    if (res.status == 200) {
      setCategories(res.data.result.HeavyCategory);
      console.log(res.data.result);
    } else {
    }
  };

  /* SUB-CATEGORY LIST*/
  const getSubCategoryList = async (e) => {
    let path = apiPath.sub_category_list;
    var formData = new FormData();
    formData.append("categoryId", e.target.value);
    let options = {
      method: "POST",
      data: formData,
    };
    if (e.target.value?.length > 1) {
      console.log("first");
    } else {
      console.log("second");
    }
    try {
      let res = await Http(path, options);
      // console.log(res, "-------------------------");
      if (res.data.success === true) {
        // console.log(artisans);
        setCategoryID(e.target.value);
        setSubCategory(res.data.result);
        console.log(res.data.result);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const handleServiceSteps = async () => {
    let path = apiPath.getHeavyServiceSteps;
    let data = {
      serviceId: state.serviceId ? state.serviceId.toString() : `${serviceId}`,
      cat_id: state.cat_id ? state.cat_id.toString() : `${categoryID}`,
      subcat_id: state.subCat_id
        ? state.subCat_id.toString()
        : `${subCategoryID}`,
    };
    let options = {
      method: "POST",
      data,
    };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setSteps(res?.data?.result?.rsData);
        setTotalSteps(res?.data?.result?.stepCount);

        let stepsArr = [];
        for (let i in res?.data?.result?.rsData) {
          const heavyStep = res.data.result.rsData[i];
          for (let j in heavyStep?.stepQuestions) {
            const heavyStep_1 = heavyStep.stepQuestions[j];
            stepsArr.push({
              stepNumber: i,
              questionNo: j,
              question: heavyStep_1.question,
              id: heavyStep_1.q_id,
              question_type:
                heavyStep_1.question_type == "Mandatory" ? true : false,
              answer: "",
              text: "",
            });
          }
        }
        console.log([...heavyData, stepsArr]);
        setHeavyData(...heavyData, stepsArr);
        setStepForm(2);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const form = steps?.map((val) => val?.stepQuestions);

  console.log("globalFormData", form);

  // const seviceDuration = [
  //   { title: "Once" },
  //   { title: "Weekly" },
  //   { title: "Bi-monthly" },
  //   { title: "Monthly" },
  //   { title: "Other" },
  // ];

  //const multiForm = steps?.map((item) => item.stepQuestions);
  // console.log("multiForm", multiForm);

  const handleCartData = async (
    idx,
    idz,
    id,
    question,
    q_id,
    e,
    quesType,
    text_1,
    inputType
  ) => {
    const { value, checked } = e.target;

    const index = heavyData?.findIndex((object) => {
      return object.id === q_id;
    });

    let newArr = heavyData[index];

    let answerArr = newArr.answer;

    let textData = newArr.text;

    console.log("textData", newArr.text === "");
    if (inputType === "checkbox") {
      if (checked) {
        answerArr.push(value);
      } else {
        const index_1 = answerArr.findIndex((object_1) => {
          // alert(object_1);
          return object_1 === value;
        });
        // alert(index_1);
        answerArr.splice(index_1, 1);
      }
    } else if (inputType === "radio") {
      answerArr = value;
    } else {
      // console.log("text", value, textData);
      textData = value;
      newArr.text = textData;
      answerArr = textData;
    }
    newArr.answer = answerArr;
    console.log("---------heavyData-----------", heavyData);

    //    console.log(fieldCheckbox.options, postalCode, fieldRadio);

    //setHeavyData([...cartData, questionData]);
  };

  const getheavyData =
    heavyData?.length > 0
      ? heavyData?.filter(
          (ans) => ans?.answer?.length > 0 || ans?.text?.length > 0
        )
      : "";
  // console.log("heavyDataFilter", getheavyData);

  const validatePinCode = (code) => {
    const errors = {};
    const regex = /^[0-9]+$/i;
    if (!regex.test(code)) {
      errors.code = "Please enter a valid Pincode!";
    }
    return errors;
  };

  const handlePostalCode = (e) => {
    setPostalCodeErr(validatePinCode(e.target.value));
    console.log("postalCodeErr.postalCode", postalCodeErr.code);
    setPostalCode(e.target.value);
  };

  const userToken = localStorage.getItem("userToken");
  const pinCodeRegx = /^[0-9]$/i;

  console.log("=====heavyData======", getheavyData);

  const handleFormSteps = (val, len) => {
    // alert(val);
    // alert(len);
    // if (parseInt(len) > 0) {
    //   console.log("steps", steps[parseInt(len) - 2]);
    //   const formSteps = steps[parseInt(len) - 2];
    //   formSteps?.stepQuestions?.map((itm) => {
    //     if (itm.question_type === "Mandatory") {
    //       console.log("answer_type", itm?.answer_type);
    //     }
    //   });
    //   return false;
    // }

    if (!pinCodeRegx.test(val)) {
      if (userToken) {
        if (val === "pre") {
          setCurrStep((curr) => curr - 1);
          setPrevStep((curr_1) => curr_1 - 1);
          setNextStep((curr_2) => curr_2 - 1);
        } else if (val === "next") {
          if (nextStep - 1 > len) {
            localStorage.setItem(
              "heavyOrderData",
              JSON.stringify({
                heavyData,
                postalCode,
                categoryID,
                subCategoryID,
                catId,
                subCatId,
                emg_amount,
                serviceId,
              })
            );
            navigate("/heavy-service-artisans", {
              state: {
                heavyData,
                postalCode,
                categoryID,
                subCategoryID,
                catId,
                subCatId,
                emg_amount,
                serviceId,
              },
            });
          } else {
            setCurrStep((next) => next + 1);
            setPrevStep((next_1) => next_1 + 1);
            setNextStep((next_2) => next_2 + 1);
          }
        }
        if (currStep == 1) {
          handleServiceSteps();
        }
      } else {
        toast.error("Login Required!");
      }
    } else {
      toast.error("Picode is Invalid!");
    }
  };

  return (
    <>
      <Toaster position="top-right" />
      <section className="heavy-service-sec">
        <Container>
          <div className="heavy_serv_inner">
            <div className="heavy-serev-left">
              <div className="heavy-serv-img">
                <img src={HeavyServ} alt="" />
              </div>
            </div>
            {currStep === 0 && (
              <>
                <div className="heavy-serv-right">
                  <div className="heavy-serv-cont">
                    <div className="step_topbar">
                      <h5>Step 1 </h5>
                      <div className="stepbar">
                        <span className="fillbar"></span>
                      </div>
                    </div>
                    <div className="title_cls">
                      <h3 className="heading_h3">Find a artisan near you</h3>
                    </div>
                    <Form.Group className="form-group">
                      <label>Enter Your Project</label>
                      <div className="sidebar_cont">
                        <h5>Category</h5>
                        <Form.Group className="form-group">
                          <FormSelect
                            value={categoryID}
                            className="form-control"
                            onChange={getSubCategoryList}
                            required={true}
                          >
                            <option>Select by</option>
                            {categories?.map((item, idx) => (
                              <option key={idx} value={item.id.toString()}>
                                {item.name}
                              </option>
                            ))}
                          </FormSelect>
                          <span className="error_msg">
                            {selectError && "Please select a category"}
                          </span>
                        </Form.Group>
                      </div>
                      <div className="sidebar_cont">
                        <h5>Select by</h5>
                        <Form.Group className="form-group">
                          <FormSelect
                            className="form-control"
                            value={subCategoryID}
                            onChange={onSubCatergorySelect}
                            required={true}
                          >
                            <option>Select Sub-category</option>

                            {subCategory?.map((item, idx) => (
                              <option key={idx} value={item.id.toString()}>
                                {item.name}
                              </option>
                            ))}
                          </FormSelect>
                          <span className="error_msg">
                            {selectError && "Please select a category"}
                          </span>
                        </Form.Group>
                      </div>
                    </Form.Group>
                    <div className="step-find">
                      <Button
                        className="btn_primary"
                        onClick={() => {
                          console.log("len", subCategoryID, categoryID);
                          if (subCategoryID && categoryID > 0) {
                            setSlectError(false);
                            handleFormSteps("next");
                          } else {
                            setSlectError(true);
                          }
                        }}
                      >
                        Find
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {currStep === 1 && (
              <>
                <div className="heavy-serv-right">
                  <div className="heavy-serv-cont">
                    <div className="step_topbar">
                      <h5>Step 2</h5>
                      <div className="stepbar">
                        <span className="fillbar"></span>
                      </div>
                    </div>
                    <div className="title_cls">
                      <h3 className="heading_h3">
                        {t("Describeyourprojectanddiscusswithartisansnearyou")}
                      </h3>
                    </div>
                    <Form.Group className="form-group">
                      <label>{t("PostalCodeofThePlaceofWork")}</label>
                      <div className="input-group">
                        <Form.Control
                          required
                          placeholder={t("PostalCode")}
                          className="postal-code"
                          disabled
                        ></Form.Control>
                        <input
                          className="form-control"
                          value={postalCode}
                          id="zip"
                          name="zip"
                          type="number"
                          pattern="[0-9]*"
                          accept="number"
                          onChange={(e) => handlePostalCode(e)}
                        />
                        <span className="error_msg">{postalCodeErr.code}</span>
                      </div>
                    </Form.Group>
                    <div className="step-find">
                      <Button
                        className="btn_primary"
                        onClick={() => handleFormSteps("pre")}
                      >
                        {t("Previous")}
                      </Button>

                      <Button
                        className="btn_primary"
                        onClick={() =>
                          postalCode.length < 1
                            ? toast.error("Please provide valid pincode!")
                            : handleFormSteps("next")
                        }
                      >
                        {t("Next")}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {currStep > 1 &&
              (totalSteps !== 0 ? (
                <>
                  <div className="heavy-serv-right">
                    <div className="heavy-serv-cont">
                      <div className="step_topbar">
                        <h5>
                          Step {currStep + 1} of {totalSteps + 2}
                        </h5>
                        <div className="stepbar">
                          <span className="fillbar"></span>
                        </div>
                      </div>
                      {steps?.map((item, idx) => (
                        <div key={idx} className="form-group">
                          {item?.step_no === currStep - 1 &&
                            item?.stepQuestions?.map((que, idz) => (
                              <div key={idz}>
                                {que.answer_type === "Checkbox" && (
                                  <>
                                    <label>
                                      {lang == "en"
                                        ? que.question
                                        : que.question_fr}{" "}
                                      ({que.question_type})
                                    </label>
                                    <div className="multi-select">
                                      {que?.options?.map((opt, id) => (
                                        <label
                                          className="custom_checkbox"
                                          key={id}
                                        >
                                          <input
                                            type="checkbox"
                                            name={`${idx + "" + id}`}
                                            checked={
                                              idx < formData.length &&
                                              formData[idx][
                                                `${idx + "" + id}`
                                              ] === opt.option_title
                                            }
                                            value={opt.option_title}
                                            onChange={(e) => {
                                              const newArray = [...formData];

                                              const data = {
                                                ...newArray[idx],
                                                [`${idx + "" + id}`]:
                                                  e.target.value,
                                              };

                                              newArray[idx] = data;
                                              setFormData(newArray);
                                              handleCartData(
                                                idx,
                                                idz,
                                                id,
                                                que.question,
                                                opt.q_id,
                                                e,
                                                que.question_type,
                                                "",
                                                "checkbox"
                                              );
                                            }}
                                          />
                                          <span className="checkmark"></span>
                                          <div className="property-bx">
                                            <div className="pr-icon">
                                              {opt.icon_image !== null ||
                                              opt.icon_image !== "undefined" ||
                                              opt.icon_image !== "" ? (
                                                <img
                                                  src={
                                                    IMAGE_PATH + opt.icon_image
                                                  }
                                                  alt="iconImg"
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="pr-name">
                                              <h5>
                                                {lang == "en"
                                                  ? opt.option_title
                                                  : opt.option_title_fr}
                                              </h5>
                                            </div>
                                          </div>
                                        </label>
                                      ))}
                                      {/* {que.question_type === "Mandatory" && (
                                        <span className="error_msg">
                                          Fill the mandatory field
                                        </span>
                                      )} */}
                                    </div>
                                  </>
                                )}
                                {que.answer_type === "Radio" && (
                                  <>
                                    <label>
                                      {lang == "en"
                                        ? que.question
                                        : que.question_fr}{" "}
                                      ({que.question_type}){" "}
                                    </label>
                                    {que?.options?.map((opt, iid) => (
                                      <>
                                        <label
                                          className="custom_radio"
                                          key={iid}
                                        >
                                          <input
                                            type="radio"
                                            name="group"
                                            checked={
                                              idx < formData.length &&
                                              formData[idx][
                                                `${idx + "" + iid}`
                                              ] === opt.option_title
                                            }
                                            value={opt.option_title}
                                            onChange={(e) => {
                                              const newArray = [...formData];
                                              const data = {
                                                ...newArray[idx],
                                                [`${idx + "" + iid}`]:
                                                  e.target.value,
                                              };

                                              newArray[idx] = data;
                                              setFormData(newArray);
                                              handleCartData(
                                                idx,
                                                idz,
                                                iid,
                                                que.question,
                                                opt.q_id,
                                                e,
                                                que.question_type,
                                                "",
                                                "radio"
                                              );
                                            }}
                                            // onChange={(e) => {
                                            //   setFieldRadio(e.target.value);
                                            // }}
                                          />
                                          <span className="checkmark"></span>
                                          <span className="heavy_cont">
                                            {lang == "en"
                                              ? opt.option_title
                                              : opt.option_title_fr}
                                          </span>
                                        </label>
                                      </>
                                    ))}
                                    {/* {que.question_type === "Mandatory" && (
                                      <span className="error_msg">
                                        Fill the mandatory field
                                      </span>
                                    )} */}
                                  </>
                                )}
                                {que.answer_type === "Textbox" && (
                                  <>
                                    <Form.Group className="form-group">
                                      <label>
                                        {lang == "en"
                                          ? que.question
                                          : que.question_fr}{" "}
                                        ({que.question_type}){" "}
                                      </label>
                                      <Form.Control
                                        placeholder=""
                                        // name={`{}`}
                                        onChange={(e) =>
                                          handleCartData(
                                            idx,
                                            idz,
                                            que.q_id,
                                            que.question,
                                            que.q_id,
                                            e,
                                            "",
                                            "input"
                                          )
                                        }
                                        required
                                      ></Form.Control>
                                      {/* {que.question_type === "Mandatory" && (
                                        <span className="error_msg">
                                          Fill the mandatory field
                                        </span>
                                      )} */}
                                    </Form.Group>
                                  </>
                                )}
                              </div>
                            ))}
                        </div>
                      ))}

                      <div className="step-find">
                        <Button
                          className="btn_primary"
                          onClick={() => handleFormSteps("pre")}
                        >
                          {t("Previous")}
                        </Button>
                        <Button
                          className="btn_primary"
                          onClick={() => {
                            console.log(
                              "onNexttttttttttt",
                              steps,
                              "steps",
                              currStep
                            );

                            const answs = heavyData[currStep - 2];
                            console.log(
                              answs,
                              "answsanswsanswsansws",
                              formData
                            );

                            const findQ = heavyData.filter(
                              (item) => item.stepNumber == currStep - 2
                            );

                            if (!findQ.length) {
                              return;
                            }
                            console.log(findQ, currStep - 2, "findQfindQ");
                            const isMan = findQ.filter(
                              (item) =>
                                item.answer.length == 0 && item.question_type
                            );
                            console.log(isMan, findQ, "isMan");
                            if (isMan.length) {
                              toast.error("Please fill the Mandatory fields!");
                              return console.log("Error ");
                            }

                            handleFormSteps("next", steps?.length);
                          }}
                        >
                          {t("Next")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "auto",
                    flexDirection: "column",
                  }}
                >
                  <h4>No Service Available</h4>
                  <Button className="btn_primary" onClick={() => navigate(-1)}>
                    Go Back
                  </Button>
                </div>
              ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default HeavyService;

import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import MapView from "../layout/MapView";
import Locate from "../assets/img/locate_ic.png";
import PriceRange from "../assets/img/price-range-img.png";
import mapStyle from "../assets/mapStyle";
import MultiRangeSlider from "./Component/MultiRangeSlider";
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import Map from "./Component/Map";
import toast, { Toaster } from "react-hot-toast";
import ReactDatePicker from "react-datepicker";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import {
  useJsApiLoader,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import ContactDetail from "./Component/modalComponents/ContactDetail";
import { useTranslation } from "react-i18next";

const Artisans = () => {
  const { t, i18n } = useTranslation();

  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: 48.7,
    lng: 32.4,
  });
  const [errmsg1, seterrmsg1] = useState();
  const [errmsg2, seterrmsg2] = useState();
  const [errmsg3, seterrmsg3] = useState();
  const [errmsg4, seterrmsg4] = useState();
  const [errmsg5, seterrmsg5] = useState();
  const [errmsg6, seterrmsg6] = useState();
  const [errmsg7, seterrmsg7] = useState(false);
  const [selectTime, setSelectTime] = useState();
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();

  const handleSelect = async (value) => {
    console.log(value, "ll");
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    const placeId = await geocodeByPlaceId();

    console.log(results[0]);

    console.log("ll", ll);
    setAddress(value);
    setCoordinates(ll);
  };
  const center = { coordinates };

  const [comman, setComman] = useState({
    loginModel: false,
    jobLocation: "Artisan Location",
    userLocation: "home",
    latitude: "",
    longitude: "",
    prodcessToCheckout: false,
  });
  const [house_number, setHouseNumber] = useState("");
  const [map, setMap] = useState();
  const [myLatLng, setMyLatLng] = useState({ lat: 48.8584, lng: 2.2945 });
  const [showContactDetails, setContactDetails] = useState(false);
  const [schedule, setSchedule] = useState("Right Now");

  const changeJobLocation = async (jobLocation) => {
    setComman({ ...comman, jobLocation: jobLocation });
    console.log(jobLocation);
  };

  const changeUserLocation = async (userLocation) => {
    setComman({ ...comman, userLocation: userLocation });
    console.log(userLocation);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setMyLatLng({
          lat: parseFloat(position.coords.latitude),
          lng: parseFloat(position.coords.longitude),
        });
      });
    }
  }, []);

  useEffect(() => {
    changeScheduling();
  }, []);

  const changeScheduling = async (scheduling) => {
    setComman({ ...comman, scheduling: scheduling });
    // console.log(scheduling);
    if (scheduling === "Later") {
      console.log("adasdasdasdasd");
    }
  };

  const onScheduling = (e) => {
    const { value } = e.target;
    setSchedule(value);
    setComman({ ...comman, value: value });
    console.log(value);
  };

  const onSubmitClick = async (e) => {
    e.preventDefault();
    const path = apiPath.addAddress;
    let formData = new FormData();
    formData.append("type", comman.jobLocation);
    formData.append("house_number", house_number);
    formData.append("address", address);
    formData.append("latitude", coordinates.lat);
    formData.append("longitude", coordinates.lng);
    formData.append("is_default", "1");
    formData.append("tag", comman.userLocation);

    const options = {
      method: "POST",
      data: formData,
    };
    if (address !== "" && address !== null && address !== "undefined") {
      try {
        let res = await Http(path, options);
        if (res.data.success === true) {
          setContactDetails(true);
          toast.success(res.data.msg);
        } else {
          toast.error(res.data.msg);
        }
      } catch (err) {
        console.log(err.res);
      }
    } else {
      seterrmsg1("Please select job location!");
      seterrmsg3("Building name required!");
      seterrmsg4("Street address required!");
      seterrmsg5("Schedule time is required!");
      seterrmsg6("Please select the service date!");
      seterrmsg2("Please select the service time!");
    }
  };

  const onTimeSelect = (e) => {
    const { value } = e.target;
    setSelectTime(value);
    console.log(value);
  };

  const timeSlot = [
    { time: "06:00-07:00", duration: "06:00 To 07:00" },
    { time: "07:00-08:00", duration: "07:00 To 08:00" },
    { time: "08:00-09:00", duration: "08:00 To 09:00" },
    { time: "09:00-10:00", duration: "09:00 To 10:00" },
    { time: "10:00-11:00", duration: "10:00 To 11:00" },
    { time: "11:00-12:00", duration: "11:00 To 12:00" },
    { time: "12:00-13:00", duration: "12:00 To 13:00" },
    { time: "13:00-14:00", duration: "13:00 To 14:00" },
    { time: "14:00-15:00", duration: "14:00 To 15:00" },
    { time: "15:00-16:00", duration: "15:00 To 16:00" },
    { time: "16:00-17:00", duration: "16:00 To 17:00" },
    { time: "17:00-18:00", duration: "17:00 To 18:00" },
    { time: "18:00-19:00", duration: "18:00 To 19:00" },
    { time: "19:00-20:00", duration: "19:00 To 20:00" },
  ];

  return (
    <>
      {showContactDetails && <ContactDetail />}
      <Toaster position="top-right" />
      <section className="artisans-sec">
        <div className="container">
          <div className="artisans-box">
            <h4>{t("Location")}</h4>
            <p>{t("Wherethejobneedstobeperformed")} </p>
            <div className="artisans_radioBtn">
              <form>
                <div className="radio_cls">
                  <label className="custom_radio">
                    <span className="error_msg">{errmsg1}</span>
                    <input
                      type="radio"
                      name="shipping_type"
                      onClick={() => changeJobLocation("Artisan Location")}
                      checked={
                        comman.jobLocation == "Artisan Location" ? true : false
                      }
                    />
                    Artisans Place
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom_radio">
                    <input
                      type="radio"
                      name="shipping_type"
                      onClick={() => changeJobLocation("Own Place")}
                      checked={comman.jobLocation == "Own Place" ? true : false}
                    />
                    Own Place
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
            </div>
            <div className="your_location_wrap">
              <h5>Your Location</h5>
              <form>
                <div className="radio_cls">
                  <label className="custom_radio">
                    <input
                      type="radio"
                      name="shipping_type"
                      onClick={() => changeUserLocation("home")}
                      checked={comman.userLocation == "home" ? true : false}
                    />
                    Home
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom_radio">
                    <input
                      type="radio"
                      name="shipping_type"
                      onClick={() => changeUserLocation("office")}
                      checked={comman.userLocation == "office" ? true : false}
                    />
                    Office
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom_radio">
                    <input
                      type="radio"
                      name="shipping_type"
                      onClick={() => changeUserLocation("other")}
                      checked={comman.userLocation == "other" ? true : false}
                    />
                    Other
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
            </div>
            <p></p>
            <div className="addressDetails">
              <Row>
                <Col md="6">
                  <Form.Group className="form-group">
                    <label htmlFor="buildingNum">Building Name & Number</label>
                    <input
                      type="text"
                      placeholder="Building Name"
                      className="form-control"
                      value={house_number}
                      onChange={(e) => setHouseNumber(e.target.value)}
                    />
                    <span className="error_msg">{errmsg3}</span>
                  </Form.Group>
                </Col>
                <Col md="6">
                  {/* <Form.Group className="form-group">
                  <label htmlFor="">Street Address</label>
                  <input type="text" className="form-control" />
                </Form.Group> */}

                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                    fields={["address_components", "opening_hours", "geometry"]}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <Form.Group className="form-group">
                          <label htmlFor="streetAddress">Street Address</label>

                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "form-control",
                            })}
                          />
                          <span className="error_msg">{errmsg4}</span>
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </Form.Group>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Col>
              </Row>
            </div>
            <div className="orSection">
              <span className="orSectionLine">OR</span>
            </div>
            <div className="mapPinLocation">
              <div className="map-title">
                <h5>Pin your Location</h5>
                <h6 onClick={() => map?.panTo(myLatLng)}>
                  <span className="locate_icon">
                    <img src={Locate} />
                  </span>
                  <span>Locate yourself</span>
                </h6>
              </div>
              <div className="map_card">
                <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API} // Replace with your own API key
                  libraries="places"
                >
                  <GoogleMap
                    center={coordinates}
                    zoom={20}
                    mapContainerClassName="googleMap"
                    onLoad={(map) => setMap(map)}
                    options={{ styles: mapStyle.light }}
                  >
                    <Marker position={coordinates} />
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
            <div className="your_location_wrap">
              <h5>Schedule Time</h5>
              <form>
                <div className="radio_cls">
                  <label className="custom_radio">
                    <span className="error_msg">{errmsg5}</span>
                    <input
                      type="radio"
                      name="shipping_type"
                      value="Right Now"
                      checked={schedule === "Right Now"}
                      onChange={onScheduling}
                    />
                    Right Now
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom_radio">
                    <input
                      type="radio"
                      name="shipping_type"
                      value="Later"
                      checked={schedule === "Later"}
                      onChange={onScheduling}
                    />
                    Later
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
            </div>
            {schedule === "Later" ? (
              <>
                <div className="addressDetails">
                  <div className=""></div>
                  <Row>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <label htmlFor="">Specific Date</label>
                        <ReactDatePicker
                          className="form-control"
                          selected={date}
                          onChange={(date) => setDate(date)}
                          dateFormat="MM/dd/yyyy"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          name="date"
                        />
                        <span className="error_msg">{errmsg6}</span>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <label htmlFor="">Specific Time</label>
                        <input
                          type="time"
                          placeholder="05:00 pm - 08:30 pm"
                          className="form-control"
                        />
                        <span className="error_msg">{errmsg2}</span>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <Col md="12">
                  <h5>Time Slot</h5>
                  <div className="time-slot">
                    {timeSlot.map((item, idx) => (
                      <label className="time-label" key={idx}>
                        <input
                          type="radio"
                          id="radio"
                          value={item.time}
                          checked={selectTime === item.time}
                          onChange={onTimeSelect}
                        />
                        <span className="checkmark">{item.duration}</span>
                      </label>
                    ))}
                    {errmsg7 && (
                      <span className="error_msg">
                        {"Pleas select a time slot!"}
                      </span>
                    )}
                  </div>
                </Col>
                <div className="price-range-sec">
                  <h5>Price</h5>
                  <div className="range_slider">
                    <MultiRangeSlider
                      min={0}
                      max={1000}
                      onChange={({ min, max }) =>
                        console.log(`min = ${min}, max = ${max}`)
                      }
                    />
                  </div>
                </div>

                <div className="your_location_wrap">
                  <form>
                    <div className="radio_cls">
                      <label className="custom_radio">
                        <input type="radio" id="radio" value="other" />
                        Include Freelancers as well.
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              ""
            )}
            <Button className="btn_primary" onClick={onSubmitClick}>
              Look For Experts & Freelancers
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Artisans;

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import { useLocation, useNavigate } from "react-router-dom";

import ContactDetail from "./Component/modalComponents/ContactDetail";
import { ChangeAddress } from "./Checkout";
import { useTranslation } from "react-i18next";

// const center = { lat: 48.8584, lng: 2.2945 };
const Address = () => {
  const { t, i18n } = useTranslation();

  const [openContactDetail, setOpenContactDetail] = useState(false);
  const [modal, setModal] = useState(false);
  const [allAddress, setAllAddress] = useState();
  const [selectAddress, setSelectAddress] = useState();
  const [showAddress, setShowAddress] = useState(false);
  const [addId, setAddId] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    getAllAddress();
  }, []);

  console.clear();
  console.log("state", state);

  const setDefaultAddress = async (id) => {
    // update-default-address
    setAddId(id);
    let path = apiPath.setDefaultAddress;
    let data = {
      address_id: `${id}`,
    };
    let options = {
      method: "POST",
      data: data,
    };
    if (id !== null || id !== "undefined" || id !== "null") {
      try {
        let res = await Http(path, options);
        console.log(res?.data);
        if (res.data.success === true) {
          toast.success(res.data.msg);
          getAllAddress();
        } else {
          toast.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const userToken = localStorage.getItem("userToken");

  const getAllAddress = async () => {
    let path = apiPath.getAddress;
    let options = {
      method: "POST",
    };
    try {
      let res = await Http(path, options);
      setAllAddress(res?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  const openContact = () => {
    if (userToken !== null || userToken !== undefined || userToken !== "") {
      setShowAddress(false);
      setOpenContactDetail(true);
    } else {
      toast.error("Login Required!");
    }
    // onHide();
  };

  const closeContact = () => {
    setOpenContactDetail(false);
  };

  let data = allAddress?.filter((idx) => idx.is_default === "1");
  let getAddressId = data?.map((elm) => elm);
  let getAddId = getAddressId?.find((elm) => elm?.id);
  let myAddId = getAddId ? getAddId?.id : null;

  return (
    <>
      {openContactDetail && (
        <ContactDetail
          categoryId={state?.category_id}
          serviceId={""}
          addressId={myAddId}
          onShow={openContact}
          onHide={closeContact}
        />
      )}
      <Toaster position="top-right" />
      <section className="artisans-sec">
        <div className="container">
          <div className="artisans-box">
            <h4>{t("Location")}</h4>
            <p>{t("Wherethejobneedstobeperformed")} </p>
            <div className="addressDetails">
              <Row>
                <Col md="12">
                  <div className="manage_address_inner">
                    {allAddress?.map((item, idx) => (
                      <div className="manage_address_listing" key={idx}>
                        <div className="single_address">
                          <h5 style={{ textTransform: "capitalize" }}>
                            {item.tag}
                          </h5>
                          <p>
                            {item.house_number}, {item.address}
                          </p>
                          <div className="select-address">
                            <label className="custom_radio">
                              <input
                                type="radio"
                                checked={item?.is_default === "1"}
                                value={selectAddress}
                                onChange={(e) => {
                                  setSelectAddress(e.target.value);
                                  setDefaultAddress(item.id);
                                }}
                                name="radio"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="add_new_address add_address mt-4">
                      <Button
                        className="btn_primary mb-4"
                        onClick={() =>
                          userToken !== null ||
                          userToken !== undefined ||
                          userToken !== ""
                            ? setModal(true)
                            : toast.error("Login Required!")
                        }
                      >
                        Add New Address
                      </Button>
                      <Button className="btn_primary" onClick={openContact}>
                        Continue
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {modal && (
              <ChangeAddress
                show={modal}
                hide={() => setModal(false)}
                selectedAddress={selectAddress}
                setSelectedAdress={setSelectAddress}
                onContinue={openContact}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Address;

import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Star from "../../assets/img/star_icon.png";
import Like from "../../assets/img/like.png";
import apiPath, { IMAGE_PATH } from "../../constants/apiPath";
import Http from "../../services/Http";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import MultiRangeSlider from "../Component/MultiRangeSlider";
import GMap from "../Component/GMap";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const SelectTime = () => {
  const { t, i18n } = useTranslation();
  const [schedule, setSchedule] = useState("Right Now");
  const [selectTime, setSelectTime] = useState();
  const [artisanList, setArtisanList] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showartisanList, setShowArtisanList] = useState();
  const [timeSlotError, setTimeSlotError] = useState();
  const [minRate, setMinRate] = useState();
  const [maxRate, setMaxRate] = useState();
  const [favArtisan, setFavArtisan] = useState(false);
  const [favVal, setFavVal] = useState(true);
  const [timeSlot, setTimeSlot] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const { state } = useLocation();

  const { heavyOrderData, item, email, mobile, emailCheck, mobileCheck } =
    state;
  const {
    categoryID,
    catId,
    subCatId,
    subCategoryID,
    heavyData,
    postalCode,
    serviceId,
  } = heavyOrderData;
  console.log("44", email, emailCheck, mobile, mobileCheck, params.id);

  useEffect(() => {
    console.log("date", date);
    getTimeSlots(date);
  }, [date]);

  const getTimeSlots = async (date) => {
    let path = apiPath.timeSlots;
    var formData = new FormData();
    formData.append("service_date", moment(date).format("YYYY-MM-DD"));
    let options = {
      method: "POST",
      data: formData,
    };
    try {
      let res = await Http(path, options);
      if (res.data.success === true) {
        const time = convertArray(res.data.result);
        setTimeSlot(time);
      } else {
        console.log(res.data.msg);
      }
    } catch (err) {
      console.log(err.res);
    }
  };

  const handleSubmit = () => {
    if (selectTime?.length > 0) {
      setTimeSlotError(false);
      navigate("/heavy-order-checkout", {
        state: {
          artisanData: item ? item : "",
          scheduling: "Later",
          categoryId: catId ? catId : categoryID,
          subCategoryId: subCatId ? subCatId : subCategoryID,
          heavyData: heavyData ? heavyData : "",
          artisanId: params ? params.id : "",
          postalCode: postalCode ? postalCode : "",
          date: moment(date).format("yyyy-MM-DD"),
          time_slot: selectTime ? selectTime : "",
          email: email ? email : "",
          mobile: mobile ? mobile : "",
          isEmail: emailCheck ? emailCheck : "",
          isMobile: mobileCheck ? mobileCheck : "",
          serviceId: serviceId ? serviceId : null,
        },
      });
    } else {
      setTimeSlotError(true);
    }
  };

  const userToken = localStorage.getItem("userToken");

  const onRightnowClick = async () => {
    setArtisanList(true);
  };
  const onScheduling = (e) => {
    const { value } = e.target;
    console.log("value", value);
    setSchedule(value);
    setArtisanList(false);
  };

  const onTimeSelect = (e) => {
    const { value } = e.target;
    setSelectTime(value);
    console.log(value);
  };

  const artisanLat = showartisanList?.map((item) => {
    const latLng = [];
    latLng?.push(item?.latitude);
    latLng?.push(item?.longitude);
    return latLng;
  });

  console.log("lat", artisanLat);
  // console.log("lng", artisanLng);

  return (
    <section className="select-time-sec">
      <Toaster position="top-right" />
      <div className="container">
        <div className="selecttime_main">
          <div className="time_map_left">
            <h4>{t("when_do_you_want_service")}</h4>
            <div className="artisans_radioBtn">
              <form>
                <div className="radio_cls">
                  <label className="custom_radio">
                    {" "}
                    <input
                      type="radio"
                      id="radio"
                      value="Right Now"
                      onClick={onRightnowClick}
                      checked={schedule === "Right Now"}
                      onChange={onScheduling}
                    />
                    {t("Today")}
                    <span className="checkmark"></span>
                  </label>
                  <label className="custom_radio">
                    <input
                      type="radio"
                      id="radio"
                      value="Later"
                      checked={schedule === "Later"}
                      onChange={onScheduling}
                    />
                    {t("Later")}
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
            </div>
            {schedule === "Later" && (
              <div className="time-select-slot">
                <Row>
                  <Col md="12">
                    <Form.Group className="form-group">
                      <label htmlFor="">{t("specific_date")}</label>
                      <DatePicker
                        showIcon
                        className="form-control"
                        showYearDropdown
                        selected={date}
                        onChange={(date) => setDate(date)}
                        minDate={moment().toDate()}
                        dateFormat="dd-MM-yyyy"
                      />
                    </Form.Group>
                  </Col>

                  <Col md="12">
                    <h5>{t("time_slot")}</h5>
                    <div className="time-slot">
                      {timeSlot.map((item, idx) => (
                        <label className="time-label" key={idx}>
                          <input
                            type="radio"
                            id="radio"
                            value={item.time}
                            checked={selectTime === item.time}
                            onChange={onTimeSelect}
                          />
                          <span className="checkmark">{item.duration}</span>
                        </label>
                      ))}
                      {timeSlotError && (
                        <span className="error_msg">
                          {"Pleas select a time slot!"}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div className="time_list_wrap mb-4">
            <div className="time_bg_wrap">
              {schedule === "Later" && (
                <div className="login-btn">
                  <Button className="btn_primary" onClick={handleSubmit}>
                    {t("Submit")}
                  </Button>
                </div>
              )}
              {schedule === "Right Now" && (
                <div className="login-btn">
                  <Link
                    to={`/heavy-order-checkout`}
                    state={{
                      artisanData: item ? item : "",
                      scheduling: "Right Now",
                      categoryId: catId ? catId : categoryID,
                      subCategoryId: subCatId ? subCatId : subCategoryID,
                      heavyData: heavyData ? heavyData : "",
                      artisanId: params ? params.id : "",
                      postalCode: postalCode ? postalCode : "",
                      email: email ? email : "",
                      mobile: mobile ? mobile : "",
                      isEmail: emailCheck ? emailCheck : "",
                      isMobile: mobileCheck ? mobileCheck : "",
                      serviceId: serviceId ? serviceId : null,
                    }}
                  >
                    <Button className="btn_primary">{t("conitnue")}</Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function convertArray(givenArray) {
  const newArray = givenArray.map((item) => {
    const time = `${item.from}-${item.to}`;
    const duration = `${item.from} To ${item.to}`;
    return { time, duration };
  });

  return newArray;
}

export default SelectTime;

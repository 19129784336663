import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import CouponIcon from "../assets/img/coupon-1.png";
import ArrowRight from "../assets/img/rightarrow.png";
import WalletIcon from "../assets/img/wallet-1.png";
import UserSidebar from "./UserSidebar";
import apiPath from "../constants/apiPath";
import Http from "../services/Http";
import { Toaster, toast } from "react-hot-toast";

const center = { lat: 48.8584, lng: 2.2945 };

const Wallet = () => {
  const [wallet, setWallet] = useState();
  const [transaction, setTransaction] = useState();

  useEffect(() => {
    getWalletData();
  }, []);

  const getWalletData = async () => {
    let path = apiPath.getWalletData;
    let options = { method: "GET" };

    try {
      let res = await Http(path, options);
      const { recentTrans, balance } = res?.data?.result;
      if (res?.data?.success === true) {
        // toast.success(res?.data?.msg);
        setWallet(balance);
        setTransaction(recentTrans);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="order-main">
        <Toaster position="top-right" />
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <UserSidebar />
            </div>
            <div className="account-sidebar-right">
              <div className="m_sidebar_bg">
                <div className="wallet-top">
                  <div className="wallet_left">
                    <div className="wallet_left-inner">
                      <span className="wallet-icon">
                        <img src={WalletIcon} alt="" />
                      </span>
                      <div className="wallet-amount">
                        <h4>Toctocme Cash</h4>
                        <h3>€ {wallet}</h3>
                        <p>Applicable on all services</p>
                      </div>
                    </div>
                  </div>
                  <div className="wallet_coupon_option">
                    <div className="coupon-icon">
                      <img src={CouponIcon} alt="" />
                    </div>
                    <div className="copuon_cont">
                      <h5>
                        Coupons{" "}
                        <span className="right-arrow">
                          <img src={ArrowRight} alt="" />
                        </span>
                      </h5>
                      <p>Special deals only for you</p>
                    </div>
                  </div>
                </div>
                <div className="wallet-history">
                  <h3>Wallet History</h3>
                  <div className="wallet_history_listing">
                    {transaction?.length > 0 ? (
                      transaction?.map((trans, idx) => (
                        <div className="single-history">
                          <div className="histroy-title">
                            <h5>Paid for home cleaning</h5>
                            <h6>€ 20.00</h6>
                          </div>
                          <h6>User Paid</h6>
                          <p>15-05-2022, 05:00 pm</p>
                        </div>
                      ))
                    ) : (
                      <div className="single-history">
                        <div className="histroy-title">
                          <h5>No Transaction made yet!</h5>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Wallet;

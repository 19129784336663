import { toast } from "react-hot-toast";
import Http from "../../services/Http";
import apiPath from "../../constants/apiPath";

export const stripePaymentMethodHandler = async (data, cb) => {
  const { amount, orderNo, result, notifyId } = data;
  if (result.error) {
    // show error in payment form
    cb(result);
  } else {
    console.log("data", data);
    const paymentResponse = await stripePayment({
      trans_id: result.paymentMethod.id,
      amount: amount,
      orderNo: orderNo,
      type: "Online",
      notifyId: `${notifyId}`,
    });
    console.log(paymentResponse);
    cb(paymentResponse);
  }
};

// place backend API call for payment
const stripePayment = async (data) => {
  let path = apiPath.payNow;
  let options = { method: "POST", data };
  try {
    const res = await Http(path, options);
    if (res?.data?.success === true) {
      toast.success(res?.data?.msg);
      setTimeout(() => {
        window.location.href = "/notification";
      }, 2000);
    } else {
      toast.success(res?.data?.msg);
    }
  } catch (error) {
    console.log(error);
  }
};

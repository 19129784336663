import React, { useEffect, useState } from "react";
import { Form, Modal, Toast } from "react-bootstrap";
import Profile from "./Profile";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import toast, { Toaster } from "react-hot-toast";
import Imgcross from "../../../assets/img/cross.png";
import Signup from "./Signup";
import Login from "./Login";
// import { internalIpV4 } from "internal-ip";
import { useNavigate } from "react-router-dom";
// import { firebaseConfig } from "../../../firebase/firebaseConfig";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { initializeApp } from "firebase/app";

const PasswordSet = ({
  openPassword,
  show,
  hide,
  closePassword,
  onClose,
  showOtp,
  showLogin,
  showProfile,
  ...props
}) => {
  // const [showPassword, setShowPassword] = useState(true);

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCnfPwd, setIsRevealCnfPwd] = useState(false);
  // const [fcmToken, setFcmToken] = useState();
  const [openProfile, setOpenProfile] = useState(false);
  const [ip, setIP] = useState("");

  const getIpv4 = async () => {
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      console.log(data.ip);
      setIP(data.ip);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getIpv4();
  }, []);

  // useEffect(() => {
  //   askForPermission();
  // }, []);

  // const askForPermission = async () => {
  //   const app = initializeApp(firebaseConfig);

  //   // Get Firebase messaging instance
  //   const messaging = getMessaging(app);

  //   // Request permission to receive notifications
  //   Notification.requestPermission()
  //     .then((permission) => {
  //       if (permission === "granted") {
  //         console.log("Notification permission granted.");

  //         // Get FCM token
  //         getToken(messaging, {
  //           vapidKey:
  //             "BLLdG9h5WrLrY5aPKL881CBET6SZAu8JKdkt8PXo7Wn8Em2KOsweAQCmFbAN546ye0e1reizzD8ZqErmZuGUaRM",
  //         })
  //           .then((token) => {
  //             console.log("FCM token:", token);
  //             setFcmToken(token);
  //           })
  //           .catch((error) => {
  //             console.log("Error getting FCM token:", error);
  //           });

  //         // Listen for incoming messages
  //         onMessage(messaging, (payload) => {
  //           console.log("Incoming message:", payload);
  //         });
  //       } else {
  //         console.log("Notification permission denied.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error requesting notification permission:", error);
  //     });
  // };

  const navigate = useNavigate();

  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
    setError(validate(input));
  };

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const validate = (value) => {
    const error = {};
    const regex = /^.{6,}$/g;
    if (!value.password?.length) {
      error.password = "Please enter Password.";
    }
    if (!regex.test(value.password)) {
      error.password = "Must contain at 6 character.";
    }
    if (!value.confirmPassword?.length) {
      error.confirmPassword = "Please enter Confirm Password.";
    } else if (value.password !== value.confirmPassword) {
      error.confirmPassword = "Password and Confirm Password does not match.";
    }
    return error;
  };

  const onSubmit = async (e) => {
    console.log(input);
    e.preventDefault();

    setError(validate(input));
    if (
      input.password &&
      input.confirmPassword !== "" &&
      input.password === input.confirmPassword
    ) {
      if (props.type === "forgot") {
        let path = apiPath.forgotPassword;
        let data = {
          type: "mobile",
          user_type: "2",
          password: input.password,
          mobile: props.mobile,
          country_code: props.cc,
        };
        let options = {
          method: "POST",
          data,
        };
        try {
          let res = await Http(path, options);

          if (res.data.success === true) {
            toast.success(res.data.msg);
            const { token } = res.data;

            navigate("/", true);
            // setShowProfile(true);
            console.log(props.type, "--------___________");
            if (props.type === "forgot") {
              handleClose();
              // setShowLogin(true)
            } else {
              toast.error(res.data.msg);
              openPassword();
            }
          } else {
            toast.error(res.data.msg);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        let path = apiPath.setPassword;
        var formData = new FormData();
        formData.append("type", props.type);
        formData.append("verify_type", "mobile");
        formData.append("mobile", props.mobile);
        formData.append("otp", props.otp);
        formData.append("password", input.password);
        formData.append("country_code", props.cc);
        formData.append("ip", ip.toString());
        formData.append("fcm_token", null);
        let options = {
          method: "POST",
          data: formData,
        };
        try {
          let res = await Http(path, options);

          if (res.data.success === true) {
            toast.success(res.data.msg);
            const { token } = res.data;

            navigate("/", true);
            // setShowProfile(true);
            console.log(props.type, "--------___________");
            if (props.type === "forgot") {
              handleClose();
              // setShowLogin(true)
            } else {
              localStorage.setItem("guestToken", "");
              localStorage.setItem("userToken", token);
              navigate("complete-profile", { state: input.password });
            }
          } else if (res.data.success === false) {
            // "Invalid OTP. Please try again."
            toast.error(res.data.msg);
            closePassword();
            showOtp();
          } else {
            toast.error(res.data.msg);
            openPassword();
          }
        } catch (err) {
          console.log(err);
          console.log("api hit but some error appeared");
        }
      }
    }
  };

  const handleClose = () => {
    if (props.type === "forgot") {
      return showLogin();
    }
    closePassword();
    onClose();
  };

  return (
    <>
      {openProfile === true && (
        <Profile
          pass={input.password}
          onClose={() => {
            setOpenProfile(false);
            hide();
          }}
        />
      )}
      <Toaster position="top-right" />
      <Modal
        show={show}
        onHide={hide}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 id="exampleModalLabel">Set password</h5>
          <button type="button" className="btn-close" onClick={hide}>
            <img src={Imgcross} onClick={hide} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_cont">
            {/* <h4>Welcome!</h4> */}
            {/* <h5>Set Password</h5> */}
            <p> Set password of your own choice for future security purpose</p>
          </div>
          <Form.Group className="form-group">
            <div className="pw-cls">
              <input
                type={isRevealPwd ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                value={input.password}
                onChange={onInputChange}
                // onBlur={validateInput}
                onKeyUp={validateInput}
                className="form-control"
              />
              <button
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
                className="btn-dark"
              >
                {isRevealPwd ? "hide" : "show"}
              </button>
            </div>
            {error.password && (
              <span className="error_msg">{error.password}</span>
            )}
          </Form.Group>
          <Form.Group className="form-group">
            <div className="pw-cls">
              <input
                type={isRevealCnfPwd ? "text" : "password"}
                name="confirmPassword"
                placeholder="Enter Confirm Password"
                value={input.confirmPassword}
                onChange={onInputChange}
                onKeyUp={validateInput}
                className="form-control"
              />
              <button
                onClick={() => setIsRevealCnfPwd((prevState) => !prevState)}
                className="btn-dark"
              >
                {isRevealCnfPwd ? "hide" : "show"}
              </button>
            </div>

            {error.confirmPassword && (
              <span className="error_msg">{error.confirmPassword}</span>
            )}
          </Form.Group>
          <div className="login-btn">
            <button className="continue-btn d-block" onClick={onSubmit}>
              Continue
            </button>
          </div>

          <div className="row">
            <div className="col-sm-4"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PasswordSet;

// import React, { useState, useEffect } from "react";

// import { Modal, Form, Button } from "react-bootstrap";

// const PasswordSet = (Props) => {
//   const [showPassword, setShowPassword] = useState(true);
//   const [isRevealPwd, setIsRevealPwd] = useState(false);
//   const [isRevealCnfPwd, setIsRevealCnfPwd] = useState(false);

//   const handleClose = () => setShowPassword(false);
//   const passwordClose = () => setShowPassword(false);

//   const handleClick = () => {
//     // 👇️ toggle isActive state on click
//     setShowProfile(true);
//     setShowPassword(false);
//     console.log("you just clicked");
//   };

//   return (
//     <>
//       {showProfile ? <Profile /> : ""}

//       <Modal
//         show={showPassword}
//         onHide={passwordClose}
//         animation={true}
//         size="lg"
//         backdrop="static"
//         keyboard={false}
//         dialogClassName="modalStyle"
//         centered
//       >
//         <div className="py-3 px-4 container modal-container ">
//           <div className="d-flex justify-content-between">
//             <span className="d-block m-3 ">Set password</span>
//             <i className="bi bi-plus-circle close-icon" onClick={handleClose}></i>
//           </div>

//           <span className="fw-bold d-block m-3">Welcome John !</span>
//           <span className="  fs-3 d-block m-3  fw-light">Set Password</span>
//           <span className="d-block m-3 text-light">
//             Set password of your own choice for future security purpose
//           </span>
//           <label className="d-block m-3 fw-bold">Password</label>
//           <div className="mob-input d-flex m-3">
//             <input
//               type={isRevealPwd ? "text" : "password"}
//               className="w-100 border-0"
//               placeholder="Password"
//             />
//             <button
//               onClick={() => setIsRevealPwd((prevState) => !prevState)}
//               className="btn-dark"
//             >
//               {isRevealPwd ? "hide" : "show"}
//             </button>
//           </div>
//           <label className="d-block m-3 fw-bold">Confirm Password</label>
//           <div className="mob-input d-flex m-3">
//             <input
//               type={isRevealCnfPwd ? "text" : "password"}
//               className="w-100 border-0"
//               placeholder="Confirm Password"
//             />
//             <button
//               onClick={() => setIsRevealCnfPwd((prevState) => !prevState)}
//               className="btn-dark"
//             >
//               {isRevealCnfPwd ? "hide" : "show"}
//             </button>
//           </div>

//           <div className="m-3">
//             <button className="continue-btn d-block" onClick={handleClick}>
//               Continue
//             </button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default PasswordSet;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { I18nextProvider } from "react-i18next";
// import store from "./store/index";
import { GoogleOAuthProvider } from "@react-oauth/google";
import i18n from "./localization/i18n";
import store from "./store";

let dir = localStorage.getItem("current_lang")
  ? localStorage.getItem("current_lang") === "ar"
    ? "rtl"
    : "ltr"
  : "";
let lang = localStorage.getItem("current_lang")
  ? localStorage.getItem("current_lang")
  : "en";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="1034075103112-542grscgem6fuics3l8o0su8fg8449lc.apps.googleusercontent.com">
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </GoogleOAuthProvider>
  </Provider>
);
document.getElementsByTagName("html")[0].setAttribute("dir", dir);
document.getElementsByTagName("html")[0].setAttribute("lang", lang);

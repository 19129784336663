import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import { stripePaymentMethodHandler } from "../Component/payment";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "45px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
        background: "#fff",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export default function CheckoutForm(props) {
  //   const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setErrorMsg("");
    const paymentMethodObj = {
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email,
      },
    };
    const paymentMethodResult = await stripe.createPaymentMethod(
      paymentMethodObj
    );
    stripePaymentMethodHandler(
      {
        result: paymentMethodResult,
        notifyId: props?.notifyId,
        amount: props?.price,
        orderNo: props?.cartId,
      },
      handleResponse
    );
    if (errorMsg === " ") {
    }
  };

  // const payNow = async (order_no) => {
  //   const path = apiPath.payNow;
  //   const data = {
  //     type: "Offline",
  //     orderNo: order_no ? order_no : "",
  //     notifyId: props?.notifyId,
  //   };
  //   const options = {
  //     method: "POST",
  //     data,
  //   };

  //   try {
  //     let res = await Http(path, options);
  //     if (res?.data?.success === true) {
  //       toast.success(res?.data?.msg);
  //       navigate("/my-orders");
  //     } else {
  //       toast.error(res?.data?.msg);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // callback method to handle the response
  const handleResponse = (response) => {
    if (response?.error) {
      setErrorMsg(response?.error?.message);
      return;
    }
    props.setPaymentCompleted(response?.success ? true : false);
  };

  return (
    <React.Fragment>
      <Toaster position="top-right" />
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Pay with card</span>
      </h4>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-name">Name on card</label>
            <input
              id="cc-name"
              type="text"
              className=""
              value={name}
              placeholder="John Doe"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {/* <div className="col-md-6 mb-3">
            <label htmlFor="cc-email">Email</label>
            <input
              id="cc-email"
              type="text"
              className=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div> */}
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card Number</label>
            <CardNumberElement
              id="cc-number"
              className="bg-white p-2 pt-0 pb-0 rounded"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="expiry">Expiration Date</label>
            <CardExpiryElement
              id="expiry"
              className="bg-white p-2 pt-0 pb-0 rounded"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="cvv">CVV</label>
            <CardCvcElement
              id="cvv"
              className="bg-white p-2 pt-0 pb-0 rounded"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <button className="btn btn_primary" type="submit">
          <hr className="mb-4" />
          PAY €{props.price}
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
      </form>
    </React.Fragment>
  );
}

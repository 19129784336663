import { parseISO, formatDistanceToNow } from "date-fns";
import React, { useState, useEffect } from "react";
import { Button, Container, Dropdown } from "react-bootstrap";
import Dots from "../assets/img/dots.png";
import UserSidebar from "./UserSidebar";
import apiPath, { IMAGE_PATH } from "../constants/apiPath";
import Http from "../services/Http";
import { Toaster, toast } from "react-hot-toast";
import DeleteIcon from "../assets/img/delete-1.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Skeleton } from "antd";

const Notification = () => {
  const [notificationList, setNotificationList] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getNotification();
  }, []);

  const getNotification = async () => {
    let path = apiPath.notificationList;
    let options = { method: "GET" };

    try {
      let res = await Http(path, options);
      const { result } = res?.data;
      if (res?.data?.success === true) {
        // toast.success(res?.data?.msg);
        setNotificationList(result);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        toast.error(res?.data?.msg);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  console.log(notificationList);

  const handleReadAll = async (orderId) => {
    const path = apiPath.readAllNotification;
    const data = {
      id: `${orderId}` === "All" ? "All" : `${orderId}`,
    };
    const options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      const { result } = res?.data;
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setNotificationList(result);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleUnreadAll = async () => {
  //   const path = apiPath.unreadAllNotification;
  //   const options = { method: "GET" };
  //   try {
  //     let res = await Http(path, options);
  //     const { result } = res?.data;
  //     if (res?.data?.success === true) {
  //       toast.success(res?.data?.msg);
  //       setNotificationList(result);
  //     } else {
  //       toast.error(res?.data?.msg);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDeleteAll = async () => {
    const path = apiPath.deleteAllNotification;
    const data = { ID: "All" };
    const options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      const { result } = res?.data;
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setNotificationList(result);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let timeAgo = "";
  const timeFormate = (timestamp) => {
    if (timestamp) {
      const date = parseISO(timestamp);
      const timePeriod = formatDistanceToNow(date);
      timeAgo = `${timePeriod} ago`;
    }
    return timeAgo;
  };

  const payNow = async (order_no) => {
    const path = apiPath.payNow;
    const data = {
      type: "Online",
      orderNo: order_no ? order_no : "",
    };
    const options = {
      method: "POST",
      data,
    };

    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        navigate("/my-orders");
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = (id) => {
    console.log("notification_id", id);
    Swal.fire({
      title: "Delete Notification!",
      text: "Do you really  want to delete this notification?",
      icon: "warning",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result?.isConfirmed === true) {
        deleteNotification(id);
      } else {
        Swal.fire("Cancelled", "", "error");
      }
    });
  };

  const deleteNotification = async (id) => {
    // console.log("address_id", id);

    let path = apiPath.deleteNotification;
    let data = {
      ID: `${id}`,
    };
    let options = {
      method: "POST",
      data: data,
    };

    if (id !== null || id !== "undefined" || id !== "null") {
      try {
        let res = await Http(path, options);
        if (res.data.success === true) {
          toast.success(res.data.msg);
          getNotification();
        } else {
          toast.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateOrderStatus = async (orderNo, val, notifyId) => {
    let path = apiPath.updateOrderStatus;
    let data = {
      statusVal: val ? val : "",
      orderNo: orderNo ? orderNo : "",
      notifyId: notifyId ? notifyId : "",
    };
    let options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        getNotification();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="order-main">
        <Toaster position="top-right" />
        <Container>
          <div className="my-account-inner">
            <div className="account-sidebar">
              <UserSidebar />
            </div>
            <div className="account-sidebar-right">
              {loading ? (
                [1, 2, 3].map((item) => <Skeleton active key={item} />)
              ) : (
                <div className="m_sidebar_bg">
                  <div className="title_cls">
                    <h3 className="heading_h3">Notification</h3>
                    {notificationList?.length > 0 && (
                      <div className="view_all notification-dropdown">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <img src={Dots} alt="" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleReadAll("All")}>
                              Mark As Read All
                            </Dropdown.Item>
                            {/* <Dropdown.Item onClick={handleUnreadAll}>
                          Mark As Unread All
                        </Dropdown.Item> */}
                            <Dropdown.Item onClick={handleDeleteAll}>
                              Delete All
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </div>

                  <div className="notification_cls">
                    {notificationList?.length > 0 ? (
                      notificationList?.map((notify, idx) => (
                        <div
                          className={
                            notify?.is_read === false
                              ? "notii_space"
                              : "noti_space"
                          }
                          key={idx}
                        >
                          {notify?.img !== null && (
                            <img
                              src={`${IMAGE_PATH + notify?.img}`}
                              className="notifyImg"
                              alt="Notification Image"
                            ></img>
                          )}
                          {notify.table_name === "user" ? (
                            <p
                              className="cursor-pointer"
                              onClick={() => {
                                handleReadAll(notify?.id);
                                // navigate("/my-account");
                              }}
                            >
                              {notify.description}
                            </p>
                          ) : notify.table_name === "order" ? (
                            <p
                              className="cursor-pointer"
                              onClick={() => {
                                handleReadAll(notify?.id);
                                navigate("/my-orders");
                              }}
                            >
                              {notify.description}
                            </p>
                          ) : (
                            <p>{notify.description}</p>
                          )}
                          <div className="time_remove">
                            {notify?.user_type === "3" && (
                              <div className="d-flex align-items-center justify-content-end">
                                <Button
                                  className="btn_primary"
                                  onClick={() => {
                                    notify?.table_id?.length > 0
                                      ? navigate(
                                          `/payment-gateway/${notify.table_id}`,
                                          {
                                            state: {
                                              amount: notify.orderAmount,
                                              notifyId: notify.id,
                                            },
                                          }
                                        )
                                      : toast.error("Order no. not valid");
                                  }}
                                >
                                  Pay Now
                                </Button>
                              </div>
                            )}
                            {notify?.user_type === "4" && (
                              <div className="d-flex align-items-center justify-content-end gap-2">
                                <Button
                                  className="btn_primary"
                                  onClick={() =>
                                    updateOrderStatus(
                                      notify?.table_id,
                                      "Yes",
                                      `${notify?.id}`
                                    )
                                  }
                                >
                                  Yes
                                </Button>
                                <Button
                                  className="btn_black"
                                  onClick={() =>
                                    updateOrderStatus(
                                      notify?.table_id,
                                      "NO",
                                      `${notify?.id}`
                                    )
                                  }
                                >
                                  No
                                </Button>
                              </div>
                            )}
                            <div className="noti_time">
                              <span
                                className="mx-2 cursor-pointer"
                                onClick={() => confirmDelete(notify.id)}
                              >
                                <img src={DeleteIcon} />
                              </span>
                              <span className="mx-2">
                                {timeFormate(notify.createdAt)}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="noti_space">
                        <p>No Notification Found!</p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Notification;

import React, { useState, useEffect } from "react";
import apiPath from "../../constants/apiPath";
import Http from "../../services/Http";
import { IMAGE_PATH } from "../../constants/apiPath";
import OwlCarousel from "react-owl-carousel3";
import ArtisansNotFound from "../Component/modalComponents/ArtisansNotFound";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";

const BookOffline = () => {
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState();
  const [showArtisans, setShowArtisans] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [catID, setCatID] = useState();
  const [selected, setSelected] = useState();
  let lang = localStorage.getItem("lang");
  const getGetCategories = async () => {
    let path = apiPath.categoryList;
    let options = {
      method: "GET",
    };
    try {
      let res = await Http(
        path + "?type=Offline" + `&search=${search}`,
        options
      );
      if (res.status == 200) {
        setCategories(res.data.result);
        console.log(res.data.result.allCategory);
        console.log(res.data.result.trandingCategory);
        console.log(res.data.result.softCategory);
        console.log(res.data.result.HeavyCategory);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onArtisansAvailable = (id) => {
    // catID = id;
    console.log(id, "idd");

    setShowArtisans(true);
    // alert(showArtisans);
    setCatID(id);
    console.log(catID);
  };

  useEffect(() => {
    setIsLoading(true);
    getGetCategories();
  }, [showArtisans, search === ""]);

  const item = [];
  const TrendingCategory = {
    rtl: true,
    margin: 15,
    nav: true,
    dots: false,
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      420: {
        items: 1,
      },
      577: {
        items: 3,
      },
      991: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };
  if (!categories) return null;
  console.log(catID, "cat");
  return (
    <>
      {showArtisans ? <ArtisansNotFound categoryId={catID} /> : ""}
      <section className="search-sec">
        <Container>
          <div className="search_cls">
            <div className="search_inner_cls">
              <div className="search_form_cls">
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <div className="input_group">
                    <Form.Control
                      type="text"
                      placeholder={t("Searchbyname")}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />

                    <div className="search-icon">
                      <svg
                        id="Icon-Search"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.064"
                        height="17.1"
                        viewBox="0 0 17.064 17.1"
                        onClick={() => getGetCategories()}
                      >
                        <path
                          id="Fill-1"
                          d="M-14.809-207.919a7,7,0,0,1-6.991-6.991,7,7,0,0,1,6.991-6.991,6.977,6.977,0,0,1,6.991,6.991,7.023,7.023,0,0,1-6.991,6.991Zm0-12.941a6,6,0,0,0-5.987,5.987,6,6,0,0,0,5.987,5.987,6,6,0,0,0,5.987-5.987,6.044,6.044,0,0,0-5.987-5.987Z"
                          transform="translate(21.8 221.9)"
                          fill="#fff"
                        />
                        <path
                          id="Fill-2"
                          d="M14.975-184.272l-4.875-4.84.789-.789,4.84,4.875-.753.753"
                          transform="translate(1.336 201.372)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="category_sec">
        <Container>
          {isLoading ? (
            [1, 2, 3].map((item) => <Skeleton active key={item} />)
          ) : (
            <div className="category-main-div">
              {categories?.allCategory?.map((item, idx) => (
                <div className="category_col" key={idx}>
                  {" "}
                  <Link
                    onClick={() =>
                      parseInt(item.is_artisan) === 0
                        ? onArtisansAvailable(item.id)
                        : ""
                    }
                    to={
                      item.is_artisan !== 0
                        ? `/offline-service-list/${item.id}`
                        : ""
                    }
                    state={{
                      category_id: item.id,
                    }}
                  >
                    <div className="category-inner">
                      <div className="category-img">
                        <img src={`${IMAGE_PATH + item.icon}`} alt="" />
                      </div>
                      <p>{lang == "en" ? item?.name : item?.fr_name}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </Container>
        {/* <div className="trending_category_bg">
          <Container>
            <div className="title_cls">
              <h3 className="heading_h3">Top Trending Categories</h3>
            </div>
            <OwlCarousel
              className="owl-carousel owl-theme"
              {...TrendingCategory}
            >
              {categories.trandingCategory.map((item) => (
                <div className="item">
                  <Link
                    onClick={() =>
                      item.is_artisan === 0 ? onArtisansAvailable(item.id) : ""
                    }
                    to={
                      item.is_artisan !== 0
                        ? `/offline-service-list/${item.id}`
                        : ""
                    }
                    state={{
                      category_id: item.id,
                    }}
                  >
                    <div className="trending_cate">
                      <div className="topTranding-img-block">
                        <img
                          src={`${IMAGE_PATH + item.image}`}
                          alt=""
                          className=" rounded-3 topTranding-img"
                        />
                      </div>

                      <span className=" m-auto">{item.name}</span>
                    </div>
                  </Link>
                </div>
              ))}
            </OwlCarousel>
          </Container>
        </div>
        <div className="soft-category">
          <Container>
            <div className="title_cls">
              <h3 className="heading_h3">Soft Categories</h3>
            </div>
            <div className="category-main-div ">
              {categories.softCategory.map((item) => (
                <div className="category_col">
                  <Link
                    onClick={() =>
                      item.is_artisan === 0 ? onArtisansAvailable(item.id) : ""
                    }
                    to={
                      item.is_artisan !== 0
                        ? `/offline-service-list/${item.id}`
                        : ""
                    }
                    state={{
                      category_id: item.id,
                    }}
                  >
                    <div className="category-inner">
                      <div className="category-img">
                        <img src={`${IMAGE_PATH + item.icon}`} alt="" />
                      </div>

                      <p>{item.name}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </Container>
        </div> */}
        {/* <div className="heavy-category">
          <Container>
            <div className="title_cls">
              <h3 className="heading_h3">Heavy Categories</h3>
            </div>
            <div className="category-main-div ">
              {categories.HeavyCategory.map((item) => (
                <div className="category_col">
                  {" "}
                  <Link
                    onClick={() =>
                      item.is_artisan === 0 ? onArtisansAvailable(item.id) : ""
                    }
                    to={item.is_artisan !== 0 ? "#" : "#"}
                    state={{
                      category_id: item.id,
                    }}
                  >
                    <div className="category-inner">
                      <div className="category-img">
                        <img src={`${IMAGE_PATH + item.icon}`} alt="" />
                      </div>
                      <p>{item.name}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </Container>
        </div> */}
      </section>
    </>
  );
};

export default BookOffline;

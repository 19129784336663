import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Toast } from "react-bootstrap";

import Locate from "../../../assets/img/locate_ic.png"; //../assets/img/locate_ic.png
import mapStyle from "../../../assets/mapStyle";
import { toast, Toaster } from "react-hot-toast";
import apiPath from "../../../constants/apiPath";
import Http from "../../../services/Http";
import { useLocation } from "react-router-dom";
import Imgcross from "../../../assets/img/cross.png";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import DrawMap from "./DrawMap";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
// const center = { lat: 48.8584, lng: 2.2945 };
const libraries = ["places"];
const AddNewAddress = ({ onOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const [map, setMap] = useState(/**@type google.maps.Map */ (null));
  const [jobLocationRadio, setJobLocationRadio] = useState("artisansPlace");
  const [userLocationRadio, setUserLocationRadio] = useState("home");
  const [buildingNum, setBuildingNum] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [streetAdd, setStreetAdd] = useState("");
  const [address, setAddress] = useState("");
  const [myLatLng, setMyLatLng] = useState({ lat: 48.8584, lng: 2.2945 });
  const [coordinates, setCoordinates] = useState({
    lat: 26.9124,
    lng: 75.7873,
  });
  const [postalCode, setPostalCode] = useState("");
  const [errmsg1, seterrmsg1] = useState();
  const [errmsg2, seterrmsg2] = useState();

  const handleSelect = async (value) => {
    console.log(value, "ll");
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log("handleSelect", ll);

    const formattedAddress = results[0]?.formatted_address;
    const postalCodeRegex = /(\d{6})[\s-]?(\d{4})?/;

    const match = formattedAddress.match(postalCodeRegex);

    const pinCode = match ? match[0] : "";
    console.log("postalCode", postalCode);
    setPostalCode(pinCode);

    setAddress(value);
    setCoordinates(ll);
  };
  const center = { coordinates };

  const location = useLocation();
  const category_id = location.state;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setMyLatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);

  const onJobLocationSelect = (e) => {
    setJobLocationRadio(e.target.value);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries,
  });
  if (!isLoaded) {
    return <h2>Loading maps</h2>;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    let path = apiPath.addAddress;
    var formData = new FormData();
    formData.append("type", "Own Place");
    formData.append("house_number", buildingNum);
    formData.append("address", address);
    formData.append("latitude", coordinates.lat);
    formData.append("longitude", coordinates.lng);
    formData.append("is_default", "0");
    formData.append("tag", userLocationRadio);
    formData.append("pincode", postalCode);

    let options = {
      method: "POST",
      data: formData,
    };

    if (buildingNum && address !== "") {
      try {
        let res = await Http(path, options);
        console.log(res, "-------------------------");
        if (res.data.success === true) {
          toast.success(res?.data?.msg);
          setTimeout(() => {
            onClose();
          }, 1000);
        } else {
          toast.error(res.data.msg);
        }
      } catch (err) {
        console.log(err.res);
      }
    } else {
      seterrmsg1("building name required");
      seterrmsg2("Street address required");
    }
  };
  const onPickupPlaces = (address, address_components, lat, lng) => {
    if (address !== undefined) {
      this.setState({
        current_location: address,
      });
    }
    if (lat !== undefined) {
      this.setState({
        current_lat: lat,
      });
    }
    if (lng !== undefined) {
      this.setState({
        current_lng: lng,
      });
    }
  };
  const handleChange = (event) => {
    console.log(event.target.value);
    setUserLocationRadio(event.target.value);
  };
  return (
    <div>
      <Toaster position="top-right" />
      <Modal
        show={onOpen}
        onHide={onClose}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalAddress"
        centered
      >
        <Modal.Header>
          <h5 id="exampleModalLabel">{t("AddAddress")}</h5>
          <button type="button" className="btn-close" onClick={onClose}>
            <img src={Imgcross} onClick={onClose} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="your_location_wrap">
            <h5>{t("YourLocation")}</h5>
            <form>
              <div className="radio_cls">
                <label className="custom_radio">
                  <input
                    type="radio"
                    id="home"
                    name="userLocation"
                    value="home"
                    checked={userLocationRadio === "home"}
                    onChange={handleChange}
                  />
                  {t("nav_home")}
                  <span className="checkmark"></span>
                </label>
                <label className="custom_radio">
                  <input
                    type="radio"
                    id="office"
                    name="userLocation"
                    value="office"
                    checked={userLocationRadio === "office"}
                    onChange={handleChange}
                  />
                  {t("Office")}
                  <span className="checkmark"></span>
                </label>
                <label className="custom_radio">
                  <input
                    type="radio"
                    id="other"
                    name="userLocation"
                    value="other"
                    checked={userLocationRadio === "other"}
                    onChange={handleChange}
                  />
                  {t("Other")}
                  <span className="checkmark"></span>
                </label>
              </div>
            </form>
          </div>
          <div className="addressDetails">
            <Row>
              <Col md="6">
                <Form.Group className="form-group">
                  <label htmlFor="">{t("BuildingName&Number")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={buildingNum}
                    onChange={(e) => setBuildingNum(e.target.value)}
                  />
                  <span className="error_msg">{errmsg1}</span>
                </Form.Group>
              </Col>
              <Col md="6">
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <Form.Group className="form-group">
                        <label htmlFor="">{t("StreetAddress")}</label>
                        <input
                          {...getInputProps({
                            placeholder: "Search Places ...",
                            className: "form-control",
                          })}
                        />
                        <span className="error_msg">{errmsg2}</span>
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Group>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>
          </div>
          <div className="orSection">
            <span className="orSectionLine">OR</span>
          </div>
          <div className="mapPinLocation">
            <div className="map-title">
              <h5>{t("PinyourLocation")}</h5>
              <h6 onClick={() => map?.panTo(myLatLng)}>
                <span className="locate_icon">
                  <img src={Locate} />
                </span>
                <span>{t("Locateyourself")}</span>
              </h6>
            </div>
            <div className="map_card">
              <GoogleMap
                center={coordinates}
                zoom={20}
                mapContainerClassName="googleMap"
                onLoad={(map) => setMap(map)}
                options={{ styles: mapStyle.light }}
              >
                <Marker position={coordinates} />
              </GoogleMap>
            </div>
          </div>

          <Button className="btn_primary" onClick={onSubmit}>
            {t("Submit")}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddNewAddress;

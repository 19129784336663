import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import ReactPhoneInput from "react-phone-input-2";
import toast, { Toaster } from "react-hot-toast";
import Imgcross from "../../../assets/img/cross.png";
import Facebook from "../../../assets/img/facebook.png";
import Google from "../../../assets/img/google.png";
import "react-phone-input-2/lib/style.css";
import Button from "react-bootstrap/Button";
import Signup from "./Signup";
import Http from "../../../services/Http";
import apiPath from "../../../constants/apiPath";
import Form from "react-bootstrap/Form";
import AuthContext from "../../../context/ProductContext";
import ForgetPassword from "./ForgetPassword";
import { Col, Row } from "react-bootstrap";
import FacebookLogin from "react-facebook-login";
import { useTranslation } from "react-i18next";
// import {
//   FacebookAuthProvider,
//   GoogleAuthProvider,
//   signInWithPopup,
// } from "firebase/auth";
// import { auth, provider, googleProvider } from "../../../firebase/firebase";
// import { firebaseConfig } from "../../../firebase/firebaseConfig";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { initializeApp } from "firebase/app";

const Login = ({
  showLogin,
  hide,
  showSignup,
  showForget,
  userLogin,
  onClose,
  show,
}) => {
  const { t, i18n } = useTranslation();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [picture, setPicture] = useState("");
  const [getLogin, setLogin] = useState({});
  const [mobileError, setMobileError] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [getSignup, setSignup] = useState({});
  const [mobileNumber, setMobilenumber] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  // const [fcmToken, setFcmToken] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const storedMob = localStorage.getItem("mob");
    const storedCc = localStorage.getItem("cc");
    const storedPwd = localStorage.getItem("pwd");
    const storedRememberMe = localStorage.getItem("remember");
    if (storedRememberMe === "true" && storedMob && storedPwd && storedCc) {
      setSignup({ phone: storedMob });
      setMobilenumber(storedMob);
      setcountryCode(storedCc);
      setLogin({
        country_code: storedCc,
        mobile: storedMob,
      });
      setPassword(storedPwd);
      setRememberMe(storedRememberMe);
    }
  }, []);

  // console.log("data", data);

  // useEffect(() => {
  //   askForPermission();
  // }, []);

  // const askForPermission = async () => {
  //   const app = initializeApp(firebaseConfig);

  //   // Get Firebase messaging instance
  //   const messaging = getMessaging(app);

  //   // Request permission to receive notifications
  //   Notification.requestPermission()
  //     .then((permission) => {
  //       if (permission === "granted") {
  //         console.log("Notification permission granted.");

  //         // Get FCM token
  //         getToken(messaging, {
  //           vapidKey:
  //             "BLLdG9h5WrLrY5aPKL881CBET6SZAu8JKdkt8PXo7Wn8Em2KOsweAQCmFbAN546ye0e1reizzD8ZqErmZuGUaRM",
  //         })
  //           .then((token) => {
  //             console.log("FCM token:", token);
  //             setFcmToken(token);
  //           })
  //           .catch((error) => {
  //             console.log("Error getting FCM token:", error);
  //           });

  //         // Listen for incoming messages
  //         onMessage(messaging, (payload) => {
  //           console.log("Incoming message:", payload);
  //         });
  //       } else {
  //         console.log("Notification permission denied.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error requesting notification permission:", error);
  //     });
  // };

  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    var mob = value.slice(data.dialCode?.length);
    console.log("country_code", country_code, mob);
    setMobilenumber(mob);
    setcountryCode(country_code);
    setLogin({
      country_code: country_code,
      mobile: value.slice(data.dialCode?.length),
    });
    setSignup({ phone: value.slice(data.dailCode?.length) });
    setMobileError("");
  };

  // const closeSignup = async () => {
  //   setShowLogin(false);
  //   setShowSignup(false);
  // };

  // const handleFacebookLogin = () => {
  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       setUser(result.user);
  //       const { displayName, email, accessToken } = result?.user;
  //       const cred = FacebookAuthProvider.credentialFromResult(result);
  //       // const fcmToken = cred.accessToken;
  //       handleSocialLogin("Facebook", email, displayName, accessToken);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleGoogleLogin = () => {
  //   signInWithPopup(auth, googleProvider)
  //     .then((result) => {
  //       setUser(result.user);
  //       const { displayName, email, accessToken } = result?.user;
  //       const cred = GoogleAuthProvider.credentialFromResult(result);
  //       const getFcmToken = cred.accessToken;
  //       handleSocialLogin("Google", email, displayName, accessToken);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleSocialLogin = async (method, userEmail, userName, userToken) => {
  //   const path = apiPath.socialLoginSignup;
  //   const data = {
  //     type: `${method}` ? `${method}` : "",
  //     email: `${userEmail}` ? `${userEmail}` : "",
  //     name: `${userName}` ? `${userName}` : "",
  //     social_id: `${userToken}` ? `${userToken}` : "",
  //     // fcm_token: `${fcmToken}` ? `${fcmToken}` : null,
  //   };
  //   const options = { method: "POST", data };
  //   try {
  //     let res = await Http(path, options);
  //     if (res?.data?.success === true) {
  //       toast.success(res?.data?.msg);
  //       console.log(res?.data?.result);
  //       setShowLogin(false);
  //       navigate("/order");
  //       // setIsLoggedIn(true);
  //       const { token } = res?.data?.result;
  //       localStorage.setItem("guestToken", "");
  //       localStorage.removeItem("guestToken");
  //       localStorage.setItem("userToken", token);
  //       localStorage.setItem("isLoggedIn", "1");
  //       userLogin(res.data.formData);
  //     } else {
  //       toast.error(res?.data?.msg);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // console.log("auth_user", user?.displayName);

  const validateMobileNumber = (values) => {
    console.log("val", values);
    const errors = {};
    if (!values?.mobile?.length) {
      errors.mobile = "Mobile Number is required";
    } else if (values?.mobile?.length < 8) {
      errors.mobile = "Mobile Number must be valid.";
    } else {
      console.log("Type Mobile number", values);
    }
    return errors;
  };

  const validatePassword = (values) => {
    console.log("val", values);
    const errors = {};
    if (!values.password) {
      errors.password = "Password is required";
      return errors;
    } else {
      console.log("Type Passeord number", values);
    }
    return errors;
  };

  useEffect(() => {}, [mobileNumber, password]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setMobileError(validateMobileNumber(getLogin));
    setPasswordError(validatePassword(password));
    setIsSubmit(true);
    if (getLogin?.mobile?.length > 7 && password?.length > 1) {
      setPasswordError("");
      let path = apiPath.login;
      var formData = new FormData();
      let mobData = getSignup?.phone?.slice(2);
      formData.append("type", "mobile");
      formData.append("mobile", mobData);
      formData.append("password", password);
      formData.append("country_code", "+" + countryCode);
      formData.append("user_type", "2");
      formData.append("fcm_token", null);

      let options = {
        method: "POST",
        data: formData,
      };

      if (rememberMe) {
        localStorage.setItem("mob", getSignup.phone);
        localStorage.setItem("cc", getLogin?.country_code);
        localStorage.setItem("pwd", password);
        localStorage.setItem("remember", rememberMe);
      } else {
        localStorage.removeItem("mob");
        localStorage.removeItem("cc");
        localStorage.removeItem("pwd");
        localStorage.removeItem("remember");
      }

      try {
        let res = await Http(path, options);
        console.log(res.data);
        if (res.data.success === true) {
          // window.location.reload();
          toast.success(res.data.msg);
          hide();
          navigate("/order");
          // setIsLoggedIn(true);
          const { token, result } = res.data;
          const { password, ...rest } = result;
          localStorage.removeItem("guestToken");
          localStorage.setItem("userToken", token);
          localStorage.setItem("isLoggedIn", "1");
          localStorage.setItem("userData", JSON.stringify(rest));
          // userLogin(res.data.formData);
          // alert("hi");
        } else {
          console.log(res.data.msg);
          toast.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
        // if (err.response) {
        //   toast.error("");
        // } else if (err.response?.status === 400) {
        //   toast.error("Missing Username or Password");
        // } else if (err.response?.status === 401) {
        //   toast.error("Unauthorized");
        // } else {
        //   toast.error(err.response);
        // }
      }
    }
  };

  const responseFacebook = (response) => {
    console.log(response);
    setLoginData(response);
    setPicture(response.picture.data.url);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  const componentClicked = (response) => {
    console.log(response);
  };

  console.log(mobileNumber, getLogin?.mobile, getSignup, password);
  console.log("rem", rememberMe);
  console.log("mobile", getSignup.phone);

  // if (getLogin.country_code === "91") {
  //   setLogin({ ...getLogin, country_code: "in" });
  // }

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        show={showLogin}
        onHide={hide}
        animation={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        dialogClassName="modalStyle"
        centered
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {t("Login")}
          </h5>
          <button type="button" className="btn-close" onClick={hide}>
            <img src={Imgcross} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_cont">
            <h4>{t("Hi")},</h4>
            <h5>{t("LoginAccount")}</h5>
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p> */}
          </div>
          <Form onSubmit={handleLogin}>
            <div className="popup-cont">
              <Form.Group className="form-group" controlId="form-group">
                <Form.Label>{t("EnterMobileNumber")}</Form.Label>
                <ReactPhoneInput
                  enableSearch={true}
                  inputProps={{
                    name: "mobile",
                    required: true,
                    autoFocus: false,
                  }}
                  isValid={(value, country) => {
                    if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else {
                      return true;
                    }
                  }}
                  country={"fr"}
                  value={getSignup.phone}
                  onChange={handleChange}
                  placeholder="Enter Mobile Number"
                />
                {mobileError.mobile && (
                  <span className="error_msg">{mobileError.mobile}</span>
                )}
              </Form.Group>
              <Form.Group className="form-group pw-show">
                <Form.Label>{t("EnterPassword")}</Form.Label>
                <Form.Control
                  type={isRevealPwd ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                />
                <button
                  type="button"
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  className="btn-dark"
                >
                  {isRevealPwd ? t("show") : t("hide")}
                </button>
                <span className="error_msg">{passwordError.password}</span>
              </Form.Group>
              <div className="remember_me_sec">
                <div className="custom_checkbox" htmlFor="rememberme">
                  <Form.Check
                    id="rememberme"
                    name="remember_me"
                    size={18}
                    label={t("RememberMe")}
                    checked={rememberMe}
                    onChange={(e) => {
                      setRememberMe(e.target.checked);
                    }}
                  />
                </div>
              </div>

              <div className="login-btn">
                <Button type="submit" className="btn btn_primary">
                  {t("SignIn")}
                </Button>
              </div>
              <div className="forgot_pw">
                <Link to="#" onClick={showForget}>
                  {t("ForgotPassword")} ?
                </Link>
              </div>
              <div className="social-login">
                {/* <Button>
                  <FacebookLogin
                    appId="920042829271854"
                    autoLoad={true}
                    fields="name,email,picture"
                    onClick={componentClicked}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button onClick={renderProps.onClick}>
                        Login with Facebook
                      </button>
                    )}
                  />
                </Button> */}
                {/* <Link to="#">
                  <img src={Facebook} onClick={handleFacebookLogin} />
                </Link>
                <Link to="#">
                  <img src={Google} onClick={handleGoogleLogin} />
                </Link> */}
              </div>
              <div className="already_cls">
                <p>
                  {t("Ifdonthaveanaccount")}
                  <Link to="#" onClick={showSignup}>
                    {t("SignUp")}
                  </Link>{" "}
                </p>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import OurMission from "../assets/img/about.png";
import Http from "../services/Http";
import apiPath from "../constants/apiPath";
import { Toaster, toast } from "react-hot-toast";
const Faq = () => {
  const [content, setContent] = useState();
  useEffect(() => {
    getContent();
  }, []);
  const getContent = async () => {
    const path = apiPath.staticPages;
    const data = { slug: "faq" };
    const options = { method: "POST", data };
    try {
      let res = await Http(path, options);
      if (res?.data?.success === true) {
        toast.success(res?.data?.msg);
        setContent(res?.data?.pageData?.description);
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <section className="aboutus-main">
      <Toaster position="top-right" />
      <Container>
        <div className="aboutinner-page">
          {/* <h5>Privacy Policy</h5> */}
          <p dangerouslySetInnerHTML={{ __html: content ? content : "" }}></p>
          {/* <p>
            {" "}
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum is simply dummy text
            of the printing and typesetting industry. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum is
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum is simply dummy text of the printing and typesetting industry.
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p> */}
          {/* <div className="our-mission">
              <Row>
                <Col md="6">
                  <div className="mission-img">
                    <img src={OurMission} alt="" />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mission-cont">
                    <h4>Our Mission</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum is simply dummy text of
                      the printing and typesetting industry. Lorem Ipsum is
                      simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry. Lorem Ipsum is simply dummy text
                      of the printing and typesetting industry. Lorem Ipsum is
                      simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry. Lorem Ipsum is simply dummy text
                      of the printing and typesetting industry.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="how-we-work-sec">
              <h5>How We Works?</h5>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>1. Lorem Ipsum</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>2. Lorem Ipsum</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    3. Lorem ipsum dolor sit amet
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div> */}
        </div>
      </Container>
    </section>
  );
};

export default Faq;
